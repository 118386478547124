@import "variables.scss";

:local {
  .ssoButtons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.6rem;

    &.longTitle {
      grid-template-columns: 1fr;
    }

    &.column {
      flex-direction: column;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    button {
      height: 40px;
      font-size: 0.9rem;
      position: relative;
      color: black;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }

      svg {
        position: absolute;
        left: 0.8rem;
      }

      @media (max-width: 600px) {
        // text-indent: 0.4rem;

        // svg {
        //   position: relative;
        //   left: initial;
        // }
      }
    }
  }
}
