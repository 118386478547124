@import 'variables.scss';

:local {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      background: white;

      .buttonWrapper {
        width: 35px;
        height: 30px;
        border-top: 1px solid $grey5;
        border-bottom: 1px solid $grey5;
        border-right: 1px solid $grey5;

        button {
          width: 100%;
          height: 100%;
          font-size: 14px;
        }

        &:first-child {
          width: auto;
          padding: 0 0.8rem;
          border-left: 1px solid $grey5;
          border-top-left-radius: $bradius;
          border-bottom-left-radius: $bradius;
          background: white;
        }

        &:last-child {
          width: auto;
          padding: 0 0.8rem;
          border-top-right-radius: $bradius;
          border-bottom-right-radius: $bradius;
        }

        &.active {
          background: rgba($grey5, 0.5);

          button {
            font-weight: 500;
          }
        }
      }
    }
  }
}
