@import 'variables.scss';

:local {
  .header {
    width: 100%;
    border-bottom: 1px solid $grey5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;
    position: fixed;
    background: white;
    z-index: 999;

    .title {
      padding-left: 2rem;

      h5 {
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 0.4rem;
      }

      p {
        font-size: 14px;
        line-height: 14px;
        color: $grey1;
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 2rem;

      a {
        border: none;
        padding: 0;
        margin-left: 0.6rem;
      }
    }

    .progressLine {
      height: 2px;
      background: $primary;
      position: absolute;
      bottom: 0;

      &.block-1 {
        width: 33%;
      }

      &.block-2 {
        width: 66%;
      }

      &.block-3 {
        width: 100%;
      }
    }
  }

  .main {
    .titleRow {
      margin: 3rem 0;
      margin-top: 6rem;
      max-width: 60%;

      p {
        font-size: 1rem;
        font-weight: 300;
        color: $darkgrey;
        margin-top: 0.6rem;
      }
    }

    .surveyWrapper {
      max-width: $wrapperWidth;
      width: 100%;
      margin: 2rem auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .droppableLeft {
        border-radius: $bradius3;
        width: 375px;
        min-height: 624px;
        height: 100%;

        .inner {
          display: flex;
          flex-direction: column;
          background: $grey6;
          width: 100%;
          min-height: 624px;
          height: 100%;
          padding: 1rem;
          border-radius: $bradius3;

          &.isDraggingOver {
            background: $grey5;
          }

          .draggable {
            &.dragging {
              .desc {
                display: none;
              }
              .draggableCard {
                height: 52px;
              }
            }
          }

          .draggableCard {
            height: 88px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            padding-left: 1.5rem;
            border-radius: $bradius3;
            border: 1px solid $grey5;
            background: white;
            padding: 1.3rem;
            margin-bottom: 1rem;
            box-shadow: 0px 2px 2px 0px #00000008;
            &:hover {
              box-shadow: 0px 22px 24px 0px #99999918;
            }

            .desc {
              margin-top: 0.2rem;
              font-size: 14px;
              line-height: 18px;
              font-weight: 400;
              color: $grey1;
            }
          }
        }

        .thatsit {
          height: 580px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            text-align: center;
            color: $grey1;
            margin-bottom: 0.6rem;
          }
        }
      }

      @keyframes bounce {
        0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(-5px);
        }
        100% {
          transform: translateX(0);
        }
      }

      @keyframes fill {
        0% {
          stroke: $grey2;
        }
        50% {
          stroke: $grey3;
        }
        100% {
          stroke: $grey2;
        }
      }

      .middle {
        margin: 17rem 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          margin-bottom: 1rem;
          animation-name: bounce;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;

          svg path {
            animation-name: fill;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
          }
        }

        p {
          text-align: center;
          width: 100%;
          color: $grey1;
          font-weight: 300;
          font-size: 14px;
        }
      }

      .droppableRight {
        width: 100%;
        width: 375px;
        min-height: 645px;
        display: flex;
        flex-direction: column;
        position: relative;

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0 1rem;

          .bgslot {
            position: relative;
            left: -3px;
            top: -3px;
            width: 102%;
            height: 59.2px;
            border-radius: $bradius3;
            margin-bottom: 0.55rem;

            &:first-child {
              background: #a8e9cb;
            }
            &:nth-child(2) {
              background: #bce9d5;
            }
            &:nth-child(3) {
              background: #e2f4ec;
            }
            &:nth-child(4) {
              background: #f0f6f3;
            }
            &:nth-child(5) {
              background: #f5f9f7;
            }
            &:nth-child(6) {
              background: #f9f9f9;
            }
            &:nth-child(7) {
              background: #f5f5f5;
            }
            &:nth-child(8) {
              background: #ececec;
            }
            &:nth-child(9) {
              background: #dddddd;
            }
            &:nth-child(10) {
              background: #c5c5c5;
            }
            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              border-radius: $bradius3;
              height: 100%;
              width: 100%;
              background-color: $grey3;
              opacity: 0;
            }

            &.hover {
              &:after {
                opacity: 0.5;
              }
            }
          }
        }

        .inner {
          display: flex;
          flex-direction: column;
          width: 100%;
          min-height: 624px;
          height: 100%;
          padding: 0 1rem;
          border-radius: $bradius3;

          &.isDraggingOver {
            transform: none !important;
          }

          .draggableCardWrapper {
            height: 52px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            padding-left: 1.5rem;
            border-radius: $bradius3;
            background: white;
            padding: 1.3rem;
            margin-bottom: 1rem;
            box-shadow: 0px 2px 2px 0px #00000008;
            border: 1px solid $grey6;

            &:hover {
              box-shadow: 0px 22px 24px 0px #99999918;
            }
            &.invisible {
              opacity: 0;
              background: transparent;
              // height: 50px;
            }
            &.noTransition {
              transform: none !important;
            }

            &.dragging {
              box-shadow: 0px 22px 24px 0px #99999918;
            }

            .dragIcon {
              opacity: 0;
              position: absolute;
              top: 1rem;
              right: 1rem;
            }
          }
        }

        .legends {
          position: absolute;
          height: 680px;
          top: -7px;
          right: -6rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 1.3rem 0;

          p {
            font-weight: 500;
            color: $darkgrey;
          }
        }
      }
    }
  }
}
