@import "variables.scss";

:local {
  .interfaceWrapper {
    right: 0;
    position: fixed;
    top: 0;
    transform: translateY(0%) translateX(0%);
    transform-origin: 0% 20%;
    height: 100svh;
    width: 100%;
    max-width: 400px;
    max-height: 100vh;
    background-color: white;
    z-index: 9000;
    border: 0.5px solid var(--greys-grey-4, $grey4);
    border-top: 0;
    border-bottom: 0;
    background: var(--primary-white, $white);
    overflow: hidden;
    padding-top: 70px;
    transition: 200ms ease-in-out;

    @media (max-width: 1400px) {
      position: relative;
      padding: 0;
      border: 0;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    @media (min-width: 2000px) {
      max-width: 500px;
    }

    @media (min-width: 2300px) {
      max-width: 600px;
    }

    .collapseBtn {
      position: absolute;
      top: calc(50% - 13px);
      left: -12px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      box-shadow: 0px 22px 24px rgba(153, 153, 153, 0.0957314),
        0px 0px 5px rgba(152, 152, 152, 0.115);
      transition: $transition;
      z-index: 9000;

      button {
        width: 100%;
        height: 100%;
      }

      &:hover {
        background: rgba($primary, 0.9);
      }

      svg {
        transform: rotate(180deg);

        path {
          stroke: white;
        }
      }

      &.collapsed {
        svg {
          transform: rotate(0deg);
        }
      }
    }

    .promptWrapper {
      margin-top: auto;
      width: 100%;
      position: relative;

      .drawerButton {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: -14px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey4;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        margin: 0 auto;

        button {
          height: 100%;
          width: 100%;
        }

        .icon {
          transform: rotate(-90deg);

          svg path {
            stroke: $darkgrey;
          }

          &.open {
            transform: rotate(90deg);
          }
        }
      }

      .examplePrompts {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        // margin-bottom: 0.6rem;
        max-width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: 200ms ease-in-out;

        &.open {
          max-height: 270px;
          padding-top: 1rem;
        }

        @media (max-width: 960px) {
          justify-content: flex-start;
          overflow: auto;
          white-space: nowrap;
          flex-wrap: nowrap;

          &.open {
            padding-top: 0.4rem;
            padding-bottom: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
          }

          &::-webkit-scrollbar {
            height: 3px;
            background: rgba($grey5, 0.4);
          }

          &::-webkit-scrollbar-thumb {
            background: $grey4;
            border-radius: 2px;
          }
        }

        button {
          margin: 0.3rem;
          padding: 5px 16px;
          border-radius: 50px;
          border-radius: 32px;
          border: 0.5px solid var(--light-med-purple, #d7c7f2);
          background: var(--light-light-purple, $lightpurple);
          transition: 200ms ease-in-out;

          p {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: var(--secondary-purple, $secondarypurple);
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.6rem;

      .icon {
        width: 24px;
        height: 24px;
        border-radius: $bradius2;
        background-color: $lightpurple;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.8rem;

        svg path {
          fill: $purple;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
      background: rgba($grey5, 0.4);
    }

    &::-webkit-scrollbar-thumb {
      background: $grey4;
      border-radius: 2px;
    }

    @media (max-width: 960px) {
      height: 100%;
      max-height: none;
      z-index: 999999;
    }
  }

  .interface {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media (max-width: 1400px) {
      padding: 0;
      max-height: calc(100vh - 326px);
    }

    .upperPart {
      min-height: 50px;
      position: relative;
      height: 100%;
      margin-top: 2rem;
      // overflow: auto;

      @media (max-width: 1400px) {
        margin-top: 0;
      }
    }

    .messagesWrapper {
      width: 100%;
      height: 100%;
      flex: 1;
      overflow: auto;
      position: relative;
      padding-right: 1rem;
      transition: $transition;

      @media (max-width: 1400px) {
        height: 100%;
      }

      @media (max-width: 1400px) {
        height: 100%;
        max-height: 500px;
        min-height: 400px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background: rgba($grey5, 0.4);
      }

      &::-webkit-scrollbar-thumb {
        background: $grey4;
        border-radius: 2px;
      }

      &.examplePromptsOpen {
        // padding-bottom: 180px;
      }

      .messageWrap {
        display: flex;
        margin-bottom: 2rem;
        gap: 1rem;
        align-items: flex-start;

        .message {
          p {
            font-size: 14px;
            line-height: 24px;
            font-weight: 300;
            color: $darkgrey;
            margin-bottom: 1rem;
          }

          ol,
          ul {
            margin-left: 1rem;
            font-size: 14px;
            line-height: 24px;
            font-weight: 300;
            color: $darkgrey;
            margin-bottom: 1rem;

            li {
              white-space: normal;
              padding-left: 0.4rem;
              font-size: 14px;
              line-height: 24px;
              font-weight: 300;
              color: $darkgrey;
              margin-bottom: 0.4rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          h1,
          h2,
          h3,
          h4 {
            font-size: 16px;
          }

          h5 {
            font-size: 14px;
          }

          ol li {
            list-style-type: decimal;
          }

          ul li {
            list-style-type: disc;
          }
        }

        &.user {
          .message {
            margin-left: auto;
            background-color: $secondarypurple;
            padding: 0.4rem 0.8rem;
            border-radius: 8px 8px 0px 8px;

            p {
              color: white;
              margin-bottom: 0;
            }
          }
        }
      }

      .retry {
        .timeout {
          max-width: 500px;
          border: 0.5px solid $grey4;
          border-radius: 8px 8px 8px 0px;
          background: var(--greys-grey-5, $grey6);
          padding: 10px 16px;

          color: $extradarkgrey;
          white-space: pre-wrap;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);

          font-size: 15px;
          line-height: 22px;
          color: $darkgrey;
        }
        button {
          margin: 1rem auto;
        }
      }
    }

    .promptWrapper {
      margin-top: 1rem;
      width: 100%;
      position: relative;

      @media (max-width: 1400px) {
        margin-top: 1rem;
      }

      .enlarged {
        padding: 0 3rem;

        @media (min-width: 480px) {
          padding: 0 6rem;
        }
      }

      .drawerButton {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: -6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey4;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        margin: 0 auto;
        transition: $transition;
        z-index: 2;

        button {
          height: 100%;
          width: 100%;
        }

        .icon {
          transform: rotate(-90deg);

          svg path {
            stroke: $darkgrey;
          }

          &.open {
            transform: rotate(90deg);
          }
        }

        // &.open {
        //   top: 16px;
        // }
      }

      .examplePrompts {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.6rem;
        max-width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: 200ms ease-in-out;
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(1px);

        &.open {
          max-height: 270px;
          padding-top: 2rem;
        }

        @media (max-width: 960px) {
          justify-content: flex-start;
          overflow: auto;
          white-space: nowrap;
          flex-wrap: nowrap;

          &.open {
            padding-top: 2rem;
            padding-bottom: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
          }

          &::-webkit-scrollbar {
            height: 3px;
            background: rgba($grey5, 0.4);
          }

          &::-webkit-scrollbar-thumb {
            background: $grey4;
            border-radius: 2px;
          }
        }

        button {
          margin: 0.3rem;
          padding: 5px 16px;
          border-radius: 50px;
          border-radius: 32px;
          border: 0.5px solid var(--light-med-purple, #d7c7f2);
          background: var(--light-light-purple, $lightpurple);
          transition: 200ms ease-in-out;

          p {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: var(--secondary-purple, $secondarypurple);
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      @media (max-width: 960px) {
        padding: 1rem 0;
        padding-bottom: 0;
      }

      form {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
      }
    }
  }
}
