* {
  box-sizing: border-box;
  // border: 1px solid red !important;
}
:root {
  --logo_url: url("~images/logo/default/logo.svg");
  --white_logo_url: url("~images/logo/default/logo-white.svg");
  --logo-header-width: 105px;
  --logo-header-height: 28px;
  --signup-logo-height: 42px;
}

#root {
  position: relative;
  // padding-bottom: 64px;
  min-height: 100vh;
  &.suspended {
    .profileNav {
      display: none;
    }
    #profileUpperButtons {
      display: none;
    }
    .headroom-wrapper {
      pointer-events: all;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fa-spinner-third {
  animation-name: spin;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

// Phone input
.PhoneInput {
  position: relative;
}

// .PhoneInputCountry {
//   position: absolute;
//   z-index: 1;
//   top: 16px;
//   left: 16px;
// }

// .PhoneInput input {
//   padding-left: 36px;
// }

.video-react .video-react-big-play-button.video-react-big-play-button-center {
  margin-top: -0.9em !important;
}

.video-react-big-play-button {
  border: none !important;
  height: 1.8em !important;
  line-height: 1.8em !important;
  transition: $transition;

  &:hover {
    background-color: $primary !important;
  }
}

div[data-slate-editor] {
  -webkit-user-modify: read-write !important;
}

.reactour__popover {
  border-radius: $bradius2;
}

.surveyHeaderMargin {
  margin-left: 25% !important;
}

@media (max-width: 500px) {
  .commentsPopupWrapper {
    .popup-overlay {
      background: rgba(0, 0, 0, 0.4);
    }
  }
}

.sc-ifAKCX:first-child {
  max-width: 366px;
  width: 100%;
}

.jrBYqH {
  justify-content: space-between;
}

.kWwJhA {
  opacity: 0.6;
  color: #484b58;
}

.eFNiMb {
  max-width: 500px;
}

.lfvGpo {
  visibility: hidden;
}

.avatarPopupWrapper {
  width: 100%;
  height: 100%;

  .popup-arrow {
    background: rgba(black, 1) !important;
  }
}

.dropdownWrapper {
  width: 100%;

  &.left {
    .popup-content {
      margin-left: 16px !important;
    }
  }

  &.right {
    .popup-content {
      margin-right: 16px !important;
    }
  }

  &.top {
    .popup-content {
      margin-top: 16px !important;
    }
  }

  &.bottom {
    .popup-content {
      margin-bottom: 16px !important;
    }
  }

  &.nested {
    .popup-content {
      margin-left: 0.5rem;
    }
  }

  &.orgDropdown {
    .popup-content {
      left: 63px !important;
      top: -5px !important;
      padding-top: 75px !important;
      width: 250px !important;
    }

    &.whitelabel {
      @media (min-width: 960px) {
        .popup-content {
          margin-top: 75px;
          left: 0 !important;
          width: 284px !important;
        }
      }
    }
  }
}

.defaultPopupWrapper {
  width: 100%;
  height: 100%;

  .popup-arrow {
    background: rgba(black, 0.8) !important;
  }
}

.popup-overlay {
  z-index: 9995;
  // background: rgba(0, 0, 0, 0.2);
  height: 100vh;
}

html {
  font-size: 100%;
  // overflow-x: hidden;

  &.disableHorizontalScroll {
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    padding: 0;
    background-color: white;
    // background-color: rgba($grey3, 0.1);
    font-family: proxima-nova;
    color: $black;
    overflow-x: hidden;
    overflow-y: overlay;

    &.forceDesktop {
      width: 1280px;
      overflow: auto;
    }

    .mainWrapper {
      padding: 0 1rem 6rem 1rem;
      min-opacity: 56vh;
    }
  }

  h1 {
    font-size: 3rem;
    line-height: 3.6rem;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: black;
  }

  h2 {
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: black;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2.1rem;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: black;
  }

  h4 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: black;
  }

  h5 {
    font-size: 15px;
    line-height: 1.4rem;
    font-weight: 500;
    color: black;
  }

  p {
    font-size: 15px;
    line-height: 1.6rem;
    font-weight: 400;
    margin: 0;
    letter-spacing: 0.2px;
  }

  .label {
    @extend p;
    color: black;
    font-size: 14px;
    font-family: proxima-nova;
    font-weight: 600;

    &.primary {
      color: $primary;
    }
  }

  .bold {
    font-weight: 600;
  }

  b,
  strong {
    font-weight: 700;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid $darkgrey;
    color: $darkgrey;

    &:hover {
      color: $black;
      border-color: $black;

      div[aria-label="card"] {
        // box-shadow: 0px 0px 5px 0px #9898981d;
        box-shadow: 0px 22px 24px 0px #99999918;
      }
    }
  }

  @for $i from 8 through 48 {
    .size#{$i} {
      font-size: #{$i}px;
    }
  }

  input,
  button,
  select {
    outline: 0;
    font-family: proxima-nova;
  }

  textarea {
    border: 1px solid $grey4;
  }

  .no-border {
    border: none !important;
  }
}

mark {
  background-color: $primary;
  color: white;
}

form {
  .row {
    display: flex;
    justify-content: space-between;
  }
}

input {
  position: relative;
  height: 48px;
  line-height: 24px;
  width: 100%;
  outline: none;
  border: 1px solid $grey4;
  border-radius: $bradius;
  color: $black;
  font-weight: 400;
  background: white;
  text-indent: 1rem;
  font-size: 1rem;

  &::placeholder {
    font-size: 1rem;
    font-family: proxima-nova;
    color: $grey2;
    font-weight: 300;
  }

  &:focus {
    border-color: $grey1;
  }
}

// New design system

.design-system {
  &__text,
  &__label,
  &__h1,
  &__h2,
  &__h3,
  &__h4 {
    color: $darkgrey;
    font-family: proxima-nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    word-wrap: break-word;
  }

  &__text,
  &__label {
    font-size: 1rem;
    line-height: 1.5;
  }

  &__h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;
    &--light {
      font-weight: 400;
      line-height: 3.5rem;
    }
  }

  &__h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.25rem;
    &--light {
      font-weight: 400;
      line-height: 2.25rem;
    }
  }

  &__h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
  }

  &__h4 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.75rem;
    &--light {
      font-weight: 400;
      line-height: 1.75rem;
    }
  }
  $font-sizes: (
    12: 0.75rem,
    14: 0.875rem,
    18: 1.125rem,
  );
  $font-weights: (
    normal: 400,
    bold: 700,
  );

  @each $name, $size in $font-sizes {
    &__size--#{$name} {
      font-size: $size;
    }
  }

  @each $name, $weight in $font-weights {
    &__weight--#{$name} {
      font-weight: $weight;
    }
  }
}

div[data-slate-editor="true"] {
  padding: 0.6rem;
}

.ReactModal__Body--open {
  overflow: hidden;
}

@keyframes slideIn {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slideOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.7);
  }
}

@keyframes rightIn {
  0% {
    margin-right: -300px;
  }

  100% {
    margin-right: 0;
  }
}

@keyframes rightOut {
  0% {
    margin-right: 0;
  }

  100% {
    margin-right: -500px;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: 100ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
  transition: 100ms;
  animation-duration: 0.1s;
  animation-timing-function: cubic-bezier(0.55, 0.03, 0.5, 0.98);
  animation-name: slideOut;

  &.commentModal {
    animation-name: rightOut;
  }
}

.ReactModal__Overlay {
  background: rgba(#484b58, 0.4) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  // @media (max-width: 760px) {
  //   align-items: flex-start;
  // }

  .ReactModal__Content {
    background: white;
    position: relative !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    max-height: 94vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: auto;
    border: none !important;
    border-radius: $bradius3 !important;
    min-width: 350px;
    max-width: 950px;
    min-height: 350px;
    max-height: 950px;
    width: 90%;
    height: 90%;
    margin: 0 auto;
    position: relative;
    box-shadow: -8px 8px 32px rgba($black, 0.25);
    outline: 0;
    padding: 3rem 4rem !important;
    animation-duration: 0.1s;
    // animation-timing-function: cubic-bezier(0.55, 0.03, 0.5, 0.98);
    animation-name: slideIn;

    .modalHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 530px) {
      padding: 2rem !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      max-height: 100vh;
      height: 100vh;
      width: 100%;
      border-radius: 0 !important;
      top: 0;
      bottom: 0;
    }

    &.create,
    &.delete {
      max-width: 900px;
      max-height: 500px;

      @media (max-width: 628px) {
        width: 100%;
        margin-left: 0 !important;
        padding: 2rem !important;
        padding-top: 4rem !important;
        left: 0 !important;
        right: 0 !important;
        max-height: 100vh;
        height: 100vh;
      }
    }

    &.verySmall {
      max-width: 900px;
      max-height: 430px;
      min-height: fit-content;

      @media (max-width: 628px) {
        width: 100%;
        margin-left: 0 !important;
        padding: 2rem !important;
        padding-top: 4rem !important;
        left: 0 !important;
        right: 0 !important;
        max-height: 100vh;
        height: 100vh;
      }
    }

    &.fitContent {
      max-width: 900px;
      height: fit-content;
      min-height: fit-content;

      @media (max-width: 628px) {
        width: 100%;
        margin-left: 0 !important;
        padding: 2rem !important;
        padding-top: 4rem !important;
        left: 0 !important;
        right: 0 !important;
        max-height: 100vh;
        height: 100vh;
      }
    }

    &.delete {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.fullModal {
      padding: 0rem !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      max-height: 100vh;
      height: 100vh;
      max-width: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      border-radius: 0 !important;
      animation-name: none;
    }

    &.videoModal {
      min-height: 0;
      max-height: 534px;
      padding: 0 !important;
      overflow: hidden;
    }

    &.small {
      background: white;
      position: relative;
      overflow-y: auto;
      width: auto;
      border: none !important;
      border-radius: $bradius3 !important;
      padding: 2rem 10rem !important;
      margin: 0 auto;
      position: relative;
      box-shadow: -16px 16px 64px rgba($black, 0.5);
      outline: 0;
      animation-duration: 0.3s;
      animation-timing-function: cubic-bezier(0.55, 0.03, 0.5, 0.98);
      animation-name: slideIn;

      @media (max-width: 1350px) {
        padding: 2rem 4rem !important;
      }

      @media (max-width: 628px) {
        width: 100%;
        margin-left: 0 !important;
        padding: 2rem !important;
        padding-top: 4rem !important;
        left: 0 !important;
        right: 0 !important;
        max-height: 100vh;
        height: 100vh;
      }
    }

    &.avatarModal {
      max-width: 650px;
      max-height: 580px;

      @media (max-width: 1350px) {
        padding: 2rem 4rem !important;
      }

      @media (max-width: 628px) {
        width: 100%;
        padding: 2rem !important;
      }
    }

    &.commentModal {
      right: 0;
      margin-right: 0;
      height: 100%;
      min-width: 450px;
      max-width: 450px;
      max-height: 100vh;
      padding: 1rem !important;
      border-radius: 0 !important;
      animation-duration: 0.2s;
      animation-name: rightIn;

      @media (max-width: 500px) {
        width: 100%;
        min-width: 100%;
      }
    }

    &.burgerMenu {
      @media (max-width: 760px) {
        position: absolute !important;
        width: 100%;
        height: auto !important;
        min-height: auto;
        margin-left: 0 !important;
        padding: 0rem !important;
        top: 0 !important;
        right: 0 !important;
        bottom: auto !important;
        animation-duration: 0ms;
        border-radius: 0;
      }
    }
  }
}

.avatarModal {
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.close {
  position: absolute;
  top: 3rem;
  right: 3rem;
  padding: 1.6rem;
  width: 48px;
  height: 48px;
  background: $extradarkgrey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border: none;

  @media (max-width: 530px) {
    top: 1rem;
    right: 1rem;
  }

  i {
    line-height: 0;
    font-size: 24px;
    color: white;
    padding: 0;
    margin: 0;
    margin-top: 1px;
  }

  &:hover {
    background-color: rgba($extradarkgrey, 0.9);
  }

  &.small {
    width: 40px;
    height: 40px;
    padding: 1rem;
    top: 1.4rem;
    right: 1.4rem;

    i {
      font-size: 20px;
      margin-top: 2px;
      margin-left: 1px;
    }
  }
}

.rap-popover-content {
  background: white;
  box-shadow: $strongboxshadow;
  border-radius: $bradius;
  position: initial;
  will-change: initial;
  transform: initial;
  margin-top: 0.5rem;
  padding: 0;
  z-index: 4000 !important;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.smallPopupWrapper .popup-content {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05) !important;
  background: #303030 !important;
  color: white !important;
  font-size: 13px;
  padding: 0.5rem 0.7rem !important;
  border: none !important;
  left: 0;
  top: -16px !important;
  -webkit-animation: anvil 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  .popup-arrow {
    background: #303030 !important;
    border: none !important;
  }
}

.aiPopupWrapper .popup-content {
  margin-top: 0.6rem;
}

.popup-content {
  width: max-content !important;
  border: none !important;
  box-shadow: 0 10px 28px rgba(0, 0, 0, 0.15) !important;
  border: 0.5px solid $grey5 !important;
  border-radius: $bradius2;
  padding: 0 !important;
  position: initial;
  will-change: initial;
  transform: initial;
  z-index: 9995 !important;

  .popup-arrow {
    // display: none;
    box-shadow: none !important;
    border: 1px solid $grey5 !important;
    // top: -1px !important;
    border-left: 0 !important;
    border-top: 0 !important;
    z-index: -1 !important;
  }
}

// .avatarModal {
//   position: relative;
//   background: white;
//   width: auto;
//   left: 0px !important;
//   margin-left: calc(50% - 520px) !important;
// }

.main.headroom-wrapper {
  z-index: 1005 !important;

  .headroom {
    z-index: 1005 !important;
  }
}

.profileNav.headroom-wrapper {
  z-index: 1000 !important;

  .headroom--pinned {
    transform: translate3d(0px, 58px, 0px) !important;
    z-index: 1000 !important;
  }
  .headroom--unpinned {
    transform: translate3d(0px, 0px, 0px) !important;
    z-index: 1000 !important;
  }
}

.suvaline__value-container {
  margin-left: 38px;
}

.css-bg1rzq-control,
.css-1pcexqc-container,
.suvaline__control--menu-is-open,
.suvaline__control--is-focused {
  background: $black !important;
  color: white !important;
  height: 60px !important;
  width: 420px !important;

  &:before {
    top: 16px;
  }

  @media (max-width: 472px) {
    width: 100% !important;
  }
}

.suvaline__input {
  color: white;
  font-size: 1.1rem;
}

.suvaline__menu {
  color: $black;
  z-index: 100 !important;
}

.suvaline__placeholder {
  color: white !important;
  font-size: 1.1rem;
}

.css-bgvzuu-indicatorSeparator {
  background-color: rgba($darkgrey, 0.5) !important;
}

.active {
  background: $primary !important;
}
