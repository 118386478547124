@import "variables.scss";

:local {
  .main {
    max-width: $wrapperWidth;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .img {
      margin: 0;
      margin-bottom: 1rem;
    }

    .title {
      font-size: 1rem;
      color: $black;
      font-weight: 600;
      text-align: left;
    }

    .subtitle {
      font-weight: 300;
      color: $grey1;
      text-align: left;
    }

    .ctaButton {
      margin-top: 1rem;
    }

    .surveys {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      .link {
        border: 0;
        padding: 0;
        margin: 0;

        &:nth-child(2) {
          .left .icon svg path:nth-child(3),
          .left .icon svg path:nth-child(4) {
            fill: $primary !important;
          }
        }
      }

      .card {
        background: white;
        border-radius: $bradius3;
        box-shadow: $boxshadow1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: $transition;
        height: 40px;
        padding: 0 0.6rem;
        border: 1px solid $grey5;
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: max-content;

        &:hover {
          box-shadow: $boxshadow2;
        }

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .icon {
            width: 18px;
            height: 18px;
            background: $primary;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.4rem;

            svg {
              height: 14px;
              width: 14px;

              path {
                fill: white;
              }
            }
          }

          .title {
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            font-weight: 500;
          }
        }

        &.complete {
          .icon {
            background: $green;

            svg {
              height: 24px;
              width: 24px;

              path {
                fill: transparent;
                stroke: white;
              }
            }
          }
        }

        .right {
          .info {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: 1rem;

            p {
              font-size: 14px;
              line-height: 14px;
              color: $primary;
              font-weight: 600;
              margin-right: 0.6rem;
            }

            svg path {
              stroke: $primary;
            }

            &.complete {
              p {
                color: $green;
              }

              svg path {
                stroke: $green;
              }
            }
          }
        }
      }
    }
  }
}
