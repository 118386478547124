@import "variables.scss";

:local {
  .wrapper {
    max-width: $modalWrapper;
    width: 100%;
  }

  .topStuff {
    width: 100%;
    padding: 2rem 0;
    text-wrap: wrap;

    &.noPadding {
      padding-bottom: 0;
    }

    .title {
      padding: 0 2rem;
      font-size: 1.1rem;
      font-weight: 500;
      margin-bottom: 0.6rem;
    }

    .subtitle {
      padding: 0 2rem;
      margin-bottom: 1rem;
      font-size: 0.9rem;
      line-height: 1.2rem;
      font-weight: 300;
    }

    .inputRow {
      width: 100%;
      padding: 0 2rem;

      &:last-child {
        margin-top: 2rem;
      }

      .label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0.2rem;
      }

      input {
        font-size: 15px;

        &::placeholder {
          font-size: 15px;
        }
      }

      .templateButton {
        margin-top: 0.6rem;
        margin-bottom: 1.8rem;
      }

      .mainButton {
        button {
          margin-top: 0.6rem;
          float: right;
          font-size: 0.95rem;
          margin-right: 0 !important;
        }
      }
    }
  }
}
