@import "variables.scss";

:local {
  .chart {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0rem;

    .wrap {
      position: relative;
      width: 100%;
    }
  }
}
