@import "variables.scss";

:local {
  .skeleton {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2rem;

    .cardLoaderWrapper {
      margin-right: 1.4rem;
      margin-bottom: 1.4rem;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .buttons {
      width: 100%;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $grey5;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        margin-bottom: 1rem;
      }

      .subtitle {
        font-size: 1rem;
        line-height: 1.6rem;
        color: $darkgrey;
        font-weight: 300;
        margin-top: 1rem;
        max-width: 60%;

        @media (max-width: 800px) {
          max-width: 80%;
        }
      }
    }

    .popup {
      min-width: 350px;
      max-width: 350px;

      button {
        justify-content: center;
        color: $primary;
        width: auto;

        &:hover {
          color: $primary;
        }
      }

      .buttonGroup {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.6rem;
        // margin-top: 0.4rem;

        button:first-child {
          margin-right: 0.6rem;
          color: white;
          padding: 0.7rem 1rem !important;

          &:hover {
            background-color: lighten($primary, 3);
            border-color: darken($primary, 0.2);
          }
        }

        button:last-child {
          border: 1px solid #e4e6e8;
          color: $black;
          padding: 0.7rem 1rem !important;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1rem;
    }

    .filters {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .filter {
        border: 1px solid $grey4;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        text-transform: capitalize;
        margin-right: 1rem;
        cursor: pointer;
        transition: $transition;

        button {
          width: 100%;
          height: 100%;
          padding: 6px 14px;
        }

        &:hover {
          border-color: $grey1;
        }

        p {
          color: $darkgrey;
          font-size: 13px;
          line-height: 13px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
        }

        &.active {
          background-color: $grey5;
          color: $black;

          p {
            font-weight: 500;
          }

          &:hover {
            border-color: $grey5;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &.green {
          .dot {
            background-color: $green;
          }

          &.active {
            border-color: $green;
            background: $green;

            p {
              color: white;
            }
          }
        }

        &.primary {
          .dot {
            background-color: $primary;
          }

          &.active {
            border-color: $primary;
            background: $primary;

            p {
              color: white;
            }
          }
        }

        &.yellow {
          .dot {
            background-color: $yellow;
          }

          &.active {
            border-color: $yellow;
            background: $yellow;

            p {
              color: white;
            }
          }
        }

        &.red {
          .dot {
            background-color: $red;
          }

          &.active {
            border-color: $red;
            background: $red;

            p {
              color: white;
            }
          }
        }

        &.purple {
          .dot {
            background-color: $purple;
          }

          &.active {
            border-color: $purple;
            background: $purple;

            p {
              color: white;
            }
          }
        }

        &.blue {
          .dot {
            background-color: $blue4;
          }

          &.active {
            border-color: $blue4;
            background: $blue4;

            p {
              color: white;
            }
          }
        }

        &.black {
          .dot {
            background-color: $black;
          }

          &.active {
            border-color: $blue;
            background: $blue;

            p {
              color: white;
            }
          }
        }

        &.active {
          .dot {
            background-color: white;
          }
        }
      }
    }

    .templatesWrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 2rem 0;
      width: 100%;

      .template {
        margin-right: 2rem;
        margin-bottom: 2rem;
        min-width: 230px;

        @media (max-width: 620px) {
          min-width: 100%;
        }

        &.list {
          min-width: 100%;
          margin: 0;
          margin-bottom: 1rem;
        }
      }
    }

    .nothing {
      width: 100%;
      max-width: 400px;

      p {
        color: $grey1;
        margin: 0.6rem 0;
        font-weight: 300;
      }
    }
  }
}
