@import "variables.scss";

:local {
  .wrapper {
    overflow: hidden;
    margin-bottom: -64px;

    .backButton {
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 1000;

      .icon {
        transform: rotate(180deg);

        svg {
          transform: scale(1.5);
        }
        svg path {
          fill: $grey1;
        }
      }

      &:hover {
        .icon {
          svg path {
            fill: $darkgrey;
          }
        }
      }
    }

    .progressBar {
      position: absolute;
      top: 1.75rem;
      left: 7.2rem;
      z-index: 1000;
      display: flex;

      .block {
        background: rgba($grey5, 0.5);
        display: flex;
        padding: 8px;
        border-radius: 28px;
        margin: 0 4px;

        &.active {
          background: $blue;
        }

        .step {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: $grey3;
          margin: 0 2px;

          &.active {
            background: $primary;
          }
        }
      }
    }
  }
}
