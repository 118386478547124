@import 'variables.scss';

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;

    .header {
      margin-bottom: 1rem;

      .defaultLogo {
        display: block;
        height: 30px;
        width: 170px;
        margin: 0 auto;
        background: url('~images/logo/default/logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 415px) {
          height: 20px;
        }
      }

      .subtitle {
        text-align: center;
        font-weight: 300;
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 1.3rem;
        color: $grey1;

        @media (max-width: 415px) {
          font-size: 1.3rem;
        }
      }
    }

    .resetPassword,
    .emailSent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border: 1px solid $grey5;
      padding: 2rem;
      border-radius: $bradius2;
      max-width: 420px;
    }

    .emailSent {
      button {
        height: 40px;
        width: 100%;
        margin-top: 0;
      }

      .subtitle {
        margin-bottom: 1rem;
      }
    }

    i {
      font-size: 4.6rem;
      color: $green;
      margin-bottom: 2rem;
    }

    .title {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 0.6rem;
      text-align: left;
    }

    .subtitle {
      font-size: 0.9rem;
      line-height: 1.3rem;
      font-weight: 300;
      text-align: left;
      color: $grey1;
      margin-bottom: 2rem;
    }

    form {
      width: 100%;
    }

    label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: black;
      font-size: 0.9rem;

      input {
        width: 100%;
        height: 50px;
        font-size: 0.9rem;
        margin-top: 0.4rem;
      }
    }

    button {
      width: 100%;
      margin-top: 1.6rem;
    }

    .footerText {
      width: 100%;
      font-size: 0.9rem;
      line-height: 1.4rem;
      font-weight: 300;
      margin-top: 1.6rem;
      color: $black;
      text-align: center;

      a {
        font-size: 0.9rem;
        line-height: 1.4rem;
      }
    }
  }
}
