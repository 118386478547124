@import "variables.scss";

:local {
  .invitedListWrapper {
    width: 100%;
    margin: 2rem 0;

    &.noHeight {
      .tableWrapper {
        min-height: auto;
        margin-bottom: 3rem;
      }
    }

    .tableWrapper {
      // max-height: 400px;
      // min-height: 388px;
      overflow: auto;
      margin-bottom: 2rem;

      .placeholderHead {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: -5px;

        h5 {
          text-align: left;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 1px;
          color: black;

          &:first-child {
            width: 48.8%;
          }

          &:nth-child(2) {
            width: 25%;
          }

          &:nth-child(3) {
            width: 20%;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
        background: rgba($grey5, 0.4);
      }

      &::-webkit-scrollbar-thumb {
        background: $grey4;
        border-radius: 2px;
      }

      table {
        width: 100%;

        tr {
          width: 100%;
          height: 30px;
        }

        th {
          text-align: left;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 1px;
          color: black;
          width: 5%;

          .name {
            width: 50%;
          }

          .org {
            width: 25%;
          }

          .role {
            width: 20%;
          }

          &.role,
          &.org {
            padding-left: 1rem;
          }
        }

        td {
          height: 50px;
          vertical-align: middle;
          font-size: 15px;
        }

        .name {
          width: 75%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .nameAndEmail {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .userName {
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: black;
              margin-right: 0.6rem;
            }

            .email {
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: $grey2;
            }
          }

          .inputs {
            margin-left: 0.6rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            min-width: max-content;

            .input {
              margin-right: 1rem;
              flex: 1;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .avatarWrapper {
            margin-right: 1rem;
          }
        }

        .select {
          width: 25%;

          &.roleType {
            width: 15%;
          }
        }

        .remove {
          min-width: 5%;
          width: 5%;
          padding-left: 1rem;
          padding-right: 1rem;

          button {
            i {
              font-size: 14px;
              color: $grey1;
              margin-top: 5px;
            }

            &:hover {
              i {
                color: black;
              }
            }
          }
        }
      }
    }

    @-webkit-keyframes rotating /* Safari and Chrome */ {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    .infoBox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 95%;

      i {
        margin-right: 1.4rem;
        margin-top: 0.4rem;
        font-size: 1.1rem;
        color: $primary;
      }

      p {
        color: $darkgrey;
      }
    }

    .notice {
      h5 {
        color: $yellow;
      }
    }

    .submitWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      bottom: 4rem;

      @media (max-height: 710px) {
        position: relative;
        bottom: 0;
        margin-top: 2rem;
      }

      &.relative {
        position: relative;
        bottom: 0;
        margin-top: 2rem;
      }

      button:first-child {
        margin-right: 1rem;
      }

      button {
        box-shadow: 0px 1.5px 3px 0px rgba(black, 0.08);
      }
      .loading {
        i {
          animation: rotating 2s linear infinite;
        }
      }
    }

    .placeholder {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 2rem;
      overflow: hidden;

      .row {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(2) {
          opacity: 0.5;
        }

        &:nth-child(3) {
          opacity: 0.3;
        }
      }
    }
  }
}
