@import "variables.scss";

.evaluationGroup {
  width: 100%;
  &__header {
    background: #ecf9ff;
    display: flex;
    flex-wrap: wrap;
  }

  &__headerCell {
    padding: 8px 16px;
    flex: 1 0 100%;
    text-align: left;

    @media (min-width: 768px) {
      flex: 0 0 auto;
    }
  }

  &__row {
    border-bottom: 0.5px solid $grey4;
    padding: 16px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 768px) {
      align-items: center;
      flex-direction: row;
      gap: 16px;
      padding: 8px 16px;
    }

    & > div > button {
      padding: 0;
    }
    .addComment {
      width: 100%;
      max-width: 70px;
      justify-content: flex-start;
      position: relative;

      @media (max-width: 1000px) {
        max-width: 100%;
        justify-content: center;
        border-bottom: 0;
      }

      @media (max-width: 700px) {
        justify-content: flex-start;
      }

      .tooltip {
        opacity: 0;
        display: flex;
        position: absolute;
        top: -15px;
        left: 2rem;
        padding: 6px 12px;
        background-color: rgba($darkgrey, 1);
        justify-content: flex-start;
        align-items: center;
        border-radius: $bradius;
        z-index: 99999;
        transition-delay: 0s opacity;
        pointer-events: none;

        &::before {
          content: "";
          position: absolute;
          left: -6px;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: 6px solid rgba($darkgrey, 1);
        }

        @media (max-width: 1000px) {
          top: 2.8rem;
          left: 0;
          right: 0;
          margin: 0 auto;
          max-width: 180px;

          &::before {
            top: -8px;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(90deg);
          }
        }

        p {
          color: white;
          font-size: 12px;
          line-height: 15px;
          width: 130px;
        }
      }

      &.disabled {
        button {
          pointer-events: none;
          opacity: 0.5;
        }

        svg {
          opacity: 0.5;
        }

        &:hover {
          .tooltip {
            opacity: 1;
            transition-delay: 500ms;
          }
        }
      }

      button {
        font-size: 14px;
        line-height: 14px;
        color: $primary;

        svg path {
          fill: $primary;
        }
      }
    }
  }
  &__title {
    @media (min-width: 768px) {
      flex-grow: 1;
    }
  }

  &__row--nowrap {
    white-space: nowrap;
  }
}

.buttonRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .buttonWrapper {
    position: relative;

    .tooltip {
      opacity: 0;
      position: absolute;
      top: -2.2rem;
      left: calc(50% - 40px);
      margin: 0 auto;
      color: white;
      width: 80px;
      transition: 100ms ease-in-out;
      pointer-events: none;

      p {
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        margin: 0 auto;
        padding: 0.3rem 0.6rem;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: $bradius2;
        max-width: fit-content;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: calc(50% - 5px);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-top: 5px solid rgba(0, 0, 0, 0.8);
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;
      }
    }
  }

  &__NAButton {
    margin-right: 0;
    border: 0;
    width: auto;
    height: auto;
    padding: 0.8rem;
    color: $grey1;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    box-shadow: none;
    border-radius: none;

    &:hover {
      color: $darkgrey;
    }

    &.active {
      color: $primary;
      font-weight: 600;
    }
  }

  &__button {
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    flex-direction: column;
    justify-content: center;
    // Add typography styles here

    &:hover {
      color: $primary;
    }
    &.active {
      border-color: $primary;
      color: $white;
      background: $primary;
    }
    &.inactive {
      border-color: $grey4;
    }
  }
}
