@import "variables.scss";

:local {
  .fileRow {
    width: 100%;
    position: relative;
    li {
      cursor: pointer;
      font-size: 0.9rem;
      line-height: 1.4rem;
      margin: 0;
      padding: 0.6rem;
      color: black;
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: none;
      position: relative;

      &.disabled {
        opacity: 0.5;
      }

      .fileName {
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 0.6rem;
      }

      .remove {
        position: absolute;
        right: 6px;
        top: 10px;

        button {
          margin: 0;
          padding: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: $red;

            svg rect {
              fill: white;
            }
          }
        }

        svg {
          width: 13px;
          height: 13px;
          margin: 0;
          padding: 0;
          margin-left: -1px;
        }

        svg rect {
          fill: $darkgrey;
        }
      }
    }

    li:first-child {
      border-bottom: 0.5px solid $grey5;
    }
  }
}
