@import "variables.scss";
@import "logos.scss";

:local {
  .mainWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;

    .logo {
      background: url("~images/logo/default/logo.svg");
      @include login_logo;
    }
  }

  .backButton {
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1000;

    button {
      font-size: 15px;
      font-weight: 500;
      color: $grey1;
    }

    .icon {
      transform: rotate(180deg);
      margin-right: 0.8rem;

      svg {
        transform: scale(1.5);
      }

      svg path {
        fill: $grey1;
      }
    }

    &:hover {
      .icon {
        svg path {
          fill: $darkgrey;
        }
      }

      button {
        color: $darkgrey;
      }
    }
  }

  .main {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  .wrapper {
    margin-top: 2rem;
    margin-bottom: 4rem;

    .smallerWrapper {
      max-width: 400px;
      width: 400px;
      padding: 2rem;
      border: 1px solid $grey4;
      border-top: 4px solid $primary;
      border-radius: $bradius;
      box-shadow: $boxshadow;
      background: white;

      @media (max-width: 415px) {
        max-width: 90%;
        min-width: 90%;
        width: 90%;
      }
    }

    .login {
      position: relative;
      justify-content: center;

      .subtitle {
        text-align: center;
        font-weight: 300;
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 1.3rem;
        color: $grey1;

        @media (max-width: 415px) {
          font-size: 1.3rem;
        }
      }

      .container {
        position: relative;
        justify-content: center;
        width: 100%;
        max-width: 400px;
        min-width: 380px;
        margin: 1rem auto;
        padding: 2rem;
        border: 1px solid $grey5;
        border-radius: $bradius2;
        background: white;

        h3 {
          font-size: 22px;
        }

        p {
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
          color: $grey1;
        }

        .userInfo {
          margin: 1.6rem 0;
          padding: 1rem 0;
          border-top: 1px solid $grey5;
          border-bottom: 1px solid $grey5;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              margin-right: 1rem;

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
            }

            .info {
              .name,
              .email {
                font-size: 14px;
                line-height: 18px;
              }

              .email {
                font-size: 13px;
              }
            }
          }
        }

        .confirmEmail {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0.4rem;
        }

        input[type="email"] {
          font-size: 15px;
          color: $darkgrey;
        }

        .checkbox {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.6rem;
          cursor: pointer;
          width: 100%;

          .label,
          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
          }

          a {
            color: $primary;
            border: none;
          }

          input {
            height: 15px;
            width: 15px;
            margin-right: 0.6rem;
          }
        }

        .submit {
          margin-top: 1.6rem;

          button {
            width: 100%;
            height: 40px;
          }
        }
      }
    }
  }
}
