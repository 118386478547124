@import 'variables.scss';

:local {
  option[disabled] {
    display: none;
  }

  .row {
    height: 60px;
    border: 1px solid $grey5;
    background: white;

    &:hover {
      .lastCol {
        button {
          opacity: 1;
        }
      }
    }

    td {
      vertical-align: middle;
    }

    &:hover {
      background: rgba($grey5, 0.2);
    }

    .avatarCol {
      display: flex;
      height: 60px;
      align-items: center;
      padding-left: 2rem;

      .avatar {
        margin-right: 1rem;
        width: 32px;
        height: 32px;
        border-radius: $bradius3;
        background: $green;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: white;
          font-size: 0.8rem;
        }

        &.blue {
          background: $primary;
        }

        &.green {
          background: $green;
        }

        &.purple {
          background: $purple;
        }
      }

      .nameAndEmail {
        .name {
          font-weight: 500;
          font-size: 0.9rem;
          line-height: 1.2rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 500px;
          color: $black;
        }

        .email {
          color: $grey1;
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
      }
    }

    .members,
    .teams,
    .positions,
    .role {
      width: 10%;

      p {
        font-size: 0.9rem;
        color: $grey1;
      }

    }

    .none { 
      color: $green;
    }


    .lastCol {
      width: 8%;
      vertical-align: middle;
      padding: 0 2rem;

      button {
        float: right;
        opacity: 0;
      }
    }
  }
}
