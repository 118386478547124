@import "variables.scss";

:local {
  .pageTitle {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .notice {
    padding: 2rem;
    border-radius: $bradius3;
    background-color: $lightorange;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid transparent;

    .icon {
      width: 48px;
      height: 48px;
      border-radius: $bradius3;
      background-color: $yellow;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2rem;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: $extradarkgrey;
        }
      }
    }

    .text {
      flex: 1;
      margin-top: 1rem;

      .noticeTitle {
        font-size: 15px;
        line-height: 22px;
      }

      .noticeLink {
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: $primary;
        border-color: $primary;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: max-content;

        svg {
          width: 14px;
          height: 14px;
          margin-left: 0.4rem;

          path {
            stroke: $primary;
          }
        }
      }

      .noticeDesc {
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
        color: $darkgrey;
        margin-top: 0.6rem;
      }
    }

    &.blue {
      background-color: white;
      border-color: $grey5;

      .icon {
        background-color: $blue;

        svg path {
          fill: $primary;
        }
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    @media (max-width: 1345px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .left {
      width: 50%;
      margin-right: 4rem;

      @media (max-width: 1345px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .subtitle {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-bottom: 1rem;
      }

      .list {
        margin: 2rem 0;

        .listItem {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 2rem;
          padding-bottom: 2rem;
          border-bottom: 1px solid $grey5;

          &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
          }

          .number {
            margin-right: 1.2rem;

            span {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              border: 1px solid $grey5;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              line-height: 14px;
              font-weight: 500;
            }
          }

          .text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 0.4rem;

            h5 {
              margin-bottom: 0.8rem;
              font-size: 15px;
            }

            p {
              font-weight: 300;
              color: $darkgrey;
            }
          }

          .cta {
            margin-top: 1.4rem;
          }
        }
      }
    }

    .right {
      width: 50%;

      @media (max-width: 1345px) {
        width: 100%;
      }

      .video {
        height: 284px;
        width: 100%;
        background-color: $grey6;
        border-radius: $bradius3;
      }
    }
  }

  .noRights {
    width: 100%;

    .title {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 600;
      margin-bottom: 2.4rem;
    }

    .ctaWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2.8rem 1.8rem;
      border: 1px solid $grey5;
      border-radius: $bradius3;
      background-image: url("~images/Logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50px;
      margin-top: 2rem;

      @media (max-width: 840px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .ctaTitle {
        width: 30%;
        margin-right: 2rem;
        font-weight: 500;

        @media (max-width: 840px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }

      .ctaDescription {
        width: 40%;
        margin-right: 3rem;
        font-size: 15px;
        line-height: 22px;
        color: $darkgrey;
        font-weight: 300;

        @media (max-width: 840px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
