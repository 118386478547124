@import "variables.scss";

:local {
  .commentWrapper {
    position: sticky;
    top: 8rem;
    margin-right: calc(-100% - 9rem);
    z-index: 999;

    @media (max-width: 1310px) {
      right: 1rem;
    }

    @media (max-width: 650px) {
      display: none;
    }

    &.relative {
      position: relative;
      top: 0;
      margin: 0;
      right: 0;

      .buttonWrapper {
        @media (max-width: 1180px) {
          opacity: 1;
        }
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: $transition;
      cursor: pointer;

      @media (max-width: 1180px) {
        opacity: 0.5;
      }

      svg {
        path {
          fill: $darkgrey;
        }
      }

      &:hover {
        .icon {
          svg path {
            fill: lighten($darkgrey, 5%);
          }
        }
      }

      .icon {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 24px;
          width: 24px;
        }
      }

      p {
        margin-left: 0.4rem;
        font-size: 13px;
        font-weight: 400;
        color: $black;
      }
    }
  }

  .popover {
    position: relative;
    z-index: 9999;
    border-radius: $bradius3;
  }

  .comments {
    background: white;
    max-width: 420px;
    min-width: 420px;
    border-radius: $bradius3;

    @media (max-width: 380px) {
      min-width: 100%;
      width: 100%;
    }

    &.sidebar {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .commentsBox {
        height: 100%;
        min-height: calc(100% - 20px);
      }

      .padding {
        padding: 1rem;
        width: 100%;
        height: 100%;

        &.empty {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .placeholder {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          max-width: 90%;

          h5 {
            margin-bottom: 0.6rem;
            font-weight: 600;
          }

          p {
            color: $grey2;
          }
        }
      }

      .newComment {
        width: 100%;
        border: 0;
      }

      .commentsTitle {
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
    }

    .padding {
      padding: 1.6rem 1.2rem 0rem 1.2rem;
    }

    .commentsTitle {
      text-align: left;
      font-size: 18px;
      color: black;
      margin-bottom: 0.6rem;
    }

    .commentsBox {
      max-height: 400px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        background: rgba($grey5, 0.4);
      }

      &::-webkit-scrollbar-thumb {
        background: $grey4;
        border-radius: 2px;
      }

      .comment {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1.4rem;

        &:last-child {
          margin-bottom: 1rem;
        }

        .left {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }

        .right {
          width: 82%;
          margin-left: 1rem;
          text-align: left;

          .upper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.2rem;

            .keepLeft {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .name,
              .time {
                font-size: 14px;
                line-height: 0.9rem;
                font-weight: 500;
                color: $darkgrey;
              }

              .name {
                max-width: 106px;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .time {
                margin-left: 0.6rem;
                font-weight: 400;
                font-size: 0.8rem;
                color: $grey1;
              }
            }

            .remove {
              margin-right: 0.4rem;
              opacity: 0;
              transition: $transition;

              button {
                i {
                  color: $grey3;
                }

                &:hover {
                  i {
                    color: $black;
                  }
                }
              }
            }
          }

          .commentText {
            .commentMessage {
              max-width: 93%;
              color: $black;
            }
          }
        }

        &:hover {
          .remove {
            opacity: 1 !important;
          }
        }
      }
    }
  }

  .newComment {
    padding: 1rem 1.2rem;
    border-top: 1px solid $grey5;

    form {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      .left {
        width: 10%;
        display: flex;
        justify-content: flex-end;

        .avatar {
          margin-top: 4px;
        }
      }

      .right {
        width: 90%;
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .textarea {
          // border: 1px solid $grey4;
          border-radius: $bradius;
          padding: 0.6rem 1rem;
          width: 100%;
          font-family: proxima-nova;
          font-size: 0.95rem;
          transition: $transition;
          resize: none;

          &::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: $grey1;
          }

          &.active {
            border-bottom: 1px solid white;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .buttons {
          padding: 0.6rem;
          border: 1px solid $grey4;
          border-bottom-left-radius: $bradius;
          border-bottom-right-radius: $bradius;
          border-top: none;
          width: 100%;
          display: flex;

          button:first-child {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
