@import "variables.scss";
@import "logos.scss";

:local {
  .mainWrapper {
    background-color: white;
  }

  .main {
    width: 100%;
    min-height: 100%;
    height: 100vh;
    background-color: white;

    @media (max-width: 550px) {
      height: auto;
      margin: 2rem 0;
    }

    .left {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;

      @media (max-width: 1280px) {
        width: 100%;
      }

      .wrapper {
        width: 100%;
        max-width: 500px;

        .header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          @media (min-width: 1280px) {
            position: absolute;
            top: 4rem;
          }
        }

        .defaultLogo {
          display: block;
          height: 32px;
          width: 112px;
          background: url("~images/logo/default/logo.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        .whitelabel {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 auto;

          .ourLogo {
            display: flex;
            justify-content: center;
            align-items: center;

            .defaultLogo {
              height: 30px;
              width: 128px;
              margin-left: 0.5rem;
            }
          }
        }

        .login {
          position: relative;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;

          @media (max-width: 1120px) {
            margin-right: 1rem;
          }

          @media (max-width: 930px) {
            margin: 0 auto;
          }

          .footer {
            width: 100%;
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .alreadyAccount {
              font-weight: 500;
              color: black;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              font-size: 0.9rem;
              margin-top: 0.4rem;

              a {
                text-decoration: none;
                border: none;
                color: $primary;
                font-weight: 400;
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }

    .right {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;

      @media (max-width: 1280px) {
        display: none;
      }

      .slides {
        height: 100%;
        position: relative;

        @keyframes showSlide {
          0% {
            opacity: 0;
          }

          4% {
            opacity: 1;
          }

          96% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }

        .slide {
          width: 100%;
          height: 100%;
          padding: 4rem;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          background-size: 100%;
          position: relative;
          display: none;
          opacity: 0;
          animation-name: showSlide;
          animation-duration: 8s;
          animation-timing-function: linear;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;

          @keyframes loading {
            0% {
              width: 0%;
            }

            100% {
              width: 100%;
            }
          }

          .progressBar {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: $grey5;

            .progress {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0%;
              height: 100%;
              background-color: $grey2;
              animation-name: loading;
              animation-duration: 8s;
              animation-timing-function: linear;
            }
          }

          .image {
            width: 100%;
            height: 100%;
            background-size: 100% !important;
            background-repeat: no-repeat;
            background-position: top center;
          }

          &:nth-child(1) {
            background-image: url("~images/backgrounds/slide-orange.png");

            .image {
              background-image: url("~images/login-first.png");
            }
          }

          &:nth-child(2) {
            background-image: url("~images/backgrounds/slide-blue.png");

            .image {
              background-image: url("~images/position.png");
            }
          }

          &:nth-child(3) {
            background-image: url("~images/backgrounds/slide-green.png");

            .image {
              background-image: url("~images/hiring-scorecards.png");
            }
          }

          &.show {
            display: flex;
          }

          &.white {
            .defaultLogo {
              background: url("~images/logo/default/logo-white.svg");
            }

            .text {
              .title,
              .subtitle {
                color: white;
              }
            }
          }

          &.dark {
            .text {
              .title,
              .subtitle {
                color: $extradarkgrey;
              }
            }
          }

          .logo {
            margin-bottom: 3rem;

            .defaultLogo {
              width: 120px;
              height: 32px;
            }
          }

          .text {
            margin-top: 8rem;
            margin-left: 30px;
            margin-bottom: 6rem;

            .title {
              color: $extradarkgrey;
              margin-bottom: 0.2rem;
              font-size: 48px;
              line-height: 48px;
              text-align: left;
            }

            .subtitle {
              color: $extradarkgrey;
              margin: 0;
              font-size: 22px;
              line-height: 28px;
              text-align: left;
            }
          }
        }
      }

      .pagination {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .previous {
          svg {
            transform: rotate(180deg);
          }
        }

        .previous,
        .next {
          svg path {
            stroke: $grey1;
          }
        }

        .bubbles {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 3rem;

          .bubble {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: $grey5;
            margin-right: 0.6rem;
            cursor: pointer;

            &:last-child {
              margin-right: 0;
            }

            &.active {
              background-color: $grey3;
            }
          }
        }
      }
    }
  }
}

a {
  font-size: 1.2rem;

  @media (max-width: 415px) {
    font-size: 0.9rem;
  }
}
