@import "variables.scss";

:local {
  a {
    padding: 0 !important;
  }

  .userWrapper {
    position: relative;
    background: white;
    padding: 0.64rem 0rem;
    width: 86px;
    min-height: 99px;
    border: 0.5px solid $grey3;
    border-radius: $bradius2;
    margin: 0.3rem;
    transition: $transition;

    &.pending {
      background: rgba($grey4, 0.4);
      border: 1px solid $grey5;
    }

    &:hover {
      box-shadow: $boxshadow1;
    }

    &.incompleteWrapper {
      cursor: default;

      &:hover {
        box-shadow: $lightboxshadow;
      }
    }

    .label {
      z-index: 999;

      &.incomplete {
        position: absolute;
        padding: 0.2rem 0.3rem;
        background: $red;
        font-size: 0.8rem;
        color: white;
        font-weight: 400;
        top: -8px;
        left: 9px;
        border-radius: $bradius;
      }

      &.pending {
        position: absolute;
        padding: 0.2rem 0.3rem;
        background: $grey1;
        font-size: 0.8rem;
        color: white;
        font-weight: 400;
        top: -8px;
        left: 18px;
        border-radius: $bradius;
        color: white;
      }

      &.onboarding {
        display: none;
        position: absolute;
        padding: 0.2rem 0.3rem;
        background: $green;
        font-size: 0.8rem;
        color: white;
        font-weight: 400;
        top: -12px;
        left: 7px;
        border-radius: $bradius;
        color: white;
      }
    }

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-bottom: 0.6rem;
      margin-top: 0.4rem;
    }

    h4 {
      font-weight: 500;
      font-size: 0.9rem;
      line-height: inherit;
      color: $black;
      max-width: 80px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 auto;
    }
  }

  @keyframes :local(shine-lines) {
    0% {
      background-position: -90px;
    }

    40%,
    100% {
      background-position: 600px;
    }
  }

  .skeleton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.6rem 1.4rem;

    div {
      width: 100%;
      height: 10px;
      margin: 0.5rem 0;
      background: $grey3;
      border-radius: 1rem;
      background-image: linear-gradient(
        90deg,
        $grey4 0px,
        $grey5 40px,
        $grey4 80px
      );
      background-size: 1200px;
      animation: shine-lines 2.6s infinite ease-in-out;

      &:first-child {
        border-radius: 50%;
        height: 55px;
        width: 55px;
      }

      &:last-child {
        width: 90%;
      }
    }
  }
}
