@import "variables.scss";

@keyframes fadeAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:local {
  .wrapper {
    position: fixed;
    display: block;
    background: white;
    pointer-events: none;
    box-shadow: $boxshadow2;
    border: 1px solid $grey5;
    border-radius: $bradius2;
    z-index: 999;
    opacity: 0;
    animation: fadeAnimation 0.1s ease-in forwards;
    margin-top: 2rem;
    max-width: 450px;

    @media (max-width: 500px) {
      max-width: calc(100% - 2rem);
    }

    .popover {
      padding: 0.8rem 1.2rem;
      padding-bottom: 0;
      width: 100%;

      .topRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.6rem;

        .dimTitle {
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          color: $darkgrey;
        }

        .dimGroup {
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          color: $grey1;
          margin: 0.5rem 0;

          &.yellow {
            color: $yellow;
          }

          &.green {
            color: $green;
          }

          &.primary {
            color: $primary;
          }
        }
      }

      .lineWrapper {
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 1rem;

        .line {
          position: relative;
          width: 100%;
          height: 8px;
          background: $grey4;
          background: linear-gradient(
            90deg,
            #d9d9d9 0%,
            #f0fff6 49.48%,
            #6fd1bb 75%,
            #30be9f 100%
          );
          border-radius: 8px;

          &:after {
            content: "";
            height: 48px;
            width: 1px;
            background: $grey3;
            position: absolute;
            top: -19px;
            left: calc(50% - 1.5px);
          }

          .avatar {
            position: absolute;
            top: -14px;
            height: 36px;
            width: 36px;
            margin: 0 auto;
            border-radius: 50%;
            background: white;
            border: 2px solid white;
            z-index: 10;

            .score {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              color: $green4;
              z-index: 1;
              margin-top: 0;

              &.grey {
                color: $darkgrey;
              }
            }
          }
        }

        .opposites {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 2rem;

          h5 {
            margin-bottom: 0.4rem;
          }

          li {
            line-height: 1.2rem;
          }

          .left {
            margin-right: 2rem;
          }

          .right {
            margin-left: 2rem;

            h5,
            li {
              text-align: right;
            }

            .labelDesc {
              text-align: right;
            }
          }

          .label {
            color: $black;
          }

          .labelDesc {
            color: $grey1;
            font-size: 0.8rem;
            line-height: 1.2rem;
            font-weight: 300;
          }
        }
      }

      .dimDescription {
        font-size: 14px !important;
        line-height: 24px !important;
        color: $darkgrey;
        margin: 0;
      }
    }
  }
}
