@import "variables.scss";

:local {
  .header {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $grey5;
    width: 100%;

    .title {
      margin-bottom: 1rem;
    }

    .subtitle {
      font-size: 1rem;
      line-height: 1.6rem;
      color: $darkgrey;
      font-weight: 300;
      margin: 1rem 0;
      max-width: 500px;
    }
  }

  .showTeams {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .teamsList {
      width: 100%;
      display: grid;
      gap: 1rem;
      align-items: start;
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: 1300px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 950px) {
        grid-template-columns: 1fr;
      }

      &.manyTeams {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media (max-width: 1400px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 1250px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 950px) {
          grid-template-columns: 1fr;
        }
      }

      a {
        border: none;
        color: inherit;
        width: 100%;
      }

      .placeholder {
        width: 100%;
        border: 1px solid $grey5;
        border-radius: $bradius2;
        height: 250px;
        position: relative;
        padding: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          height: 4px;
          width: 100%;
          background-color: $grey5;
          border-top-left-radius: $bradius2;
          border-top-right-radius: $bradius2;
        }

        .top {
          width: 100%;

          .avatars {
            display: flex;

            .avatar {
              width: 34px;
              height: 34px;
              border-radius: 50%;
              background-color: $grey5;
              border: 2px solid white;

              &:last-child {
                margin-left: -12px;
              }
            }
          }

          .lines {
            margin-top: 2rem;

            .line {
              width: 70%;
              height: 9px;
              background-color: $grey5;

              &:nth-child(2) {
                height: 4px;
                margin-top: 2rem;
                margin-bottom: 1rem;
              }

              &:nth-child(3) {
                width: 40%;
                height: 4px;
              }
            }
          }
        }

        .bottom {
          width: 100%;
          border-top: 1px solid $grey5;
          padding-top: 1rem;

          .line {
            width: 30%;
            height: 4px;
            background-color: $grey5;
          }
        }
      }
    }
  }

  .banner {
    width: 100%;
    height: 312px;
    border-radius: $bradius3;
    background: url("~images/bg_teams@2x.png") no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .text {
      margin-left: 3rem;

      .title {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 400;
        margin-bottom: 0.8rem;
        max-width: 600px;
      }

      .small {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 2rem;
        max-width: 450px;
      }
    }
  }

  .placeHolderDivs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 4rem;
    }
  }

  .showMore {
    display: flex;
    margin-top: 1rem;
    margin-left: 0.6rem;

    button {
      padding: 0.6rem 1rem;
      font-weight: 400;
    }

    @media (max-width: 450px) {
      button {
        width: 100%;
        margin: 0 0.9rem;
      }
    }
  }

  .skeleton {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .cardLoaderWrapper {
      margin-right: 1.4rem;
      margin-bottom: 1.4rem;
    }
  }
}
