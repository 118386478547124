@import "variables.scss";

:local {
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1.6rem 1.6rem 1.6rem;
    height: 280px;
    background: $grey5;
    position: absolute;
    top: 0;
    left: 0;
    padding: 4rem;

    &.green {
      background-color: $lightgreen;
    }

    &.primary {
      background-color: rgba($primary, 0.2);
    }

    &.yellow {
      background-color: $lightyellow;
    }

    &.red {
      background-color: $lightred;
    }

    &.purple {
      background-color: $lightpurple;
    }

    &.blue {
      background-color: rgba($blue4, 0.2);
    }

    &.global {
      height: 376px;
    }

    .title {
      font-size: 2.2rem;
      line-height: 2.8rem;
      font-weight: 600;
      color: black;
      z-index: 1;
      white-space: pre-line;
    }

    .description {
      font-size: 15px;
      line-height: 22px;
      font-weight: 300;
      margin-top: 1rem;
      max-width: 80%;
    }

    .title {
      font-size: 2.8rem;
      line-height: 3.4rem;
      font-weight: 700;
      max-width: 600px;
      color: #122663;
      z-index: 1;
      white-space: pre-line;

      @media (max-width: 1080px) {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }

    .labels {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1.4rem;

      .label {
        padding: 5px 10px;
        background: white;
        color: white;
        font-size: 13px;
        line-height: 13px;
        display: inline-block;
        border-radius: 24px;
        border: 1.5px solid transparent;
        margin-right: 0.6rem;
        margin-bottom: 0.4rem;

        &:last-child {
          margin-right: 0;
        }

        &.green {
          background-color: $green;
        }

        &.primary {
          background-color: $primary;
        }

        &.yellow {
          background-color: $yellow;
        }

        &.red {
          background-color: $red;
        }

        &.purple {
          background-color: $purple;
        }

        &.blue {
          background-color: $blue4;
        }

        &.border-green {
          background-color: transparent;
          border-color: $green;
          color: $green;
        }

        &.border-primary {
          background-color: transparent;
          border-color: $primary;
          color: $primary;
        }

        &.border-yellow {
          background-color: transparent;
          border-color: $yellow;
          color: $yellow;
        }

        &.border-red {
          background-color: transparent;
          border-color: $red;
          color: $red;
        }

        &.border-purple {
          background-color: transparent;
          border-color: $purple;
          color: $purple;
        }

        &.border-blue {
          background-color: transparent;
          border-color: $blue4;
          color: $blue4;
        }
      }
    }

    .image {
      position: absolute;
      width: calc(100% + 3px);
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .popup {
    min-width: 350px;
    max-width: 350px;

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1.6rem;
      // margin-top: 0.4rem;

      button:first-child {
        margin-right: 0.6rem;
      }
    }
  }

  .notAdmin {
    padding: 1.4rem;
    width: 320px;
    font-size: 15px;
    line-height: 22px;

    a {
      color: $primary;
    }
  }

  .wrapper {
    margin-top: 355px;

    &.global {
      margin-top: 470px;

      @media (max-width: 650px) {
        margin-top: 505px;
      }

      .bar {
        top: 376px;
      }
    }

    .includes {
      margin-top: 3rem;
      margin-bottom: 3rem;

      @media (max-width: 650px) {
        padding-top: 4rem;
      }

      .title {
        font-size: 1.1rem;
        line-height: 1.1rem;
        font-weight: 500;
        margin-bottom: 2rem;
      }

      .numbers {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: flex-start;
        gap: 3rem;
        margin-top: 1.6rem;
        margin-bottom: 2rem;

        @media (max-width: 840px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 620px) {
          grid-template-columns: 1fr;
        }

        .number {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .icon {
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($yellow, 0.2);
            border-radius: $bradius3;
            margin-right: 1rem;

            svg {
              width: 20px;
              height: 20px;
            }

            svg path {
              fill: $yellow;
            }

            &.primary {
              background-color: $blue;

              svg path {
                fill: $primary;
              }
            }

            &.green {
              background-color: rgba($green, 0.2);

              svg path {
                fill: $green;
              }
            }
          }

          .right {
            flex: 1;

            .numberTitle {
              font-size: 1.3rem;
              line-height: 1.6rem;
              margin-bottom: 0.6rem;
            }

            .numberDesc {
              font-size: 15px;
              line-height: 22px;
              font-weight: 300;
            }
          }
        }
      }
    }

    &.global {
      .includes {
        margin-top: 0;
      }
    }

    .bar {
      padding: 1.6rem 5.5rem;
      border-top: 1px solid $grey5;
      border-bottom: 1px solid $grey5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% + 8rem);
      margin-left: -4rem;

      @media (max-width: 650px) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1.6rem 1.6rem;
        width: 100%;
      }

      .buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .buttonWrapper {
          margin-right: 1rem;

          &:last-child {
            margin-right: 0;
          }
        }

        a {
          border: none;
          margin: 0;
          padding: 0;
        }
      }

      .createdBy {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 650px) {
          margin-bottom: 1rem;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          @media (max-width: 650px) {
            justify-content: flex-start;
            align-items: flex-start;
          }

          h5 {
            font-size: 0.9rem;
            line-height: 0.9rem;
            margin-bottom: 0.3rem;
          }

          p {
            font-size: 0.8rem;
            line-height: 0.8rem;
            color: $grey1;
            font-weight: 400;
          }
        }

        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-left: 1rem;
        }

        .logo {
          margin-left: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    @media (max-width: 650px) {
      margin-top: 365px;
    }

    .bar {
      position: absolute;
      top: 280px;
      left: 0;
      right: 0;
      padding: 1.6rem 8rem;

      @media (max-width: 650px) {
        left: 4.6rem;
        padding: 1.6rem 1.6rem;
      }
    }

    .content {
      .description {
        margin-bottom: 3rem;
        max-width: 100%;
        white-space: pre-line;
      }

      .separate {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 1rem;
        transition: 400ms;

        &.show {
          margin-bottom: 2rem;
        }

        &::before {
          content: "";
          width: 100%;
          height: 1px;
          background-color: $grey5;
          position: absolute;
          top: 16px;
          left: 0;
          z-index: 0;
        }
      }

      .table {
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        transition: max-height 500ms;

        &.show {
          max-height: 2000px;
          overflow: auto;
        }

        .titleBar {
          width: 100%;
          height: 70px;
          background-color: rgba($yellow, 0.15);
          padding: 0.6rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-top: 2rem;

          &:first-child {
            margin-top: 0;
          }

          h5 {
            color: $yellow;
          }

          p {
            font-size: 14px;
            line-height: 18px;
            color: $darkgrey;
            max-width: 100%;
          }

          &.primary {
            background-color: $blue;

            h5 {
              color: $primary;
            }
          }

          &.green {
            background-color: $lightgreen;

            h5 {
              color: $green4;
            }
          }
        }

        .list {
          li {
            padding: 0.8rem 0.6rem;
            border-bottom: 1px solid $grey5;
            font-size: 15px;
            line-height: 22px;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &.hoverable {
              cursor: pointer;
            }

            .listRow {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }

            .moreDetail {
              max-height: 0;
              overflow: hidden;
              transition: max-height 200ms ease-in-out;

              &.show {
                max-height: 150px;
                overflow: auto;
              }

              .rowDescription,
              .rowDescription p {
                font-size: 14px;
                line-height: 20px;
                margin-top: 1rem;
                color: $darkgrey;
                font-weight: 300;
              }
            }

            .arrow {
              transform: rotate(90deg);
              transition: 400ms;
              margin-left: 1.4rem;

              svg path {
                stroke: $grey2;
              }

              &.open {
                transform: rotate(-90deg);
              }
            }

            .stars {
              margin-left: 1.2rem;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: $green4;
              opacity: 0.5;
              font-size: 13px;

              svg {
                height: 12px;
                width: 12px;
                margin-right: 0.2rem;
              }

              svg path {
                fill: $green4;
              }

              &.light {
                color: $green;

                svg path {
                  fill: $green;
                }
              }

              &.lightest {
                color: $green2;

                svg path {
                  fill: $green2;
                }
              }
            }
          }
        }
      }

      .section {
        margin: 3rem 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        &:last-child {
          margin-bottom: 0;
        }

        .dot {
          width: 8px;
          height: 8px;
          background: $yellow;
          border-radius: 50%;
          margin-right: 1rem;
          margin-top: 0.3rem;

          &.primary {
            background: $primary;
          }

          &.green {
            background: $green;
          }
        }

        .right {
          width: 90%;

          .title {
            font-size: 1.1rem;
            font-weight: 600;
            margin-bottom: 1rem;
          }

          .subtitle {
            max-width: 80%;
            font-weight: 300;
          }

          .keyObjectives {
            padding-top: 1rem;
            margin-top: 1rem;
            border-top: 1px solid $grey5;
            max-width: 80%;

            h1 {
              font-size: 2rem;
              line-height: 2.4rem;
              color: $black;
            }

            h2 {
              font-size: 1.4rem;
              line-height: 1.8rem;
              color: $black;
            }

            p {
              font-weight: 300;
              font-size: 15px;
              margin: 1rem 0;
            }

            strong {
              font-weight: 600;
            }

            ul {
              margin: 1rem;

              li {
                list-style-type: disc;
                font-size: 15px;
                line-height: 1.5rem;
                font-weight: 300;
                margin: 0.6rem 0;
                padding-left: 0.3rem;

                &::marker {
                  font-weight: 600;
                  color: $black !important;
                }
              }
            }

            ol {
              margin: 1rem;

              li {
                list-style-type: decimal;
                font-weight: 300;
                font-size: 15px;
                line-height: 1.5rem;
                margin: 0.6rem 0;
                padding-left: 0.6rem;

                &::marker {
                  font-weight: 500;
                  color: $black !important;
                }
              }
            }
          }

          .requirements {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 1rem;

            .requirement {
              padding: 1rem 0;
              width: 70%;
              border-bottom: 1px solid $grey5;

              &:last-child {
                border: none;
              }

              .reqDesc {
                margin-top: 0.4rem;

                p {
                  font-weight: 300;
                  font-size: 15px;
                  margin: 0.6rem 0;
                }

                strong {
                  font-weight: 600;
                }

                ul {
                  margin: 0.6rem 1rem;

                  li {
                    list-style-type: disc;
                    font-size: 15px;
                    line-height: 1.5rem;
                    font-weight: 300;
                    margin: 0.2rem 0;
                    padding-left: 0.3rem;

                    &::marker {
                      font-weight: 600;
                      color: $black !important;
                    }
                  }
                }

                ol {
                  margin: 0.6rem 1rem;

                  li {
                    list-style-type: decimal;
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 1.5rem;
                    margin: 0.2rem 0;
                    padding-left: 0.6rem;

                    &::marker {
                      font-weight: 500;
                      color: $black !important;
                    }
                  }
                }
              }
            }
          }

          .competencies {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 1rem;
            width: 100%;

            .group {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 2rem;
              max-width: 70%;

              .groupTitle {
                margin-bottom: 0.6rem;
                padding-bottom: 1rem;
                width: 100%;
                font-weight: 600;
                border-bottom: 1px solid $grey5;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .stars {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  svg {
                    margin-left: 0.2rem;
                    transform: scale(0.9);
                  }
                }
              }

              &:first-child {
                .groupTitle .stars svg path {
                  fill: $green;
                }
              }

              &:nth-child(2) {
                .groupTitle .stars svg path {
                  fill: $primary;
                }
              }

              &:nth-child(3) {
                .groupTitle .stars svg path {
                  fill: $grey1;
                }
              }

              .competency {
                padding: 1rem 0;
                width: 100%;
                border-bottom: 1px solid $grey5;

                .title {
                  font-size: 0.9rem;
                  margin-bottom: 0.4rem;
                }

                .description {
                  margin: 0;
                  max-width: 100%;
                  font-weight: 300;
                  font-size: 0.9rem;
                  line-height: 1.4rem;
                }

                &:last-child {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
