@import "variables.scss";

:local {
  .wrapper {
    border: 1px solid $grey5;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.8rem 1.4rem;
    border-radius: $bradius3;

    &.candidate {
      height: 100%;
    }

    @media (max-width: 1080px) {
      max-width: 100%;
    }

    .left {
      margin-right: 1.8rem;
    }

    .right {
      h3 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0.4rem;
      }

      p {
        color: $darkgrey;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
      }

      button {
        margin-top: 1rem;
        font-size: 14px;
        background-color: $blue;
      }
    }
  }
}
