@import "variables.scss";

:local {
  .bigSelectorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6rem auto;

    @media (max-width: 1720px) {
      margin: 4rem auto;
      // left: 0 !important;
      margin-left: -256px !important;
    }

    @media (max-width: 960px) {
      margin-left: 0 !important;
    }

    &::before {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 99999;
    }

    h3 {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }

    p {
      color: $grey1;
      font-weight: 300;
      text-align: center;
    }

    .bigSelector {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 99999;

      @media (min-width: 960px) {
        margin-left: 0;
      }

      .account {
        font-size: 0.8rem;
        font-weight: 400;
        color: $grey2;
        max-width: 50%;
        text-align: center;

        @media (max-width: 960px) {
          max-width: 100%;
        }
      }

      .workspaces {
        width: 100%;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 2rem;

        button {
          width: 100%;
          justify-content: flex-start;
          padding: 0;
          border: none;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            color: $darkgrey;
            padding: 0.8rem;

            .icon {
              width: 32px;
              height: 32px;
              border-radius: $bradius3;
              background-color: $haze;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 1rem;

              svg path {
                fill: $yellow;
              }
            }

            .arrow {
              margin-left: 0.6rem;

              &.close {
                svg {
                  transform: rotate(-90deg);
                }
              }

              svg {
                transform: rotate(90deg);

                path {
                  stroke: $grey1;
                }
              }
            }
          }
        }
      }

      .accountsWrapper {
        max-height: 0;
        height: 100%;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1rem;
        transition: max-height 200ms ease-in-out;
        position: relative;

        &.show {
          max-height: 300px;
          padding: 0.6rem 0;
          border-top: 1px solid $grey5;
          border-bottom: 1px solid $grey5;
        }

        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin-bottom: 0.5rem;

          button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-radius: $bradius2;
            padding: 0.8rem;
            border: 1px solid $grey5;
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;

            &:hover {
              border-color: $grey3;
            }

            .left {
              display: flex;
              align-items: center;
              padding: 0;

              .avatar {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: $purple;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 2px;
                margin-right: 1.2rem;

                h5 {
                  font-size: 11px;
                  line-height: 11px;
                  font-weight: 600;
                  color: white;
                }

                &.blue {
                  background: $primary;
                }

                &.green {
                  background: $green;
                }

                &.purple {
                  background: $purple;
                }

                &.yellow {
                  background: $yellow;
                }

                &.red {
                  background: $red;
                }
              }
            }

            .arrow {
              margin-right: 0.2rem;

              svg path {
                stroke: $grey1;
              }
            }
          }
        }
      }

      .orgsTitle {
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 500;
      }

      .orgList {
        width: 500px;

        @media (max-width: 960px) {
          max-width: 95%;
          width: 100%;
        }

        button {
          width: 100%;
          margin-bottom: 1rem;

          &:last-child {
            margin: 0;
          }

          .org {
            border: 1px solid $grey5;
            padding: 0.8rem;
            border-radius: $bradius3;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            transition: $transition;

            &.new {
              h5 {
                color: $primary;
                font-weight: 400;
              }

              .left .avatar {
                background: $blue;

                h5 {
                  color: $primary;
                }
              }
            }

            &:hover {
              border-color: $grey2;
            }

            .left {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .avatar {
                width: 32px;
                height: 32px;
                border-radius: $bradius3;
                background: $purple;
                border: none;
                margin-right: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;

                h5 {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 500;
                  color: white;
                }

                &.blue {
                  background: $primary;
                }

                &.green {
                  background: $green;
                }

                &.purple {
                  background: $purple;
                }

                &.yellow {
                  background: $yellow;
                }

                &.red {
                  background: $red;
                }
              }
            }

            .members {
              font-size: 13px;
              color: $grey1;
              font-weight: 300;
              margin-right: 0.6rem;
            }
          }
        }
      }
    }
  }
}
