@import 'variables.scss';

:local {
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .filters {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;

      .filter {
        margin-right: 0.6rem;

        button {
          background: $grey5;
          border-radius: $bradius3;
          font-size: 14px;
          font-weight: 600;
          padding: 0.6rem 1.2rem;

          &:hover {
            background: rgba($grey5, 0.7);
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &.selected {
          button {
            background: $blue;
            color: $primary;
          }
        }
      }
    }

    .insights {
      columns: 3;
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      column-gap: 1.2rem;
      padding: 0.8rem;

      @media (max-width: 915px) {
        columns: 2;
      }

      @media (max-width: 520px) {
        columns: 1;
      }

      .insight {
        margin: 0rem 0.4rem 1.6rem 0.4rem;
        display: block;
        width: 100%;
        border-radius: $bradius3;
        border: 1px solid $grey5;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03);
        break-inside: avoid-column;
        transition: $transition;

        button {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 1.6rem;
        }

        &:hover {
          box-shadow: $boxshadow;
        }

        .icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: $primary;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;

          svg path {
            fill: white;
          }
        }

        .avatars {
          display: flex;
          margin-top: 1rem;
        }

        .score {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          border: 4px solid $green4;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;

          p {
            color: $green4;
            font-size: 16px;
            font-weight: 600;
          }

          &.primary {
            border-color: $primary;

            p {
              color: $primary;
            }
          }
        }

        .title {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 0.6rem;
          max-width: 95%;
        }

        .description {
          color: $grey1;
          font-size: 14px;
          line-height: 19px;
          font-weight: 300;
        }

        .miniChartWrapper {
          width: 100%;

          .miniChart {
            margin-top: 1.6rem;
            width: 100%;
            height: 8px;
            background: linear-gradient(
              to left,
              #018949 0%,
              #37c884 31.85%,
              #d3ffeb 50.72%,
              #dadada 85.42%
            );
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 2px;

            .chart {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              width: 100%;

              &:before {
                position: absolute;
                content: '';
                width: 2px;
                height: 4px;
                background: $grey3;
                left: 50%;
                bottom: 0px;
              }

              &:after {
                position: absolute;
                content: '';
                width: 2px;
                height: 4px;
                background: $grey3;
                left: 50%;
                top: 0px;
              }

              .group {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 50%;
                padding-left: 1rem;

                .avatar {
                  border: 2px solid white;
                  border-radius: 50%;

                  &:nth-child(2) {
                    margin-left: -10px;
                    z-index: 10;
                  }
                  &:nth-child(3) {
                    margin-left: -10px;
                    z-index: 10;
                  }
                  &:nth-child(4) {
                    margin-left: -10px;
                    z-index: 11;
                  }
                }

                .more {
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                  border: 2px solid white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: $blue;
                  color: $primary;
                  font-weight: 500;
                  font-size: 12px;
                  margin-left: -10px;
                  z-index: 12;
                  letter-spacing: 1px;
                }

                &:last-child {
                  justify-content: flex-end;
                  padding-right: 1rem;
                }
              }
            }
          }

          .legend {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 0.8rem;

            p {
              font-size: 12px;
              font-weight: 300;
              color: $grey2;

              &:last-child {
                color: $green4;
              }
            }
          }
        }
      }
    }
    .emptyState {
      width: 100%;
      text-align: center;
      margin: 0 auto;

      p {
        color: $grey2;
      }
    }
  }
}
