@import "variables.scss";

:local {
  .title {
    font-family: proxima-nova;
    color: $black;
    max-width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
      margin-right: 1rem;
    }

    .icon {
      margin-right: 1.6rem;
      width: 48px;
      height: 48px;
      border-radius: $bradius2;
      background-color: $blue;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: $primary;
        }
      }

      &.grey {
        background-color: $grey5;

        svg path {
          fill: $darkgrey;
        }
      }

      &.purple {
        background-color: $lightpurple;

        svg path {
          fill: $purple;
        }
      }
    }

    @media (max-width: 476px) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }
}
