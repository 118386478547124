@import 'variables.scss';

:local {
  .wrapper {
    max-width: $modalWrapper;
    margin: 1rem auto;

    .title {
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .description {
      font-size: 18px;
      line-height: 30px;
      font-weight: 300;
      max-width: 80%;
    }

    .behaviors {
      margin-top: 3rem;

      .interviewTitle {
        font-size: 18px;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $grey5;
        width: 100%;
      }

      .prefix {
        color: black;
        font-size: 1rem;
        font-weight: 300;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1.4rem;

        svg {
          margin-right: 0.6rem;
          margin-top: -2px;
        }

        svg path {
          fill: $green;
        }

        i {
          color: $green;
        }
      }

      .questionsWrapper {
        border: 1px solid $grey4;
        border-radius: $bradius;
        position: relative;

        &:after {
          content: '';
          width: calc(100% + 2px);
          height: 4px;
          background: $green;
          position: absolute;
          top: -1px;
          left: -1px;
          border-top-left-radius: $bradius;
          border-top-right-radius: $bradius;
          z-index: 9;
        }

        .question {
          border-bottom: 1px solid $grey4;
          justify-content: flex-start;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.4rem;
          font-weight: 300;
          color: $black;
          padding: 1.2rem;

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
