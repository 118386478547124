@import "variables.scss";

:local {
  .editor {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6rem;
    height: 100%;
    width: 100%;
    max-height: 440px;
    font-family: proxima-nova;
    color: $black;
    overflow: auto;

    .textEditor {
      @media (max-height: 830px) {
        max-height: 300px;
      }

      @media (max-height: 730px) {
        max-height: 200px;
      }
    }

    h1 {
      font-size: 1.8rem;
      font-weight: 500;
      font-family: proxima-nova;
      color: $black;
      line-height: 2.8rem;
    }

    h2 {
      font-size: 1.4rem;
      font-weight: 500;
      font-family: proxima-nova;
      color: $black;
      line-height: 2.4rem;
    }

    h3 {
      font-size: 1.6rem;
      font-weight: 500;
      font-family: proxima-nova;
      color: $black;
      line-height: 2rem;
    }

    strong {
      font-weight: 600;
    }

    em {
      font-style: italic;
    }

    ul {
      margin-left: 1rem;

      li {
        list-style: disc;
        font-family: proxima-nova;
        color: $black;
        line-height: 2rem;
      }
    }

    ol {
      margin-left: 1rem;

      li {
        list-style: decimal;
        font-family: proxima-nova;
        color: $black;
        line-height: 2rem;
      }
    }
  }

  .menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 100;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    flex-wrap: wrap;
    border-bottom: 1px solid $grey4;
    background: white;

    .button {
      color: $black;
      font-size: 1.1rem;
      margin: 0 0.4rem;
      padding: 0.2rem 0.6rem;
      border: none;
      border-radius: $bradius;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: $grey5;
      }

      &.active,
      &:active {
        background: $grey5;
      }
    }
  }
}
