@import 'variables.scss';

:local {
  .main {
    padding: 1.6rem;

    .title {
      margin-bottom: 0.6rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.1rem;
    }

    .subtitle {
      font-size: 0.9rem;
      line-height: 1.3rem;
      color: $darkgrey;
      font-weight: 300;
    }

    .content {
      width: 100%;
      margin-top: 1rem;

      form {
        .row {
          margin-bottom: 1rem;

          h5 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 0.85rem;
            font-weight: 400;
            margin-bottom: 0.4rem;
          }
        }

        .footerButtons {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          button:first-child {
            margin-right: 0.6rem;
          }
        }

        input {
          width: 100%;
          font-size: 15px;
          color: $black;

          &::placeholder {
            font-size: 14px;
            color: $grey1;
          }
        }

        textarea {
          height: 110px !important;
          width: 100%;
          font-size: 15px;
          color: $black;

          &::placeholder {
            font-size: 14px;
            color: $grey1;
          }
        }
      }
    }
  }
}
