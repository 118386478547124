@import "variables.scss";

:local {
  .skillsWrapper {
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    z-index: 9999;

    .header {
      justify-content: center;
      padding: 1.4rem 0;

      .title {
        padding: 0;

        h5 {
          margin: 0;
        }
      }

      button {
        position: absolute;
        right: 1rem;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .skills {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 2rem;
      margin-top: 6rem;

      button {
        width: 100%;

        &:focus {
          .skill {
            border: 2px solid $primary;
          }
        }
      }

      .seeMore {
        width: auto;
        margin-top: 0.4rem;

        button {
          font-size: 0.9rem;
        }
      }

      .skill {
        width: 100%;
        padding: 1.4rem;
        margin-bottom: 0.6rem;
        border: 1px solid $grey5;
        border-radius: $bradius3;
        box-shadow: 0px 2px 2px 0px #00000008;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.selected {
          opacity: 0.5;
        }

        p {
          margin-top: 0.4rem;
          font-size: 0.9rem;
          line-height: 1.4rem;
          font-weight: 300;
          color: $darkgrey;
        }
      }
    }
  }

  .header {
    width: 100%;
    border-bottom: 1px solid $grey5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;
    position: fixed;
    background: white;
    z-index: 999;

    .title {
      padding-left: 2rem;

      h5 {
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 0.4rem;
      }

      p {
        font-size: 14px;
        line-height: 14px;
        color: $grey1;
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 2rem;

      a {
        border: none;
        padding: 0;
        margin-left: 0.6rem;
      }
    }

    .progressLine {
      height: 2px;
      background: $primary;
      position: absolute;
      bottom: 0;

      &.step-1 {
        width: 33%;
      }

      &.step-2 {
        width: 66%;
      }

      &.step-3 {
        width: 100%;
      }
    }
  }

  .main {
    @media (max-width: 768px) {
      padding: 0 2rem;
    }

    .titleRow {
      margin: 3rem 0;
      margin-top: 6rem;
      max-width: 60%;

      @media (max-width: 768px) {
        max-width: 100%;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        color: $darkgrey;
        margin-top: 0.6rem;
      }

      .hints {
        margin-top: 1.2rem;

        .hint {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          p {
            font-size: 0.9rem;
            font-weight: 400;
            color: $darkgrey;
            margin: 0.3rem 0;
          }

          i {
            margin-right: 0.6rem;
          }

          svg path {
            fill: $grey2;
          }
        }
      }
    }

    .surveyWrapper {
      max-width: $wrapperWidth;
      width: 100%;
      margin: 4.8rem auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .droppable {
        width: 92%;
        // min-height: 938px;

        &.isDraggingOver {
          .slots {
            background: rgba($grey5, 0.3);
            transition: $transition;
            // min-height: 938px;
          }
        }
      }

      .slots {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: rgba($grey5, 0.5);
        padding: 0.8rem;
        border-radius: $bradius3;
        position: relative;
        margin-left: 2rem;
        transition: $transition;

        .legend {
          position: absolute;
          left: 0;
          font-weight: 500;
          color: $darkgrey;

          &:first-child {
            top: -2.4rem;
          }

          &:nth-child(2) {
            bottom: -2.4rem;
          }
        }

        .bar {
          content: "";
          height: calc(100% + 4.8rem);
          min-height: 852px;
          width: 5px;
          border-radius: $bradius2;
          background: $green;
          position: absolute;
          left: -2rem;
          top: -2.4rem;
          background: linear-gradient(
            #23c97b 0%,
            #4ec88e 18.75%,
            #72c79f 30.73%,
            #d6d6d6 38.2%,
            #d6d6d6 54.04%,
            #bebebe 71.3%,
            #9f9f9f 84.9%,
            #696969 95.83%
          );
        }

        .draggable {
          width: 100%;

          &.isDragging {
            height: 200px;
            background: red;
          }
        }

        .slot {
          width: 100%;
          height: 59.2px;
          border-radius: $bradius3;
          margin-bottom: 0.55rem;
          background: rgba($grey4, 0.5);
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .lines {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 1.6rem;

            svg g rect {
              fill: $grey2;
            }

            &.second {
              svg g rect {
                fill: rgba($grey2, 0.5);
              }
            }

            &.third {
              svg g rect {
                fill: rgba($grey2, 0.3);
              }
            }
          }

          &.card {
            background: white;
            border: 1px solid $grey5;
            padding: 1rem;
            height: auto;
            position: relative;
            .dragIcon {
              padding-right: 1rem;
            }
            .content {
              flex-direction: column;
              align-items: flex-start;
            }

            .edit {
              position: absolute;
              right: 0.6rem;
              top: 0.6rem;

              button {
                height: 24px;
                width: 24px;

                svg {
                  height: 18px;
                  width: 18px;

                  path {
                    fill: $grey2;
                  }
                }
              }
            }

            h5 {
              font-size: 0.9rem;
              line-height: 1.3rem;
            }

            p {
              margin-top: 0.2rem;
              font-size: 0.85rem;
              line-height: 1.4rem;
              font-weight: 300;
              color: $darkgrey;
            }

            .seeMore {
              width: auto;
              margin-top: 0.6rem;

              button {
                font-size: 0.9rem;
                color: $primary;
              }
            }
          }

          button {
            width: 100%;
            height: 100%;
            font-weight: 500;
            color: rgba($darkgrey, 0.5);
          }

          &.hover {
            &:after {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
