@import "variables.scss";

:local {
  .wrapper {
    position: relative;
    background: white;
    border: 1px solid $grey5;
    border-radius: $bradius2;
    box-shadow: $boxshadow1;
    transition: $transition;
    max-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-overflow: ellipsis;
    font-family: proxima-nova;

    &.primary {
      border-top: 4px solid $primary;
    }

    &.green {
      border-top: 4px solid $green;
    }

    &.active {
      border: 2px solid $primary;
    }

    &.large {
      max-width: 100%;
      width: 100%;
    }

    &.noBorder {
      border: none;
    }

    &.noShadow {
      box-shadow: none;
    }

    &.padding {
      padding: 2rem;
    }

    &.height {
      min-height: 360px;
    }
  }
}
