@import "variables.scss";

:local {
  .group {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      position: relative;

      @media (max-width: 340px) {
        button {
          margin-bottom: 0.4rem;
          width: 100%;
        }
      }
    }

    .avatarWrapper {
      margin-right: 1rem;
    }

    button {
      margin-right: 0.6rem;
      position: relative;
    }

    .fileInput {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .filedrop {
      position: absolute;
      width: 100%;
      height: 100%;

      &.isDragging {
        width: 115%;
        height: 200%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        background: $grey4;
        border: 2px dashed $grey3;
        border-radius: $bradius;

        &:after {
          content: "Drop here";
          color: $darkgrey;
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
  }
}
