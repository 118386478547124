@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: $wrapperWidth;
    width: 100%;

    .topWrapper {
      width: 100%;
      margin-bottom: 2rem;

      .name {
        color: $grey2;
      }
    }

    .searchrow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 1rem;

      .search {
        width: 40%;
      }

      @media (max-width: 486px) {
        flex-direction: column;

        .search {
          width: 100%;
          margin-bottom: 1rem;
        }

        .invite,
        .invite button {
          width: 100%;
        }
      }
    }
  }
}
