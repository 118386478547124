@import "variables.scss";

:local {
  .lineWrapper {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    .line {
      position: relative;
      width: 100%;
      height: 8px;
      background: $grey4;
      background: linear-gradient(
        90deg,
        #d9d9d9 0%,
        #f0fff6 49.48%,
        #6fd1bb 75%,
        #30be9f 100%
      );
      border-radius: 8px;

      &.personal {
        &:after {
          content: "";
          height: 48px;
          width: 1px;
          background: $grey3;
          position: absolute;
          top: -19px;
          left: calc(50% - 1.5px);
        }
      }

      .teamScore {
        position: absolute;
        top: -19px;
        height: 48px;
        width: 1px;
        background: $grey2;
        display: flex;
        justify-content: center;

        .number {
          position: relative;
          top: -20px;
          text-align: center;
          font-weight: 500;
          color: $primary;
        }

        &.highlight {
          background: $darkgrey;

          .number {
            color: $darkgrey;
          }
        }
      }

      .avatar {
        position: absolute;
        top: -14px;
        height: 36px;
        width: 36px;
        margin: 0 auto;
        border-radius: 50%;
        background: white;
        border: 2px solid white;
        z-index: 10;

        div[aria-label="initials"] {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-size: 12px;
            line-height: 12px;
            margin: 0;
          }
        }

        &.highlight {
          z-index: 11;
          transform: scale(1.3);
          border: 2px solid $primary;
          box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
        }

        .score {
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: $green4;
          z-index: 1;
          margin-top: 0;

          &.grey {
            color: $darkgrey;
          }
        }
      }
    }

    .opposites {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 2rem;

      h5 {
        margin-bottom: 0.4rem;
      }

      li {
        line-height: 1.2rem;
      }

      .left {
        margin-right: 2rem;
      }

      .right {
        margin-left: 2rem;

        h5,
        li {
          text-align: right;
        }

        .labelDesc {
          text-align: right;
        }
      }

      .label {
        color: $black;
      }

      .labelDesc {
        margin-top: 0.2rem !important;
        color: $grey1;
        font-size: 0.8rem !important;
        line-height: 1.2rem !important;
        font-weight: 300;
      }
    }
  }
}
