@import "variables.scss";

:local {
  .footer {
    margin-top: 6rem;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 3rem;
    width: 100%;
    margin-bottom: 4rem;

    &.incomplete {
      margin-bottom: 6rem;
    }

    &.start {
      grid-auto-rows: auto;
    }

    @media (max-width: 1330px) {
      grid-template-columns: 1fr;
    }

    .usercard {
      .incomplete {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.4rem;

        @media (max-width: 470px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .left {
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 470px) {
            margin-bottom: 1rem;
          }

          h5 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $black;

            .icon {
              border: 1px solid $yellow;
              border-radius: 50%;
              width: 18px;
              height: 18px;
              margin-right: 0.6rem;

              svg {
                width: 16px;
                height: 16px;

                path {
                  stroke: $yellow;
                }
              }
            }
          }

          .progress {
            margin-left: 1.4rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p {
              font-size: 14px;
              line-height: 14px;
              font-weight: 300;
              color: $darkgrey;
            }

            .bar {
              width: 40px;
              height: 4px;
              border-radius: 25px;
              background-color: $grey5;
              margin-left: 0.6rem;

              .fill {
                border-radius: 25px;
                background-color: $primary;
                height: 100%;
              }
            }
          }
        }

        a {
          font-size: 15px;
          line-height: 15px;
          color: $primary;
          border-color: $primary;
        }
      }
    }

    .actions {
      width: 100%;

      .candidateNotice {
        padding: 2.8rem 2rem;
        background-color: $grey6;
        border-radius: $bradius2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .subtitle {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 0.8rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .icon {
            margin-right: 0.4rem;

            svg {
              width: 18px;
              height: 18px;

              path:nth-child(1) {
                fill: $primary;
              }
            }
          }
        }

        .info {
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;

          a {
            margin-right: 0.2rem;
          }
        }
      }
    }
  }

  .banner {
    margin-top: 5rem;
  }

  .section {
    margin-bottom: 4rem;
    width: 100%;

    .top {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $grey5;
      padding-bottom: 1.4rem;
      margin-bottom: 1.4rem;

      @media (max-width: 976px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .left {
        .title {
          font-weight: 500;
          font-size: 1.4rem;
        }

        .subtitle {
          font-weight: 300;
        }

        @media (max-width: 976px) {
          margin-bottom: 1rem;
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .progressWrapper {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          p {
            margin-right: 1rem;
            font-size: 12px;
            line-height: 12px;
            color: $grey1;
          }

          .progressBar {
            width: 100px;
            height: 4px;
            border-radius: 25px;
            background-color: $grey5;

            .progress {
              height: 100%;
              background-color: $primary;
              border-radius: 25px;
            }
          }
        }
      }
    }
  }
}
