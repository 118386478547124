@import "variables.scss";

:local {
  .inputWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 56px;
    font-size: 15px;
    line-height: 22px;
    padding: 0.4rem 0.8rem;
    padding-right: 0.4rem;
    background-color: white;
    transition: 200ms ease-in-out;
    border: 1px solid $grey5;
    border-radius: $bradius;

    @media (max-width: 1280px) {
      margin: 0;
    }

    @media (max-width: 960px) {
      margin: 0;
    }

    &.fullscreen {
      @media (max-width: 960px) {
        max-width: calc(100% - 65px);
        margin: 0;
      }
    }

    &.isEnlarged {
      max-width: 100%;
      border: 1px solid $grey5;
      border-radius: 50px;
      margin: 1rem 0;

      &:focus-within {
        border-color: $grey3;
      }
    }

    // &:hover {
    //   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    // }

    .wisnioAvatar {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: $lightpurple;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: $purple;
        }
      }
    }

    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .actionButtons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 1;

      .sendIcon {
        button {
          height: 42px;
          width: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0.5px solid $grey4;
          border-radius: 50%;

          &:hover {
            background-color: white;
            border-color: $grey2;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

            svg {
              fill: $purple;
            }
          }
        }
      }

      .fullscreenIcon {
        button {
          height: 42px;
          width: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0.5px solid transparent;
          border-radius: 50%;

          &:hover {
            background-color: white;
            border-color: $grey4;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

            svg {
              fill: $purple;
            }
          }
        }
      }
    }

    textarea {
      width: 100%;
      font-size: 15px;
      line-height: 22px;
      font-family: proxima-nova;
      border: 0;
      flex: 1;
      resize: none;

      &:disabled {
        background-color: transparent;
        color: $grey3;
        cursor: not-allowed;
      }

      &::placeholder {
        font-size: 15px;
        line-height: 22px;
        white-space: nowrap;
        font-weight: 400;
        color: $grey2;
      }
    }
  }
}
