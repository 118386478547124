@import "variables.scss";

:local {
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    20% {
      transform: translateX(100%);
    }
  }

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.8rem;
    border-radius: 25px;
    background-color: $lightpurple;
    max-width: 160px;
    border: 1px solid $lightpurple;
    background: linear-gradient(-45deg, #f8f4ff, #f8efff, #f8f3ff, #f8f4ff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    transition: ease-in-out 250ms;
    position: relative;
    cursor: default;

    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: -3px;
    //   left: -3px;
    //   width: calc(100% + 6px);
    //   height: calc(100% + 6px);
    //   border-radius: 25px;
    //   background-color: rgba(205, 47, 231, 0.15);
    //   filter: blur(3px);
    //   -webkit-filter: blur(3px);
    //   z-index: -1;
    //   opacity: 0;
    // }

    &:hover {
      border-color: rgba($purple, 0.1);

      // &:before {
      //   opacity: 1;
      // }
    }

    &:after {
      content: "";
      top: 0;
      transform: translateX(100%);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      animation: slide ease-in-out 6s infinite;
      background: -moz-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
      ); /* FF3.6+ */
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(50%, rgba(255, 255, 255, 0.6)),
        color-stop(99%, rgba(128, 186, 232, 0)),
        color-stop(100%, rgba(125, 185, 232, 0))
      ); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
      ); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
    }

    .icon {
      margin-right: 0.6rem;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    p {
      color: $purple;
      font-size: 13px;
      line-height: 13px;
    }
  }
}