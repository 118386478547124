@import "variables.scss";

:local {
  .label {
    font-size: 11px;
    line-height: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: $grey6;
    border-radius: $bradius;
    color: $darkgrey;
    padding: 0.3rem 0.4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    // Colors

    &.primary {
      background-color: $blue;
      color: $primary;
    }

    &.blue {
      background-color: $primary;
      color: white;
    }

    &.green {
      background-color: $lightgreen;
      color: $green;
    }

    &.yellow {
      background-color: $lightorange;
      color: $yellow;
    }

    &.red {
      background-color: $red;
      color: $white;
    }

    &.grey {
      background-color: $grey5;
      color: $grey1;
      font-weight: 500;
    }

    // Types

    &.round {
      border-radius: 25px;
      padding: 0.3rem 0.6rem;
    }
  }
}
