@import 'variables.scss';

:local {
  .frozenWrapper {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    // pointer-events: none;
    overflow: hidden;
    // z-index: 9995;

    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      background: rgba(white, 0.8);
      z-index: 1003;
    }
  }
}
