@import "variables.scss";

:local {
  .main {
    width: 100%;

    .close {
      svg {
        height: 22px;
        width: 22px;
      }
    }

    .arrow {
      transform: rotate(180deg);

      svg {
        margin-left: 0.6rem;
      }
    }

    .downArrow {
      transform: rotate(90deg);
      margin-left: 0.4rem;
    }

    .sidebarWrapper {
      width: 260px;
      min-width: 260px;
      position: relative;
      margin-right: 6rem;
    }

    .content {
      max-width: 100%;
      margin: 2rem 0;
      margin-top: 6rem;
      width: 100%;

      .mainRow {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: 1350px) {
          flex-direction: column;
        }

        .mainLeft {
          width: 55%;
          margin-right: 4rem;

          @media (max-width: 1350px) {
            width: 100%;
          }
        }

        .mainRight {
          flex: 1;

          .rightTitle {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              width: 24px;
              height: 24px;
              border-radius: $bradius2;
              background-color: $yellow;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 0.8rem;

              svg path {
                fill: $extradarkgrey;
              }
            }
          }

          .rightDescription {
            ul {
              li {
                margin: 1rem 0;
                color: $darkgrey;
                font-size: 14px;
                line-height: 24px;
                font-weight: 300;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .checkIcon {
                  margin-right: 0.6rem;

                  svg {
                    width: 28px;
                    height: 28px;

                    path {
                      stroke: $green;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .head {
        // border-bottom: 1px solid $grey5;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: 1238px) {
          flex-direction: column;
        }

        .row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .candidate {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          &.marginLeft {
            margin-left: 3rem;
          }

          .right {
            margin-left: 1rem;

            .name {
              display: flex;
              align-items: center;
              margin: 0;
              font-size: 1.2rem;
              letter-spacing: 0;
              text-wrap: nowrap;
            }

            .email {
              color: $darkgrey;
            }
          }
        }

        .left {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          flex: 1;

          @media (max-width: 1350px) {
            width: 100%;
          }

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 4rem;
            width: 100%;

            @media (max-width: 1350px) {
              justify-content: flex-start;

              .candidate {
                margin-right: 4rem;
              }
            }

            .icon {
              margin-left: 0.4rem;
            }
          }
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @media (max-width: 440px) {
            font-size: 1.6rem;
            line-height: 1.8rem;
          }

          .icon {
            margin-right: 1.6rem;
            width: 48px;
            height: 48px;
            border-radius: $bradius3;
            background-color: $extradarkgrey;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 18px;
              height: 18px;

              path {
                fill: white;

                &:nth-child(3),
                &:nth-child(4) {
                  fill: $extradarkgrey !important;
                }
              }
            }
          }
        }

        .subtitle {
          color: $darkgrey;
          font-size: 1rem;
          line-height: 1.6rem;
          width: 50%;

          @media (max-width: 1238px) {
            width: 100%;
          }
        }

        h4 {
          margin-top: 3rem;
          font-size: 1.4rem;
          font-weight: 500;
        }

        .list {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;

          .listItem {
            width: 100%;
            margin: 1rem 0;

            h5 {
              margin-bottom: 0.4rem;
              font-size: 1rem;
              font-weight: 500;
            }

            p {
              color: $darkgrey;
            }
          }
        }

        .buttonGroup {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.6rem;

          .button:first-child {
            margin-right: 1rem;
          }

          a {
            border: none;
            padding: 0;
          }

          i {
            font-size: 12px;
          }
        }
      }

      .basedOn {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: $darkgrey;
      }

      .template {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 3rem;

        .templateTitle {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transition: $transition;

          h5 {
            border-bottom: 1px solid transparent;
          }

          &:hover {
            h5 {
              color: $primary;
              border-color: $primary;
            }

            .templateIcon {
              display: none;

              &.hover {
                display: flex;
              }
            }
          }

          .templateIcon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: $blue;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;

            svg {
              width: 14px;
              height: 14px;

              path {
                fill: $primary;
              }
            }

            &.hover {
              background-color: $primary;
              display: none;

              svg {
                width: 16px;
                height: 16px;
                margin-top: -1px;

                path {
                  fill: white;
                }
              }
            }
          }
        }

        .labels {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: 1rem;

          .label {
            margin-right: 0.6rem;
            font-size: 11px;
            line-height: 11px;
            color: white;
            background-color: $primary;
            border: 0.5px solid $primary;
            padding: 4px 12px;
            border-radius: 25px;

            &.stage {
              background-color: white;
              border-color: $primary;
              color: $primary;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .info {
        margin-top: 3rem;
        margin-bottom: 3rem;

        .infoTitle {
          margin-top: 2rem;
          margin-bottom: 1rem;
          font-size: 11px;
          line-height: 11px;
          font-weight: 500;
          text-transform: uppercase;
          color: $grey1;
          letter-spacing: 1px;
        }

        .infoName {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: $black;
          margin-bottom: 0.6rem;
        }

        .infoText {
          color: $darkgrey;
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
        }
      }
    }

    .switchWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .label {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-right: 1rem;
      }

      .switch {
        display: flex;

        .active {
          button {
            background-color: $grey4;
          }
        }

        button:first-child {
          margin-right: 0.6rem;
        }
      }
    }

    .footerButton {
      margin: 4rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .button:first-child {
        margin-right: 1rem;
      }

      button {
        margin-right: 0.6rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
