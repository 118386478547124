@import "variables.scss";

:local {
  .wisGPTSummary {
    margin-top: 3rem;

    .text {
      min-height: 152px;
    }

    .buttons {
      margin-top: 2rem;
    }

    p {
      font-size: 1.1rem;
      font-weight: 300;
      color: $darkgrey;
      margin-bottom: 0.8rem;
    }

    a {
      color: $primary;
      border-color: $primary;

      &:hover {
        color: $primary;
        border-color: $primary;
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .loadingIcon {
      i {
        animation-name: spin;
        animation-duration: 1200ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    .submitWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 3rem;

      &.relative {
        position: relative;
        bottom: 0;
        margin-top: 2rem;
      }

      button:first-child {
        margin-right: 1rem;
      }

      button {
        box-shadow: 0px 1.5px 3px 0px rgba(black, 0.08);
      }
      .loading {
        i {
          animation: rotating 2s linear infinite;
        }
      }
    }
  }
}
