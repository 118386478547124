@import 'variables.scss';

:local {
  .label {
    background: $yellow;
    color: white;
    line-height: 1rem;
    font-weight: 400;
    border-radius: 0.3rem;
    padding: 0.6rem 0.8rem;
    font-size: 0.9rem;

    &.similar {
      background: $yellow;
    }
    &.verySimilar {
      background: $red;
    }
    &.diverse {
      background: $grey1;
    }
    &.veryDiverse {
      background: $green;
    }
  }
}
