@import "variables.scss";

:local {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .main {
    margin-top: 1rem;

    @media (max-width: 1080px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    h2 {
      margin-bottom: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .checkWrapper {
        width: 41px;
        height: 41px;
        border-radius: 50%;
        border: 1px solid $grey3;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;

        i {
          font-size: 1rem;
          color: $grey3;
        }
      }
    }

    p {
      font-size: 18px;
      line-height: 26px;
      color: $darkgrey;
      font-weight: 300;
      margin-top: 1rem;
      max-width: 85%;
    }

    .content {
      width: 100%;
      margin-top: 2rem;

      .containerWrapper {
        margin-bottom: 1rem;
      }
    }

    .bigWrapper {
      padding: 2rem;
      width: 100%;
    }

    .content {
      margin: 2rem 0;
      width: 100%;

      .containerWrapper {
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border: 1px solid $grey5;
        border-radius: $bradius3;
        position: relative;

        .buttons {
          position: absolute;
          top: 1.55rem;
          right: 1.4rem;
          z-index: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          button {
            font-size: 15px;
          }

          &.open {
            top: 0.8rem;
          }

          .icon {
            transform: rotate(90deg);

            &.open {
              transform: rotate(-90deg);
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .row {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0.6rem 1rem;

          .col {
            &:first-child {
              margin-right: 0.6rem;
            }

            &:nth-child(2) {
              width: 100%;
            }

            .number {
              width: 30px;
              height: 30px;
              border-radius: $bradius;
              display: flex;
              justify-content: center;
              align-items: center;
              background: $primary;
              font-size: 0.9rem;
              font-weight: 500;
              color: white;
            }

            input {
              border: 0;

              &.title {
                font-weight: 500;
                font-size: 1rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 98%;
                padding-left: 0;
                text-indent: 0.6rem;

                &::placeholder {
                  font-weight: 400;
                  font-size: 1rem;
                  color: $grey2;
                }
              }
            }

            .textAreaWrapper {
              max-width: 827px;
              width: 100%;
              margin-top: 1.4rem;

              p {
                font-size: 15px;
              }

              textarea {
                resize: none;
                width: 100%;
                max-width: 100%;
                margin-top: 0.4rem;
                box-sizing: border-box;

                &.text {
                  font-family: proxima-nova;
                  font-size: 15px;
                  color: $black;
                  font-weight: 400;
                  border: 0;

                  &::placeholder {
                    font-weight: 400;
                    font-size: 15px;
                    color: $grey2;
                  }
                }
              }
            }

            button {
              padding: 0 !important;

              &:first-child {
                margin-right: 1.6rem;
              }

              &:hover {
                background: none;
              }
            }

            .buttons {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-left: 2rem;
              margin-bottom: 1rem;
            }
          }
        }

        &.open {
          border-color: $grey2;
          box-shadow: $boxshadow;

          .number {
            margin-top: 8px;
          }
        }

        &.closed {
          cursor: pointer;

          .row {
            align-items: center;
          }

          .col {
            .number {
              background: $blue;
              color: $primary;
            }

            input,
            textarea {
              pointer-events: none;
            }
          }
        }

        &.add {
          border: none;

          .row {
            align-items: center;
            min-height: 62px;
            padding: 0;

            button {
              width: 100%;
              height: 62px;
              padding: 0 !important;
              text-align: left;
              justify-content: flex-start;
              text-indent: 1.2rem;
            }
          }
        }
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;

      button:first-child {
        margin-right: 1rem;
      }
    }
  }
}
