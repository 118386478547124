@import "variables.scss";

:local {
  .cellTrigger {
    cursor: pointer;
  }

  .popover {
    position: relative;
    z-index: 999;
    border-radius: $bradius;

    .highDev:not(.lights) {
      margin: 0.6rem 0;
      padding: 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: $blue;
      border-radius: $bradius2;

      h5 {
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 1.4rem;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.4rem;
        font-weight: 300;
        max-width: 80%;
        text-align: left;
      }

      .image {
        margin-top: 1.6rem;
      }
    }

    .comments {
      background: white;
      padding: 1.6rem 1.2rem;
      min-width: 400px;
      border-radius: $bradius;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .commentsTitle {
          text-align: left;
          font-size: 1rem;
          color: black;
          width: 60%;
        }

        .trafficLight {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .score {
            font-size: 14px;
            color: $grey1;
            margin-left: 0.6rem;
            font-weight: 300;
          }
        }
      }

      .totalDrilldown {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0.6rem;

        .members {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;

          button {
            margin-right: 0.2rem;
            cursor: pointer;

            &:last-child {
              margin-right: 0;
            }

            .avatar {
              border-radius: 50%;
              border: 2px solid transparent;
              padding: 2px;
              cursor: pointer;

              &.selected {
                border: 2px solid $primary;
              }
            }
          }
        }

        .title {
          border-bottom: 1px solid $grey5;
          font-weight: 500;
          font-size: 0.8rem;
          width: 100%;
          text-align: left;
          margin-bottom: 0.2rem;
          padding-bottom: 0.2rem;
        }

        .total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-bottom: 0.4rem;
          padding-right: 0.4rem;

          p {
            font-size: 0.8rem;
            font-weight: 500;
          }
        }

        .evaluations {
          max-height: 300px;
          overflow-y: auto;
          width: 100%;
          padding-right: 0.4rem;

          &::-webkit-scrollbar {
            width: 5px;
            background: rgba($grey5, 0.4);
          }

          &::-webkit-scrollbar-thumb {
            background: $grey4;
            border-radius: 2px;
          }

          .evaluation {
            width: 100%;
            padding: 0.4rem 0;

            .top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              p {
                font-weight: 600;
                text-align: left;
              }
            }

            .comment {
              width: 100%;
              padding: 0.2rem 0;
              position: relative;
              margin-top: 0.8rem;

              .time {
                position: absolute;
                left: 0;
                top: -14px;
                opacity: 0;
                font-weight: 400;
                font-size: 0.7rem;
                color: $grey3;
              }

              &:hover {
                .time {
                  opacity: 1;
                }
              }

              p {
                width: 100%;
                font-weight: 400;
                font-size: 0.9rem;
                line-height: 1.2rem;
                text-align: left;
              }
            }
          }
        }
      }

      .evaluationsBox {
        max-height: 300px;
        overflow-y: auto;

        .evaluationRow {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 0.7rem;
          margin-top: 0.7rem;
          border-top: 1px solid $grey5;

          &:last-child {
            margin-bottom: 0;
          }

          .left {
            width: 10%;
            display: flex;
            justify-content: flex-end;
          }

          .right {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .scores {
              width: 100%;
              padding-left: 1rem;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .upper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;

                .name,
                .time {
                  font-size: 0.9rem;
                  line-height: 1.4rem;
                  font-weight: 500;
                }

                .time {
                  font-weight: 400;
                  font-size: 0.8rem;
                  color: $grey3;
                }
              }

              .evaluationScore {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .score {
                  font-size: 14px;
                  color: $grey1;
                  margin-top: 0.6rem;
                  font-weight: 300;
                }
                p {
                  font-size: 0.9rem;
                  line-height: 1.2rem;
                  font-weight: 400;

                  &:first-child {
                    color: $darkgrey;
                  }

                  &.noinfo {
                    font-size: 0.8rem;
                    line-height: 1rem;
                  }
                }
              }
            }

            .commentsColumn {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              margin-left: 1rem;
              width: 90%;

              .commentWrapper {
                max-width: 90%;
                position: relative;

                .commentTime {
                  position: absolute;
                  left: 0;
                  opacity: 0;
                  font-weight: 400;
                  font-size: 0.7rem;
                  color: $grey3;
                }

                .commentMessage {
                  margin-top: 0.6rem;
                  font-weight: 400;
                  font-size: 0.9rem;
                  line-height: 1.4rem;
                  text-align: left;
                  max-width: 100%;
                }

                &:hover {
                  .commentTime {
                    opacity: 1;
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
