@import "variables.scss";

:local {
  .wrapper {
    max-width: 960px;

    .imageWrapper {
      width: 80%;
      height: 300px;
      background: $blue;
      border-radius: $bradius;
      margin: 2rem 0;
      position: relative;

      .box {
        width: 200px;
        height: 120px;
        background: $primary;
        border-radius: 15px;
        position: absolute;
        left: 25%;
        top: 10%;
      }

      svg {
        position: absolute;
        left: 30%;
        bottom: 0;
      }
    }

    .subtitle {
      max-width: 70%;
      margin: 1rem 0;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.6rem;
      list-style-type: initial;
    }

    .peopleInvited {
      padding: 1rem 0;
      margin-top: 2rem;
      border-top: 1px solid $grey5;
      border-bottom: 1px solid $grey5;

      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .avatars {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.6rem;

        .avatar {
          border-radius: 50%;
          margin-right: 0.6rem;
          position: relative;

          img,
          div[aria-label="initials"] {
            height: 40px;
            width: 40px;
          }

          .completedIcon {
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $green;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .emptyState {
      margin-top: 4rem;
      color: $grey2;
    }

    .bigScoreWrapper {
      width: 100%;
      padding: 1.4rem 0;
      margin-top: 1rem;

      .bigScore {
        padding: 1.4rem;
        border: 1px solid $grey5;
        border-radius: $bradius2;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .score {
          margin-right: 2rem;

          .scoreCircle {
            margin: 0;
          }
        }

        .right {
          h5 {
            font-size: 1rem;
            line-height: 1rem;
            margin-bottom: 0.4rem;
          }

          p {
            font-size: 14px;
            line-height: 14px;
            color: $grey1;
          }
        }
      }
    }

    .insights {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 3rem 0;
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey5;
    }

    .dimensionsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding-top: 6rem;

      .dimensionWrapper {
        width: 100%;
      }

      .dimension {
        width: 100%;
        display: flex;

        .top {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 0.8rem;
          width: 50%;
          margin-right: 4rem;

          .title {
            font-size: 1.3rem;
            font-weight: 600;
            color: black;
          }

          .description {
            margin-top: 0.4rem;
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
            color: $grey1;
            min-height: 80px;
          }

          .commentsIcon {
            position: absolute;
            top: 20px;
            right: 58px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            svg {
              height: 17px;
              width: 17px;

              path {
                fill: $primary;
              }
            }

            p {
              margin-left: 0.3rem;
              font-size: 14px;
              line-height: 14px;
              color: $darkgrey;
              font-weight: 500;
            }
          }

          .score {
            position: absolute;
            top: 0.8rem;
            right: 0.8rem;

            .scoreCircle {
              width: 32px;
            }
          }
        }

        .distribution {
          width: 50%;

          .title {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: black;
          }
        }
      }
    }

    .action {
      padding: 2rem;
      border-radius: $bradius2;
      background: $grey6;
      margin-bottom: 1.4rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .img {
        margin-right: 2rem;
        width: 120px;

        svg {
          width: 100%;
        }
      }

      &:first-child {
        background: white;
        border: 0.36px solid #e4e6e8;
        box-shadow: 0px 1.4251338243484497px 1.4251338243484497px 0px #00000008;
      }

      .title {
        font-size: 18px;
        margin-bottom: 0.4rem;
      }

      .subtitle {
        font-size: 14px;
        line-height: 18px;
        color: $grey1;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
