@import "variables.scss";

:local {
  .topWrapper {
    margin-bottom: 2rem;
    width: 100%;

    .name {
      color: $grey2;
    }

    .header {
      margin: 1rem 0;
      width: 100%;
      border-bottom: 1px solid $grey5;

      @media (max-width: 1150px) {
        padding: 0 1rem;
      }

      .items {
        max-width: $wrapperWidth;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .item {
          padding: 1rem 0;
          margin-right: 2rem;
          border-bottom: 2px solid transparent;

          button {
            font-size: 15px;
            font-weight: 500;
          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            border-bottom: 2px solid $primary;

            button {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
