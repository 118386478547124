@import 'variables.scss';

:local {
  .orderButtons {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    margin-left: 0.3rem;
    top: -5px;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8px;
      cursor: pointer;

      svg {
        path {
          fill: $grey2;
        }
      }

      &.active {
        svg {
          path {
            fill: $black;
          }
        }
      }

      &:hover {
        i {
          color: $black;
        }
      }

      &.down {
        transform: rotate(180deg);
      }
    }
  }
}
