@import "variables.scss";

:local {
  .wisGpt {
    width: 100%;

    .messageAction {
      display: inline-block;
      position: relative;
      height: 24px;
      width: 24px;
      .tooltip {
        position: absolute;
        top: -6px;
        left: 100%;
        background-color: $extradarkgrey;
        padding: 0.4rem 0.8rem;
        border-radius: $bradius;
        white-space: nowrap;
        font-size: 14px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease, visibility 0.2s ease;
        z-index: 9999;
        color: white !important;
        line-height: 0.9rem;
        text-align: center;
        white-space: nowrap;
      }

      &:hover {
        .tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.6rem;

      .icon {
        width: 24px;
        height: 24px;
        border-radius: $bradius2;
        background-color: $lightpurple;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.8rem;

        svg path {
          fill: $purple;
        }
      }
    }
  }
}
