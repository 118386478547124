@import "variables.scss";

:local {
  button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
    background-color: white;
    border: 1px solid $grey4;
    font-family: proxima-nova;
    font-weight: 500;
    font-size: 14px;
    -webkit-border-radius: $bradius;
    border-radius: $bradius2;
    cursor: pointer;
    color: $darkgrey;
    transition: $transition;
    box-shadow: 0px 1.5px 3px 0px rgba(black, 0.08);

    i {
      margin-right: 0.5rem;
    }

    &:hover {
      border-color: $grey1;
    }

    &:active {
      background-color: rgba($black, 0.02);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    // TYPES
    &.primary {
      background-color: $primary;
      color: white;
      font-weight: 400;
      border-color: transparent;
      text-indent: 0;
      box-shadow: 0px 1px 3px 0px #00000017;
      text-shadow: 0px 1px 2px #00000033;

      &:hover {
        background-color: lighten($primary, 3);
        border-color: darken($primary, 0.2);
      }

      &:active {
        background-color: darken($primary, 0.5);
      }

      i {
        margin-right: 0.5rem;
      }

      svg path {
        fill: white;
      }
    }

    &.secondary {
      text-indent: 0;

      &:focus {
        background: white;
      }

      i {
        margin-right: 0.5rem;
      }

      svg {
        color: $darkgrey;
      }

      &.notext {
        i {
          margin-right: 0;
        }
      }

      &.grey {
        background: $grey5;
        border: none;

        &:hover {
          background: rgba($grey5, 0.8);
        }
      }
    }

    &.transparent {
      background-color: transparent;
      border-color: $primary;
      color: $primary;

      svg {
        path {
          fill: $primary;
        }
      }

      &:hover {
        border-color: rgba($primary, 0.5);
      }
    }

    &.bluetext {
      color: $primary;

      svg path {
        fill: $primary;
      }
    }

    &.round {
      border-radius: 50%;
      padding: 1.2rem;
      border: 1px solid $grey4;
      background: white;
      font-size: 1rem;
    }

    &.none {
      background: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      margin: 0;
      text-indent: 0;
      text-align: initial;
      font-size: initial;
      font-weight: initial;

      i {
        margin: 0;
      }
    }

    &.onlyicon {
      i {
        margin: 0;
      }
    }

    &.arrowButton {
      padding: 0;
      margin: 0;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 1.2rem;
      }

      &.left {
        margin-right: 1rem;

        i {
          margin: 0;
          margin-left: -2px;
          line-height: 55px;
        }
      }

      &.right {
        margin-left: 1rem;

        i {
          margin: 0;
          margin-right: -2px;
          line-height: 55px;
        }
      }
    }

    &.simple {
      background: none;
      border: none;
      box-shadow: none;
      border-bottom: 1px solid $grey2;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      color: $darkgrey;
      font-weight: 400;

      &:after {
        left: -8px !important;
      }

      &:hover {
        color: $black;
        background: none;
        border-color: $black;

        &:after {
          color: $black;
        }
      }

      &:active {
        background: none;
      }

      i {
        margin-right: 0.8rem;
        margin-left: -10px;
        font-size: 0.8rem;
      }

      &.noborder {
        border: 0;
      }

      &.icon {
        padding-left: 10px !important;
      }
    }

    &.borderradius {
      border-radius: $bradius2 !important;
    }

    &.newsimple {
      background: none;
      border: none;
      box-shadow: none;
      border-radius: $bradius2;
      color: $primary;
      font-size: 1rem;
      font-weight: 400;

      &:hover {
        background: rgba($blue, 0.7);
      }

      &.nopadding {
        padding: 0;

        &:hover {
          background: none;
        }
      }

      i {
        margin-right: 0.6rem;
        font-size: 0.8rem;
      }

      &.large {
        font-size: 1.2rem;
        font-weight: 500;

        i {
          margin-right: 0.8rem;
          font-size: 1rem;
        }
      }

      &.red {
        color: $red;
        background: none;
        border: none;

        &:hover {
          background: none;
        }
      }

      &.black {
        color: $black;
      }

      &.grey {
        color: $grey3;

        &:hover {
          color: $black !important;
        }
      }

      &.white {
        background: white;
        border: 1px solid $grey4;
        font-size: 0.9rem;
        font-weight: 500;

        &:hover {
          border-color: $grey2;
        }
      }

      &.small {
        font-size: 14px;
        font-weight: 600;
      }
    }

    &.backdrop {
      &:before {
        content: "";
        width: calc(100% + 1.8rem);
        height: calc(100% + 1.8rem);
        background: $grey5;
        position: absolute;
        border-radius: $bradius;
        border: 1px solid $grey4;
        top: -1rem;
        left: -1rem;
        z-index: -1;
      }
    }

    // COLORS
    &.green {
      background-color: $green;
      color: white;
      border-color: $green;
      box-shadow: 0px 1px 3px 0px #00000017;
      text-shadow: 0px 1px 2px #00000033;

      &:hover {
        border-color: $green;
        background-color: lighten($green, 2%);
      }
    }

    &.iconGreen {
      i {
        color: $green;
      }
    }

    &.iconPrimary {
      i {
        color: $primary;
      }
    }

    &.purple {
      background-color: $purple;
      color: white;
      border-color: $purple;
      box-shadow: 0px 1px 3px 0px #00000017;
      text-shadow: 0px 1px 2px #00000033;

      svg path {
        fill: white;
      }

      &:hover {
        border-color: $purple;
        background-color: lighten($purple, 5%);
      }
    }

    &.blue {
      background-color: $blue;
      color: $primary;
      border-color: $blue;

      svg path {
        fill: $primary;
      }

      &:hover {
        border-color: $blue;
        background-color: lighten($blue, 2%);
      }
    }

    &.dark {
      background-color: $darkgrey;
      color: white;
      border-color: $darkgrey;

      &:hover {
        border-color: $darkgrey;
        background-color: lighten($darkgrey, 2%);
      }

      svg path {
        fill: white;
      }
    }

    &.red {
      background-color: $red;
      color: white;
      border-color: $red;

      &:hover {
        border-color: $red;
        background-color: lighten($red, 2%);
      }
    }

    &.grey {
      background-color: $grey3;
      color: white;
      border-color: $grey3;

      svg path {
        fill: white;
      }

      &:hover {
        border-color: $grey3;
        background-color: lighten($grey3, 2%);
      }
    }

    // SIZES
    &.verySmall {
      padding: 0.4rem 0.8rem !important;
      font-size: 0.9rem;
      border-radius: $bradius2;

      &.onlyicon {
        padding: 0 1rem;

        i {
          margin: 0;
          line-height: 1px;
        }
      }
    }

    &.small {
      padding: 0.7rem 1rem !important;
      font-size: 14px;
      border-radius: $bradius2;

      &.primary {
        border: none;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.0890512);
      }

      &.onlyicon {
        padding: 0 1rem;

        i {
          margin: 0;
          line-height: 1px;
        }
      }
    }

    &.large {
      font-size: 1.2rem;
      padding: 1rem 2rem 1rem 2rem;
    }

    &.extralarge {
      font-size: 1.4rem;
      padding: 1.4rem 5rem 1.4rem 5rem;
    }

    &.linkedin {
      margin: 0 auto;
      width: 100%;
      height: 50px;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;

      @media (max-width: 420px) {
        font-size: 1rem;
      }

      i {
        font-size: 1.1rem;
      }
    }
    &.microsoft {
      margin: 0 auto;
      width: 100%;
      height: 50px;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
      svg {
        margin-left: 0.3rem;
      }
      @media (max-width: 420px) {
        font-size: 1rem;
      }

      i {
        font-size: 1.1rem;
      }
    }

    &.signup {
      color: $darkgrey;
    }

    &.iconHover {
      border: none;
      box-shadow: none;
      background: none;
      margin: 0;
      padding: 0;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      &:hover {
        background: $grey5;
      }

      i {
        margin: 0;
        margin-top: 1px;
        margin-left: 1px;
      }
    }

    &.popoverclose {
      height: 24px;
      width: 24px;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    &.create {
      margin: 0 auto;
      margin-top: 2rem;
      max-width: $wrapperWidth;
      width: 100%;
      height: 60px;
      font-size: 1.2rem;
      color: white;
      background-color: $green;
      border: $green;

      &:hover {
        background-color: lighten($green, 2%);
      }

      &:focus,
      &:active {
        background-color: darken($green, 2%);
      }

      &:disabled {
        background-color: $grey3;

        &:hover {
          background-color: $grey3;
          cursor: not-allowed;
        }
      }
    }

    .zeroMinHeight {
      min-height: 0 !important;
    }
    .noPadding {
      padding: 0 !important;
    }
  }

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    button {
      margin: 0.4rem;
    }
  }
}
