@import "variables.scss";

:local {
  .wrap {
    header {
      display: flex;

      .icon {
        width: 48px;
        height: 48px;
        border-radius: $bradius3;
        background-color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;
        svg {
          height: 20px;
          width: 20px;
          path {
            fill: $primary;
          }
        }
      }
    }
    .listTitle {
      font-weight: 500;
      margin-top: 3rem;
    }
    .list {
      padding: 0.5rem 0;
      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 1rem 0;
        .name {
          margin-left: 1rem;
          font-weight: 500;
        }

        .email {
          margin-left: 1rem;
          color: $grey1;
        }
        .btn {
          margin-left: auto;
          position: relative;
          .popup {
            position: absolute;
            top: -36px;
            left: -8px;
            width: 139px;
          }
        }
      }
    }
  }
}
