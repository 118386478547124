@import "variables.scss";

:local {
  .header {
    width: 100%;
    margin: 4rem 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .settings {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .form {
        margin-right: 4rem;
        width: 50%;
      }

      @media (max-width: 1100px) {
        margin: 0;
        padding: 0;
        border: none;
        margin-bottom: 4rem;
      }

      @media (max-width: 950px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .form {
          width: 100%;
          margin-bottom: 2rem;
        }
      }
    }

    .left,
    .right {
      width: 100%;

      @media (max-width: 1100px) {
        width: 100%;
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        margin-right: 1rem;
      }

      .success {
        color: $green;
        font-weight: 400;
      }
      .error {
        color: $red;
        font-weight: 400;
      }
    }

    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.6rem;

      @media (max-width: 750px) {
        flex-direction: column;
        margin-bottom: 0;
      }

      .input {
        margin-right: 0.8rem;
        width: 100%;

        @media (max-width: 750px) {
          margin: 0;
          margin-bottom: 1rem;

          input {
            min-width: auto;
          }
        }

        .label {
          font-size: 1rem;
          line-height: 1.2rem;
          font-weight: 500;
          margin-bottom: 0.6rem;
        }

        .birthday {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        // select {
        //   width: 25%;
        //   font-size: 1rem;
        //   background: white;
        //   height: 40px;
        //   margin-right: 0.6rem;
        //   border: 1px solid $grey4;
        //   box-shadow: $lightboxshadow;

        //   &:nth-child(2) {
        //     width: 50%;
        //   }

        //   &:last-child {
        //     margin-right: 0;
        //   }
        // }
      }
    }

    .label {
      font-weight: 500;
      margin-bottom: 0.6rem;
    }

    .phoneNumber {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > div {
        width: 100%;
      }

      input {
        border-radius: 4px;
        height: 40px !important;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        border-color: $grey4;
      }
    }

    .sublabel {
      font-weight: 300;
      line-height: 1.4rem;
      margin-bottom: 1rem;
      color: $grey1;
    }

    .surveys {
      margin-top: 3rem;

      .label {
        margin-top: 3rem;
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $grey4;
        padding: 0.6rem 0;
        margin-bottom: 0;

        .name {
          font-weight: 500;
          font-size: 1.1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .twitter,
          .linkedin {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            height: 30px;
            width: 30px;
            margin-right: 0.6rem;
          }

          .twitter {
            background: #38b0ee;
          }

          .linkedin {
            background: #027cba;
          }

          i {
            font-size: 0.9rem;
            margin-left: 1px;
          }
        }
      }
    }

    .socialmedia {
      border-top: 1px solid $grey4;
      margin-top: 2rem;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $grey4;
        padding: 0.6rem 0;
        margin-bottom: 0;

        .name {
          font-weight: 500;
          font-size: 1.1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .twitter,
          .linkedin {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            height: 30px;
            width: 30px;
            margin-right: 0.6rem;
          }

          .twitter {
            background: #38b0ee;
          }

          .linkedin {
            background: #027cba;
          }

          i {
            font-size: 0.9rem;
            margin-left: 1px;
          }
        }
      }
    }

    .changepassword {
      width: 50%;

      @media (max-width: 950px) {
        width: 100%;
      }

      .buttonGroup {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
      }

      input {
        margin-top: 0.6rem;
      }

      .error {
        font-weight: 400;
        color: $red;
      }
      .success {
        font-weight: 400;
        color: $green;
      }
    }
  }

  .footer {
    max-width: 510px;
    margin: 4rem 0;

    .label {
      font-weight: 500;
      text-align: left;
    }

    .sublabel {
      font-weight: 400;
      line-height: 1.4rem;
      text-align: left;
      margin-top: 1rem;

      a {
        font-weight: 400;
        font-size: 1rem;
        color: $black;
      }
    }
  }
}
