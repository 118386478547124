@import "variables.scss";

:local {
  .main {
    .content {
      // width: 80%;

      @media (max-width: 1080px) {
        width: 100%;
      }

      .subtitle {
        max-width: 90%;
        margin: 1rem 0;
        font-weight: 300;
        font-size: 1.1rem;
        line-height: 1.6rem;
        list-style-type: initial;

        strong {
          font-weight: 500;
        }
      }

      .final {
        margin-bottom: 1rem;
      }

      .finalGoalsWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $grey4;

        .goal {
          position: relative;
          width: 47.5%;
          height: 220px;
          padding: 2rem;
          background: white;
          border: 1px solid $grey4;
          border-radius: $bradius;
          margin-right: 1rem;
          margin-top: 1rem;

          &:nth-child(2) {
            .iconActive {
              background: $yellow;
            }
          }
          &:nth-child(3) {
            .iconActive {
              background: $blue;
            }
          }
          &:nth-child(4) {
            .iconActive {
              background: $primary;
            }
          }
          &:nth-child(5) {
            .iconActive {
              background: $red;
            }
          }
          &:nth-child(6) {
            .iconActive {
              background: $pink;
            }
          }
          &:nth-child(7) {
            .iconActive {
              background: $lightpurple;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          .remove {
            position: absolute;
            top: 1rem;
            right: 1rem;

            button {
              width: 24px;
              height: 24px;

              i {
                margin: 0;
                font-size: 1.2rem;
                color: $grey3;
              }

              &:hover {
                i {
                  color: $black;
                }
              }
            }
          }

          .icon,
          .iconActive {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background: $grey4;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              transform: scale(0.6);
            }

            svg path {
              fill: white;
            }
          }

          .iconActive {
            background: $green4;
          }

          h5 {
            margin-top: 1rem;

            &.italic {
              font-style: italic;
            }
          }

          .line {
            width: 80%;
            height: 8px;
            margin-top: 1rem;
            background: $grey4;
            border-radius: 2px;

            &:last-child {
              width: 60%;
            }
          }
        }
      }

      .goalGroupsWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .group {
          margin-bottom: 1rem;
          width: 100%;

          .row {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 1rem;

            .col {
              &:first-child {
                margin-right: 1rem;
              }

              &:nth-child(2) {
                width: 100%;
              }

              &:last-child {
                width: 20%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                i {
                  color: $grey3;
                }

                button {
                  padding: 0 !important;
                }
              }

              .text {
                width: 100%;

                h5 {
                  &.italic {
                    font-style: italic;
                  }
                }
              }

              .number {
                width: 30px;
                height: 30px;
                border-radius: $bradius;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;
                font-size: 0.9rem;
                font-weight: 500;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
