@import "variables.scss";

:local {
  .modalWrapper {
    max-width: $modalWrapper;
    margin: 0 auto;

    .modalHead {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 1rem;
      margin-bottom: 3rem;

      .title {
        font-size: 2rem;
        line-height: 2.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          width: 48px;
          height: 48px;
          background-color: $blue;
          border-radius: $bradius3;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.2rem;

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: $primary;
            }
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 26px;
        color: $darkgrey;
        font-weight: 300;
        margin-top: 1rem;
        max-width: 70%;
      }
    }

    .newTeam {
      form {
        .row {
          margin-bottom: 2rem;

          h5 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: 0.8rem;

            span {
              font-size: 1rem;
              font-weight: 400;
              color: $darkgrey;
              margin-left: 0.5rem;
            }

            @media (max-width: 472px) {
              flex-direction: column;
              align-items: flex-start;

              span {
                margin: 0;
                margin-top: 0.5rem;
              }
            }
          }
        }

        .buttonGroup {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: absolute;
          bottom: 3em;
          right: 4rem;

          button {
            margin-right: 1rem;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
