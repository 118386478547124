@import 'variables.scss';

:local {
  .modalWrapper {
    max-width: $modalWrapper;
    margin: 0 auto;

    .modalHead {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 1rem;
      margin-bottom: 3rem;

      .subtitle {
        margin-top: 1.6rem;
        font-size: 1.2rem;
        font-weight: 300;
      }
    }

    .evaluatorsWrapper {
      .evaluatorBox {
        width: 100%;
        border: 1px solid $grey5;
        border-radius: $bradius;
        margin-bottom: 2rem;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              margin-right: 0.6rem;
              padding-left: 1rem;
            }

            .info {
              padding: 1rem 0;

              h5 {
                padding: 0;
                font-size: 1rem;
              }

              p {
                color: $grey1;
                font-size: 0.9rem;
                line-height: 1rem;
                font-weight: 300;
              }
            }
          }

          .status {
            padding-right: 1rem;
            font-size: 0.85rem;
            font-weight: 300;
            color: $grey1;
          }
        }
      }

      .evaluatedBox {
        border-top: 1px solid $grey5;
        padding: 1rem 0;

        .evaluated {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0.4rem 1rem;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              margin-right: 0.6rem;
            }

            h5 {
              padding: 0;
              font-size: 0.9rem;
            }
          }

          .status {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
              font-size: 0.85rem;
              font-weight: 300;
              color: $grey1;
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              background: $green;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              margin-left: 0.6rem;

              i {
                color: white;
                font-size: 0.6rem;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
  }
}
