@import 'variables.scss';

:local {
  .wrapper {
    width: 100%;

    .ieOverlay {
      width: 100%;
      padding: 4rem 2rem;
      margin: 2rem 0;
      background-color: $grey5;
      border-radius: $bradius;

      .title {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin: 0.4rem auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i {
          margin-bottom: 1rem;
          font-size: 2rem;
        }
      }

      .subtitle {
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 1.5rem;
        text-align: center;
        max-width: 70%;
        margin: 0 auto;

        a {
          font-size: 1.1rem;
        }
      }
    }
  }
}
