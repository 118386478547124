@import "variables.scss";

:local {
  .wrapper {
    position: relative;

    &.disabled {
      opacity: 0.7;
    }

    &.icon {
      input {
        text-indent: 55px;

        &:focus {
          .icon i {
            color: $black;
          }
        }
      }
    }

    .icon {
      position: absolute;
      height: 100%;
      width: 60px;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;

      i {
        color: $darkgrey;
        font-size: 1.2rem;
      }
    }

    input {
      position: relative;
      height: 48px;
      min-width: 300px;
      max-width: 90%;
      outline: none;
      border: 1px solid $grey3;
      border-radius: $bradius;
      color: black;
      font-weight: 400;
      background: rgba($grey5, 0.4);
      text-indent: 1rem;
      font-size: 15px;
      line-height: 1.4rem;

      &::placeholder {
        font-size: 15px;
        font-family: proxima-nova;
        color: $grey1;
        font-weight: 400;
      }

      &:focus {
        border-color: $grey1 !important;
      }
    }

    &.paddingRight {
      input {
        padding-right: 100px;
      }
    }

    &.active {
      input {
        border: 2px solid $primary !important;
      }
    }

    &.noBorder {
      input {
        border: none !important;
        border-radius: 0 !important;
      }

      &:hover {
        .icon i {
          color: $primary;
        }
      }

      &.writing {
        .icon i {
          color: $black;
        }
      }
    }

    &.small {
      input {
        border-radius: $bradius;
        min-width: 300px;
        height: 40px;
        text-indent: 10px;
        font-size: 14px;
        line-height: 20px;
        // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);

        &::placeholder {
          font-size: 14px;
          font-weight: 300;
          color: $grey2;
        }
      }

      &.icon {
        .icon {
          width: 48px;
        }
        i {
          font-size: 1rem;
          color: $grey2;
        }
        input {
          text-indent: 40px;
        }
      }
    }

    &.white {
      input {
        background: white;
        border: 1px solid $grey4;

        &:focus {
          border-color: $grey2;
        }
      }
    }

    &.fullWidth {
      width: 100%;

      input {
        width: 100%;
        max-width: 100%;
        min-width: 150px;
      }
    }

    &.large {
      input {
        height: 57px;
      }
    }
  }

  .delete {
    position: absolute;
    right: 1rem;
    top: 30%;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
    }

    &:hover {
      svg path {
        fill: $black;
      }
    }
  }

  .fieldStatus {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin: 0.6rem 0;
    padding-left: 0.2rem;

    &.success {
      color: $green;
    }

    &.warning {
      color: $yellow;
    }

    &.error {
      color: $red;
    }
  }
}
