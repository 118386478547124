@import "variables.scss";

:local {
  .initiateScreen {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.6rem;

      .small {
        font-size: 14px;
        line-height: 18px;
        color: $darkgrey;
        font-weight: 300;
        margin-bottom: 0.2rem;
      }

      .logo {
        height: 32px;
        width: 32px;
        border-radius: $bradius;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $purple;
        margin: 0.4rem 0;

        svg {
          height: 24px;
          width: 24px;

          path {
            fill: white;
          }
        }
      }

      .title {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }

    .description {
      text-align: center;
      margin-bottom: 3rem;
      color: $grey1;
      font-weight: 300;
      font-size: 15px;
      max-width: 80%;
    }

    .examplesWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 3rem;

      .title {
        margin-bottom: 1rem;
        font-size: 14px;
        line-height: 18px;
        color: $darkgrey;
      }

      .examples {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .example {
          font-size: 13px;
          line-height: 19px;
          color: $purple;
          margin: 0.3rem;
          font-weight: 300;
          padding: 4px 14px;
          border-radius: 50px;
          border-radius: 32px;
          border: 0.5px solid var(--light-med-purple, #d7c7f2);
          background: var(--light-light-purple, $lightpurple);
          text-align: center;
        }
      }
    }

    .disabledText {
      color: $darkgrey;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        margin-right: 0.6rem;

        svg {
          width: 18px;
          height: 18px;

          path:nth-child(1) {
            fill: $yellow;
          }
        }
      }
    }

    .initiateButton {
      position: relative;

      button {
        transition: 200ms ease-in-out;

        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 16px rgba($purple, 0.2);
        }
      }

      .tooltip {
        opacity: 0;
        position: absolute;
        top: calc(100% + 0.6rem);
        left: -50%;
        padding: 0.6rem;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: $bradius2;
        color: white;
        max-width: 250px;
        width: max-content;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        transition: 100ms ease-in-out;
        pointer-events: none;

        &:before {
          content: "";
          position: absolute;
          top: -5px;
          left: calc(50% - 5px);
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid rgba(0, 0, 0, 0.8);
        }
      }

      &.notReady {
        &:hover {
          .tooltip {
            opacity: 1;
            display: block;
          }
        }
      }
    }
  }
}
