@import "src/style/variables.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.selectedTemplate {
  margin-bottom: 48px;
}

.templateHeader {
  // Add styles for template header here
}

.label {
  // Add styles for label here
}

.body {
  // Add styles for body here
}

.scorecardWrapper {
  border-radius: $bradius3 64px $bradius3 $bradius3;
  border: 2px solid transparent;
  transition: $transition;
  position: relative;

  &.selected {
    border-color: $primary;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $bradius3 64px $bradius3 $bradius3;
      background-color: rgba(#80d2e5, 0.1);
    }
  }
}

.templateSelection {
  display: flex;
  justify-content: space-between;
}

.grid {
  display: grid;
  gap: 48px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 64px;
  }

  .createButton {
    margin-top: 4rem;
  }
}

.selectPersonForm {
  gap: 48px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__group {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__avatar {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.positionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__field {
    border-radius: $bradius3;
    border: 0.5px solid $grey4;
    box-shadow: $boxshadow, $boxshadowhover;
    padding: 0.75rem 1rem;
    width: 100%;
  }
}

.inviteReviewers {
  display: flex;
  gap: 48px;

  &__column {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    flex: 1;
  }

  &__invitePerson {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__inviteList {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__inviteItem {
    display: flex;
    justify-content: space-between;
  }

  &__emptyFlex {
    flex: 1;
  }
}

.conclusionContainer {
  display: flex;
  flex-direction: column;
  gap: 72px;

  &__text {
    width: 50%;
  }
}

.selectedUser {
  &__wrapper {
    display: flex;
    gap: 25px;
    align-items: center;
  }
}
