@import "variables.scss";

:local {
  .main {
    width: 100%;

    .wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      .teamName {
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 1rem;

        h4 {
          color: $black;

          a {
            margin-left: 0.5rem;
            color: $primary;
            padding-bottom: 0.6rem;
            // border-bottom: 1px solid $primary
            border: none;
          }
        }
      }

      .exploreWrapper {
        margin-bottom: 8rem;
      }

      .afterUser {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        @media (max-width: 423px) {
          flex-direction: column-reverse;
        }

        div {
          padding: 0.4rem 2rem 0.4rem 2rem;

          @media (max-width: 486px) {
            padding: 0.4rem 1rem 0.4rem 1rem;
          }

          &.left {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 423px) {
              border: 0;
              margin-top: 1rem;
            }

            .iconWrapper {
              border: 2px solid $grey3;
              border-radius: 50%;
              margin-right: 1rem;
              padding: 0.6rem 0.6rem 0.6rem 7px;
              height: 40px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            svg {
              margin-right: -3.5px;
              margin-top: 4px;
            }

            h5 {
              color: $darkgrey;
            }
          }

          button {
            font-weight: 400;
            font-size: 1.1rem;

            i {
              margin-right: 0.8rem;
            }
          }
        }
      }

      .bigButton {
        border: 0;
        margin: 0 auto;
        margin-bottom: 2rem;
      }
    }
  }
}
