@import "variables.scss";

:local {
  .changeTab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    a {
      border: 0;
      line-height: 0;
      margin: 0 !important;
    }

    button {
      svg path {
        fill: $darkgrey;
      }
    }

    a button {
      svg path {
        fill: $grey3;
      }
    }

    button {
      margin: 0 !important;
    }

    .separate {
      width: 1px;
      height: 8px;
      background: $grey4;
      margin-left: 0.6rem;
      margin-right: 0.3rem;
    }
  }

  .extraButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-right: 1rem;
      padding: 0.4rem !important;
      border-radius: $bradius3;
    }

    .item {
      flex: 1;
      height: calc(100% + 1px);
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: fit-content;
      cursor: pointer;

      &:first-child {
        margin-left: -0.5px;
      }

      &:last-child {
        margin-right: -0.5px;
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 0.4rem 0.8rem !important;
      }

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 0.4rem;

        svg path {
          fill: $grey2;
        }
      }

      p {
        font-size: 14px;
        line-height: 14px;
        color: $grey1;
      }

      &.active {
        background-color: white;
        border: 0.5px solid $grey3;
        padding: 0.4rem 0.8rem;

        .icon svg path {
          fill: $black;
        }

        p {
          color: $black;
          font-weight: 500;
        }
      }
    }
  }

  .commentWrapper {
    margin-right: 1rem;
  }

  .printBtn {
    margin: 0 1rem;
  }

  .shareWrapper {
    display: flex;
    margin-right: 1rem;

    .avatarsWrapper {
      display: flex;
      margin-left: 0.6rem;
    }

    .icon {
      svg {
        width: 16px;
        height: 16px;

        path {
          fill: $darkgrey;
        }
      }
    }
  }

  .moreBtn {
    // margin-left: 1rem;

    .button {
      i {
        font-size: 1.2rem;
      }
    }
  }

  .trafficLight {
    display: flex;

    .score {
      font-size: 0.8rem !important;
      line-height: 1rem;
      color: $grey2;
      margin-left: 0.6rem;
    }

    .lights {
      display: flex;
      justify-content: center;
      align-items: center;

      .light {
        width: 10px;
        height: 10px;
        background: $grey4;
        margin-right: 2px;
        border-radius: 2px;
      }

      &.lights-1 {
        .light {
          &.active {
            background: $red;
          }

          &.half {
            background: linear-gradient(to right, $red 5px, $grey4 0px);
          }
        }
      }

      &.lights-2 {
        .light {
          &.active {
            background: $red;
          }
          &.half {
            background: linear-gradient(to right, $red 5px, $grey4 0px);
          }
        }
      }
      &.lights-3 {
        .light {
          &.active {
            background: $yellow;
          }
          &.half {
            background: linear-gradient(to right, $yellow 5px, $grey4 0px);
          }
        }
      }
      &.lights-4 {
        .light {
          &.active {
            background: $green;
          }
          &.half {
            background: linear-gradient(to right, $green 5px, $grey4 0px);
          }
        }
      }
      &.lights-5 {
        .light {
          &.active {
            background: $green4;
          }
          &.half {
            background: linear-gradient(to right, $green4 5px, $grey4 0px);
          }
        }
      }

      &.highDev {
        .light {
          &.active {
            background: $grey1;
          }
          &.half {
            background: linear-gradient(to right, $grey1 5px, $grey4 0px);
          }
        }
      }
    }
  }

  .descModal {
    margin-top: 1rem;

    h2 {
      margin-bottom: 2rem;
    }

    .desc {
      max-width: 90%;

      h1 {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: $darkgrey;
      }

      h2 {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $darkgrey;
        margin: 0;
      }

      p,
      li {
        margin: 0.6rem 0;
        line-height: 1.6rem;
      }

      ul,
      ol {
        margin: 1rem 0;
      }

      ul li {
        list-style-type: initial;
        margin-left: 1rem;
      }

      ol li {
        list-style-type: decimal;
        margin-left: 1rem;
      }

      em {
        font-style: italic;
      }

      u {
        text-decoration: underline;
      }
    }
  }

  .main {
    margin-bottom: 2rem;
    width: 100%;

    .topInfoWrapper {
      margin: 1rem 0;
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey5;

      .topInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .left {
          h4 {
            color: $grey1;
            font-size: 1rem;
            font-weight: 400;
          }
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .commentWrapper {
            margin-right: 1rem;
          }

          .shareWrapper {
            display: flex;

            .avatarsWrapper {
              display: flex;
              margin-right: 0.6rem;
            }

            .shareIcon {
              width: 30px;
              height: 30px;
              background: $primary;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                background: lighten($primary, 5%);
              }

              svg {
                width: 20px;
                height: 20px;

                path {
                  fill: white;
                }
              }
            }
          }

          .moreBtn {
            margin-left: 1rem;

            .button {
              i {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }

    .bottomInfoWrapper {
      width: 100%;
      padding: 0;

      .bottomInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .toolbarButtons {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .selectWrapper {
            margin-right: 1rem;

            select {
              min-width: 145px;
            }

            &:last-child {
              select {
                width: 155px;
              }
            }
          }
        }

        .changeTab {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 1rem;

          .printBtn {
            margin-right: 1rem;

            button {
              box-shadow: 0px 1px 3px 0px #00000017;
              text-shadow: 0px 1px 2px 0px #00000033;

              svg path {
                &:first-child {
                  fill: white;
                }

                &:nth-child(2) {
                  fill: white;
                }

                &:nth-child(3) {
                  fill: $primary !important;
                }

                &:nth-child(4) {
                  fill: $primary !important;
                }
              }
            }
          }

          a {
            border: 0;
            line-height: 0;
          }

          a {
            svg path {
              fill: $grey3;
            }

            &:last-child {
              svg path {
                fill: $grey3;
              }
            }
          }

          .separate {
            width: 1px;
            height: 8px;
            background: $grey4;
            margin-left: 0.6rem;
            margin-right: 0.3rem;
          }
        }
      }
    }

    .tableWrapper {
      // max-width: 100%;
      // max-width: 100%;
      margin: 0 auto;
      min-height: 500px;
      overflow-x: auto;
      overflow-y: visible;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 2rem;
      width: calc(100vw - 400px);

      &.center {
        justify-content: center;
        align-items: center;
      }

      .emptyState {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4 {
          margin-bottom: 1rem;
          font-weight: 500;
        }

        p {
          max-width: 65%;
          text-align: center;
          font-size: 1.1rem;
          color: $grey1;
        }
      }

      table {
        box-sizing: border-box;
        width: 100%;
        background: white;
        // border: 1px solid $grey5;

        th,
        td {
          min-width: 180px;

          &:first-child {
            width: 25%;
            min-width: 280px;
          }

          &:last-child {
            width: 100%;
          }
        }

        .basicCell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-height: 110px;
          overflow-x: hidden;

          .tooltipButton {
            margin-left: 1rem;

            button {
              i {
                font-size: 0.9rem;
                color: $grey2;
              }
            }
          }

          .tooltipPopover {
            max-width: 250px;
            padding: 1rem;
            margin-top: 0;
            border: 1px solid $grey5;
            border-radius: $bradius;
            background: white;
            z-index: 9999;

            p {
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.4rem;
            }
          }
        }

        .cellTrigger {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .highDevNotice {
            position: absolute;
            right: -3rem;

            svg path:first-child {
              fill: $primary;
            }
          }

          .noInfo {
            position: absolute;
            right: -3rem;
            font-size: 0.8rem;
            color: $grey2;

            svg rect {
              fill: $grey2;
            }
          }
        }

        .cell {
          position: relative;

          .eval {
            .deviationScore {
              z-index: 1;
              cursor: default;
            }

            .noinfo {
              color: $grey2;
              font-size: 0.85rem;
            }
          }

          .deviationPopover {
            max-width: 300px;
            z-index: 999;
            background: white;
            border-radius: $bradius;

            .highDev {
              padding: 1.6rem 1.2rem 0 1.2rem;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              z-index: 999;

              h5 {
                font-weight: 600;
                margin-bottom: 0.4rem;
                color: black;
              }

              p {
                font-weight: 300;
                text-align: left;
                color: black;
              }

              .image {
                margin-top: 1rem;

                svg {
                  width: 250px;
                }
              }
            }
          }

          .eval {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            width: 55px;
            position: relative;

            p {
              font-size: 14px;
            }

            &.deviation {
              .deviationScore {
                z-index: 1;
                color: $primary;
                font-weight: 500;
                position: relative;
                background: $blue;
                border-radius: 16px;
                padding: 0.1rem 0.6rem 0.1rem 1.4rem;

                &::after {
                  position: absolute;
                  content: "";
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: $primary;
                  left: 10px;
                  top: calc(50% - 4px);
                }
              }
            }
          }

          .popover {
            position: relative;
            z-index: 999;
            border-radius: $bradius;
            max-width: 400px;

            .highDev:not(.lights) {
              margin: 0.6rem 0;
              padding: 1.2rem;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              background: $blue;
              border-radius: $bradius2;

              h5 {
                font-weight: 600;
                font-size: 0.9rem;
                line-height: 1.4rem;
              }

              p {
                font-size: 0.9rem;
                line-height: 1.4rem;
                font-weight: 300;
                max-width: 80%;
                text-align: left;
              }

              .image {
                margin-top: 1.6rem;
              }
            }

            .comments {
              background: white;
              padding: 1.6rem 1.2rem;
              min-width: 400px;
              border-radius: $bradius;

              .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid $grey5;
                padding-bottom: 1rem;

                .commentsTitle {
                  text-align: left;
                  font-size: 1rem;
                  color: black;
                  width: 60%;
                }

                .trafficLight {
                  width: 40%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  .score {
                    font-size: 0.9rem;
                    // color: $grey1;
                    font-weight: 400;
                  }
                }
              }

              .totalDrilldown {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 0.6rem;

                .members {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 1rem;

                  button {
                    margin-right: 0.2rem;
                    cursor: pointer;

                    &:last-child {
                      margin-right: 0;
                    }

                    .avatar {
                      border-radius: 50%;
                      border: 2px solid transparent;
                      padding: 2px;
                      cursor: pointer;

                      &.selected {
                        border: 2px solid $primary;
                      }
                    }
                  }
                }

                .title {
                  border-bottom: 1px solid $grey5;
                  font-weight: 500;
                  font-size: 0.8rem;
                  width: 100%;
                  text-align: left;
                  margin-bottom: 0.2rem;
                  padding-bottom: 0.2rem;
                }

                .total {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  padding-bottom: 0.4rem;
                  padding-right: 0.4rem;

                  p {
                    font-size: 0.8rem;
                    font-weight: 500;
                  }
                }

                .evaluations {
                  max-height: 300px;
                  overflow-y: auto;
                  width: 100%;
                  padding-right: 0.4rem;

                  &::-webkit-scrollbar {
                    width: 5px;
                    background: rgba($grey5, 0.4);
                  }

                  &::-webkit-scrollbar-thumb {
                    background: $grey4;
                    border-radius: 2px;
                  }

                  .evaluation {
                    width: 100%;
                    padding: 0.4rem 0;

                    .top {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      width: 100%;

                      p {
                        font-weight: 600;
                        text-align: left;
                      }
                    }

                    .comment {
                      width: 100%;
                      padding: 0.2rem 0;
                      position: relative;
                      margin-top: 0.8rem;

                      .time {
                        position: absolute;
                        left: 0;
                        top: -14px;
                        opacity: 0;
                        font-weight: 400;
                        font-size: 0.7rem;
                        color: $grey3;
                      }

                      &:hover {
                        .time {
                          opacity: 1;
                        }
                      }

                      p {
                        width: 100%;
                        font-weight: 400;
                        font-size: 0.9rem;
                        line-height: 1.2rem;
                        text-align: left;
                      }
                    }
                  }
                }
              }

              .evaluationsBox {
                max-height: 300px;
                padding-right: 8px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                  width: 5px;
                  background: rgba($grey5, 0.4);
                }

                &::-webkit-scrollbar-thumb {
                  background: $grey4;
                  border-radius: 2px;
                }

                .evaluationRow {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-top: 1rem;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  .left {
                    width: 10%;
                    display: flex;
                    justify-content: flex-end;
                  }

                  .right {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .scores {
                      width: 100%;
                      padding-left: 1rem;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .upper {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        width: 100%;

                        .name,
                        .time {
                          font-size: 0.9rem;
                          line-height: 1.4rem;
                          font-weight: 500;
                        }

                        .time {
                          font-weight: 400;
                          font-size: 0.8rem;
                          color: $grey3;
                        }
                      }

                      .evaluationScore {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        p {
                          font-size: 0.9rem;
                          line-height: 1.2rem;
                          font-weight: 400;

                          &:first-child {
                            color: $darkgrey;
                          }

                          &.noinfo {
                            font-size: 0.8rem;
                            line-height: 1rem;
                          }
                        }
                      }
                    }

                    .commentsColumn {
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: flex-start;
                      margin-left: 1rem;
                      width: 100;

                      .commentWrapper {
                        max-width: 100%;
                        position: relative;

                        .commentTime {
                          position: absolute;
                          left: 0;
                          opacity: 0;
                          font-weight: 400;
                          font-size: 0.7rem;
                          color: $grey3;
                        }

                        .commentMessage {
                          margin-top: 0.6rem;
                          font-weight: 400;
                          font-size: 0.9rem;
                          line-height: 1.4rem;
                          text-align: left;
                          max-width: 100%;
                        }

                        &:hover {
                          .commentTime {
                            opacity: 1;
                          }
                        }

                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .commentsWrapper {
            position: absolute;
            top: -2px;
            right: -5px;

            .iconWrapper {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: transparent;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                margin: 0;
                color: $grey2;
                font-size: 0.7rem;
              }

              &:hover {
                background: white;
                box-shadow: $lightboxshadow;

                i {
                  color: $primary;
                }
              }
            }
          }
        }

        .totalRow {
          background: rgba($grey6, 0.5) !important;

          td,
          td p {
            font-weight: 600;
            font-size: 0.9rem;
          }
        }

        .epicTotal {
          background: rgba($grey5, 0.5) !important;

          td,
          td p {
            font-weight: 600;
            font-size: 0.9rem;
          }
        }

        .headerRow {
          background: $grey6 !important;

          td,
          td p {
            font-weight: 600;
            font-size: 0.9rem;
            border: none;
          }
        }

        tr {
          height: 30px;

          &:not(:first-child):not(.headerRow):not(.totalRow) {
            &:hover {
              // background: rgba($grey4, 0.2);
              box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);

              .cellDesc::after {
                background: linear-gradient(transparent, #fafafa);
              }
            }
          }

          tbody {
            tr {
              &:nth-child(1) {
                background: $grey5;
              }
            }
          }

          th {
            width: 200px;
            height: 100px;
            padding: 1rem;
            border: 1px solid $grey5;
            font-size: 0.9rem;
            vertical-align: middle;

            .userCol {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .avatarWrapper {
                margin-bottom: 0.2rem;
              }

              .name {
                font-weight: 500;
                font-size: 0.9rem;
                margin-bottom: 0.6rem;
              }

              a {
                border: none;
                color: $black;

                button {
                  box-shadow: 0px 1px 3px 0px #0000000f;
                }
              }
            }

            .evaluatedByCol {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              height: 100%;

              button {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                h5 {
                  font-size: 0.9rem;
                }
              }
            }
          }

          td {
            width: 200px;
            height: 30px;
            border: 1px solid $grey5;
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
            text-align: center;
            vertical-align: middle;

            &:first-child {
              text-align: left;
            }

            .notEvaluated {
              svg rect {
                fill: $grey2;
              }
            }

            .cellTitle {
              font-size: 0.9rem;
              line-height: 1.3rem;
              cursor: pointer;
            }

            .cellDesc {
              color: $grey2;
              font-size: 0.9rem;
              line-height: 1.1rem;
              max-height: 75px;
              margin-top: 0.4rem;
              overflow: hidden;
              position: relative;
              cursor: pointer;

              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50%;
                background: linear-gradient(transparent, white);
              }

              h1 {
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: $darkgrey;
              }

              h2 {
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $darkgrey;
              }

              p,
              li {
                margin: 0.2rem 0;
                line-height: 1.2rem;
                font-weight: 300;
              }

              ul li {
                list-style-type: initial;
                margin-left: 1rem;
              }

              ol li {
                list-style-type: decimal;
                margin-left: 1rem;
              }

              em {
                font-style: italic;
              }

              u {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .footerWrapper {
      margin-top: 4rem;
    }
  }
}
