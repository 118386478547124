@import 'variables.scss';

:local {
  .limitExceeded {
    max-width: 100%;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    border-radius: $bradius2;
    padding: 1.4rem 1.6rem;
    background: $blue;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .icon {
      margin-right: 1.4rem;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: $primary;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: white;
        font-size: 0.8rem;
      }
    }

    .close {
      position: absolute;
      top: 1.2rem;
      right: 1.6rem;

      i {
        font-size: 1.1rem;
      }
    }

    .right {
      width: 90%;

      .title {
        margin-bottom: 0.8rem;
        font-size: 1rem;
        line-height: 1.4rem;
        font-weight: 500;
      }

      .subtitle {
        margin-bottom: 1.2rem;
        font-size: 0.9rem;
        line-height: 1.4rem;
        font-weight: 300;
        max-width: 80%;
      }

      .owner {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        h5 {
          margin-bottom: 1rem;
        }

        .user {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .avatar {
            margin-right: 0.6rem;
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .name {
              font-size: 0.9rem;
              line-height: 0.9rem;
              margin-bottom: 0.2rem;
            }

            .email {
              font-size: 0.9rem;
              line-height: 0.9rem;
              color: $grey1;
              font-weight: 300;

              a {
                text-decoration: none;
                border: none;
              }
            }
          }
        }
      }

      .button {
        margin-top: 2rem;

        button {
          // font-size: 0.9rem;
          // font-weight: 500;
        }
      }
    }
  }
}
