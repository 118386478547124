@import "variables.scss";

:local {
  .main {
    .content {
      // width: 80%;

      @media (max-width: 1080px) {
        width: 100%;
      }

      .subtitle {
        max-width: 90%;
        margin: 1rem 0;
        font-weight: 300;
        font-size: 1.1rem;
        line-height: 1.6rem;
        list-style-type: initial;
      }

      .finalGoalsWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $grey4;

        .noGoals {
          color: $black;
          margin-top: 1rem;
        }

        .goal {
          position: relative;
          width: 47.5%;
          min-height: 220px;
          padding: 2rem;
          background: white;
          border: 1px solid $grey4;
          border-radius: $bradius;
          margin-right: 1rem;
          margin-top: 1rem;

          .subGoals {
            margin-top: 1.4rem;

            .subGoal {
              width: 100%;
              margin-bottom: 0.6rem;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;

              &:last-child {
                margin-bottom: 0;
              }

              .avatarWrapper {
                margin-right: 0.6rem;

                img,
                div[aria-label="initials"] {
                  width: 20px;
                  height: 20px;
                }
              }

              .title {
                font-size: 0.9rem;
                line-height: 1.1rem;
                margin-top: 1px;
                color: $grey1;
              }
            }
          }

          &:nth-child(2) {
            .iconActive {
              background: $yellow;
            }
          }
          &:nth-child(3) {
            .iconActive {
              background: $blue;
            }
          }
          &:nth-child(4) {
            .iconActive {
              background: $primary;
            }
          }
          &:nth-child(5) {
            .iconActive {
              background: $red;
            }
          }
          &:nth-child(6) {
            .iconActive {
              background: $pink;
            }
          }
          &:nth-child(7) {
            .iconActive {
              background: $lightpurple;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          .iconActive {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background: $grey4;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              transform: scale(0.6);
            }

            svg path {
              fill: white;
            }
          }

          .iconActive {
            background: $green4;
          }

          h5 {
            margin-top: 1rem;

            &.italic {
              font-style: italic;
            }
          }
        }
      }

      .section {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $grey4;

        &:last-child {
          border: none;
        }

        h5,
        h4 {
          margin-bottom: 1rem;
        }

        p {
          strong {
            font-weight: 600;
          }
        }

        ul {
          margin-top: 2rem;
          margin-left: 1rem;

          li {
            margin: 1rem 0;
            list-style-type: disc;
          }
        }

        .boxes {
          margin-top: 2rem;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          button {
            width: 33%;
            margin-right: 1rem;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &:hover {
              .box {
                box-shadow: $boxshadow;
              }
            }
          }

          .box {
            width: 100%;
            min-height: 235px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 1.4rem;
            background: white;
            border: 1px solid $grey4;
            border-radius: $bradius;
            box-shadow: $lightboxshadow;
            transition: $transition;

            .icon {
              width: 40px;
              height: 40px;
              border-radius: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 1.4rem;

              &.competency {
                background: rgba($green, 0.2);
                svg {
                  transform: scale(0.8);
                }
              }

              &.finish {
                background: rgba($primary, 0.15);

                i {
                  color: $primary;
                  font-size: 0.9rem;
                }
              }
            }

            h5 {
              margin-bottom: 0.4rem;
              font-size: 1rem;
            }

            p {
              color: $darkgrey;
              font-size: 0.8rem;
              line-height: 1.3rem;
            }

            .comingSoon {
              margin-top: 1.4rem;
              padding: 0.1rem 0.5rem;
              border: 1px solid #e5e6e8;
              color: $black;
              border-radius: 0.3rem;
              font-size: 0.8rem;
              font-weight: 500;
              max-width: 96px;
            }
          }
        }
      }
    }
  }
}
