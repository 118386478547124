@import "variables.scss";

:local {
  .wrapper {
    background-image: url("~images/competencies-blank.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 800px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 4rem;
    font-family: proxima-nova;

    @media (max-width: 800px) {
      width: 100%;
    }

    &.candidate {
      background-image: url("~images/candidate-competencies-blank.jpg");
    }

    .noComplete {
      margin-top: calc(175px - 140px);
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        padding: 0.8rem;
        background: rgba(black, 0.8);
        border-radius: $bradius;
        box-shadow: $lightboxshadow;
        font-size: 1rem;
        line-height: 1.4rem;
        font-weight: 400;
        color: white;
        max-width: 320px;
        text-align: center;
      }
    }

    a {
      display: block;
      margin: 1rem auto;
      max-width: 250px;
      border: none;
    }
  }
}
