@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    position: relative;
    h2 {
      margin-top: 2rem;
    }
    .searching {
      margin: 2rem 0;
    }
    .user {
      display: flex;
      align-items: center;
      margin: 2rem 0;
      [aria-label="avatarWrapper"] {
        width: 45px;
        margin-right: 2rem;
      }
      .right {
        width: 100%;
        .name {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
    .scorecards {
      margin-top: 2rem;
      display: flex;
      .scorecardWrapper {
        border-radius: $bradius3 64px $bradius3 $bradius3;
        border: 2px solid transparent;
        transition: $transition;
        position: relative;
        min-width: 230px;
        &.selected {
          border-color: $primary;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: $bradius3 64px $bradius3 $bradius3;
            background-color: rgba(#80d2e5, 0.1);
          }
        }
      }
    }
  }
  .submit {
    margin-top: 2rem;
  }
}
