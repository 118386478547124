@import "variables.scss";

:local {
  .lights {
    display: flex;
    justify-content: center;
    align-items: center;

    .light {
      width: 10px;
      height: 10px;
      background: $grey4;
      margin-right: 2px;
      border-radius: 2px;
    }

    &.lights-1 {
      .light {
        &.active {
          background: $red;
        }

        &.half {
          background: linear-gradient(to right, $red 5px, $grey4 0px);
        }
      }
    }

    &.lights-2 {
      .light {
        &.active {
          background: $red;
        }
        &.half {
          background: linear-gradient(to right, $red 5px, $grey4 0px);
        }
      }
    }
    &.lights-3 {
      .light {
        &.active {
          background: $yellow;
        }
        &.half {
          background: linear-gradient(to right, $yellow 5px, $grey4 0px);
        }
      }
    }
    &.lights-4 {
      .light {
        &.active {
          background: $green;
        }
        &.half {
          background: linear-gradient(to right, $green 5px, $grey4 0px);
        }
      }
    }
    &.lights-5 {
      .light {
        &.active {
          background: $green4;
        }
        &.half {
          background: linear-gradient(to right, $green4 5px, $grey4 0px);
        }
      }
    }

    &.highDev {
      .light {
        &.active {
          background: $grey1;
        }
        &.half {
          background: linear-gradient(to right, $grey1 5px, $grey4 0px);
        }
      }
    }
  }
}
