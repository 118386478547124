@import "variables.scss";

:local {
  .graphWrapper {
    width: 100%;

    &.minimal {
      border: none;
      padding: 0;
      transform: scale(0.8);
      transform-origin: left;
    }

    .lineWrapper {
      margin: 2rem 0;

      .line {
        position: relative;
        width: 100%;
        height: 2px;
        background: $grey4;

        .distribution {
          position: absolute;
          top: -8px;
          height: 16px;
          background: linear-gradient(
            270deg,
            #018949 0%,
            #37c884 31.85%,
            #d3ffeb 53.93%,
            #dadada 85.42%
          );
          border-radius: $bradius;
          box-sizing: content-box;
          width: 100%;

          &:after {
            position: absolute;
            content: "";
            height: 40px;
            width: 100%;
            transform: translateX(-2px);
            border-left: 2px solid white;
            border-right: 2px solid white;
          }

          &.highlight {
            background: rgba($grey3, 0.4);
          }
        }

        .teamScore {
          position: absolute;
          top: -26px;
          width: 3px;
          height: 50px;
          background: black;
          display: flex;
          justify-content: center;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -13px;
            width: 10px;
            height: 50px;
            background: rgba($darkgrey, 0.1);
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 6px;
            width: 10px;
            height: 50px;
            background: rgba($darkgrey, 0.1);
          }

          .number {
            position: relative;
            top: -20px;
            text-align: center;
            font-weight: 500;
            color: black;
          }

          &.highlight {
            background: $darkgrey;

            .number {
              color: $darkgrey;

              &.bg {
                background: rgba(white, 0.9);
                padding: 4px 8px;
              }
            }
          }
        }

        .avatar {
          position: absolute;
          top: -24px;
          height: 30px;
          width: 30px;
          margin: 0 auto;
          background: white;
          border-radius: 50%;

          img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            position: relative;
            padding: 3px;
          }

          .initials {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            position: relative;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              font-size: 1.2rem;
              color: $primary;
              font-weight: 600;
              letter-spacing: 1px;
            }

            &:hover {
              border-color: $grey4;
            }
          }
        }
      }

      &.green,
      &.veryDiverse {
        .distribution {
          border-color: $green !important;
        }
      }

      &.yellow,
      &.similar {
        .distribution {
          border-color: $yellow !important;
        }
      }

      &.grey,
      &.diverse {
        .distribution {
          border-color: $darkgrey !important;
        }
      }

      &.red,
      &.verySimilar {
        .distribution {
          border-color: $red !important;
        }
      }

      &.large {
        .distribution {
          top: -30px;
          height: 60px;
          transform: translateX(-37px);
          border-left: 12px solid $primary;
          border-right: 12px solid $primary;
          box-sizing: content-box;

          &:after {
            position: absolute;
            content: "";
            height: 60px;
            width: 100%;
            transform: translateX(-4px);
            border-left: 4px solid white;
            border-right: 4px solid white;
          }
        }

        .teamScore {
          height: 80px;
          top: -40px;
          width: 5px;
        }
      }

      .wrapper {
        position: absolute;
        top: -17px;
        height: 30px;
        width: 30px;
        margin: 0 auto;
        background: white;
        border-radius: 50%;
        border: 2px solid white;
        box-sizing: content-box;
        transform: translateX(-15px);
        opacity: 0.75;
        transition: $transition;

        &:hover {
          opacity: 1;
          z-index: 1000;
        }

        &.notmember {
          border-color: $grey1;

          div[aria-label="initials"] {
            background: white;

            p {
              color: $grey1;
            }
          }
        }

        &.large {
          height: 50px;
          width: 50px;
          top: -28px;
          border: 2px solid white;
          transform: translateX(-25px);

          &.notmember {
            border-color: $grey1;

            div[aria-label="initials"] {
              background: white;

              p {
                color: $grey1;
              }
            }
          }

          &.highlight {
            height: 65px;
            width: 65px;
            top: -38px;
            border: 6px solid $green;
            transform: translateX(-38px);
            box-shadow: $boxshadow;

            .number {
              width: 20px;
              text-align: center;
              position: absolute;
              bottom: -2rem;
              left: 37%;
              font-weight: 500;
              color: $green;
              font-size: 1rem;
              text-shadow: $boxshadow;

              &.bg {
                top: -1.65rem;
              }
            }

            img {
              width: 55px;
              height: 55px;
            }

            &.green {
              border: 6px solid $green;

              div[aria-label="initials"] {
                background: white;

                p {
                  color: $green;
                }
              }
            }

            &.red {
              border: 6px solid $red;

              div[aria-label="initials"] {
                background: white;

                p {
                  color: $red;
                }
              }
            }

            &.yellow {
              border: 6px solid $yellow;

              div[aria-label="initials"] {
                background: white;

                p {
                  color: $yellow;
                }
              }
            }

            &.grey {
              border: 6px solid $grey1;

              div[aria-label="initials"] {
                background: white;

                p {
                  color: $grey1;
                }
              }
            }
          }
        }

        &.highlight {
          z-index: 10;
          height: 50px;
          width: 50px;
          top: -28px;
          border: 6px solid $green;
          transform: translateX(-28px);
          box-shadow: $boxshadow;
          opacity: 0.9;

          &.black {
            border-color: $black;
            div[aria-label="initials"] {
              p {
                color: $black;
              }
            }
          }

          &.green {
            border: 6px solid $green;

            div[aria-label="initials"] {
              p {
                color: $green;
              }
            }
          }

          &.red {
            border: 6px solid $red;

            div[aria-label="initials"] {
              p {
                color: $red;
              }
            }
          }

          &.yellow {
            border: 6px solid $yellow;

            div[aria-label="initials"] {
              p {
                color: $yellow;
              }
            }
          }

          &.grey {
            border: 6px solid $grey1;

            div[aria-label="initials"] {
              p {
                color: $grey1;
              }
            }
          }
        }

        .scoreWrapper {
          text-align: center;
          font-weight: 500;
          margin-top: 0.8rem;
          text-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

          &.green {
            color: $green;
          }

          &.yellow {
            color: $yellow;
          }

          &.grey {
            color: $grey1;
          }

          &.red {
            color: $red;
          }
        }
      }
    }

    .compGraphLegends {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 2.4rem;
      padding: 0 1rem;

      .legend {
        font-size: 0.9rem;
        line-height: 1.3rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #5be2a3;

        @media (max-width: 802px) {
          font-size: 0.7rem;
          line-height: 1.1rem;
        }

        &:first-child {
          color: $grey1;
        }

        &:last-child {
          color: $green4;
        }
      }
    }
  }
}
