@import "variables.scss";

:local {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: $wrapperWidth;
    flex-wrap: wrap;

    @media (max-width: 1280px) {
      justify-content: flex-start;
      align-items: flex-start;
    }

    .addMember {
      position: relative;
      background: $blue;
      border: 1px dashed $primary;
      border-radius: $bradius2;
      margin: 0.3rem;
      cursor: pointer;
      width: 86px;
      height: 98px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: $transition;

      &:hover {
        border-color: $grey3;
        box-shadow: $boxshadow;
      }

      h4 {
        font-weight: 500;
        font-size: 0.9rem;
        color: $primary;
        text-align: center;
      }

      .icon {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.6rem;
        margin-bottom: 0.4rem;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: $primary;
          }
        }
      }
    }

    a {
      border: none;
    }

    .memberWrapper {
      position: relative;

      .removeWrapper button {
        position: absolute;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: $extradarkgrey;
        color: white;
        border-radius: 50%;
        top: 0;
        right: 0px;
        font-size: 0.9;
        font-weight: 400;
        width: 24px;
        height: 24px;
        transition: $transition;
        z-index: 100;

        &:hover {
          opacity: 1;
          background: $red;
        }

        i {
          font-size: 0.8rem;
          margin: 0;
          margin-top: 1px;
        }

        @media (max-width: 1024px) {
          display: flex;
        }
      }

      &.isLoading {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.6;

        .removeWrapper button {
          display: flex;

          i {
            font-size: 0.8rem;
            margin: 0;
            animation-name: spin;
            animation-duration: 1200ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        }
      }

      &:hover {
        .removeWrapper button {
          display: flex;
        }
      }
    }

    .legend {
      width: 100%;
      font-size: 0.9rem;
      line-height: 1.3rem;
      font-weight: 300;
      color: $darkgrey;
      margin-top: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      @media (max-width: 1280px) {
        justify-content: flex-start;
        text-align: left;
      }

      @media (max-width: 768px) {
        flex-direction: column;

        i {
          margin: 0;
          margin-bottom: 0.2rem;
        }
      }

      i {
        margin-right: 0.6rem;
      }
    }
  }
}
