@import "variables.scss";

:local {
  .positionsLength {
    padding-top: 1px;
    margin: -0.4rem 0;
    margin-left: 0.4rem;
    background: rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .afterUser {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 423px) {
      flex-direction: column-reverse;
    }

    div {
      padding: 0.4rem 2rem 0.4rem 2rem;

      @media (max-width: 486px) {
        padding: 0.4rem 1rem 0.4rem 1rem;
      }

      &.left {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 423px) {
          border: 0;
          margin-top: 1rem;
        }

        .iconWrapper {
          border: 2px solid $grey3;
          border-radius: 50%;
          margin-right: 1rem;
          padding: 0.6rem 0.6rem 0.6rem 7px;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          margin-right: -3.5px;
          margin-top: 4px;
        }
      }

      h5 {
        color: $darkgrey;
      }

      button {
        font-size: 1.1rem;
        font-weight: 400;

        i {
          margin-right: 0.8rem;
        }
      }
    }
  }
}
