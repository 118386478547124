@import "variables.scss";

:local {
  @keyframes changeFromCollapsible {
    0% {
      margin: 4rem 0;
      width: calc(100% - 192px);
    }
    100% {
      margin: 4rem auto !important;
      width: 100%;
    }
  }

  .sidebarWrapper {
    position: relative;
    padding: 0 6rem;
    margin: 4rem auto;
    width: 100%;
    max-width: 1280px;
    left: -128px;
    margin-top: 0;
    padding-top: 0;
    width: calc(100% - 192px);
    transition: 500ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1720px) {
      &.wide {
        &:not(.collapsed) {
          left: -8rem;
          max-width: 1440px;
        }
      }
    }

    .body {
      display: block;
    }

    &.noSidebar {
      margin: 4rem auto;
      left: unset;
    }

    &.collapsed {
      // margin: 4rem auto !important;
      width: 100%;
    }

    &.noHeader {
      margin-top: 4rem;

      @media (max-width: 960px) {
        margin-top: 5rem !important;
      }
    }

    &.noMarginBottom {
      margin-bottom: 0;
    }

    @media (min-width: 1720px) {
      left: initial;
      min-height: 90vh;
    }

    @media (max-width: 2000px) {
      left: 0;
    }

    @media (max-width: 1720px) {
      margin: 4rem auto;
    }

    @media (max-width: 960px) {
      padding: 0 1rem;
      left: 0;
      max-width: 100%;
      width: 100%;
      margin-top: 7.4rem !important;
    }
  }
}
