@mixin logo {
  width: var(--logo-header-width);
  height: var(--logo-header-height);
  margin-right: 1.6rem;
  margin-left: var(--logo-header-margin-left);
  font-family: proxima-nova;
  background: var(--logo_url);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin white_logo {
  width: var(--logo-header-width);
  height: var(--logo-header-height);
  margin-right: 1.6rem;
  margin-left: var(--logo-header-margin-left);
  font-family: proxima-nova;
  background: var(--white_logo_url);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin login_logo {
  display: block;
  margin: 0 auto;
  margin-left: -25px;
  height: 42px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin signup_logo {
  height: var(--signup-logo-height);
  width: var(--signup-logo-width);
  background: var(--logo_url);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
