@import "variables.scss";

:local {
  .inviteToWisnio {
    width: 100%;
    position: relative;
    padding: 2.4rem 4.2rem;
    max-height: 148px;
    height: 100%;
    border-radius: $bradius2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue;
    background-image: url("~images/inviteToWisnio.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right 0px;
  }

  .linkedInPlugin {
    width: 100%;
    padding: 1.6rem 2.2rem;
    max-height: 148px;
    height: 100%;
    border-radius: $bradius2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue;
    position: relative;

    @media (max-width: 920px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-height: max-content;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("~images/icons/LinkedIn.svg");
      background-repeat: no-repeat;
      background-size: 170px;
      background-position: 42px center;
      z-index: 0;
      opacity: 0.1;

      @media (max-width: 920px) {
        background-position: right center;
      }
    }

    .title {
      font-size: 22px;
      line-height: 28px;
      font-weight: 500;
      width: 170px;
      margin-right: 1.6rem;
      color: $extradarkgrey;
      z-index: 2;

      @media (max-width: 920px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0.6rem;
      }
    }

    .subtitle {
      flex: 1;
      margin-right: 1.6rem;
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;

      @media (max-width: 920px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    button {
      min-width: max-content;
    }
  }
}
