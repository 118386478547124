@import "variables.scss";

:local {
  .signup {
    position: relative;
    width: 100%;
    background: white;

    .heading {
      line-height: 28px;
      font-size: 22px;
      font-weight: 500;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .loading {
      width: 100%;
      max-width: 450px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .ssoTitle {
      margin-bottom: 0.6rem;
    }

    .separate {
      width: 100%;
      margin: 0 auto;
      font-size: 0.8rem;
      font-weight: 300;
      color: $grey1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 48%;
        width: 43%;
        height: 1px;
        background: $grey4;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 48%;
        width: 43%;
        height: 1px;
        background: $grey4;
      }
    }

    .ssoButtons {
      width: 100%;

      button {
        height: 40px;
        font-size: 0.9rem;
        position: relative;
        color: black;

        svg {
          position: absolute;
          left: 0.8rem;
        }
      }

      .row {
        display: flex;
        justify-content: space-between;

        @media (max-width: 450px) {
          flex-direction: column;
        }

        &:first-child div {
          margin-top: 0;
        }

        div {
          width: 100%;
          margin-top: 1rem;
          margin-right: 1.4rem;
          justify-content: space-between;

          &:last-child {
            margin-right: 0;
          }

          .label {
            font-weight: 500;
            font-size: 0.9rem;
            color: black;
            margin-bottom: 0.2rem;
          }
        }

        .checkbox {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 1.2rem;
          cursor: pointer;
          width: 100%;

          .label,
          a {
            font-weight: 300;
            font-size: 0.9rem;
            line-height: 1.4rem;
            color: black;
          }

          a {
            color: $primary;
            border: none;
          }

          input {
            height: 15px;
            width: 15px;
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
}
