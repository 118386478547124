@import 'variables.scss';

:local {
  .modalWrapper {
    max-width: $modalWrapper;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 2rem auto;

    .subtitle {
      margin-top: 1rem;
      font-weight: 300;
      max-width: 70%;
      line-height: 1.8rem;
    }

    .buttonGroup {
      width: 100%;
      margin-top: 3rem;

      button {
        width: 100%;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .card {
        width: 100%;
        border: 1px solid $grey4;
        border-radius: $bradius3;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.1rem;
        height: 88px;
        padding: 1.4rem;
        transition: $transition;

        &:hover {
          border-color: $grey2;
          box-shadow: $lightboxshadow;
        }

        .text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          p {
            font-weight: 300;
            font-size: 0.9rem;
            color: $grey1;
          }
        }

        .icon {
          width: 48px;
          height: 48px;
          background: rgba($black, 0.15);
          border-radius: $bradius3;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.2rem;

          &.blue {
            background: $blue;

            svg {
              fill: $blue;
            }
          }

          &.red {
            background: rgba($red, 0.15);

            i {
              color: $red;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
