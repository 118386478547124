@import "variables.scss";

:local {
  .wrapper {
    display: grid;
    grid-template-columns: minmax(600px, 40%) 1fr;
    position: relative;

    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
    }

    // &::after {
    //   content: "";
    //   width: 40%;
    //   min-width: 640px;
    //   height: 150%;
    //   position: absolute;
    //   background: #e3f7eb;
    //   right: 0;
    //   top: 0;
    //   z-index: -1;
    // }

    @media (max-width: 1080px) {
      flex-direction: column;
      min-height: auto;
    }

    .left {
      // width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      min-height: 100vh;
      overflow: hidden;
      // max-width: 900px;
      padding: 2rem 8rem;

      @media (max-width: 1300px) {
        padding: 4rem;
      }

      @media (max-width: 1080px) {
        padding: 8rem 4rem;
        // max-width: 100%;
        // width: 100%;
      }

      @media (max-width: 550px) {
        padding: 4rem 2rem;
        padding-top: 6rem;
      }

      .title {
        font-weight: 700;
        // max-width: 470px;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 2.4rem;

        @media (max-width: 1080px) {
          font-size: 36px;
          line-height: 42px;
          margin-bottom: 1.6rem;
        }
      }

      .subtitle {
        color: $grey1;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 3rem;
        max-width: 500px;

        @media (max-width: 1080px) {
          font-size: 0.9rem;
        }
      }

      .fields {
        width: 100%;
        max-width: 600px;

        .field {
          p {
            margin: 1rem 0;
            color: $grey1;
          }

          .options {
            margin: 2rem 0;
          }

          .option {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: 1px solid $grey5;
            border-radius: $bradius3;
            margin-bottom: 1rem;
            transition: $transition;

            &:hover {
              background-color: rgba($blue, 0.5);
            }

            &.selected {
              background-color: $blue;
              border-color: $primary;
            }

            &:last-child {
              margin-bottom: 0;
            }

            input {
              width: 30px;
              height: 30px;
              margin: 0;
              margin-left: 1.4rem;
              position: absolute;
              cursor: pointer;
            }

            label {
              font-size: 15px;
              line-height: 20px;
              color: $darkgrey;
              width: 100%;
              height: 100%;
              padding: 1.4rem;
              padding-left: 4.4rem;
              cursor: pointer;

              strong {
                font-weight: 500;
                color: $black;
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 600px;

        @media (max-height: 800px) {
          position: fixed;
          z-index: 1;
          bottom: 1rem;
          width: 100%;
          max-width: 408px;

          @media (max-width: 1280px) {
            max-width: 504px;
          }

          @media (max-width: 1080px) {
            max-width: 600px;
          }
        }

        a {
          border-bottom: none;
        }

        button {
          width: 100%;

          &:first-child {
            margin-bottom: 1rem;
          }
        }

        .noBackgroundGrey {
          color: $grey1;
          font-size: 0.9rem;

          &:hover {
            background-color: $grey5;
          }
        }
      }
    }

    .right {
      // width: 55%;
      // min-width: 640px;
      // height: 100vh;
      background: $blue;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;

      @keyframes float {
        0% {
          margin-left: 1.6rem;
          margin-top: 1rem;
          transform: rotate(0deg);
        }
        25% {
          margin-left: 0;
          margin-top: 0;
          transform: rotate(5deg);
        }
        50% {
          margin-left: 1.6rem;
          margin-top: 1rem;
          transform: rotate(10deg);
        }
        75% {
          margin-left: 0;
          margin-top: 0;
        }
        100% {
          margin-left: 1.6rem;
          margin-top: 1rem;
        }
      }

      @keyframes float2 {
        0% {
          margin-right: 1rem;
          margin-top: 2rem;
          transform: rotate(0deg);
        }
        25% {
          margin-left: 0;
          margin-top: 0;
          transform: rotate(15deg);
        }
        50% {
          margin-left: 1rem;
          margin-top: 2rem;
          transform: rotate(10deg);
        }
        75% {
          margin-left: -1rem;
          margin-top: 1rem;
        }
        100% {
          margin-left: 1rem;
          margin-top: 2rem;
        }
      }

      .floatingLogo {
        position: absolute;
        background-image: url("~images/Logo.svg");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 50px;
        height: 50px;

        &:first-child {
          width: 600px;
          height: 600px;
          top: -150px;
          left: -150px;
          animation: float 15s infinite linear;
        }

        &:nth-child(2) {
          width: 150px;
          height: 150px;
          top: 350px;
          left: 600px;
          animation: float2 20s infinite linear;
        }

        &:nth-child(3) {
          width: 600px;
          height: 600px;
          bottom: -300px;
          left: 260px;
          animation: float 18s infinite linear;
        }

        @media (max-width: 1080px) {
          &:first-child {
            width: 300px;
            height: 300px;
            top: -80px;
            left: -80px;
          }

          &:nth-child(2) {
            width: 150px;
            height: 150px;
            top: 100px;
            left: 350px;
          }

          &:nth-child(3) {
            width: 300px;
            height: 300px;
            bottom: -150px;
            left: 60%;
          }
        }
      }

      &.green {
        background: #e3f7eb;
      }

      &.yellow {
        background: #fcf6ef;
      }

      &.blue {
        background: #eff9ff;
      }

      &.grey {
        background: $grey6;
      }

      @media (max-width: 1080px) {
        width: 100%;
        height: 400px;
      }

      .img {
        z-index: 1000;

        img {
          height: 300px;
        }
      }
    }
  }

  .modalContent {
    display: grid;
    grid-template-columns: minmax(600px, 40%) 1fr;
    position: relative;

    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
    }

    // &::after {
    //   content: "";
    //   width: 40%;
    //   min-width: 640px;
    //   height: 150%;
    //   position: absolute;
    //   background: #e3f7eb;
    //   right: 0;
    //   top: 0;
    //   z-index: -1;
    // }

    @media (max-width: 1080px) {
      flex-direction: column;
      min-height: auto;
    }

    .left {
      // width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      min-height: 100vh;
      overflow: hidden;
      // max-width: 900px;
      padding: 2rem 6rem;

      @media (max-width: 1280px) {
        // width: 60%;
        padding: 4rem 3rem;
      }

      @media (max-width: 1080px) {
        width: 100%;
        padding: 4rem 6rem;
      }

      @media (max-width: 620px) {
        padding: 4rem 2rem;
      }

      .defaultLogo {
        display: block;
        height: 32px;
        width: 112px;
        background: url("~images/logo/default/logo.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .content {
        margin-top: 6rem;

        .useCaseTitle {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 0.4rem;

          .icon {
            width: 48px;
            height: 48px;
            border-radius: $bradius3;
            background-color: $blue;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;

            svg {
              width: 24px;
              height: 24px;

              path {
                fill: $primary;
              }
            }

            &.primary {
              background-color: $blue;

              svg path {
                fill: $primary;
              }
            }

            &.green {
              background-color: $lightgreen;

              svg path {
                fill: $green;
              }
            }

            &.yellow {
              background-color: rgba($lightyellow, 0.3);

              svg path {
                fill: $yellow;
              }
            }

            &.purple {
              background-color: $lightpurple;

              svg path {
                fill: $purple;
              }
            }
          }

          h3 {
            font-size: 2rem;
          }
        }

        .description {
          margin-left: calc(48px + 1rem);
          font-weight: 300;
          color: $darkgrey;
        }

        .list {
          margin: 4rem 0;

          .listItem {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid $grey5;

            &:last-child {
              margin-bottom: 0;
              border-bottom: 0;
            }

            .number {
              margin-right: 1.6rem;

              span {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid $grey5;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
              }
            }

            .text {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 0.6rem;

              h5 {
                margin-bottom: 0.8rem;
                font-size: 15px;
              }

              p {
                font-weight: 300;
                color: $darkgrey;
              }
            }
          }
        }

        .buttons {
          width: 100%;

          @media (max-height: 800px) {
            position: fixed;
            z-index: 1;
            bottom: 1rem;
            width: 100%;
            max-width: 408px;

            @media (max-width: 1280px) {
              max-width: 504px;
            }
          }

          button {
            width: 100%;
          }
        }
      }
    }

    .right {
      // width: 55%;
      // min-width: 640px;
      // height: 100vh;
      background-color: $blue;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      padding: 6rem;

      @media (max-width: 1280px) {
        // width: 40%;
      }

      @media (max-width: 1080px) {
        width: 100%;
        min-width: 100%;
        padding: 2rem 2rem;
        // height: auto;
      }

      &.primary {
        background-color: $blue;
      }

      &.green {
        background-color: $lightgreen;
      }

      &.yellow {
        background-color: rgba($lightyellow, 0.3);
      }

      &.purple {
        background-color: $lightpurple;
      }

      .video {
        width: 100%;
        background-color: white;
        border-radius: $bradius3;

        @media (max-width: 1080px) {
          // height: 200px;
        }
      }
    }
  }
}
