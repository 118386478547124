@import 'variables.scss';

:local {
  .avatars {
    margin-bottom: 0.3rem;
  }

  .link {
    @media (max-width: 620px) {
      max-width: 100%;
      width: 100%;
    }
  }
}
