@import "variables.scss";

:local {
  .wrapper {
    max-width: $modalWrapper;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    .icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 2rem;

      i {
        &:first-child {
          font-size: 6rem;
          color: $darkgrey;
        }

        &:last-child {
          color: $primary;
          font-size: 2rem;
          margin-left: 0.4rem;
        }
      }
    }

    .icon {
      width: 72px;
      height: 72px;
      background-color: $blue;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;

      svg {
        width: 36px;
        height: 36px;

        path {
          fill: $primary;
        }
      }
    }

    .title {
      // font-size: 2.8rem;
      font-weight: 500;
      margin-bottom: 1rem;
      text-align: center;
    }

    .subtitle {
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 300;
      text-align: center;
      color: $black;
      max-width: 60%;
      margin-bottom: 2rem;
    }

    .buttonGroup {
      display: flex;
      justify-content: center;
      align-items: center;

      button:first-child {
        margin-right: 1rem;
      }
    }
  }
}
