@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;

    img,
    div[aria-label="initials"] {
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &.blue {
      .initials {
        background: $blue;

        p {
          color: $primary;
        }
      }
    }

    &.large {
      img {
        height: 150px;
        width: 150px;
      }

      .initials {
        height: 150px;
        width: 150px;

        p {
          font-size: 2.2rem;
          font-weight: 600;
          letter-spacing: 2px;
        }
      }
    }

    &.almostLarge {
      img {
        height: 110px;
        width: 110px;
      }

      .initials {
        height: 110px;
        width: 110px;

        p {
          font-size: 1.6rem;
          font-weight: 600;
          letter-spacing: 2px;
        }
      }
    }

    &.mediumLarge {
      img {
        height: 60px;
        width: 60px;
      }

      .initials {
        height: 60px;
        width: 60px;

        p {
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }
    }

    &.medium {
      img {
        height: 45px;
        width: 45px;
      }

      .initials {
        height: 45px;
        width: 45px;

        p {
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }
    }

    &.mediumSmall {
      img {
        height: 32px;
        width: 32px;
      }

      .initials {
        height: 32px;
        width: 32px;

        p {
          font-size: 0.7rem;
          font-weight: 500;
        }
      }
    }

    &.small {
      img {
        height: 30px;
        width: 30px;
      }

      .initials {
        height: 30px;
        width: 30px;

        p {
          font-size: 0.7rem;
          font-weight: 500;
        }
      }
    }
    &.extra-small {
      img {
        height: 24px;
        width: 24px;
      }

      .initials {
        height: 24px;
        width: 24px;

        p {
          font-size: 0.5rem;
          font-weight: 500;
        }
      }
    }
  }
}
