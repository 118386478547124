@import "variables.scss";

:local {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
    margin: 4rem 0;
    width: 100%;

    @media (max-width: 1240px) {
      grid-template-columns: 1fr 1fr;
      margin: 2rem 0;
    }

    @media (max-width: 990px) {
      grid-template-columns: 1fr;
      margin-bottom: 6rem;
    }

    .box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      .popup {
        min-width: 450px;
      }

      .icon {
        width: 48px;
        height: 48px;
        margin-right: 1rem;
        background-color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $bradius3;

        i {
          font-size: 20px;
        }

        &.primary {
          background-color: $blue;

          i {
            color: $primary;
          }
        }

        &.green {
          background-color: $lightgreen;

          i {
            color: $green;
          }
        }

        &.yellow {
          background-color: $haze;

          i {
            color: $yellow;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .emptyState {
        font-size: 15px;
        line-height: 22px;
        color: $grey1;
        font-weight: 300;
      }

      .name::first-letter,
      .listName::first-letter {
        text-transform: uppercase;
      }

      .listTitle {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 500;
        margin-bottom: 0.8rem;
        max-width: 100px;
        min-height: 58px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .listName {
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
        text-align: left;
        // padding: 3px 8px;
        // border: 1px solid $grey5;
        color: $darkgrey;
        border-radius: $bradius;
        max-width: max-content;
        margin-top: 0.8rem;
        transition: $transition;
        // cursor: pointer;

        &:hover {
          border-color: $grey2;
        }
      }
    }
  }

  @keyframes :local(shine-lines) {
    0% {
      background-position: -90px;
    }

    40%,
    100% {
      background-position: 600px;
    }
  }

  .skeleton {
    position: relative;
    width: 75%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 550px) {
      flex-direction: column;
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 2.6rem 2rem 0 2rem;

      div {
        width: 100%;
        height: 12px;
        margin: 0.5rem 0;
        background: $grey3;
        border-radius: 1rem;
        background-image: linear-gradient(
          90deg,
          $grey4 0px,
          $grey5 40px,
          $grey4 80px
        );
        background-size: 1200px;
        animation: shine-lines 2.6s infinite ease-in-out;

        &:first-child {
          width: 65%;
        }
        &:nth-child(4) {
          width: 75%;
        }
      }
    }
  }
}
