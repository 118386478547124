@import 'variables.scss';

:local {
  .legends {
    margin-top: 2rem;

    .colorDrops {
      margin-bottom: 2rem;

      .row {
        padding: 0.8rem 8rem;
        background: rgba($grey5, 0.5);
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: $bradius;

        @media (max-width: 680px) {
          flex-direction: column;
          padding: 0.4rem 2rem;
          min-width: auto;

          div {
            margin: 0.4rem 0;
          }
        }

        .column {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 3rem;

          .blueDrop {
            width: 30px;
            height: 30px;
            background-image: url('~images/icons/values-1.png');
            background-size: contain;
            background-repeat: no-repeat;
          }

          .greyDrop {
            width: 30px;
            height: 30px;
            background-image: url('~images/icons/values-2.png');
            background-size: contain;
            background-repeat: no-repeat;
          }

          h4 {
            font-weight: 400;
            font-size: 1rem;
          }

          .avatar {
            margin-right: 1rem;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            color: white;

            img {
              border: 3px solid $primary;
              padding: 3px;
              background-color: white;
            }

            div[aria-label='initials'] {
              border: 3px solid $primary;
              padding: 3px;
              background-color: white;
            }
          }
        }
      }
    }

    .row {
      margin: 0 auto;
      margin-bottom: 0.8rem;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 450px) {
        flex-direction: column;
      }

      .legend {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;
        border-bottom: 1px solid $grey3;

        &:first-child {
          padding-right: 2rem;

          @media (max-width: 450px) {
            padding-right: 0rem;
            margin-bottom: 1rem;
          }
        }
      }

      h4 {
        font-weight: 400;
        font-size: 1rem;
      }

      span {
        margin-right: 1rem;
        width: 6px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        color: white;

        &.green {
          background: $green;
        }
        &.yellow {
          background: $yellow;
        }
      }
    }

    .pointers {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 560px) {
        flex-direction: column;
      }

      .pointer {
        padding: 0.5rem 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto 1rem;
        margin-bottom: 0.8rem;
        border-radius: 4rem;

        @media (max-width: 560px) {
          margin-bottom: 0.6rem;
          padding: 0;
        }

        @media (max-width: 520px) {
          min-width: auto;

          h4 {
            line-height: 1.6rem;
          }
        }

        h4 {
          font-weight: 400;
          font-size: 1rem;
          color: $darkgrey;

          @media (max-width: 400px) {
            font-size: 0.9rem;
          }
        }

        svg {
          margin-right: 0.6rem;
        }
      }
    }
  }
}
