@import "variables.scss";

:local {
  .wrapper {
    .imageWrapper {
      width: 80%;
      height: 300px;
      background: $grey5;
      border-radius: $bradius2;
      margin: 2rem 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .subtitle {
      max-width: 70%;
      margin: 1rem 0;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.6rem;
      list-style-type: initial;
    }

    .smallSubtitle {
      font-weight: 300;
      max-width: 700px;
    }

    .infoBox {
      h4 {
        margin-bottom: 1rem;
      }

      p {
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        max-width: 700px;
        margin-bottom: 0.8rem;
      }

      ul {
        margin-top: 1rem;

        li {
          max-width: 70%;
          margin: 1rem 0;
          font-weight: 300;
          font-size: 15px;
          list-style-type: initial;
        }
      }
    }
  }
}
