@import "variables.scss";

:local {
  .wrapper {
    div:first-child {
      .personalityRow {
        margin-top: 2rem;
        padding-top: 0;
        border-top: 0;
      }
    }
  }

  .personalityRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: $wrapperWidth;
    padding-top: 4rem;
    margin-top: 4rem;
    border-top: 1px solid $grey5;

    @media (max-width: 860px) {
      flex-direction: column;
      align-items: center;
    }

    .titleRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        @media (max-width: 480px) {
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
      }

      i {
        margin-right: 1rem;
      }

      .label {
        background: $yellow;
        color: white;
        border-radius: 0.3rem;
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;

        &.similar {
          background: $yellow;
        }
        &.verySimilar {
          background: $red;
        }
        &.diverse {
          background: $grey1;
        }
        &.veryDiverse {
          background: $green;
        }
      }
    }

    .leftSide {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin-right: 1rem;
      width: 55%;

      @media (max-width: 860px) {
        width: 100%;
        margin-right: 0;
      }

      .rowHeader {
        width: 100%;
      }

      .titleRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 412px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .label {
            margin-top: 1rem;
          }
        }
      }

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1rem;

        .score {
          padding-top: 1px;
          margin-left: 1rem;
          background: $primary;
          font-size: 1.1rem;
          font-weight: 500;
          color: white;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: $primary;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }

      .subtitle {
        margin-top: 0.4rem;
      }

      .description {
        color: $darkgrey;

        h5 {
          text-transform: capitalize;
        }
      }

      .lineWrapper {
        width: 100%;
        margin-top: 2rem;

        .line {
          position: relative;
          width: 100%;
          height: 2px;
          background: $grey4;

          &:after {
            content: "";
            height: 23px;
            width: 2px;
            background: $grey3;
            position: absolute;
            top: -9.5px;
            left: calc(50% - 1.5px);
          }

          .avatar {
            position: absolute;
            top: -24px;
            height: 50px;
            width: 50px;
            margin: 0 auto;
            border-radius: 50%;
            background: white;
            z-index: 10;
          }
        }

        .opposites {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 4rem;

          h5 {
            margin-bottom: 0.4rem;
          }

          li {
            line-height: 1.2rem;
          }

          .left {
            margin-right: 2rem;
          }

          .right {
            margin-left: 2rem;

            h5,
            li {
              text-align: right;
            }

            .labelDesc {
              text-align: right;
            }
          }

          .label {
            color: $black;
            text-transform: capitalize;
          }

          .labelDesc {
            color: $grey1;
            font-size: 0.9rem;
            line-height: 1.3rem;
            font-weight: 300;
          }
        }
      }
    }

    .rightSide {
      position: relative;
      width: 45%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 1rem;

      .card {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        background: white;
        border: 1px solid $grey4;
        border-radius: $bradius;
        box-shadow: $lightboxshadow;

        // &:first-child {
        //   margin-bottom: 2rem;
        // }
      }

      @media (max-width: 860px) {
        width: 100%;
        margin-left: 0;
        margin-top: 2rem;
      }

      .description {
        margin: 0.6rem 0;
        color: $black;
        padding: 0.6rem 2rem;

        .titleRow {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 1rem;

          svg {
            height: 24px;
            width: 24px;
            margin-right: 0.4rem;

            path {
              fill: $primary;
            }
          }

          i {
            margin-right: 1rem;
            font-size: 1.2rem;
          }
        }

        h5 {
          color: $black;

          &::first-letter {
            text-transform: capitalize;
          }
        }
      }

      .motivator {
        width: 100%;
        .description {
          i {
            color: $green;
          }
        }
      }

      .derailer {
        width: 100%;
        .description {
          i {
            color: $yellow;
          }
        }
      }

      .more {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border-top: 1px solid $grey4;

        button {
          font-size: 1rem;
          height: 60px;
          width: 100%;
          color: $primary;

          @media (max-width: 412px) {
            font-size: 1rem;
            padding: 0;
          }

          i {
            margin-right: 0.6rem;
          }

          &:hover {
            background: rgba($grey5, 0.2);
          }
        }
      }
    }
  }

  @keyframes :local(shine-lines) {
    0% {
      background-position: -90px;
    }

    40%,
    100% {
      background-position: 600px;
    }
  }

  .skeleton {
    display: relative;
    width: $wrapperWidth;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1045px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .leftSide {
      margin-right: 2rem;
      width: 50%;

      @media (max-width: 1045px) {
        margin: 0;
        margin-bottom: 2rem;

        @media (max-width: 580px) {
          width: 25%;
          margin: 2rem;
        }
      }

      div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 15px;
        margin: 1rem 0;
        background: $grey3;
        border-radius: 1rem;
        background-image: linear-gradient(
          90deg,
          $grey4 0px,
          $grey5 40px,
          $grey4 80px
        );
        background-size: 1200px;
        animation: shine-lines 2.6s infinite ease-in-out;

        &:first-child {
          height: 30px;
          width: 65%;
          margin-bottom: 1.6rem;
        }
        &:nth-child(4) {
          width: 85%;
        }
        &:nth-child(5) {
          height: 30px;
          width: 65%;
          margin-top: 2rem;
          margin-bottom: 1.6rem;
        }
        &:last-child {
          width: 85%;
        }
      }
    }

    .rightSide {
      margin-left: 2rem;
      width: 50%;

      @media (max-width: 1045px) {
        margin: 0;

        @media (max-width: 580px) {
          width: 25%;
          margin: 2rem;
        }
      }

      div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 15px;
        margin: 1rem 0;
        background: $grey3;
        border-radius: 1rem;
        background-image: linear-gradient(
          90deg,
          $grey4 0px,
          $grey5 40px,
          $grey4 80px
        );
        background-size: 1200px;
        animation: shine-lines 2.6s infinite ease-in-out;

        &:first-child {
          height: 30px;
          width: 65%;
          margin-bottom: 1.6rem;
        }
        &:nth-child(4) {
          width: 85%;
        }
        &:nth-child(5) {
          height: 30px;
          width: 65%;
          margin-top: 2rem;
          margin-bottom: 1.6rem;
        }
        &:last-child {
          width: 85%;
        }
      }
    }
  }
}
