@import "variables.scss";

:local {
  .template {
    position: relative;
    border: 0.5px solid $grey4;
    border-radius: $bradius3 64px $bradius3 $bradius3;
    box-shadow: 0px 4px 10px 0px #00000008;
    transition: box-shadow $transition;
    min-height: 300px;
    max-width: 230px;
    width: 100%;
    background-color: $grey6;

    @media (max-width: 620px) {
      max-width: 100%;
      width: 100%;
    }

    &.list {
      width: 100%;
      max-width: 100%;
      min-height: auto;
      border-radius: $bradius2;
      box-shadow: none;

      &.personal {
        min-height: auto;
      }

      &:hover {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
        border-color: $grey3;
      }

      &::after {
        display: none;
      }

      .ellipsisButton {
        bottom: 0.75rem;
      }

      .buttonWrapper {
        .background {
          display: none;
        }

        button {
          min-height: auto;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0.8rem;

          .topAndMiddle {
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
            max-width: max-content;
            margin-right: 1rem;
            display: flex;

            .top {
              flex-wrap: nowrap;
              max-width: initial;
              min-height: auto;
              gap: 4px;

              .label {
                margin-bottom: 0;
                white-space: nowrap;
              }
            }

            .middle {
              margin-right: 1rem;

              .name {
                margin-top: 0;
                font-size: 1.05rem;
                line-height: 1.05rem;
              }
            }
          }

          .bottom {
            margin: 0;
            margin-right: 3rem;
            flex-direction: row-reverse;

            .avatar {
              margin-left: 0.6rem;
              margin-right: 0;
            }

            .info {
              white-space: nowrap;
              align-items: flex-end;
            }
          }
        }
      }
    }

    &:after {
      content: "";
      height: 64px;
      width: 64px;
      background-color: $grey4;
      border-radius: 0px 64px 0px $bradius3;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.personal {
      min-height: 182px;
    }

    &:hover {
      box-shadow: 0px 22px 24px 0px #99999918;
    }

    .buttonWrapper {
      .background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 164px;
        height: 164px;
      }

      button {
        width: 100%;
        height: 100%;
        min-height: 300px;
        padding: 1.2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .topAndMiddle {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          max-width: 140px;
          height: 100%;
          flex: 1;

          .top {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            max-width: 140px;
            min-height: 65px;
            align-items: flex-start;
            gap: 8px;

            .label {
              padding: 4px 8px;
              background: white;
              color: white;
              font-size: 12px;
              line-height: 12px;
              display: inline-block;
              border-radius: 24px;
              border: 1.5px solid transparent;
              margin-right: 0.6rem;
              margin-bottom: 0.4rem;

              &:last-child {
                margin-right: 0;
              }

              &.green {
                background-color: $green;
              }

              &.primary {
                background-color: $primary;
              }

              &.yellow {
                background-color: $yellow;
              }

              &.red {
                background-color: $red;
              }

              &.purple {
                background-color: $purple;
              }

              &.blue {
                background-color: $blue4;
              }

              &.border-green {
                background-color: transparent;
                border-color: $green;
                color: $green;
              }

              &.border-primary {
                background-color: transparent;
                border-color: $primary;
                color: $primary;
              }

              &.border-yellow {
                background-color: transparent;
                border-color: $yellow;
                color: $yellow;
              }

              &.border-red {
                background-color: transparent;
                border-color: $red;
                color: $red;
              }

              &.border-purple {
                background-color: transparent;
                border-color: $purple;
                color: $purple;
              }

              &.border-blue {
                background-color: transparent;
                border-color: $blue4;
                color: $blue4;
              }
            }
          }

          .middle {
            width: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .name {
              font-weight: 500;
              max-width: 100%;
              width: 100%;
              margin-top: -0.8rem;
            }
          }
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          margin-top: 0.8rem;

          .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .name {
              font-size: 0.9rem;
              line-height: 0.9rem;
              margin-bottom: 0.2rem;
              color: $black;
            }

            .description {
              font-size: 0.75rem;
              line-height: 0.8rem;
              color: $grey1;
              font-weight: 400;
            }
          }

          .actions {
            .modalButton {
              min-height: 0;
              height: auto;
            }
          }

          .avatar {
            margin-right: 0.6rem;
          }

          .logo {
            margin-right: 0.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .ellipsisButton {
      position: absolute;
      bottom: 1rem;
      right: 0.5rem;
      width: 32px;
      height: 32px;
      z-index: 2;

      button {
        padding: initial;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .popup {
      min-width: 350px;
      max-width: 350px;

      button {
        justify-content: center;
        color: $primary;
        width: auto;

        &:hover {
          color: $primary;
        }
      }

      .buttonGroup {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.6rem;
        // margin-top: 0.4rem;

        button:first-child {
          margin-right: 0.6rem;
          color: white;
          padding: 0.7rem 1rem !important;

          &:hover {
            background-color: lighten($primary, 3);
            border-color: darken($primary, 0.2);
          }
        }

        button:last-child {
          border: 1px solid #e4e6e8;
          color: $black;
          padding: 0.7rem 1rem !important;
        }
      }
    }
  }
}
