@import "variables.scss";

:local {
  .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 0.5px solid $grey4;
    height: 32px;
    background-color: $grey6;
    max-width: fit-content;

    .item {
      flex: 1;
      height: calc(100% + 1px);
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: fit-content;
      cursor: pointer;

      &:first-child {
        margin-left: -0.5px;
      }

      &:last-child {
        margin-right: -0.5px;
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 0.4rem 0.8rem !important;
      }

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 0.4rem;

        svg path {
          fill: $grey2;
        }
      }

      p {
        font-size: 14px;
        line-height: 14px;
        color: $grey1;
      }

      &.active {
        background-color: white;
        border: 0.5px solid $grey3;

        .icon svg path {
          fill: $black;
        }

        p {
          color: $black;
          font-weight: 500;
        }
      }
    }
  }
}
