@import "variables.scss";

:local {
  .wrapper {
    .subtitle {
      max-width: 70%;
      margin: 1rem 0;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.6rem;
      list-style-type: initial;
    }

    .inviteWrapper {
      margin-top: 2rem;
      // max-width: 750px;

      .smallRow {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: 1px solid $grey5;
        border-bottom: 1px solid $grey5;
        position: relative;

        &.disabled {
          opacity: 0.5;
        }

        .addButton {
          button {
            height: 80%;
            min-width: auto;
          }
        }

        .delete {
          position: absolute;
          right: 2rem;
          top: 0%;
          cursor: pointer;

          svg {
            width: 20px;
            height: 20px;
          }

          &:hover {
            svg path {
              fill: $black;
            }
          }
        }

        input {
          border: none !important;
          font-size: 15px !important;
          margin: 0 !important;
          height: 48px !important;

          &::placeholder {
            font-size: 15px !important;
          }
        }

        button {
          margin-left: 1rem;
          min-width: 120px;
          height: 48px;
        }

        select {
          margin-left: 1rem;
          min-height: 48px;
        }

        @media (max-width: 915px) {
          // flex-direction: column;

          button {
            margin: 0;
            // margin-top: 1rem;
            width: 100%;
          }
        }

        .searchResults {
          background: white;
          position: absolute;
          top: 56px;
          z-index: 100;
          width: 100%;
          max-height: 288px;
          overflow: auto;
          border: 1px solid $grey5;
          box-shadow: $lightboxshadow;
          border-radius: $bradius;
          margin-bottom: 2rem;

          &::-webkit-scrollbar {
            width: 5px;
            background: rgba($grey5, 0.4);
          }

          &::-webkit-scrollbar-thumb {
            background: $grey4;
            border-radius: 2px;
          }

          .searchWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0.8rem 1rem;
            color: $darkgrey;
            border-bottom: 1px solid $grey5;

            .avatarWrapper {
              margin-right: 1rem;
            }

            .info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              .userName {
                font-weight: 500;
                color: black;
                font-size: 14px;
                line-height: 14px;
                margin-bottom: 0.4rem;
              }

              .userEmail {
                font-weight: 300;
                color: $grey1;
                font-size: 14px;
                line-height: 14px;
              }
            }

            &:hover {
              background: rgba($grey5, 0.4);
              color: $black;

              .userName {
                color: $black;
              }
            }
          }
        }
      }

      .error {
        max-width: 90%;
        margin-top: 0.8rem;
        margin-bottom: 1.2rem;
        border-left: 2px solid $red;
        padding-left: 0.8rem;

        p {
          font-weight: 400;
          line-height: 1.2rem;
          color: $red;
        }
      }

      .members {
        margin-top: 1rem;
        max-width: 750px;

        &:last-child {
          border-top: 1px solid $grey5;
          padding-top: 1rem;
        }

        .title {
          letter-spacing: 1px;
          font-size: 13px;
        }

        .memberWrap {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0.6rem 0;
          color: $darkgrey;

          .avatarWrapper {
            margin-right: 1rem;
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .userName {
              font-weight: 500;
              color: black;
              font-size: 14px;
              line-height: 14px;
              margin-bottom: 0.2rem;
            }

            .userEmail {
              font-weight: 400;
              color: $grey1;
              font-size: 14px;
              line-height: 14px;
            }
          }

          .right {
            margin-left: auto;
          }

          .remove {
            margin-left: auto;
            svg {
              width: 20px;
              height: 20px;
            }

            &:hover {
              svg path {
                fill: $black;
              }
            }
          }
        }
      }

      .inviteButton {
        margin-top: 2rem;
      }
    }
  }
}
