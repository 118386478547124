@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: $wrapperWidth;
    gap: 6rem;
    @media (max-width: 768px) {
      gap: 1rem;
    }
  }
  .candidate {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 4rem;

    @media (max-width: 1238px) {
      margin: 2rem 0;
    }

    .right {
      margin-left: 1rem;

      .name {
        margin: 0;
      }

      .email {
        color: $grey1;
      }
    }
  }

  .resultsContainer {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .conclusionBox {
    width: auto;
    padding: 1.5rem 0;
    border-bottom: 1px solid $grey5;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &:last-child {
      border-bottom: none;
    }

    &__reviewer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;

      .name {
        margin: 0;
      }
    }

    .bold {
      font-weight: bold;
    }
  }
}
