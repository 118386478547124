@import 'variables.scss';

:local {
  .title {
    text-align: center;
    font-family: proxima-nova;
    color: black;

    i {
      margin-right: 1rem;
    }

    @media (max-width: 486px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      i {
        margin: 0;
        margin-bottom: 1rem;
      }
    }

    &.white {
      color: white;
      text-shadow: $textshadow;

      i {
        color: white;
      }
    }

    &.small {
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }
}
