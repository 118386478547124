@import "variables.scss";

:local {
  .wrapper {
    max-width: $wrapperWidth;
    width: 100%;

    .row {
      margin-top: 4rem;
      width: 100%;

      .compWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        width: 100%;

        @media (max-width: 1115px) {
          flex-direction: column;
        }

        .compBox {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin: 0;
          padding: 1rem;
          width: 33%;
          background: $grey4;
          border-radius: $bradius2;

          @media (max-width: 1115px) {
            width: 100%;
            margin: 1rem 0;
          }

          &:nth-child(2) {
            margin: 0 2rem;
          }

          &.mustHave {
            background: $lightgreen;

            .title {
              i {
                color: $green;
                margin: 0;

                &:last-child {
                  margin-right: 0.6rem;
                }
              }
            }
          }
          &.important {
            background: $blue;

            .title {
              i {
                color: $primary;
                margin: 0;

                &:last-child {
                  margin-right: 0.6rem;
                }
              }
            }
          }
          &.goodToHave {
            background: rgba($grey4, 0.3);

            .title {
              i {
                color: $darkgrey;
                margin: 0;

                &:last-child {
                  margin-right: 0.6rem;
                }
              }
            }
          }

          .title {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 640px) {
              .stars {
                margin-bottom: 0.4rem;
              }
            }
          }

          .compColumn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: white;
            border-radius: $bradius2;
            box-shadow: $boxshadow;
            margin-top: 1rem;
            width: 100%;
            // border: 1px solid $grey4;

            .comp {
              min-height: 120px;
              width: 100%;
              margin: 0;
              border-bottom: 1px solid $grey4;

              button {
                height: 100%;
                width: 100%;
                min-height: 120px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 0.6rem 0;
                align-items: center;
                border: 1px solid transparent;

                &:hover {
                  box-shadow: $lightboxshadow;
                }
              }

              .label {
                text-transform: capitalize;
                padding: 0.3rem 0.7rem;
                background: $green;
                border-radius: $bradius;
                color: white;
                font-weight: 400;
                line-height: 0.9rem;
                font-size: 0.9rem;
                display: flex;
                justify-content: center;
                align-items: center;

                i,
                svg {
                  margin-right: 0.6rem;
                  height: 15px;
                  width: 15px;

                  g {
                    fill: white;
                  }
                }

                &.expert {
                  background: $green;
                }
                &.strong {
                  background: $lightgreen;
                }
                &.good {
                  background: $lightgreen;
                }
                &.lacking {
                  background: $yellow;
                  i {
                    font-size: 0.9rem;
                    color: white;
                  }
                }
              }

              .compTitle {
                margin-bottom: 0.2rem;
                color: $black;
                text-align: center;
              }

              .compDesc {
                max-width: 90%;
                color: $black;
                text-align: center;
                font-size: 0.9rem;
                line-height: 1.4rem;
                font-weight: 300;
              }

              i {
                font-size: 1.2rem;
                color: $grey3;
              }

              &:last-child {
                margin-bottom: 0;
                border: none;
              }
            }
          }
        }
      }

      .competenciesPlaceholder {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .competencies {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          @media (max-width: 1115px) {
            flex-direction: column;
          }

          .columnWrapper {
            width: 33%;
            height: 350px;
            background: $grey5;
            border-radius: $bradius;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            @media (max-width: 1115px) {
              width: 100%;
              height: 200px;
            }

            &:nth-child(2) {
              margin: 0 2rem;

              @media (max-width: 1115px) {
                margin: 1rem 0;
              }
            }

            .title {
              font-size: 1.2rem;
              line-height: 1.6rem;
              color: $grey3;
              font-weight: 500;
              margin-top: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;

              .stars {
                margin-right: 0.6rem;
                font-size: 1rem;
              }
            }
          }
        }

        .button {
          position: absolute;
        }
      }

      .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        max-width: 100%;
        margin-top: 2rem;

        a {
          border: 0;
        }
      }
    }
  }
}
