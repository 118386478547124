@import "variables.scss";

:local {
  .candidateData {
    margin: 4rem 0;
    margin-bottom: 6rem;

    .headRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $grey5;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      @media (max-width: 480px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: 480px) {
          margin-bottom: 1rem;
        }

        .title {
          font-size: 1.2rem;
          line-height: 1.2rem;
          font-weight: 500;
          margin-bottom: 0.4rem;
        }

        .subtitle {
          font-size: 14px;
          line-height: 14px;
          font-weight: 300;
          color: $darkgrey;
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .sectionTitle {
          font-size: 11px;
          line-height: 24px;
          font-weight: 600;
          letter-spacing: 1px;
          color: $grey1;
          text-transform: uppercase;
          padding: 0.4rem 1rem;
          padding-bottom: 0.2rem;
          display: flex;
          justify-self: flex-start;
          align-items: center;

          svg {
            width: 14px;
            height: 14px;
            margin-right: 0.4rem;
          }
        }
      }
    }

    .dataItems {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem 3rem;

      @media (max-width: 1080px) {
        grid-template-columns: 1fr;
      }

      .item {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;
        border-bottom: 0.5px solid $grey5;

        .nameAndLabel {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .itemName {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 15px;
            line-height: 20px;

            .title {
              border-bottom: 0.5px solid transparent;
              cursor: pointer;
              transition: $transition;
            }

            .downloadIcon {
              margin-left: 0.6rem;
              display: none;

              svg {
                width: 14px;
                height: 14px;

                path {
                  fill: $darkgrey;
                }
              }
            }

            &.withAction {
              &:hover {
                .title {
                  border-color: $darkgrey;
                }

                .downloadIcon {
                  display: initial;
                }
              }
            }

            .icon {
              margin-right: 1rem;

              svg {
                path {
                  fill: $darkgrey;
                }
              }
            }
          }

          .labels {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 2rem;
            margin-top: 0.4rem;

            .label {
              font-size: 12px;
              line-height: 12px;
              font-weight: 300;
              background-color: $grey5;
              color: $grey1;
              border-radius: 25px;
              padding: 4px 8px;
              margin-right: 0.4rem;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .remove {
            margin-right: 0.8rem;

            button {
              margin: 0;
              padding: 0;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: transparent;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                background-color: $red;

                svg rect {
                  fill: white;
                }
              }
            }
          }

          button {
            margin-left: 0.8rem;
          }
        }
      }
    }
  }
}
