@import "variables.scss";

:local {
  .main {
    padding-top: 4rem;
    padding-bottom: 8rem;

    .chartButton {
      margin-top: 3rem;

      button {
        svg path {
          fill: $primary;
        }

        .arrowIcon {
          transform: rotate(90deg);
          margin-left: 0.6rem;
          transition: $transition;

          svg path {
            fill: transparent;
            stroke: $primary;
          }

          &.open {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .chartSection {
      width: 100%;

      .candLegend {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem 8rem;
        border-radius: $bradius2;
        background: rgba($grey5, 0.5);
        margin: 0 auto;
        margin-top: 3rem;
        margin-bottom: 1rem;
        max-width: 600px;

        .avatar {
          margin-right: 0.8rem;
          border: 3px solid $green;
          border-radius: 50%;

          div[aria-label="initials"] p {
            font-size: 0.7rem;
            font-weight: 500;
          }
        }

        p {
          font-weight: 500;
          font-size: 16px;
        }
      }

      .candCompChart {
        position: relative;
        width: 100%;
        height: 100vw;
        margin-top: 1rem;
        max-height: 755px;
      }
    }

    .disclaimer {
      max-width: $modalWrapper;
      padding: 1.2rem 3rem;
      border: 1px solid $grey5;
      border-radius: $bradius2;
      box-shadow: $lightboxshadow;
      margin-top: 2rem;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      i {
        margin-right: 1rem;
        margin-top: 0.3rem;
      }

      p {
        text-align: left;
        font-size: 1rem;
        line-height: 1.6rem;
        font-weight: 400;
      }

      &:after {
        content: "";
        width: calc(100% + 2px);
        height: 4px;
        background: $yellow;
        position: absolute;
        top: -1px;
        left: -1px;
        border-top-left-radius: $bradius;
        border-top-right-radius: $bradius;
        z-index: 9;
      }
    }

    .readMoreWrapper {
      button {
        margin: 2rem auto;
        width: auto;
      }
    }
  }
}
