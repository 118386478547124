@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: proxima-nova;
    position: relative;

    .placeholder {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 32px;

      i {
        font-size: 1.4rem;
        color: $grey3;
      }
    }

    .avatarWrapper {
      border: 3px solid white;
      border-radius: 50%;

      &:nth-child(2) {
        margin-left: -18px;
        z-index: 6;
      }
      &:nth-child(3) {
        margin-left: -18px;
        z-index: 7;
      }
      &:nth-child(4) {
        margin-left: -18px;
        z-index: 8;
      }
      &:nth-child(5) {
        margin-left: -18px;
        z-index: 9;
      }
      &:nth-child(6) {
        margin-left: -18px;
        z-index: 10;
      }
    }

    &.mediumSmall {
      align-items: center;

      .avatarWrapper {
        border: 2px solid white;
        border-radius: 50%;

        &:nth-child(2) {
          margin-left: -14px;
        }
        &:nth-child(3) {
          margin-left: -14px;
        }
        &:nth-child(4) {
          margin-left: -14px;
        }
        &:nth-child(5) {
          margin-left: -14px;
        }
        &:nth-child(6) {
          margin-left: -14px;
        }
      }
    }

    &.small {
      align-items: center;

      .avatarWrapper {
        border: 2px solid white;
        border-radius: 50%;

        img {
          height: 28px;
          width: 28px;
        }

        div[aria-label="initials"] {
          height: 28px;
          width: 28px;

          p {
            font-size: 0.7rem;
            font-weight: 500;
          }
        }

        &:nth-child(2) {
          margin-left: -10px;
        }
        &:nth-child(3) {
          margin-left: -10px;
        }
        &:nth-child(4) {
          margin-left: -10px;
        }
        &:nth-child(5) {
          margin-left: -10px;
        }
        &:nth-child(6) {
          margin-left: -10px;
        }
      }
    }

    &.verySmall {
      align-items: center;

      .avatarWrapper {
        border: 3px solid white;
        border-radius: 50%;

        img {
          height: 24px;
          width: 24px;
        }

        div[aria-label="initials"] {
          height: 24px;
          width: 24px;
        }

        &:nth-child(2) {
          margin-left: -10px;
        }
        &:nth-child(3) {
          margin-left: -10px;
        }
        &:nth-child(4) {
          margin-left: -10px;
        }
        &:nth-child(5) {
          margin-left: -10px;
        }
        &:nth-child(6) {
          margin-left: -10px;
        }
      }
    }

    .andMore {
      margin-left: -8px;
      background: $blue;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 12;
      border: 2px solid white;

      p {
        font-size: 12px !important;
        letter-spacing: 0.5px !important;
        color: $primary !important;
        font-weight: 500 !important;
      }
    }
  }
}
