@import "variables.scss";

:local {
  .changeTab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    @media (max-width: 650px) {
      margin-left: 0;
      margin-right: 1rem;
    }

    a {
      border: 0;
      line-height: 0;
      margin: 0 !important;
    }

    button {
      margin: 0 !important;
    }

    .separate {
      width: 1px;
      height: 8px;
      background: $grey4;
      margin-left: 0.6rem;
      margin-right: 0.3rem;
    }
  }

  .extraButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-right: 1rem;
      padding: 0.4rem !important;
      border-radius: $bradius3;
    }

    .item {
      flex: 1;
      height: calc(100% + 1px);
      margin-left: -0.5px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: fit-content;
      cursor: pointer;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 0.4rem;

        svg path {
          fill: $grey2;
        }
      }

      p {
        font-size: 14px;
        line-height: 14px;
        color: $grey1;
      }

      &.active {
        background-color: white;
        border: 0.5px solid $grey3;

        .icon svg path {
          fill: $black;
        }

        p {
          color: $black;
          font-weight: 500;
        }
      }
    }
  }

  .printBtn,
  .inviteButton {
    margin-left: 1rem;
  }

  .commentWrapper {
    margin-right: 1rem;
  }

  .shareWrapper {
    display: flex;
    margin-left: 1rem;
    // margin-right: 1rem;

    .avatarsWrapper {
      display: flex;
      margin-left: 0.6rem;
    }

    .icon {
      svg {
        width: 16px;
        height: 16px;

        path {
          fill: $darkgrey;
        }
      }
    }
  }

  .moreBtn {
    margin-left: 1rem;
    margin-right: 0;

    @media (max-width: 650px) {
      margin-left: 1rem;
    }

    .button {
      i {
        font-size: 1.2rem;
      }
    }
  }

  .main {
    .wrapper {
      margin: 0 auto;
    }

    .footerWrapper {
      margin-top: 4rem;
    }
  }
}
