@import 'variables.scss';

:local {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  tr,
  td {
    width: 100%;
    height: 60px;
  }

  .index {
    font-size: 0.7rem;
    padding-right: 0.5rem;
  }

  .state {
    p {
      font-size: 0.75rem;
      font-weight: 400;
      padding: 0.1rem 0.8rem;
      border: 1px solid $grey5;
      border-radius: $bradius2;
      text-align: center;
      text-transform: capitalize;
    }

    &.active {
      p {
        color: $black;
      }
    }

    &.pending {
      p {
        color: $yellow;
        border-color: $yellow;
      }
    }
  }

  .avatarCol {
    .avatarAndName {
      display: flex;
      align-items: center;

      .avatar {
        margin-right: 1rem;
      }

      .nameAndEmail {
        p {
          font-size: 0.9rem;
          line-height: 1rem;
        }

        .name {
          font-weight: 600;
          margin-bottom: 0.2rem;
        }
      }
    }
  }

  .nameCol,
  .emailCol {
    width: 39%;

    p {
      width: 392px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .nameColSmall,
  .emailColSmall {
    width: 36%;
  }

  .resendCol {
    vertical-align: middle;
  }

  .rightCol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem;
  }

  .row {
    border-bottom: 1px solid $grey5;

    td {
      vertical-align: middle;
    }
  }
}

.emailColSmall {
  @media (max-width: 1085px) {
    padding: 0;
    width: 100%;
  }
}
