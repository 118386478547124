@import "variables.scss";

:local {
  .modalWrapper {
    max-width: $modalWrapper;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .title {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.2rem;
      max-width: 60%;

      .icon {
        margin-bottom: 2rem;
      }
    }

    .subtitle {
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 300;
      margin-top: 1rem;
      max-width: 80%;
    }

    .dangerous {
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
      margin-top: 2rem;
      color: $red;

      i {
        margin-right: 0.6rem;
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;

      button {
        i {
          font-size: 1rem;
        }

        &:first-child {
          margin-right: 1.4rem;
        }
      }
    }
  }
}
