@import "variables.scss";

:local {
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .wrap {
    position: relative;
    cursor: pointer;

    button {
      padding: 0 !important;
      color: $primary;
      font-size: 15px;
      line-height: 15px;

      svg path {
        fill: $primary;
      }
    }

    .loader {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.9rem;
      line-height: 1.4rem;
      color: $grey2;

      i {
        margin-right: 0.6rem;
        animation: rotating 2s linear infinite;
      }
    }

    .fileInput {
      position: absolute;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    input[type="file"]::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  .modalHead {
    p {
      margin-top: 1rem;
    }
  }
}
