@import "variables.scss";

:local {
  .main {
    margin-top: 8rem;
    padding-top: 8rem;
    border-top: 1px solid $grey4;

    .candidatesWrapper {
      margin: 2rem 0;

      @media (max-width: 450px) {
        max-width: 100%;
        width: 100%;
      }
    }

    .footerButtonWrapper {
      margin-bottom: 8rem;

      button {
        margin: 0 auto;

        i {
          margin-right: 1rem;
        }
      }
    }
  }
}
