@import "variables.scss";

:local {
  .scoringWrapper {
    margin-top: 0.6rem;
    border: 1px solid $grey5;
    box-shadow: $boxshadow;
    border-radius: $bradius3;

    .scoring {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5rem;

      .button {
        position: relative;

        button {
          margin-right: 0.4rem;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid $grey4;
          font-size: 14px;
          font-weight: 500;
          color: black;

          &:hover {
            border-color: $darkgrey;
          }
        }

        &.selected {
          button {
            background: $primary;
            border-color: $primary;
            color: white;
          }
        }

        .tooltip {
          opacity: 0;
          display: flex;
          position: absolute;
          bottom: calc(100% + 1rem);
          left: 0;
          right: 0;
          min-width: max-content;
          padding: 12px 12px;
          background-color: rgba($extradarkgrey, 1);
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          border-radius: $bradius;
          z-index: 99999;
          transition-delay: 0s opacity;
          pointer-events: none;

          &::before {
            content: "";
            position: absolute;
            bottom: -11px;
            width: 0;
            height: 0;
            border-top: 6px solid rgba($extradarkgrey, 1);
            border-bottom: 6px solid transparent;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
          }

          h5 {
            color: white;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 0.4rem;
            font-weight: 600;
          }

          p {
            color: white;
            font-size: 13px;
            line-height: 18px;
            font-weight: 300;
            max-width: 200px;
          }
        }

        &:hover {
          .tooltip {
            opacity: 1;
            transition-delay: 500ms;
          }
        }
      }

      .noInfo {
        button {
          margin-right: 0;
          border: 0;
          width: auto;
          height: auto;
          padding: 0.8rem;
          color: $grey1;
          font-size: 14px;
          font-weight: 500;
          box-shadow: none;
          border-radius: none;

          &:hover {
            color: $darkgrey;
          }
        }

        &.selected {
          button {
            color: $primary;
            font-weight: 600;
          }
        }
      }

      .addNote {
        button {
          font-weight: 500;

          svg path,
          svg rect {
            fill: $primary;
          }
        }

        &.active {
          button {
            background-color: $blue;
          }
        }
      }
    }

    .scoreSpan {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5rem 1rem;
      border-top: 1px solid $grey5;

      .label {
        font-size: 14px;
        line-height: 16px;
        color: $darkgrey;
      }

      .scores {
        margin: 0 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;

        .button {
          position: relative;
          border-radius: 50%;

          button {
            margin-right: 0rem;
            padding: 0;
            width: 30px;
            height: 40px;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 500;
            color: black;

            &:hover {
              border-color: $darkgrey;
            }
          }

          &.selected {
            z-index: 1;
            border: 2px solid white;

            button {
              width: 40px;
              background: $primary;
              border-color: white;
              color: white;
            }
          }

          &.highlighted {
            z-index: 0;

            button {
              color: $primary;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: $blue;
            }

            &.highlightedFirst {
              &::before {
                left: -1rem;
                width: calc(100% + 1rem);
              }
            }

            &.highlightedLast {
              &::before {
                right: -1rem;
                width: calc(100% + 1rem);
              }
            }

            &.highlightedOnly {
              &::before {
                left: -1rem;
                width: calc(100% + 2rem);
              }
            }
          }
        }
      }
    }
  }
}
