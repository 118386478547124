@import "variables.scss";

:local {
  .wrapper {
    .imageWrapper {
      // width: 80%;
      height: 300px;
      background: $blue;
      border-radius: $bradius;
      margin: 2rem 0;
      position: relative;

      .box {
        width: 200px;
        height: 120px;
        background: $primary;
        border-radius: 15px;
        position: absolute;
        left: 25%;
        top: 10%;
      }

      svg {
        position: absolute;
        left: 30%;
        bottom: 0;
      }
    }

    .subtitle {
      max-width: 70%;
      margin: 1rem 0;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.6rem;
      list-style-type: initial;
    }

    .infoBox {
      h4 {
        margin-bottom: 1rem;
      }

      p {
        font-weight: 500;
      }

      ul {
        margin-top: 1rem;

        li {
          max-width: 70%;
          margin: 1rem 0;
          font-weight: 300;
          list-style-type: initial;
        }
      }
    }
  }
}
