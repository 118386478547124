@import "variables.scss";

:local {
  .wrapper {
    // max-width: 672px;

    .subtitle {
      max-width: 70%;
      margin: 1rem 0;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.6rem;
      list-style-type: initial;
    }

    .peopleInvited {
      padding: 1rem 0;
      margin: 2rem 0;
      border-top: 1px solid $grey5;
      border-bottom: 1px solid $grey5;

      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .avatars {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.6rem;

        .avatar {
          border-radius: 50%;
          margin-right: 0.6rem;
          position: relative;

          img,
          div[aria-label="initials"] {
            height: 40px;
            width: 40px;
          }

          .completedIcon {
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $green;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .actions {
      .action {
        padding: 2rem;
        border-radius: $bradius2;
        background: $grey6;
        margin-bottom: 1.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 672px;

        .img {
          margin-right: 2rem;
          min-width: 120px;

          svg {
            width: 100%;
          }
        }

        &:first-child {
          background: white;
          border: 0.36px solid #e4e6e8;
          box-shadow: 0px 1.4251338243484497px 1.4251338243484497px 0px
            #00000008;
        }

        .title {
          font-size: 18px;
          margin-bottom: 0.4rem;
        }

        .subtitle {
          font-size: 14px;
          line-height: 18px;
          max-width: 80%;
          color: $grey1;
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
