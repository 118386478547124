@import "variables.scss";

:local {
  .hoganScores {
    .modalHead {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 1rem;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid $grey5;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          width: 48px;
          height: 48px;
          background-color: $blue;
          border-radius: $bradius3;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.2rem;

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: $primary;
            }
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 26px;
        color: $darkgrey;
        font-weight: 300;
        margin-top: 1rem;
        max-width: 70%;
      }
    }

    .dimGroup {
      font-size: 1.2rem;
      margin: 1rem 0;
      margin-top: 3rem;
    }

    .table {
      width: 100%;
      border-collapse: separate;
      margin-bottom: 2rem;
      border-collapse: collapse;

      th {
        text-align: left;
        height: 40px;
        padding: 0 1rem;
        vertical-align: middle;

        p {
          font-size: 12px;
          line-height: 14px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        &:last-child {
          text-align: center;
        }
      }

      tr {
        height: 32px;

        &:first-child {
          td {
            &:first-child {
              border-top-left-radius: $bradius2;
            }

            &:last-child {
              border-top-right-radius: $bradius2;
            }
          }
        }

        &:last-child {
          td {
            border-bottom: 1px solid $grey5;

            &:first-child {
              border-bottom-left-radius: $bradius2;
            }

            &:last-child {
              border-bottom-right-radius: $bradius2;
              border-bottom: 1px solid #80d2e5;
            }
          }
        }
      }

      td {
        border: 1px solid $grey5;
        border-right: 0;
        height: 32px;
        padding: 0 1rem;
        vertical-align: middle;

        &:last-child {
          border-right: 1px solid #80d2e5;
          border-color: #80d2e5;
          text-align: center;
        }

        p {
          font-size: 15px;
          line-height: 18px;
          font-weight: 300;
        }

        &.inputCell {
          padding: 0;
          background-color: white;
          min-width: 100px;

          &.empty {
            background-color: $blue;
          }

          input {
            border: 0;
            background-color: transparent;
            border-radius: 0;

            &:focus {
              background-color: #80d2e5;
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
