@import 'variables.scss';

:local {
  .wrapper {
    max-height: 400px;
    max-width: 350px;
    width: 350px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      background: rgba($grey5, 0.4);
    }

    &::-webkit-scrollbar-thumb {
      background: $grey4;
      border-radius: 2px;
    }

    h5 {
      padding: 1rem;
      border-bottom: 1px solid $grey5;
    }

    .search {
      border-bottom: 1px solid $grey5;
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;
    }

    .teams {
      padding: 0.6rem 0;

      .noteams {
        margin-left: 1rem;
        font-size: 14px;
        color: $grey2;
      }

      button {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        &:hover {
          .team {
            background: rgba(0, 0, 0, 0.01);
          }
        }

        .team {
          padding: 0.6rem 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              margin-right: 1rem;
              border-radius: 50%;
              background: $blue;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 32px;

              &.green {
                background: rgba($green, 0.2);

                svg path {
                  fill: $green;
                }
              }

              &.purple {
                background: rgba($purple, 0.2);

                svg path {
                  fill: rgba($purple, 0.7);
                }
              }

              &.yellow {
                background: rgba($yellow, 0.2);

                svg path {
                  fill: $yellow;
                }
              }
            }

            .info {
              .name {
                font-weight: 500;
                color: black;
                font-size: 14px;
                line-height: 14px;
                margin-bottom: 0.2rem;
                max-width: 180px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .members {
                color: $grey2;
                font-size: 14px;
                line-height: 14px;
              }
            }
          }

          .right {
            width: 30%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
              font-size: 12px;
              color: $grey4;
            }
          }
        }
      }
    }
  }
}
