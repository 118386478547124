@import 'variables.scss';

:local {
  .wrap {
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: 100vw;
    margin: 2rem 0;
    max-height: 755px;

    .avatarsWrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      img {
        border: 4px solid white;
      }
    }
    .index {
      font-size: 1px;
    }
  }
}
