@import "variables.scss";

:local {
  .loader {
    padding: 0 1.2rem;
    height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dotPulse {
    position: relative;
    left: -9999px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: $purple;
    color: $purple;
    box-shadow: 9999px 0 0 -5px;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .dotPulse::before,
  .dotPulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: $purple;
    color: $purple;
  }
  .dotPulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dotPulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .dotPulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dotPulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dotPulse-before {
    0% {
      box-shadow: 9984px 0 0 -5px;
    }
    30% {
      box-shadow: 9984px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px;
    }
  }
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px;
    }
    30% {
      box-shadow: 9999px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px;
    }
  }
  @keyframes dotPulse-after {
    0% {
      box-shadow: 10014px 0 0 -5px;
    }
    30% {
      box-shadow: 10014px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px;
    }
  }
}
