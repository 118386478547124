@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 1080px) {
      flex-direction: column;
      min-height: auto;
    }

    .backButton {
      position: absolute;
      top: 1.4rem;
      left: 2rem;
      z-index: 1000;

      button {
        font-size: 0.95rem;
        color: $grey1;
        padding: 0.6rem 0;

        .icon {
          transform: rotate(180deg);
          margin-right: 0.8rem;

          svg {
            transform: scale(1.5);
          }

          svg path {
            fill: $grey1;
          }
        }

        &:hover {
          color: $darkgrey;

          .icon {
            svg path {
              fill: $darkgrey;
            }
          }
        }
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      max-width: 900px;
      padding: 0 8rem;

      @media (max-width: 1300px) {
        padding: 4rem;
      }

      @media (max-width: 1080px) {
        padding: 8rem 4rem;
      }

      @media (max-width: 550px) {
        padding: 4rem 2rem;
        padding-top: 6rem;
      }

      .content {
        max-width: 90%;
        margin-bottom: 2rem;

        h1 {
          font-size: 3.6rem;
          line-height: 4.2rem;
          font-weight: 700;
          margin-bottom: 1.6rem;
        }

        h2 {
          font-size: 2.4rem;
          line-height: 2.8rem;
          font-weight: 700;
          margin-bottom: 1.6rem;
        }

        p {
          font-size: 1rem;
          line-height: 1.6rem;
          font-weight: 300;
        }

        .listWrap {
          margin: 1rem 0;
          padding: 1rem 0;
          max-width: 75%;
          margin-top: 2rem;

          @media (max-width: 480px) {
            max-width: 100%;
          }

          .listItem {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $darkgrey;
            font-size: 1rem;
            line-height: 1.7rem;
            font-weight: 300;
            padding: 1rem 0;

            .icon {
              height: 24px;
              width: 24px;
              background: $green;
              border-radius: 50%;
              margin-right: 1.6rem;
              svg {
                width: 24px;
                height: 24px;
                path {
                  stroke-width: 1;
                }
              }
            }
            p {
              font-size: 0.9rem;
              line-height: 1.6rem;
              font-weight: 300;
            }
          }
        }
      }

      .disclaimer {
        border: 1px solid $grey5;
        border-radius: $bradius3;
        padding: 1.2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2rem;

        .icon {
          margin-right: 1rem;
        }

        p {
          font-size: 0.8rem;
          line-height: 1.2rem;
          color: $black;
        }
      }
    }

    .right {
      width: 40%;
      min-width: 640px;
      height: 100vh;
      background: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @media (max-width: 1080px) {
        width: 100%;
        height: 400px;
      }

      img {
        height: 300px;
      }
    }
  }
}
