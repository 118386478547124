@import "variables.scss";

:local {
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $grey5;
    border-radius: $bradius3;
    padding: 3rem 2rem;
    background-image: url("~images/magic-wand.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50px;
    position: relative;

    @media (max-width: 1170px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: hsla(0, 0%, 100%, 0.1);
      backdrop-filter: blur(2px);
      z-index: 1;

      p {
        padding: 0.8rem 2rem;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
        font-size: 1rem;
        line-height: 1.4rem;
        font-weight: 400;
        color: white;
        max-width: 320px;
        text-align: center;
      }
    }

    .col:first-child {
      margin-right: 2rem;

      @media (max-width: 1170px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .title {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 500;
        max-width: 90%;
      }
    }

    .col:last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 1060px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .subtitle {
        max-width: 450px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: $grey1;
      }

      .ctaButton {
        margin-left: 2rem;

        @media (max-width: 1060px) {
          margin-left: 0;
          margin-top: 2rem;
        }

        button {
          box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
          transition: 250ms ease-in-out;
          white-space: nowrap;

          &:hover {
            margin-top: -2px;
            box-shadow: 0 6px 16px rgb(182, 139, 240, 0.8);
          }

          .loading {
            i {
              animation: rotating 2s linear infinite;
            }
          }

          svg {
            margin-right: 0.4rem;
            width: 15px;
            height: 15px;
          }

          svg path {
            fill: white;
          }
        }
      }
    }
  }
}
