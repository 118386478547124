@import "variables.scss";
@import "logos.scss";

:local {
  .mainWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }

  .main {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  .login {
    position: relative;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;

    .subtitle {
      text-align: center;
      font-weight: 300;
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-size: 1.3rem;
      color: $grey1;

      @media (max-width: 415px) {
        font-size: 1.3rem;
      }
    }

    .logo {
      background: url("~images/logo/default/logo.svg");
      @include login_logo;
    }

    .separator {
      text-align: center;
      color: $grey3;
      font-weight: 400;
      line-height: 4rem;
      font-size: 1.2rem;
    }

    .footer {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .alreadyAccount {
        font-weight: 500;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.9rem;
        margin-top: 0.4rem;

        a {
          text-decoration: none;
          border: none;
          color: $primary;
          font-weight: 400;
          font-size: 0.9rem;
        }
      }
    }
  }
}
