@import "variables.scss";

:local {
  .wrapper {
    max-width: $modalWrapper;
    margin: 0 auto;

    .modalHead {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 1rem;
      margin-bottom: 3rem;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          width: 48px;
          height: 48px;
          background-color: $blue;
          border-radius: $bradius3;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.2rem;

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: $primary;
            }
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 26px;
        color: $darkgrey;
        font-weight: 300;
        margin-top: 1rem;
        max-width: 70%;
      }
    }

    .inputs {
      max-width: 700px;

      .inputRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      bottom: 4rem;

      @media (max-height: 710px) {
        position: relative;
        bottom: 0;
        margin-top: 2rem;
      }

      &.relative {
        position: relative;
        bottom: 0;
        margin-top: 2rem;
      }

      button:first-child {
        margin-right: 1rem;
      }

      button {
        box-shadow: 0px 1.5px 3px 0px rgba(black, 0.08);
      }
      .loading {
        i {
          animation: rotating 2s linear infinite;
        }
      }
    }
  }
}
