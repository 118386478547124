@import 'variables.scss';

:local {
  .main {
    padding-top: 8rem;
    margin-bottom: 8rem;
    border-top: 1px solid $grey4;

    .personalityTraits {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
    }
  }
}
