@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    @media (max-width: 1320px) {
      align-items: flex-start;
    }

    .popup {
      min-width: 400px;
      max-width: 400px;

      button {
        justify-content: center;
        color: $primary;
        width: auto;

        &:hover {
          color: $primary;
        }
      }

      .buttonGroup {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.6rem;
        // margin-top: 0.4rem;

        button:first-child {
          margin-right: 0.6rem;
          color: white;
          padding: 0.7rem 1rem !important;

          &:hover {
            background-color: lighten($primary, 3);
            border-color: darken($primary, 0.2);
          }
        }

        button:last-child {
          border: 1px solid #e4e6e8;
          color: $black;
          padding: 0.7rem 1rem !important;
        }
      }
    }

    .actionButton {
      width: 100%;
      margin-bottom: 1rem;
      position: relative;

      &.noMargin {
        margin-bottom: 0;
      }

      &:hover {
        .tooltip {
          opacity: 1;
          transition-delay: 500ms;
        }
      }

      .tooltip {
        opacity: 0;
        display: flex;
        position: absolute;
        top: calc(100% + 1rem);
        left: 1rem;
        padding: 6px 12px;
        background-color: rgba($extradarkgrey, 0.9);
        justify-content: flex-start;
        align-items: center;
        border-radius: $bradius;
        z-index: 99999;
        transition-delay: 0s opacity;
        pointer-events: none;

        &::before {
          content: "";
          position: absolute;
          top: -5px;
          left: 1.4rem;
          width: 0;
          height: 0;
          transform: rotate(135deg);
          border-top: 8px solid transparent;
          border-bottom: 8px solid rgba($darkgrey, 1);
          border-right: 8px solid transparent;
        }

        @media (max-width: 1000px) {
          top: 2.8rem;
          left: 0;
          right: 0;
          margin: 0 auto;
          max-width: 180px;

          &::before {
            top: -8px;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(90deg);
          }
        }

        p {
          color: white;
          font-size: 12px;
          line-height: 15px;
          width: 250px;
        }
      }

      button {
        width: 100%;
        border: 0.5px solid $grey4;
        border-radius: $bradius3;
        box-shadow: $boxshadow;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: $transition;

        @media (max-width: 1080px) {
          max-width: 100%;
        }

        &:hover {
          box-shadow: $boxshadowhover;
        }

        .card {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 1rem;

          .icon {
            width: 40px;
            height: 40px;
            border-radius: $bradius3;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1.4rem;

            &.check {
              border: 1px solid $grey5;

              svg {
                width: 24px;
                height: 24px;
              }

              svg path {
                stroke: $primary;
              }
            }

            &.green {
              background: rgba($green, 0.1);

              svg path {
                fill: $green;
              }
            }

            &.primary {
              background: $blue;

              svg path {
                fill: $primary;
              }
            }

            &.blue {
              background: $blue;

              svg path {
                fill: $primary;
              }
            }

            &.yellow {
              background: rgba($yellow, 0.1);

              svg path {
                fill: $yellow;
              }
            }
          }

          .right {
            .actionTitle {
              font-size: 1rem;
              line-height: 1rem;
              font-weight: 500;
              margin-bottom: 0.2rem;
            }

            .action {
              font-size: 14px;
              line-height: 14px;
              font-weight: 300;
              color: $darkgrey;
            }
          }
        }
      }
    }
  }
}
