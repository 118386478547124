@import "variables.scss";

:local {
  .selectWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    position: relative;
    min-width: 140px;
    max-width: 180px;
    width: auto;
    border: 1px solid transparent;

    &.fullWidth {
      max-width: 100%;
      width: 100%;
    }

    .icon {
      position: absolute;
      margin-left: 0.8rem;
    }

    .select {
      height: 35px;
      font-size: 14px;
      text-indent: 1rem;
      border: 1px solid $grey4;
      border-radius: $bradius;
      color: $black;
      appearance: none;
      position: relative;
      z-index: 100;
      background: transparent;
      padding-right: 3rem;
      width: 100%;
      font-family: proxima-nova;
      transition: $transition;
      box-shadow: 0 1px 2px rgba($black, 0.1);

      &:hover {
        border-color: $grey1;
        color: $black;
        box-shadow: $lightboxshadow;
        cursor: pointer;
      }

      &:focus {
        border-color: $primary;
      }

      &:disabled {
        border-color: $grey4;
        color: $darkgrey;

        &:hover {
          border-color: $grey3;
          cursor: not-allowed;
        }
      }
    }

    &.noBorder {
      background: none;

      &:hover {
        border: 1px solid $grey5;
        border-radius: $bradius3;
      }

      .select {
        border: none;
        box-shadow: none;

        &:hover {
          color: $black;
          box-shadow: none;
          cursor: pointer;
        }

        &:disabled {
          border: none;
          color: $grey2;

          &:hover {
            border-color: $grey3;
            cursor: not-allowed;
          }
        }
      }
    }
    &.None {
      .select {
        color: $grey1;
      }
    }

    &.fullWidth {
      max-width: 100%;

      .select {
        width: 100%;
        height: 48px;
        box-shadow: none;
      }
    }

    &.large {
      min-width: 160px;

      .select {
        height: 57px;
        font-size: 1.2rem;
        text-indent: 16px;
      }
    }

    &.small {
      min-width: auto;
      width: 66px;

      .select {
        padding-right: 0;
        width: 66px;
      }
    }

    &.verySmall {
      min-width: auto;
      width: 100%;

      .icon i {
        font-size: 0.9rem;
      }

      .select {
        width: 100%;
        padding-right: 0;
        font-size: 0.9rem;

        height: 31.78px;
        border-radius: $bradius3;

        option {
          font-size: 0.9rem;
        }
      }
    }

    &.withIcon {
      .select {
        text-indent: 2rem;
      }
    }

    .selectArrow {
      position: absolute;
      right: 1.4rem;
      z-index: 10;
      width: 0;

      i {
        width: 0;
        font-size: 0.8rem;
        color: $darkgrey;
      }
    }
  }
}
