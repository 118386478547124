@import "variables.scss";

:local {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .main {
    width: 100%;

    .topInfoWrapper {
      margin: 1rem 0;
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey5;

      .topInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .left {
          .title {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .template {
              color: $grey2;
              font-size: 14px;
              line-height: 14px;
              margin-top: 0.6rem;
              font-weight: 400;
              margin-left: 0.6rem;
            }
          }

          h4 {
            color: $grey1;
            font-size: 15px;
            font-weight: 400;
          }
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .commentWrapper {
            margin-right: 1.6rem;
          }

          .shareWrapper {
            display: flex;

            .avatarsWrapper {
              display: flex;
              margin-right: 0.6rem;
            }

            .shareIcon {
              width: 30px;
              height: 30px;
              background: $primary;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                background: lighten($primary, 5%);
              }

              svg {
                width: 20px;
                height: 20px;

                path {
                  fill: white;
                }
              }
            }
          }

          .moreBtn {
            margin-left: 1rem;

            .button {
              i {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }

    .bottomInfoWrapper {
      width: 100%;
      padding-top: 1px;

      .bottomInfo {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .changeTab {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 1rem;

          a {
            border: 0;
            line-height: 0;
          }

          .separate {
            width: 1px;
            height: 8px;
            background: $grey4;
            margin-left: 0.6rem;
            margin-right: 0.3rem;
          }
        }
      }
    }

    .kanbanWrapper {
      height: 100%;
      overflow: auto;
      position: relative;
      margin: 2rem 0;
      width: 100%;
      transition: 500ms;

      ::-webkit-scrollbar {
        height: 8px;
        background: rgba($grey5, 0.4);
      }

      ::-webkit-scrollbar-thumb {
        background: $grey4;
        border-radius: 2px;
      }

      ::-webkit-scrollbar {
        width: 0px;
      }

      @media (max-width: 1080px) {
        // width: 1024px;

        &.collapsed {
          // margin-left: -16rem;
          width: 100%;
        }
      }

      @media (min-width: 2000px) and (max-width: 2249px) {
        // margin-left: -16rem;
        // width: calc(100vw - 250px - 3rem);
      }

      @media (min-width: 2250px) {
        // margin-left: -24rem;
      }
    }

    .kanban {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      // width: 100%;
      // width: calc(100% - 250px + 6rem);
      // max-width: calc(100vw - 250px + 6rem);

      // @media (max-width: 960px) {
      //   width: 100%;
      //   max-width: 100%;
      // }

      .column {
        min-width: 320px;
        width: 320px;
        min-height: 640px;
        height: 640px;
        margin-right: 1rem;
        overflow-y: visible;

        @media (min-height: 900px) {
          min-height: 74vh;
          height: 74vh;
        }

        &:first-child {
          // margin-left: 3rem;
        }

        &:last-child {
          margin-right: 3rem;
        }

        .colHead {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          min-height: 66px;
          padding: 1rem;
          background: white;
          border: 0.5px solid $grey5;
          border-radius: $bradius3;
          margin-bottom: 1rem;

          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-height: 32px;
          }

          button {
            padding: 0.4rem 0.8rem !important;
          }

          .candidateAmount {
            margin-top: 0.4rem;
            font-size: 13px;
            line-height: 13px;
            font-weight: 300;
            color: $grey1;
          }
        }

        .cards {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          height: 550px;
          width: 100%;
          position: relative;
          background: $grey6;
          border: 0.5px solid $grey5;
          border-radius: $bradius3;
          padding-top: 0.6rem;

          @media (min-height: 900px) {
            height: calc(100% - 85px);
          }

          &::-webkit-scrollbar {
            width: 1px;
          }

          button {
            width: 100%;

            &:hover {
              .card {
                box-shadow: $boxshadow;
              }
            }
          }

          .placeholderWrapper {
            top: 0;
            left: 0;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            z-index: 0;

            .placeholder {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              min-height: 265px;

              .image {
                height: 100px;
                min-height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              p {
                color: $grey1;
                font-size: 14px;
                line-height: 22px;
                font-weight: 400;
                margin-top: 1rem;
                text-align: center;
                max-width: 90%;
              }

              button {
                margin-top: 1rem;
                width: auto;
              }
            }
          }

          .droppable {
            width: 100%;
            height: 100%;
            min-height: 550px;
            overflow-y: scroll;
          }

          .draggable {
            margin-bottom: 0.6rem;
          }

          .card {
            width: auto;
            background: white;
            border-radius: $bradius3;
            box-shadow: $lightboxshadow;
            border: 0.5px solid $grey5;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            transition: $transition;
            margin: 0 0.6rem;
            margin-bottom: 0.6rem !important;

            &.contact {
              background-color: $grey6;
              border-color: $grey4;
            }

            &.complete {
              opacity: 0.6;

              &:hover {
                opacity: 1;
              }
            }

            &.onboarding {
              opacity: 1;
            }

            &:hover {
              box-shadow: $boxshadow;

              .dragIcon {
                opacity: 1 !important;
              }

              .avatar {
                opacity: 0;
                margin-right: 0 !important;
              }
            }

            &.button {
              background: $grey4;
              box-shadow: none;

              button {
                padding: 0.8rem 1rem;
              }

              &:hover {
                background: rgba($grey4, 0.8);
              }
            }

            .top {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              padding: 0.8rem 1rem;
              width: 100%;
              position: relative;

              .external {
                position: absolute;
                top: 0.4rem;
                right: 0.6rem;

                i {
                  font-size: 12px;
                  color: $grey2;
                }
              }

              &.hover {
                &:hover {
                  background: rgba($grey3, 0.05);
                }
              }

              .left {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 90%;

                a {
                  color: $black;
                  border: none;
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }
              }

              .right {
                width: 10%;

                .button {
                  width: 30px;
                  z-index: 900;

                  .primary {
                    color: $primary;

                    button {
                      color: $primary;

                      svg path {
                        fill: $primary;
                      }
                    }
                  }
                }
              }

              .text {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
              }

              &:last-child {
                margin-bottom: 0;
              }

              .avatarWrapper {
                position: relative;

                .avatar {
                  display: block;
                  margin-right: 0.8rem;
                  transition: 200ms;

                  .person {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $grey2;

                    svg {
                      margin: 0;
                      font-size: 0.8rem;

                      path {
                        fill: white;
                      }
                    }
                  }
                }

                .dragIcon {
                  opacity: 0;
                  margin-top: 0.4rem;
                  position: absolute;
                  top: 0;
                  transition: 200ms;

                  svg {
                    width: 14px;
                    height: 14px;

                    path {
                      fill: $grey2;
                    }
                  }
                }
              }

              .icon {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $green;
                margin-right: 1rem;

                svg path {
                  fill: white;
                }

                i {
                  margin: 0;
                  font-size: 0.8rem;
                  color: white;
                  margin-top: 2px;
                }

                &.blue {
                  background: $primary2;
                }

                &.primary {
                  background: $primary;
                }

                &.yellow {
                  background: $yellow;
                }

                &.grey {
                  background: $grey3;
                }

                &.pink {
                  background: $pink;
                }
              }

              .text {
                width: 82%;

                .cardTitle {
                  margin-bottom: 0.2rem;
                  width: 180px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  &.centered {
                    margin-bottom: 0;
                    margin-top: 0.2rem;
                  }
                }

                .cardDesc {
                  font-size: 14px;
                  line-height: 18px;
                  color: $grey1;
                  font-weight: 300;
                  width: 220px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  &.noTruncate {
                    width: auto;
                    text-overflow: inherit;
                    overflow: inherit;
                    white-space: inherit;
                  }
                }
              }
            }

            .bottom {
              width: 100%;
              border-top: 0.5px solid $grey5;
              padding: 0.6rem 1rem;

              .label {
                position: relative;
                font-size: 13px;
                line-height: 13px;
                padding-left: 11px;

                &::before {
                  content: "";
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background-color: $yellow;
                  top: 5.2px;
                  left: 0px;
                }

                &.grey {
                  color: $grey1;

                  &::before {
                    background-color: $grey1;
                  }
                }

                &.yellow {
                  color: $yellow;

                  &::before {
                    background-color: $yellow;
                  }
                }

                &.green {
                  color: $green;

                  &::before {
                    background-color: $green;
                  }
                }

                &.blue {
                  color: $primary;

                  &::before {
                    background-color: $primary;
                  }
                }
              }

              &.incomplete {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 1rem;
                height: 45px;

                .incomplete {
                  font-size: 0.9rem;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }

                i {
                  font-size: 0.5rem;
                  color: $yellow;
                }
              }

              .triggerButton {
                button {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0 1rem;
                  height: 45px;
                  font-size: 0.9rem;
                  font-weight: 400;
                  line-height: 0.9rem;
                  color: $grey1;

                  i {
                    margin: 0;
                    margin-left: 0.6rem;
                    margin-top: 1px;
                    font-size: 1rem;
                  }

                  &:hover {
                    background: rgba($grey5, 0.2);
                    color: $black;
                  }
                }
              }

              &.complete {
                button {
                  color: $darkgrey;
                  opacity: 0.6;

                  .icon {
                    border-color: rgba($green, 1);
                    background: rgba($green, 1);

                    i {
                      margin-top: 1px;
                      color: white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
