@import "variables.scss";

:local {
  .totalsWrapper {
    width: 100%;

    .totals {
      width: 100%;
      margin-bottom: 4rem;

      &:last-child {
        margin-bottom: 0;
      }

      thead {
        background-color: $extradarkgrey;
        border-collapse: collapse;

        th {
          padding: 0.8rem 0.8rem;
          border: 0.5px solid $grey1;
          vertical-align: middle;
          text-align: center;
          min-width: 150px;

          @media (max-width: 1280px) {
            min-width: fit-content;
          }

          &:first-child {
            text-align: left;
          }

          p {
            color: white;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 1px;
          }
        }
      }

      tbody {
        td {
          padding: 0.8rem 0.8rem;
          border: 0.5px solid $grey4;
          vertical-align: middle;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          min-width: 150px;

          @media (max-width: 1280px) {
            min-width: fit-content;
          }

          &:first-child {
            text-align: left;
          }

          &.selected {
            background-color: $lightgreen;

            svg {
              width: 32px;
              height: 32px;

              path {
                stroke: $green;
              }
            }
          }
        }
      }
    }
  }
}
