@import 'variables.scss';

:local {
  form {
    input {
      position: relative;
      height: 48px;
      outline: none;
      border: 1px solid $grey5;
      border-radius: $bradius;
      color: black;
      font-weight: 300;
      background: white;
      text-indent: 1rem;
      font-size: 0.9rem;
      line-height: 1.4rem;

      &::placeholder {
        font-size: 0.9rem;
        font-family: proxima-nova;
        color: $grey1;
        font-weight: 300;
      }

      &:focus {
        border-color: $grey1 !important;
      }
    }

    .row {
      @media (max-width: 450px) {
        font-size: 1.4rem;
        flex-direction: column;
      }

      &:first-child div {
        margin-top: 0;
      }

      div {
        width: 100%;
        margin-top: 1rem;
        justify-content: space-between;

        .label {
          font-weight: 400;
          color: $black;
        }
      }

      .inputTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 0.4rem;

        .forgot {
          font-size: 13px;
          font-weight: 300;
          color: $grey1;
          cursor: pointer;
          transition: $transition;

          &:hover {
            color: black;
          }
        }

        .label {
          color: black;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }

      .passwordField {
        position: relative;
        width: 100%;

        input[type='password'] {
          letter-spacing: 2px;
          font-size: 1.1rem;

          &::placeholder {
            letter-spacing: initial;
            font-size: 0.9rem;
          }
        }

        .showPassword {
          position: absolute;
          width: 20px;
          right: 0.8rem;
          bottom: 1.1rem;

          i {
            font-size: 0.85rem;
            color: $black;
          }
        }
      }

      .rememberMe {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
        cursor: pointer;
        width: 100%;

        .label {
          font-weight: 400;
          font-size: 0.9rem;
          color: $black;
          margin-top: 1px;
        }

        input {
          height: 15px;
          width: 15px;
          margin-right: 0.6rem;
        }
      }
    }
    .submitError p {
      color: $red;
      font-size: 0.9rem;
      font-weight: 400;
      margin-top: 0.6rem;
    }

    .buttons,
    .loginButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.2rem;

      a {
        border: none;
      }

      &.caseStudy {
        button {
          width: 100%;
        }
      }
    }

    .loginButtons {
      button {
        height: 40px;
        font-size: 1rem;
        width: 100%;
      }

      @media (max-width: 416px) {
        flex-direction: column;

        button,
        a {
          width: 100%;
          margin: 6px 0;
        }
      }
    }

    a {
      border: none;
      padding: 0;
    }
  }
}
