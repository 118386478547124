@import 'variables.scss';

:local {
  .link {
    @media (max-width: 620px) {
      max-width: 100%;
      width: 100%;
    }

    h4 {
      margin: 0 !important;
      margin-bottom: 0.4rem !important;
    }

    .team {
      margin-bottom: 1rem !important;
    }
  }
}
