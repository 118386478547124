@import "variables.scss";

:local {
  .wrapper {
    background-image: url("~images/values-blank.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 600px;
    width: 600px;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 2rem;
    font-family: proxima-nova;

    @media (max-width: 600px) {
      width: 100%;

      @media (max-width: 500px) {
        height: 500px;
      }

      @media (max-width: 400px) {
        height: 370px;
      }
    }

    .noComplete {
      display: block;
      margin-top: calc(50% - 45px);

      p {
        padding: 0.8rem;
        background: rgba(black, 0.8);
        border-radius: $bradius;
        box-shadow: $lightboxshadow;
        font-size: 1rem;
        line-height: 1.4rem;
        font-weight: 400;
        color: white;
        max-width: 320px;
        text-align: center;
      }
    }
  }
}
