@import "variables.scss";

:local {
  .wrapper {
    // max-width: 960px;

    .subtitle {
      max-width: 70%;
      margin: 1rem 0;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.6rem;
      list-style-type: initial;
    }

    .conclude {
      padding: 1.4rem 0;

      h5 {
        font-size: 17px;
      }

      p {
        font-weight: 300;
      }

      .conclusion {
        margin-top: 1rem;
        position: relative;

        .autosave {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 999;
          color: $grey1;
          font-size: 13px;
          opacity: 0;
          transition: 300ms;
          padding: 2px 10px;
          background: $grey5;
          border-radius: $bradius2;

          &.show {
            opacity: 1;
          }
        }

        button {
          margin-top: 1rem;
        }
      }
    }

    .summary {
      padding-top: 1.4rem;
      margin-top: 2rem;
      border-top: 1px solid $grey5;

      h5 {
        font-size: 17px;
      }

      .dims {
        margin-top: 1rem;
        display: grid;
        gap: 1.4rem;
        align-items: start;
        grid-template-columns: repeat(auto-fit, minmax(235px, 31.4%));

        .card {
          padding: 1rem;
          border: 1px solid $grey5;
          border-radius: $bradius2;

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
            border-bottom: 1px solid $grey5;

            .icon {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background: $green4;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 1rem;

              svg path {
                fill: white;
              }
            }

            h5 {
              font-size: 15px;
            }
          }

          &:nth-child(2) {
            .icon {
              background: #80d2e5;
            }
          }

          &:nth-child(3) {
            .icon {
              background: $primary;
            }
          }

          &:nth-child(4) {
            .icon {
              background: $darkgrey;
            }
          }

          &:nth-child(5) {
            .icon {
              background: $yellow;
            }
          }

          .comments {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0.4rem 0;

            .comment {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-top: 0.8rem;

              &.flexstart {
                align-items: flex-start;
              }

              .avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 1rem;
              }

              .message {
                flex: 1;
                font-size: 14px;
                line-height: 20px;
                font-weight: 300;
                color: $darkgrey;
              }
            }
          }
        }
      }
    }
  }
}
