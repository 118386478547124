@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .extraItems {
      flex: 1;
    }

    .breadcrumbs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // width: 100%;

      a {
        border: none;
      }

      button {
        padding: 12px;
        margin-right: -0.6rem;
        margin-left: -12px;

        svg {
          height: 11px;
          width: 11px;
        }

        svg path {
          fill: $grey2;
        }

        &:hover {
          svg path {
            fill: $primary;
          }
        }
      }

      .arrow {
        margin-left: 0.6rem;
        color: $grey2;
        font-size: 12px;
        font-weight: 400;

        &:first-child {
          margin-left: 0;
        }
      }

      .pageDataWrapper {
        &.candidate {
          .pageData {
            max-width: 100%;
          }
        }
      }

      .location {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 0.6rem;

        &:hover {
          .pageData {
            color: $primary;
          }
        }

        .pageData {
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0.5px;
          color: $grey1;
          font-weight: 400;
          text-transform: uppercase;

          @media (max-width: 480px) {
            max-width: 50px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
