@import "variables.scss";

:local {
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    margin-bottom: 4rem;

    @media (max-width: 1312px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 1070px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 860px) {
      grid-template-columns: 1fr;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border: 1px solid $grey5;
      border-radius: $bradius3;
      // max-width: 215px;
      width: 100%;
      min-height: 150px;
      box-shadow: $boxshadow;
      transition: $transition;
      padding: 1rem;
      position: relative;
      // margin-right: 1rem;
      // margin-bottom: 1rem;

      &:hover {
        box-shadow: $boxshadowhover;

        .deleteIcon {
          opacity: 1;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          width: 20px;
          height: 20px;
          transition: $transition;

          svg {
            width: 16px;
            height: 16px;

            rect {
              fill: white;
            }
          }

          &:hover {
            background: rgba(0, 0, 0, 0.8);
          }
        }
      }

      &.addNew {
        border: 1px dashed $grey3;
        background-color: $grey6;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;

        &:hover {
          box-shadow: none;
        }
      }

      .deleteIcon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;

        button {
          width: 100%;
          height: 100%;
        }

        &:hover {
          svg path {
            fill: $black;
          }
        }

        svg {
          width: 16px;
          height: 16px;

          path {
            fill: $grey1;
          }
        }
      }

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .dragHandle {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        opacity: 0;
        transition: $transition;

        svg {
          height: 14px;

          path {
            fill: $grey4;
          }
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        .title {
          font-family: proxima-nova;
          font-size: 1.2rem;
          line-height: 1.6rem;
          font-weight: 600;
          padding: 0;
          margin: 0;
          text-indent: 0;
          border: none;
          background: none;
          margin-bottom: 0.4rem;
          resize: none !important;
          width: 100%;

          &::placeholder {
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: 600;
            color: $grey3;
          }
        }

        .desc {
          font-family: proxima-nova;
          font-size: 15px;
          line-height: 18px;
          font-weight: 400;
          color: $darkgrey;
          border: none;
          padding: 0;
          margin: 0;
          resize: none;

          &::placeholder {
            font-size: 15px;
            line-height: 18px;
            font-weight: 400;
            color: $grey3;
          }
        }
      }
    }
  }
}
