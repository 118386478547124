@import "variables.scss";

:local {
  .wrapper {
    padding-top: 4rem;
    display: none;

    &.show {
      display: initial;
    }

    .top {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 2rem;

      .left {
        width: 50%;
        margin-right: 4rem;

        .title {
          margin-bottom: 1rem;
          font-size: 32px;
          font-weight: 600;
        }

        .description {
          font-size: 18px;
          line-height: 28px;
          font-weight: 300;
          color: $darkgrey;
          max-width: 600px;
          width: 100%;
        }
      }
    }

    .chart {
      width: 50%;
      margin-top: 2rem;
    }

    .facetsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 3rem;
      width: 100%;
      margin-right: 4rem;

      .facet {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2rem;
        width: 100%;
        padding-bottom: 2rem;
        border-bottom: 1px solid $grey5;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 50%;
          margin-right: 4rem;

          .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            max-width: 550px;

            h5 {
              font-weight: 600;
              margin-bottom: 0.2rem;
            }

            p {
              font-size: 15px;
              line-height: 20px;
              font-weight: 300;
              color: $grey1;
            }
          }

          .facetChart {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 1rem;
          }
        }

        .right {
          width: 50%;
          background: $blue;
          padding: 2rem;
          border-radius: $bradius2;

          h5 {
            font-size: 15px;
            font-weight: 600;
            color: black;
            margin-bottom: 0.4rem;
          }

          p {
            font-size: 14px;
            line-height: 21px;
            font-weight: 300;
            max-width: 70%;
            color: $darkgrey;
          }
        }
      }
    }

    .commentsWrapper {
      margin-top: 2rem;
      width: 100%;

      .title {
        font-size: 18px;
        line-height: 18px;
        width: 100%;
        padding-bottom: 0.8rem;
        border-bottom: 1px solid $grey5;

        span {
          position: relative;
          margin-left: 25px;

          &::before {
            content: "";
            position: absolute;
            top: 9px;
            left: -15px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: black;
          }
        }
      }

      .comments {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .comment {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 1rem 0;
          width: 100%;

          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 1rem;
          }

          .right {
            flex: 1;

            .top {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 0.2rem;
              position: relative;
              width: 100%;

              .name {
                margin-right: 0.6rem;
                font-size: 14px;
                line-height: 14px;
              }

              .time {
                font-size: 12px;
                line-height: 12px;
                color: $darkgrey;
                font-weight: 300;
              }

              .removeButton {
                position: absolute;
                top: 0;
                right: 0;
              }
            }

            .message {
              max-width: 550px;
              font-size: 15px;
              line-height: 26px;
              color: black;
              font-weight: 300;
            }
          }
        }
      }

      .newComment {
        margin-top: 1rem;
        max-width: 600px;
      }
    }
  }
}
