@import "variables.scss";

:local {
  .title {
    font-size: 1.1rem;
    margin-bottom: 0.4rem;
    color: $darkgrey;
  }

  .description {
    font-size: 15px;
    line-height: 18px;
    font-weight: 300;
  }

  .options {
    width: 100%;
    margin-top: 2rem;

    button {
      width: 100%;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .option {
      width: 100%;
      border: 1.5px solid $grey5;
      border-radius: $bradius3;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: $transition;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 768px) {
          margin-bottom: 1rem;
        }

        .checkbox {
          position: relative;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1.5px solid $grey4;
          margin-right: 1rem;

          @media (max-width: 428px) {
            display: none;
          }
        }

        .icon {
          width: 32px;
          height: 32px;
          border-radius: $bradius3;
          background-color: $grey5;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;

          svg path {
            fill: $darkgrey;
          }
        }
      }

      .instruction {
        font-size: 12px;
        line-height: 12px;
        font-weight: 300;
      }

      &.selected {
        border-color: $primary;

        .checkbox {
          border-color: $primary;

          &::after {
            content: "";
            position: absolute;
            top: 2.5px;
            left: 2.5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $primary;
          }
        }

        .left .icon {
          background-color: $blue;

          svg path {
            fill: $primary;
          }
        }
      }
    }
  }

  .cta {
    margin-top: 3rem;
  }

  .submitWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 4rem;

    @media (max-height: 710px) {
      position: relative;
      bottom: 0;
      margin-top: 2rem;
    }

    &.relative {
      position: relative;
      bottom: 0;
      margin-top: 2rem;
    }

    button:first-child {
      margin-right: 1rem;
    }

    button {
      box-shadow: 0px 1.5px 3px 0px rgba(black, 0.08);
    }

    .loading {
      i {
        animation: rotating 2s linear infinite;
      }
    }
  }
}
