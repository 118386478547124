@import 'variables.scss';

:local {
  .wrapper {
    position: relative;

    .title {
      margin-bottom: 2rem;
    }

    canvas {
      border-radius: $bradius;
      box-shadow: $boxshadow;
    }

    input[type='range'] {
      -webkit-appearance: none;
      background: $grey4;
      height: 0.4rem;
      border-radius: $bradius;
      width: 100%;
      border: 0;
      padding: 0;
      margin: 1.6rem 0;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        border-radius: 10px;
        background: $primary;
        cursor: pointer;
        border: none;
      }
    }
    button {
      text-indent: 0;
      i {
        margin-right: 0.5rem;
        margin-top: -1px;
        color: $darkgrey;
      }
    }

    .loaderWrapper {
      position: absolute;
      width: calc(100% + 4rem);
      height: calc(100% + 4rem);
      z-index: 9999;
      background: white;
      margin: -2rem;
      box-sizing: border-box;
      top: 0;
    }
  }
}
