@import "variables.scss";

:local {
  .wrapper {
    display: grid;
    grid-template-columns: minmax(600px, 40%) 1fr;
    position: relative;

    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
    }

    // &::after {
    //   content: "";
    //   width: 60%;
    //   min-width: 640px;
    //   height: 100%;
    //   position: absolute;
    //   background: #e3f7eb;
    //   right: 0;
    //   top: 0;
    //   z-index: -1;
    // }

    @media (max-width: 1080px) {
      flex-direction: column;
      min-height: auto;
    }

    .left {
      //   width: 40%;
      //   min-width: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      overflow: hidden;
      // max-width: 900px;
      padding: 0 8rem;

      @media (max-width: 1300px) {
        padding: 4rem;
      }

      @media (max-width: 1080px) {
        padding: 8rem 4rem;
        // max-width: 100%;
        // width: 100%;
      }

      @media (max-width: 550px) {
        padding: 4rem 2rem;
        padding-top: 6rem;
      }

      .title {
        font-weight: 700;
        max-width: 400px;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 2.4rem;

        @media (max-width: 1080px) {
          font-size: 36px;
          line-height: 36px;
          margin-bottom: 1.6rem;
        }
      }

      .subtitle {
        color: $grey1;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 3rem;
        max-width: 500px;

        @media (max-width: 1080px) {
          font-size: 0.9rem;
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        max-width: 500px;
        margin-bottom: 3rem;

        .card {
          border-radius: $bradius3;
          border: 1px solid $grey5;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
          margin-bottom: 1rem;
          transition: $transition;
          cursor: pointer;

          .link {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: none;
            padding: 1rem !important;
            margin: 0;
          }

          &:hover {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
          }

          &:last-child {
            margin-bottom: 0;
          }

          .cardLeft {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background: $grey3;
              margin-right: 0.8rem;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                height: 16px;

                path {
                  fill: white;
                }
              }
            }
          }

          .cardRight {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .time {
              color: $grey3;
              font-weight: 500;
              margin-right: 0.6rem;
            }

            svg {
              path {
                stroke: $grey3;
              }
            }
          }

          &.active {
            .icon {
              background: $primary;
            }

            .cardRight {
              .time {
                color: $primary;
              }

              svg {
                path {
                  stroke: $primary;
                }
              }
            }
          }

          &.complete {
            .icon {
              background: $green;

              svg {
                width: 20px;
                height: 20px;
              }
            }

            .cardRight {
              .time {
                color: $green;
              }

              svg {
                path {
                  stroke: $green;
                }
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        a {
          border: none;
          margin: 0;
          padding: 0;
        }

        button {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
    }

    .right {
      // width: 60%;
      // min-width: 640px;
      height: 100vh;
      background: #e3f7eb;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &.green {
        background: #e3f7eb;
      }

      &.yellow {
        background: #fcf6ef;
      }

      &.blue {
        background: #eff9ff;
      }

      @media (max-width: 1080px) {
        width: 100%;
        height: 400px;
      }

      .img {
        z-index: 1000;

        img {
          height: 400px;

          @media (max-width: 1080px) {
            height: 300px;
          }
        }
      }
    }
  }
}
