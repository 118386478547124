@import 'variables.scss';

:local {
  button {
    box-shadow: none;
  }
  .wrapper {
    padding: 0rem;

    .person {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.8rem;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .avatar {
          margin-right: 0.8rem;
          background-image: url('~images/alo.png');
          background-size: 100%;
          background-position: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        p {
          color: $grey1;
          font-size: 0.9rem;
        }
      }

      .close {
        i {
          font-size: 1.2rem;
          color: $grey1;
        }

        &:hover {
          i {
            color: $black;
          }
        }
      }
    }

    h4 {
      color: $primary;
      margin-bottom: 0.6rem;
      font-size: 18px;
      font-weight: 500;
    }

    p {
      font-size: 1rem;
    }

    .image {
      margin: 2rem 0;
      width: 100%;

      svg {
        width: 100%;
        transform: scale(1.3);
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
  }
}
