@import "variables.scss";

:local {
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }

    &.Compare {
      svg path {
        fill: black;
      }
    }

    &.Clipboard {
      svg path {
        &:nth-child(3) {
          fill: white;
        }
        &:nth-child(4) {
          fill: white;
        }
      }
    }

    &.Check {
      svg {
        path {
          stroke-width: 1;
          fill: none;
        }
      }
    }

    &.marginRight {
      margin-right: 0.4rem;
    }

    &.white {
      svg path {
        fill: white;
      }
    }

    &.purple {
      svg path {
        fill: #5a17d1;
      }
    }

    &.primary {
      svg path {
        fill: $primary;
      }
    }

    &.large {
      svg {
        width: 22px;
        height: 22px;
      }
    }

    &.epic {
      svg {
        width: 64px;
        height: 64px;
      }
    }
    &.grey {
      svg path {
        fill: $grey1;
      }
    }
    &.green {
      svg path {
        fill: $green3;
      }
    }
    &.yellow {
      svg path {
        fill: $yellow;
      }
    }
  }
}
