@import "variables.scss";

:local {
  .wrapper {
    padding: 2rem 0;

    h4 {
      max-width: 60%;
      font-weight: 300;
      margin-top: 1rem;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    .imageWrapper {
      width: 100%;
      height: 300px;
      background: $blue;
      border-radius: $bradius;
      margin-top: 1rem;
      margin-bottom: 2rem;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: $bradius2;
      }

      svg {
        position: absolute;
        left: 30%;
        bottom: 0;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;
      padding-top: 2rem;
      width: 100%;
      border-top: 1px solid $grey4;

      .col {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 4rem;

        .icon {
          margin-right: 1.4rem;

          i {
            font-size: 1.4rem;
          }
        }

        .text {
          h5 {
            font-size: 1.1rem;
            line-height: 1.1rem;
          }

          .difficulty {
            text-transform: capitalize;
          }

          p {
            color: $darkgrey;
            font-size: 0.9rem;
          }
        }
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 2rem 0;

      button {
        font-size: 1rem;
      }

      button:first-child {
        margin-right: 1rem;
      }
    }

    .playsWrapper {
      padding: 0 1rem;
      border: 1px solid $grey5;
      border-radius: $bradius2;
      box-shadow: $boxshadow1;

      .play {
        // margin-bottom: 1rem;
        border-bottom: 1px solid $grey5;

        &:last-child {
          border-bottom: 0;
        }

        button {
          padding: 1rem 0;
          color: $black;
          font-size: 0.95rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .mainLeft {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .mainRight {
            i {
              display: none;
            }
          }

          .top {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            h5 {
              font-size: 15px;
              line-height: 15px;
            }
          }

          .icon {
            width: 32px;
            height: 32px;
            background: $green;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 9px;
            margin-right: 0.8rem;

            svg {
              transform: scale(1.2);
            }

            &.progress {
              background: $grey5;
            }
          }
        }

        .time {
          font-size: 12px;
          line-height: 12px;
          color: $grey1;
          margin-top: 3px;

          time {
            margin-left: 4px;
          }
        }
      }
    }

    .descTitle {
      margin-bottom: 1rem;
      font-weight: 500;
    }

    .desc {
      li {
        margin: 0.6rem 0;
        list-style-type: initial;
        margin-left: 1rem;
      }
    }
  }
}
