@import 'variables.scss';

:local {
  @keyframes :local(fade) {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .wrap {
    width: 100%;
    position: relative;

    .memberCurve {
      display: none;
      position: absolute;
      height: calc(100% - 100px);
      top: 2rem;
      z-index: 1000;
      pointer-events: none;
      &.isCurrentUser {
        display: block;
      }
    }
    .distribution {
      position: absolute;
      height: calc(100% - 100px);
      top: 2rem;
      pointer-events: none;
      animation-duration: 1s;
      animation-timing-function: cubic-bezier(0.55, 0.03, 0.5, 0.98);
      animation-name: fade;

      .curve {
        fill: rgba($primary, 0.2);
      }
    }
  }
}
