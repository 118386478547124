@import "variables.scss";
@import "logos.scss";

:local {
  .wrap {
    display: flex;
    position: relative;

    .mobileHeader {
      background: white;
      width: 100%;
      border-bottom: 1px solid $grey5;
      min-width: 100vw;
      max-width: 100vw;
      padding: 0.4rem 1rem;
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999999;

      .logo a {
        border: none;
      }
    }

    .burgerMenu {
      height: 40px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 200ms;
      border-radius: 50%;

      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .line {
          width: 100%;
          height: 2px;
          border-radius: 4px;
          background-color: $black;
          margin-bottom: 6px;
          transition: 200ms;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.open {
        button {
          .line {
            width: 26px;

            &:first-child {
              transform: rotate(-45deg);
            }

            &:last-child {
              transform: rotate(45deg);
              margin-top: -8px;
            }
          }
        }
      }
    }

    &.sidebarCollapsed {
      .sidebar {
        border-right: 0.5px solid $grey5;
      }
    }

    .sidebar {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: sticky;
      top: 0;
      z-index: 9994;
      max-width: 100%;
      transform: 500ms;

      &.mobile {
        display: none;

        @media (max-width: 960px) {
          position: fixed;
          top: 0;
          left: 0;
        }

        &.open {
          display: flex;
          box-shadow: 0 0px 48px rgba(0, 0, 0, 0.15);
        }
      }

      .left {
        width: 64px;
        height: 100%;
        min-height: 100vh;
        background: white;
        padding: 1rem;
        padding-top: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .section {
          padding-top: 1.4rem;
          margin-top: 1.4rem;
          border-top: 1px solid $grey5;
        }

        .icon {
          margin-bottom: 1.6rem;

          svg {
            width: 16px;
            height: 16px;

            path {
              fill: $grey4;
            }
          }
        }

        .top {
          .logo {
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 300ms ease-in-out;

            &:hover {
              transform: rotate(45deg);
            }

            a {
              border: none;
            }
          }
        }

        .bottom {
          .avatarWrapper {
            width: 26px;
            height: 26px;
            background-color: $grey5;
            border-radius: 50%;
          }
        }
      }

      .pane {
        width: 192px;
        height: 100vh;
        background: $grey6;
        padding: 1rem;
        padding-top: 1.4rem;
        box-shadow: -2px -2px 3px inset rgb(0 0 0 / 3%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h5 {
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          margin: 0;
        }

        p {
          margin: 1rem 0;
          margin-top: 0.6rem;
          font-size: 14px;
          color: $darkgrey;
          text-align: center;
        }

        a {
          border: none;
        }
      }
    }
  }
}
