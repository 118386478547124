@import "variables.scss";

:local {
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .wrapper {
    margin: 0 auto;

    .explore {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        h5 {
          font-size: 1rem;
          font-weight: 400;
          color: $darkgrey;
        }

        .iconWrapper {
          width: 40px;
          margin-right: 1rem;
          height: 40px;
          width: 40px;
          border: 1px solid $grey3;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin: 0;
            margin-top: 3px;
            color: $darkgrey;
            animation-name: bounce;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
          }
        }
      }

      &:hover {
        h5 {
          color: $black;
        }
      }
    }
  }
}
