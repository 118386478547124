@import "variables.scss";

:local {
  .wrapper {
    background-image: url("~images/personality-blank.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 800px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 4rem;
    font-family: proxima-nova;

    @media (max-width: 800px) {
      width: 100%;
    }

    .noComplete {
      margin-top: calc(175px - 90px);

      p {
        padding: 0.8rem;
        background: rgba(black, 0.8);
        border-radius: $bradius;
        box-shadow: $lightboxshadow;
        font-size: 1rem;
        line-height: 1.4rem;
        font-weight: 400;
        color: white;
        max-width: 320px;
        text-align: center;
      }
    }
  }
}
