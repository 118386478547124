@import "variables.scss";

:local {
  .customSelect {
    position: relative;
    height: 31.78px;
    padding: 0 0.6rem;
    border: 1px solid $grey4;
    border-radius: $bradius3;
    color: $black;
    font-family: proxima-nova;
    transition: $transition;
    box-shadow: 0 1px 2px rgba($black, 0.1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.selected {
      border-color: $primary;
    }

    &:hover {
      border-color: $grey1;
      color: $black;
      box-shadow: $lightboxshadow;
      cursor: pointer;
    }

    .icon {
      margin-right: 0.4rem;

      i {
        font-size: 0.9rem;
      }
    }

    h5 {
      font-size: 0.9rem;
      line-height: 0.9rem;
    }

    .selectArrow {
      z-index: 10;
      margin-left: 0.6rem;

      i {
        font-size: 0.8rem;
        color: $darkgrey;
        margin: 0;
      }
    }

    .options {
      display: none;
      position: absolute;
      width: 150%;
      top: 100%;
      left: 0;
      background: white;
      border: 1px solid $grey4;
      border-radius: $bradius3;
      box-shadow: $lightboxshadow;
      margin-top: 3px;
      z-index: 9999;

      button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          background: rgba($grey5, 0.4);
        }

        .user {
          width: 100%;
          padding: 0.5rem 0.8rem;
          font-size: 0.9rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              margin-right: 0.5rem;
            }
          }

          &.active {
            color: $primary;
          }
        }
      }
    }

    &.open {
      .options {
        display: block;
      }
    }
  }
}
