@import "variables";

:local {
  .wrap {
    margin: 200px 0;
    .chart {
      position: relative;
      width: 100%;
      background-color: $grey3;
      height: 1px;

      .avg {
        position: absolute;
        top: -50px;
        width: 3px;
        height: 120px;
        background: $primary;
        display: none;
        justify-content: center;

        .number {
          position: relative;
          top: -20px;
          text-align: center;
          font-weight: 500;
          color: $primary;
        }

        &.highlight {
          background: $darkgrey;

          .number {
            color: $darkgrey;
          }
        }
      }
      .membersWrap {
        position: relative;
        width: 100%;
        top: -8px;
        .blobWrap {
          filter: url("#goo");
        }
        .blob {
          position: absolute;
          height: 40px;
          width: 40px;
          transform-origin: center;
          border-radius: 50%;
          background: #a1d2ed;
        }
        .member {
          position: absolute;
          border: 2px solid #048fd2;
          border-radius: 50%;
          background: white;
          transform: translate(-14px, -6px);
        }
      }
    }
  }
}
