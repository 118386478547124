@import "variables.scss";

:local {
  .wrapper {
    z-index: 9999;
    // min-width: 220px;
    min-width: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ul {
      width: 100%;
      padding: 0.2rem;

      li {
        button,
        a {
          font-size: 0.9rem;
          line-height: 1.4rem;
          margin: 0 !important;
          padding: 0.6rem 1rem;
          color: $darkgrey;
          border-radius: $bradius2;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: none;
          transition: 150ms ease-in-out;

          &:hover {
            color: $black;
            background: $grey6;

            svg path {
              fill: $black;
            }
          }

          svg {
            margin-right: 0.2rem;
            transition: 150ms ease-in-out;

            path {
              fill: $darkgrey;
            }
          }

          i {
            width: 12px;
            margin-right: 0.8rem;
            font-size: 0.8rem;
            line-height: 1.4rem;
            color: black;
            text-align: center;
          }
        }
      }
    }
  }
}
