@import "variables.scss";

:local {
  .infoPopup {
    padding: 0.8rem 1.2rem;
    width: 450px;

    .topRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.6rem;

      .dimTitle {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        color: $darkgrey;
      }

      .dimGroup {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        color: $grey1;
        margin: 0.5rem 0;

        &.yellow {
          color: $yellow;
        }

        &.green {
          color: $green;
        }

        &.primary {
          color: $primary;
        }
      }
    }

    .chart {
      margin-top: 2.8rem;
    }

    .dimDescription {
      font-size: 14px !important;
      line-height: 24px !important;
      color: $darkgrey;
      margin: 0;
    }
  }
}
