@import 'variables.scss';

:local {
  .avatarPosition {
    position: absolute;
    height: 15px;
    width: 15px;
    background: transparent;
    border-radius: 50%;
    // border: 1px solid $primary;
    pointer-events: fill;
    display: flex;
    justify-content: center;
    align-items: center;
    .wrapper {
      transition: $transition;
      opacity: 0.7;
      transform: scale(0.7);
      pointer-events: none;
      box-shadow: 0 0px 12px rgba(0, 0, 0, 0.1);
      border-radius: 50%;

      .image {
        height: 45px;
        width: 45px;
        min-width: 45px;
        border-radius: 50%;
        position: relative;
      }

      .initials {
        height: 45px;
        width: 45px;
        min-width: 45px;
        border-radius: 50%;
        position: relative;
        border: 2px solid $grey5;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;

        p {
          font-size: 1rem;
          color: $primary;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }
      &.isCurrentUser {
        opacity: 1;
        transform: scale(1);
        z-index: 100;
      }
    }
  }
  .blobWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.5s ease-in-out;
    &.visible {
      opacity: 1;
    }
  }
}
