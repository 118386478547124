@import "variables.scss";

:local {
  .wrapper {
    max-width: $wrapperWidth;
    // padding-right: 1rem;
    min-height: 80vh;

    .subtitle {
      max-width: 70%;
      margin-top: 0.6rem;
      font-weight: 300;
      font-size: 1.1rem;
    }

    .imageWrapper {
      width: 80%;
      height: 300px;
      background: $blue;
      border-radius: $bradius;
      margin: 2rem 0;
      position: relative;

      .box {
        width: 200px;
        height: 120px;
        background: $primary;
        border-radius: 15px;
        position: absolute;
        left: 25%;
        top: 10%;
      }

      svg {
        position: absolute;
        left: 30%;
        bottom: 0;
      }
    }
  }
}
