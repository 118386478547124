@import 'variables.scss';

:local {
  .miniChartWrapper {
    width: 100%;
    margin-top: 1rem;

    .miniChart {
      width: 100%;
      height: 8px;
      background: linear-gradient(
        to left,
        #018949 0%,
        #37c884 31.85%,
        #d3ffeb 50.72%,
        #dadada 85.42%
      );
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 2px;

      .chart {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;

        &:before {
          position: absolute;
          content: '';
          width: 2px;
          height: 4px;
          background: $grey3;
          left: 50%;
          bottom: -10px;
        }

        &:after {
          position: absolute;
          content: '';
          width: 2px;
          height: 4px;
          background: $grey3;
          left: 50%;
          top: -10px;
        }

        .avatar {
          position: absolute;
          opacity: 0.8;
          transition: $transition;
          border: 2px solid white;
          border-radius: 50%;

          &:hover {
            opacity: 1;
          }

          &.highlight {
            opacity: 1;

            .highlightScore {
              margin-top: -36px;
              margin-bottom: 4px;
              font-weight: 500;
              font-size: 13px;
              color: $green4;
              text-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
              text-align: center;
            }

            img,
            div[aria-label='initials'] {
              border: 2px solid white;
              transform: scale(1.7);
              box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
              opacity: 1;

              p {
                font-size: 7px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .legend {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 1rem;

      p {
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: $grey2;
        width: 48%;

        &:last-child {
          color: $green4;
          text-align: right;
        }
      }
    }
  }
}
