@import "variables.scss";

:local {
  .wrapper {
    width: 100%;

    .subtitle {
      max-width: 70%;
      margin: 1rem 0;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.6rem;
      list-style-type: initial;
    }

    .listBoxes {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      // max-width: 80%;
      margin-bottom: 1rem;

      .list {
        width: 48.9%;
        box-shadow: $lightboxshadow;

        .containerWrapper {
          width: 100%;

          .row {
            border-top-left-radius: $bradius;
            border-top-right-radius: $bradius;

            &.alsoColumn {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              min-height: 79px;
              max-height: 246px;
              overflow-y: scroll;
              border-radius: 0;
              padding: 0;
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none;

              &::-webkit-scrollbar {
                width: 5px;
                background: rgba($grey5, 0.4);
              }

              &::-webkit-scrollbar-thumb {
                background: $grey4;
                border-radius: 2px;
              }

              .draggable {
                width: 100%;
                // min-height: 79px;
                // max-height: 300px;
                padding: 1rem;
                background: rgba($grey5, 0.4);
                border-bottom: 1px solid $grey5;

                &:last-child {
                  border-bottom: 0;
                }

                &.draggingBlock {
                  background: white;
                  border: 1px solid $grey4;
                  box-shadow: $boxshadow;
                  border-radius: $bradius;
                }
              }
            }
          }

          .row {
            &:first-child {
              background: white;
              border-bottom: 1px solid $grey5;
              padding: 0.6rem 1rem;
            }

            .text {
              input {
                border: none;
                font-size: 1rem;
                height: 30px;
                text-indent: 0;

                &::placeholder {
                  color: $darkgrey;
                  font-size: 1rem;
                }
              }
            }

            .remove {
              button {
                i {
                  margin: 0;
                  font-size: 0.8rem;
                  color: $grey3;
                }

                &:hover {
                  i {
                    color: $black;
                  }
                }
              }
            }
          }
        }
      }
    }

    .list {
      margin-bottom: 1rem;
      box-shadow: $lightboxshadow;
      width: 80%;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(2) {
        .row .col .icon {
          background: $yellow;
        }
      }
      &:nth-child(3) {
        .row .col .icon {
          background: $primary;
        }
      }
      &:nth-child(4) {
        .row .col .icon {
          background: $primary;
        }
      }
      &:nth-child(5) {
        .row .col .icon {
          background: $red;
        }
      }
      &:nth-child(6) {
        .row .col .icon {
          background: $pink;
        }
      }
      &:nth-child(7) {
        .row .col .icon {
          background: $lightpurple;
        }
      }
    }

    .containerWrapper {
      width: 100%;

      .droppableGroup {
        width: 100%;
        height: auto !important;
        transition: $transition;

        &.dragging {
          background: rgba($grey5, 0.5);
          height: 220px;
        }
      }

      .row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;

        .col {
          &:first-child {
            margin-right: 1rem;
          }

          &:nth-child(2) {
            width: 100%;
          }

          .text {
            width: 100%;

            p {
              color: $darkgrey;
            }
          }

          .icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $green4;
            font-size: 0.9rem;
            font-weight: 500;

            svg {
              transform: scale(0.6);
            }

            svg path {
              fill: white;
            }
          }
        }

        .goal {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          // max-height: 300px;

          .text {
            h5,
            p {
              font-size: 0.9rem;
            }
          }
        }
      }
    }

    .goalsWrapper {
      border-top: 1px solid $grey4;
      padding-top: 2rem;
      margin-top: 2rem;
      margin-bottom: 4rem;
      max-width: 80%;
      width: 80%;

      .teamGoals {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .droppable {
          width: calc(100% + 1rem);
          min-height: 220px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          margin-left: -0.4rem;
          margin-top: -0.4rem;

          &.isDragging {
          }
        }

        .draggable {
          width: 31.3%;
          margin: 0.4rem;

          &.draggingBlock {
            box-shadow: 0 12px 28px rgba(0, 0, 0, 0.1);
          }

          @media (max-width: 1205px) {
            width: 47.9%;
          }

          @media (max-width: 1050px) {
            width: 100%;
          }
        }

        .goal {
          padding: 1.4rem;
          border: 1px solid $grey4;
          border-radius: $bradius;
          background: white;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          min-height: 200px;
          box-shadow: $lightboxshadow;

          .avatarWrapper {
            margin-bottom: 1rem;
          }

          .text {
            margin-bottom: 2rem;
            min-height: 60px;

            p {
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
