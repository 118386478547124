@import "variables.scss";

:local {
  .popover {
    background: rgba(black, 1);
    padding: 0.4rem 0.8rem;
    border-radius: $bradius;
    pointer-events: none;
    // max-width: 100px;
    width: auto;
    display: block;
    white-space: nowrap;

    p {
      color: white;
      font-size: 0.7rem;
      line-height: 0.9rem;
      text-align: center;
      white-space: nowrap;
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    cursor: default;

    &.pointer {
      cursor: pointer;
    }

    img,
    div[aria-label="initials"] {
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &.isGuest {
      position: relative;

      &:after {
        content: "";
        top: -4px;
        left: -4px;
        position: absolute;
        height: 15px;
        width: 15px;
        background-image: url("~images/icons/no_seat.svg");
        fill: $primary;
      }

      &:hover {
        cursor: help;

        &:before {
          position: absolute;
          content: "This user does not take up a seat for billing purposes";
          bottom: -75px;
          left: 15px;
          width: 100px;
          font-size: 0.8rem;
          background: $darkgrey;
          color: white;
          padding: 0.8rem;
          border-radius: $bradius;
          line-height: 1.2;
          z-index: 100;
        }
      }
    }

    .initials {
      background: $blue;

      p {
        color: $primary !important;
      }
    }

    // &.blue {
    //   .initials {
    //     background: $blue;
    //
    //     p {
    //       color: $primary;
    //     }
    //   }
    // }

    // &.green {
    //   .initials {
    //     background: #daf4e5;
    //
    //     p {
    //       color: $green;
    //     }
    //   }
    // }

    // &.yellow {
    //   .initials {
    //     background: #fdefe1;
    //
    //     p {
    //       color: $yellow;
    //     }
    //   }
    // }

    &.grey {
      .initials {
        background: rgba($grey5, 0.5);

        p {
          color: $grey3;
        }
      }
    }

    &.large {
      img {
        height: 150px;
        width: 150px;
      }

      .initials {
        height: 150px;
        width: 150px;

        p {
          font-size: 2.2rem;
          font-weight: 600;
          letter-spacing: 2px;

          svg {
            width: 28px;
            margin-top: 6px;

            path {
              fill: $grey1;
            }
          }
        }
      }
    }

    &.almostLarge {
      img {
        height: 110px;
        width: 110px;
      }

      .initials {
        height: 110px;
        width: 110px;

        p {
          font-size: 1.6rem;
          font-weight: 600;
          letter-spacing: 2px;
        }
      }
    }

    &.mediumLarge {
      img {
        height: 60px;
        width: 60px;
      }

      .initials {
        height: 60px;
        width: 60px;

        p {
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 1px;

          svg {
            width: 24px;
            margin-top: 6px;

            path {
              fill: $grey1;
            }
          }
        }
      }
    }

    &.medium {
      img {
        height: 45px;
        width: 45px;
      }

      .initials {
        height: 45px;
        width: 45px;

        p {
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 1px;

          svg {
            width: 22px;
            margin-top: 6px;

            path {
              fill: $grey1;
            }
          }
        }
      }
    }

    &.mediumSmall {
      img {
        height: 32px;
        width: 32px;
      }

      .initials {
        height: 32px;
        width: 32px;

        p {
          font-size: 0.7rem;
          font-weight: 500;

          svg {
            width: 20px;
            margin-top: 6px;

            path {
              fill: $grey1;
            }
          }
        }
      }
    }

    &.small {
      img {
        height: 30px;
        width: 30px;
      }

      .initials {
        height: 30px;
        width: 30px;

        p {
          font-size: 0.7rem;
          font-weight: 500;

          svg {
            width: 16px;
            margin-top: 6px;

            path {
              fill: $grey1;
            }
          }
        }
      }
    }

    &.verySmall {
      img {
        height: 24px;
        width: 24px;
      }

      .initials {
        height: 24px;
        width: 24px;

        p {
          font-size: 0.6rem;
          font-weight: 500;
        }
      }
    }

    .invite {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1px;
      margin-left: 1px;
    }
  }
}
