$whitelabel: "default";
$logo_format: "svg";
// Primary colours
$primary: #0ea3c3;
$primary1: #ecf9ff;
$primary2: #80d2e5;
$primary3: #0ea3c3;
$primary4: #0fa7ca;

// Blacks
$black: #000000;
$white: #fff;

// Greys
$extradarkgrey: #232644;
$darkgrey: #454754;
$grey1: #91939a;
$grey2: #b5b7bf;
$grey3: #c5cbd3;
$grey4: #e4e6e8;
$grey5: #ededed;
$grey6: #fafafa;

// Secondary colours
$green: #30be9f;
$green1: #d0f1e4;
$green2: #91e2d0;
$green3: #30be9f;
$green4: #168f75;
$red: #f27a5a;
$yellow: #ffb575;
$pink: #d97272;
$purple: #640fda;
$blue: #ecf9ff;
$blue2: #56bef1;
$blue4: #0fa7ca;

// Light colours
$lightblue: #d8edfa;
$lightgreen: #f0fff6;
$lightred: #fff7f4;
$lightteal: #d0f1e4;
$haze: #fcf6ef;
$lightorange: #fcf6ef;
$lightpink: rgba($pink, 0.4);
$lightyellow: #fde3c8;
$lightpurple: #f8f4ff;
$secondarypurple: #5a17d1;

// Sizes
$wrapperWidth: 1080px;
$modalWrapper: 752px;
$bradius: 4px;
$bradius2: 6px;
$bradius3: 8px;

// Box shadows
$boxshadow1: 0px 4px 10px rgba(0, 0, 0, 0.03);
$boxshadow2: 0px 4px 14px rgba(0, 0, 0, 0.08);
$lightboxshadow: 0 4px 10px rgba(0, 0, 0, 0.03);
// $boxshadow: 0 4px 8px rgba($grey3, 0.5);
$boxshadow: 0px 4px 10px 0px #00000008;
$boxshadowhover: 0px 22px 24px 0px #99999918;
$strongboxshadow: 0 8px 16px rgba($darkgrey, 0.4);
$superstrongboxshadow: 0 8px 16px rgba($black, 0.2); // used for whites on primary backgrounds
$headerboxshadow: 0px 1px 4px rgba($black, 0.2);

// Text shadows
$textshadow: 1px 1px 5px rgba($black, 0.2); // used for white text on primary backgrounds

// Transitions
$transition: all ease-in 75ms;
