@import "variables.scss";

:local {
  .modalHead {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 3rem;

    p {
      font-size: 18px;
      line-height: 26px;
      color: $darkgrey;
      font-weight: 300;
      margin-top: 1rem;
      max-width: 70%;
    }
  }

  .positionList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -6px;

    @media (max-width: 450px) {
      margin: 6px;
    }

    a {
      border: none;
      color: inherit;

      @media (max-width: 450px) {
        width: 100%;
      }
    }

    .createPositionButton {
      @media (max-width: 450px) {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }

  @keyframes :local(shine-lines) {
    0% {
      background-position: -90px;
    }

    40%,
    100% {
      background-position: 600px;
    }
  }

  .skeleton {
    position: relative;
    width: 100%;
    margin-left: 140px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 20%;
      margin: 2.6rem 2rem 0 2rem;

      div {
        width: 100%;
        height: 12px;
        margin: 0.5rem 0;
        background: $grey3;
        border-radius: 1rem;
        background-image: linear-gradient(
          90deg,
          $grey4 0px,
          $grey5 40px,
          $grey4 80px
        );
        background-size: 1200px;
        animation: shine-lines 2.6s infinite ease-in-out;

        &:first-child {
          width: 65%;
        }
        &:nth-child(4) {
          width: 75%;
        }
      }
    }
  }
}
