@import 'variables.scss';

:local {
  .main {
    margin-bottom: 8rem;
    padding-top: 8rem;

    .membersWrapper {
      margin-top: 2rem;
    }
  }
}
