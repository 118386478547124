@import 'variables.scss';

:local {
  @keyframes :local(shine-lines) {
    0% {
      background-position: -90px;
    }
    40%,
    100% {
      background-position: 600px;
    }
  }

  .card {
    min-width: 100%;
    min-height: 184px;
    height: 256px;
    background: rgba($grey6, 0.3);
    border-radius: $bradius3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: linear-gradient(
      90deg,
      rgba($grey6, 0.5) 0px,
      white 40px,
      rgba($grey6, 0.5) 80px
    );
    background-size: 1200px;
    animation: shine-lines 2.6s infinite ease-in-out;

    &.small {
      width: 96px;
      height: 96px;
    }

    &.template {
      height: 350px;
      width: 270px;
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: rgba($grey6, 0.5);

      .top {
        height: 130px;
        width: 100%;
        background-color: rgba($grey5, 0.2);
      }
  
      .bottom {
        padding: 1rem;

        .line {
          width: 120px;
          height: 8px;
          background-color: rgba($grey5, 0.6);
          margin-bottom: 1rem;
          border-radius: $bradius2;

          &:nth-child(2) {
            width: 200px;
            height: 12px;
          }
        }
      }
    }

    .avatar {
      height: 32px;
      width: 32px;
      background: rgba($grey5, 0.5);
      border-radius: 50%;
      margin-bottom: 1.2rem;
    }

    svg {
      opacity: 0.2;
    }

    .lines {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2rem;

      .line {
        width: 80%;
        height: 10px;
        background: rgba($grey5, 0.8);
        border-radius: $bradius;
        margin: 0.6rem 0;
      }

      .top {
        width: 100%;
        margin-top: 2rem;

        .line {
          width: 90%;
        }
      }

      .bottom {
        width: 100%;
        margin-top: 2rem;

        .line {
          width: 60%;
          margin: 1rem 0;
        }
      }
    }
  }
}
