@import "variables.scss";

:local {
  .iconActive {
    background: $grey5;

    svg path {
      fill: $black !important;
    }

    &.primary {
      svg path {
        fill: $primary !important;
      }
    }
  }

  .pane {
    width: 192px;
    height: 100vh;
    background: $grey6;
    padding-top: 1.4rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: -2px -2px 3px inset rgb(0 0 0 / 3%);
    transition: width 150ms;

    &.collapsed {
      width: 0;
      overflow: hidden;
    }

    &.whitelabel {
      @media (min-width: 960px) {
        margin-top: 75px;
        height: calc(100vh - 75px);

        .orgSelect {
          margin-left: -62px;
          width: calc(100% + 62px);
        }
      }
    }

    .top,
    .bottom {
      width: 100%;
    }

    .top {
      flex: 1;
    }

    .orgSelect {
      height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0 1rem;
      min-width: 192px;
    }

    .content {
      max-height: calc(62vh);
      width: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      position: relatve;
      min-width: 192px;

      @media (max-height: 916px) {
        max-height: calc(60vh - 20px);
      }

      @media (min-height: 954px) {
        max-height: calc(60vh + 25px);
      }

      @media (min-height: 1166px) {
        max-height: calc(60vh + 100px);
      }

      @media (min-height: 1266px) {
        max-height: calc(60vh + 200px);
      }

      &.whitelabel {
        @media (max-height: 916px) {
          max-height: calc(60vh - 20px - 75px);
        }

        @media (min-height: 954px) {
          max-height: calc(60vh + 25px - 75px);
        }

        @media (min-height: 1166px) {
          max-height: calc(60vh + 100px - 75px);
        }

        @media (min-height: 1266px) {
          max-height: calc(60vh + 200px - 75px);
        }
      }

      &::-webkit-scrollbar {
        width: 2px;
        background: rgba($grey5, 0.4);
      }

      &::-webkit-scrollbar-thumb {
        background: $grey4;
        border-radius: 2px;
      }
    }

    .section {
      margin: 0 1rem;
      padding-top: 1.4rem;
      margin-top: 1.4rem;
      border-top: 1px solid $grey5;
      width: calc(100% - 2rem);

      &:last-child {
        padding-bottom: 1.3rem;
      }

      .sectionTitle {
        font-size: 11px;
        line-height: 1rem;
        margin-bottom: 0.4rem;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $grey1;
      }

      .navTitle {
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        position: relative;
        overflow: visible;

        &.sticky {
          background-color: $grey6;
          position: sticky;
          top: 0;
          margin-top: -0.5rem;
          padding: 0.5rem 0;
          z-index: 999;
          width: calc(100% + 14px);
          padding-left: 7px;
          margin-left: -7px;
        }

        button p {
          font-size: 14px;
          font-weight: 500;
          z-index: 2;
          color: $black;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .amount {
          margin-left: 0.3rem;
          font-size: 13px;
          color: $grey2;
        }

        &:hover {
          .default:not(.primary),
          .dropdownTitle {
            &::before {
              position: absolute;
              content: "";
              top: 0;
              left: -7px;
              overflow: visible;
              width: calc(100% + 14px);
              height: 100%;
              background-color: $grey5;
              border-radius: $bradius2;
              z-index: -1;
            }
          }

          .active {
            color: $black;
          }
        }

        &.active {
          button {
            position: relative;
            color: $black;

            p {
              z-index: 2;
            }

            &::before {
              position: absolute;
              content: "";
              top: 0;
              left: -7px;
              overflow: visible;
              width: calc(100% + 14px);
              height: 100%;
              background-color: $grey5;
              border-radius: $bradius2;
            }
          }

          &:hover {
            button {
              color: $black;
            }
          }
        }

        &.primary {
          button {
            color: $primary;

            p {
              color: $primary;
            }

            svg path {
              fill: $primary;
            }

            &:hover {
              color: $primary;
            }
          }
        }

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          a {
            width: 100%;
            border: none;
          }

          button {
            width: 100%;
            height: 32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
          }

          .dropdownTitle {
            width: 110px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .default {
            &.bold {
              font-weight: 500;
              color: $black;
              font-size: 14px;
            }
          }
        }

        .dropdownIcon {
          transform: rotate(90deg);
          position: absolute;
          top: 8px;
          right: 0;
          z-index: 2;

          &.down {
            transform: rotate(-90deg);
          }
        }

        .link {
          border: none;
          width: 100%;

          &.activeLink {
            background: none;
            color: $black;
            font-weight: 600;
          }
        }

        .icon {
          margin-right: 0.5rem;
          z-index: 4;
        }

        .dot {
          margin-right: 0.6rem;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $grey2;
          z-index: 999;

          &.green {
            background-color: $green;
          }

          &.primary {
            background-color: $primary;
          }

          &.yellow {
            background-color: $yellow;
          }

          &.red {
            background-color: $red;
          }

          &.blue {
            background-color: $blue2;
          }

          &.purple {
            background-color: $purple;
          }
        }

        .avatar {
          width: 20px;
          height: 20px;
          border-radius: $bradius2;
          margin-right: 12px;
          font-size: 10px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          &.extradarkgrey {
            background-color: $extradarkgrey;
          }

          &.blue4 {
            background-color: $blue4;
          }

          &.blue2 {
            background-color: $blue2;
          }

          &.yellow {
            background-color: $yellow;
          }

          &.primary {
            background-color: $primary;
          }

          &.green {
            background-color: $green;
          }
        }

        .dropdown {
          margin-top: 0.4rem;
          margin-left: 32px;

          .dropdownItem {
            button {
              font-size: 13px;
              font-weight: 400;
              height: auto;
              margin-bottom: 0.4rem;
              padding: 0.2rem 0;
              width: 126px;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              &:hover {
                color: $primary;
              }
            }

            &.active {
              button {
                color: $primary;
              }
            }
          }

          &.largeDropdown {
            margin-left: 0;

            button {
              font-size: 14px;
              padding: 0.3rem 0;
              width: 150px;
            }
          }
        }

        &.pageTitle {
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          min-width: 160px;

          h4 {
            font-size: 22px;
            line-height: 24px;
            font-weight: 600;
            color: black;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .bottom {
      min-width: 192px;

      .section {
        &:last-child {
          border-bottom: 0;
        }

        .myUser {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 3px 0;

          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 15px;
            line-height: 15px;
            font-weight: 500;

            .currentOrg {
              font-size: 13px;
              font-weight: 400;
              color: $grey1;
              margin-top: 2px;
              width: 130px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .ellipsis {
            .dropdownSection {
              border-bottom: 1px solid $grey5;

              &:last-child {
                border-bottom: 0;
              }

              .accountsWrapper {
                border-radius: $bradius;
                max-height: 600px;
                overflow: auto;
              }

              li {
                &.selected {
                  button {
                    font-weight: 500;
                    background-color: $grey6;
                  }
                }
              }

              .avatar {
                width: 22px;
                height: 22px;
                border-radius: $bradius3;
                background: $purple;
                border: none;
                margin-right: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;

                h5 {
                  font-size: 11px;
                  line-height: 11px;
                  font-weight: 500;
                  color: white;
                }

                &.blue {
                  background: $primary;
                }

                &.green {
                  background: $green;
                }

                &.purple {
                  background: $purple;
                }

                &.yellow {
                  background: $yellow;
                }

                &.red {
                  background: $red;
                }
              }
            }
          }
        }

        .notifications {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            padding: 4px;
            background: $red;
            color: white !important;
            font-size: 11px !important;
            line-height: 11px !important;
            font-weight: 500 !important;
            border-radius: 4px;
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }
}
