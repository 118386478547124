@import 'variables.scss';

:local {
  .subtitle {
    margin: 0 auto;
    margin-top: 1rem;
    padding: 0 1rem;
    text-align: center;
    max-width: 620px;
    font-family: proxima-nova;
    color: black;

    @media (max-width: 486px) {
      font-size: 1.2rem;
    }

    &.white {
      color: white;
      text-shadow: $textshadow;
    }

    &.small {
      font-size: 1.4rem;
      line-height: 1.8rem;

      @media (max-width: 486px) {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }

    &.left {
      text-align: left;
      padding: 0;
      margin: 0;
      margin-top: 1rem;
    }

    &.verySmall {
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.8rem;
      color: $black;
      max-width: 620px;
      margin-top: 0.4rem;

      @media (max-width: 486px) {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }
  }
}
