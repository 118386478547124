@import "variables.scss";

:local {
  .modalWrapper {
    padding-top: 1rem;

    .modalHead {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $grey5;

      @media (max-width: 850px) {
        flex-direction: column;
      }

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: -0.4rem;
      }

      .right {
        margin-top: -0.7rem;
        margin-right: 3rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 850px) {
          margin: 0;
          margin-left: 3rem;
        }

        .role {
          margin-right: 1rem;
        }
      }

      .avatar {
        margin-right: 1rem;
      }

      .user {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        h3 {
          font-size: 2rem;
        }

        .info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 0.4rem;

          @media (max-width: 800px) {
            flex-wrap: wrap;

            p {
              margin-bottom: 0.4rem;
            }
          }

          .label {
            font-size: 0.75rem;
            font-weight: 400;
            padding: 0.1rem 0.8rem;
            border: 1px solid $grey5;
            border-radius: $bradius2;
            text-align: center;
            margin-right: 1rem;
            text-transform: capitalize;
          }

          .email {
            color: $grey1;
            font-size: 14px;
            margin-right: 1rem;
          }

          .profileComplete {
            color: $grey1;
            font-size: 14px;

            @media (max-width: 800px) {
              // margin-left: 0;
            }
          }
        }
      }
    }

    .content {
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;

        .dropdown {
          max-width: 200px;
          display: flex;
          justify-content: flex-end;
        }

        h4 {
          font-size: 18px;
          font-weight: 500;
        }
      }

      .column {
        border: 1px solid $grey5;
        border-radius: $bradius3;
        margin-bottom: 3rem;
        box-shadow: $lightboxshadow;

        &.empty {
          border: none;
          background: $grey6;
        }

        &.emptyPos {
          border: none;
          box-shadow: none;
          padding: 0;

          .emptyState {
            padding: 0;
          }
        }

        .emptyState {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 3rem 0;

          h5 {
            font-size: 16px;
            margin-bottom: 0.6rem;
          }

          p {
            font-size: 16px;
            color: $grey1;
          }

          .inviteRow {
            background: $grey6;
            border-radius: $bradius3;
            margin-bottom: 0.6rem;
            width: 100%;
            padding: 1rem;

            &:last-child {
              margin-bottom: 0;
              opacity: 0.5;
            }
          }
        }

        a {
          &:last-child {
            .row {
              border-bottom: none;
            }
          }
        }

        .row {
          border-bottom: 1px solid $grey5;
          padding: 1.2rem 1.8rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: $transition;

          &:hover {
            box-shadow: $lightboxshadow;
          }

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              margin-right: 1.4rem;
              width: 32px;
              height: 32px;
              background: $blue;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $primary;
              font-size: 11px;
              font-weight: 500;
              text-transform: uppercase;

              &.green {
                background: rgba($green, 0.2);
                color: $green;
              }

              &.purple {
                background: rgba($purple, 0.2);
                color: rgba($purple, 0.7);
              }

              &.yellow {
                background: rgba($yellow, 0.2);
                color: $yellow;
              }
            }

            .info {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;

              h4 {
                font-size: 15px;
              }

              p {
                font-size: 14px;
                color: $grey1;
              }
            }
          }

          .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            i {
              color: black;
              margin-left: 1.4rem;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
