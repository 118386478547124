@import "variables.scss";
@import "logos.scss";

:local {
  .labelActive {
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: -7px;
      overflow: visible;
      width: calc(100% + 14px);
      height: 100%;
      background-color: $grey5;
      border-radius: $bradius2;
      z-index: -1;
    }
  }

  .wrap {
    display: flex;
    position: relative;

    .logo.mobile {
      a {
        border: none;
      }

      .clientLogo {
        @include logo;
      }
    }

    .mobileHeader {
      background: white;
      width: 100%;
      border-bottom: 1px solid $grey5;
      min-width: 100vw;
      max-width: 100vw;
      padding: 0.4rem 1rem;
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999999;
    }

    .burgerMenu {
      height: 40px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 200ms;
      border-radius: 50%;

      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .line {
          width: 100%;
          height: 2px;
          border-radius: 4px;
          background-color: $black;
          margin-bottom: 6px;
          transition: 200ms;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.open {
        button {
          .line {
            width: 26px;

            &:first-child {
              transform: rotate(-45deg);
            }

            &:last-child {
              transform: rotate(45deg);
              margin-top: -8px;
            }
          }
        }
      }
    }

    &.sidebarCollapsed {
      .main {
        border-right: 0.5px solid $grey5;
      }
    }
  }

  .main {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: sticky;
    top: 0;
    z-index: 9994;
    max-width: 100%;
    transform: 500ms;

    @media (min-width: 1720px) {
      position: fixed;
    }

    @media (max-width: 960px) {
      z-index: 999999;
    }

    .collapseBtn {
      position: absolute;
      top: calc(50% - 13px);
      right: -12px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      box-shadow: 0px 22px 24px rgba(153, 153, 153, 0.0957314),
        0px 0px 5px rgba(152, 152, 152, 0.115);
      transition: $transition;
      z-index: 9000;

      @media (max-width: 960px) {
        display: none;
      }

      button {
        width: 100%;
        height: 100%;
      }

      &:hover {
        background: rgba($primary, 0.9);
      }

      svg {
        transform: rotate(180deg);

        path {
          stroke: white;
        }
      }

      &.collapsed {
        svg {
          transform: rotate(0deg);
        }
      }
    }

    .overLay {
      position: fixed;
      top: 50px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: -1;
    }

    &.mobile {
      display: none;

      @media (max-width: 960px) {
        position: fixed;
        top: 0;
        left: 0;
      }

      &.open {
        display: flex;
        box-shadow: 0 0px 48px rgba(0, 0, 0, 0.15);
      }
    }

    .whitelabelLogo {
      position: absolute;
      height: 75px;
      width: 256px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 1rem;
      background-color: white;
      box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);

      .clientLogo {
        @include logo;
      }
    }

    .left {
      width: 64px;
      height: 100%;
      min-height: 100vh;
      background: white;
      padding: 1rem;
      padding-top: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 22px 24px rgba(153, 153, 153, 0.0957314),
        0px 0px 5px rgba(152, 152, 152, 0.115);

      .top,
      .bottom {
        width: 100%;
      }

      .top {
        @media (min-width: 960px) {
          &.whitelabel {
            margin-top: 75px;
          }
        }
      }

      .logo {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 300ms ease-in-out;

        &:hover {
          transform: rotate(45deg);
        }

        a {
          border: none;
        }
      }

      .section {
        padding-top: 1.4rem;
        margin-top: 1.4rem;
        border-top: 1px solid $grey5;

        .avatarDropdown {
          a {
            border: none;
          }
        }

        .button {
          margin-bottom: 0.5rem;
          border-radius: $bradius3;
          position: relative;

          .kanbanIcon {
            svg {
              rect {
                fill: $grey1;
                opacity: 1;
              }
            }
          }

          &:hover {
            background: $grey5;

            svg path {
              fill: $black;
            }

            .kanbanIcon {
              svg {
                rect {
                  fill: $black;
                }
              }
            }

            &.learningIcon {
              svg path {
                fill: $primary;
              }
            }

            .tooltip {
              opacity: 1;
              transition-delay: 500ms;
            }
          }

          .tooltip {
            opacity: 0;
            display: flex;
            position: absolute;
            top: 3px;
            left: calc(100% + 0.8rem);
            padding: 6px 12px;
            background-color: rgba($darkgrey, 1);
            justify-content: flex-start;
            align-items: center;
            border-radius: $bradius;
            z-index: 99999;
            transition-delay: 0s opacity;
            pointer-events: none;

            &::before {
              content: "";
              position: absolute;
              left: -6px;
              width: 0;
              height: 0;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-right: 6px solid rgba($darkgrey, 1);
            }

            p {
              color: white;
              font-size: 14px;
              line-height: 14px;
              white-space: nowrap;
            }
          }

          .avatar img {
            cursor: pointer;
            border: 1px solid transparent;
            padding: 2px;
            transition: $transition;
            width: 32px;
            height: 32px;

            &:hover {
              border: 1px solid $black;
            }
          }

          &.active {
            background: $grey5;

            svg path {
              fill: $black;
            }

            .kanbanIcon {
              svg {
                rect {
                  fill: $black;
                  opacity: 1;

                  &:nth-child(2) {
                    fill: $black;
                  }
                }
              }
            }
          }

          button {
            height: 32px;
            width: 32px;

            svg path {
              fill: $grey1;
            }

            .clipboard {
              svg path {
                &:nth-child(3) {
                  fill: white;
                }
                &:nth-child(4) {
                  fill: white;
                }
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:last-child {
          padding-bottom: 1.4rem;
          border-bottom: 1px solid $grey5;
        }
      }

      .bottom {
        .section {
          &:last-child {
            border-bottom: 0;
          }

          .notifications {
            position: relative;

            .notificationBell {
              height: 32px;
              width: 32px;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border-radius: $bradius3;

              svg path {
                fill: $grey1;
              }

              &:hover {
                svg path {
                  fill: $black;
                }
              }

              .bellDot {
                width: 6px;
                height: 6px;
                background: $red;
                border-radius: 50%;
                position: absolute;
                top: -3px;
                right: -3px;
              }

              &:hover {
                background: $grey5;
              }

              &.active {
                background: $grey5;

                svg path {
                  fill: $black;
                }
              }
            }
          }
        }
      }
    }

    .avatarWrapper {
      border: 1px solid transparent;
      border-radius: 50%;
      padding: 2px;
      transition: $transition;

      &:hover {
        border: 1px solid $darkgrey;
      }
    }

    .right {
      width: 192px;
      height: 100vh;
      background: $grey6;
      padding: 1rem;
      padding-top: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .top,
      .bottom {
        width: 100%;
      }

      .orgSelect {
        height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
      }

      .section {
        padding-top: 1.4rem;
        margin-top: 1.4rem;
        border-top: 1px solid $grey5;
        width: 100%;

        &:last-child {
          padding-bottom: 1.4rem;
          border-bottom: 1px solid $grey5;
        }

        .navTitle {
          margin-bottom: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          button {
            width: 100%;
            height: 32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
          }

          &.dashboard {
            button {
              font-size: 22px;
              font-weight: 600;
              color: black;
            }
          }

          &:hover {
            button {
              color: $black;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .bottom {
        .section {
          &:last-child {
            border-bottom: 0;
          }

          .myUser {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            line-height: 15px;

            span {
              font-size: 13px;
              font-weight: 400;
              color: $grey1;
              margin-top: 2px;
            }
          }

          .notifications {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            span {
              padding: 4px;
              background: $red;
              color: white !important;
              font-size: 11px !important;
              line-height: 11px !important;
              font-weight: 500 !important;
              border-radius: 4px;
              height: 16px;
              width: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
