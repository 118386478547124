@import "variables.scss";

:local {
  .attachmentForm {
    margin-top: 2rem;

    .inputGroup {
      margin-bottom: 3.6rem;

      &:last-child {
        margin-bottom: 0;
      }

      .inputTitle {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-bottom: 0.2rem;
      }

      .inputDescription {
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        color: $darkgrey;
        margin-bottom: 0.8rem;
      }

      .multiselect {
        input {
          margin-top: 0;

          &::placeholder {
            font-size: 15px;
            line-height: 15px;
          }
        }
      }

      .textarea {
        border-radius: $bradius;
        padding: 0.6rem 1rem;
        width: 100%;
        font-family: proxima-nova;
        font-size: 0.95rem;
        transition: $transition;
        resize: none;

        &::placeholder {
          font-size: 0.9rem;
          font-weight: 400;
          color: $grey1;
        }

        &.active {
          border-bottom: 1px solid white;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
