@import "variables.scss";
@import "logos.scss";

:local {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 1rem 0;
    height: 30px;

    .backButton {
      position: absolute;
      top: 1.4rem;
      left: 2rem;
      z-index: 1000;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        font-size: 0.9rem;
        color: $grey1;
        padding: 0.6rem 0;

        .icon {
          transform: rotate(180deg);
          margin-right: 0.8rem;

          svg {
            transform: scale(1.5);
          }

          svg path {
            fill: $grey1;
          }
        }

        &:hover {
          color: $darkgrey;

          .icon {
            svg path {
              fill: $darkgrey;
            }
          }
        }
      }
    }

    .middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;

      p {
        color: $grey1;
        font-weight: 400;
        font-size: 0.9rem;
      }

      .progressBar {
        position: relative;
        width: 100%;
        max-width: 48px;
        height: 3px;
        border-radius: 1rem;
        background-color: $grey4;
        margin-top: 0.4rem;

        .progress {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          background-color: $grey1;
          height: 100%;
          border-radius: 1rem;
        }
      }
    }
  }
}
