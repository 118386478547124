@import "variables.scss";

:local {
  .wrapper {
    display: grid;
    grid-template-columns: minmax(600px, 40%) 1fr;
    position: relative;

    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      min-height: 100vh;
      overflow: hidden;
      padding: 4rem 8rem;

      @media (max-width: 1300px) {
        padding: 4rem;
      }

      @media (max-width: 1080px) {
        padding: 8rem 4rem;
        max-width: 100%;
        width: 100%;
      }

      @media (max-width: 550px) {
        padding: 4rem 2rem;
        padding-top: 6rem;
        min-height: 52vh;
      }

      .title {
        font-weight: 700;
        max-width: 300px;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 2.4rem;

        @media (max-width: 1080px) {
          font-size: 36px;
          line-height: 42px;
          margin-bottom: 1.6rem;
        }
      }

      .subtitle {
        color: $grey1;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 3rem;
        max-width: 500px;

        @media (max-width: 1080px) {
          font-size: 0.9rem;
        }
      }

      .avatarSection {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.6rem;
        position: relative;

        @media (max-width: 442px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .avatar {
          margin-right: 1.6rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          img,
          div[aria-label="initials"] {
            width: 72px;
            height: 72px;
          }

          @media (max-width: 442px) {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }

        .avatarButton {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          cursor: pointer;

          p {
            margin-top: 0.2rem;
            font-size: 14px;
            color: $grey1;
            font-weight: 300;
          }
        }

        .filedrop {
          position: absolute;
          width: 108%;
          height: 130%;

          &.isDragging {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 999;
            background: $grey6;
            border: 2px dashed $grey3;
            border-radius: $bradius3;

            &:after {
              content: "Drop file here";
              color: $darkgrey;
              font-size: 0.9rem;
              font-weight: 500;
            }
          }
        }

        .fileInput {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }

      .form {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid $grey5;
        width: 100%;
        max-width: 450px;

        select {
          width: 100%;
          height: 48px;
          box-shadow: none;
        }

        .row {
          display: flex;
          width: 100%;
          margin-bottom: 1rem;

          @media (max-width: 475px) {
            flex-direction: column;
          }

          .column {
            width: 50%;

            @media (max-width: 475px) {
              width: 100%;
            }

            &:first-child {
              margin-right: 2rem;

              @media (max-width: 475px) {
                margin-right: 0;
                margin-bottom: 1rem;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            &.single {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }

      .buttons {
        margin-top: 3rem;
      }
    }

    .right {
      background: #e3f7eb;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &.green {
        background: #e3f7eb;
      }

      &.yellow {
        background: #fcf6ef;
      }

      &.blue {
        background: #eff9ff;
      }

      @media (max-width: 1080px) {
        width: 100%;
        height: 300px;
      }

      .img {
        z-index: 1000;

        img {
          height: 500px;

          @media (max-width: 1080px) {
            height: 200px;
          }
        }
      }
    }
  }
}
