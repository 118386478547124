@import "variables.scss";

:local {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .main {
    .content {
      // width: 80%;

      @media (max-width: 1080px) {
        width: 100%;
      }

      .subtitle {
        max-width: 90%;
        margin: 1rem 0;
        font-weight: 300;
        font-size: 1.1rem;
        line-height: 1.6rem;
        list-style-type: initial;
      }

      .avatarsWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $grey4;
        padding-bottom: 2rem;
        margin-bottom: 2rem;

        .avatar {
          margin-right: 0.6rem;
          position: relative;

          &:last-child {
            margin-right: 0;
          }

          .completeIcon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 15px;
            height: 15px;
            background: $green;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
              color: white;
              font-size: 0.5rem;
              margin-top: 2px;
            }
          }
        }
      }

      .containerWrapper {
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &:last-child {
          margin-bottom: 0;
        }

        .row {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 1rem;

          .col {
            &:first-child {
              margin-right: 1rem;
            }

            &:nth-child(2) {
              width: 100%;
            }

            &:last-child {
              width: 20%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;

              i {
                color: $grey3;
              }

              button {
                padding: 0 !important;
              }
            }

            .text {
              width: 100%;
            }

            .number {
              width: 30px;
              height: 30px;
              border-radius: $bradius;
              display: flex;
              justify-content: center;
              align-items: center;
              background: $primary;
              font-size: 0.9rem;
              font-weight: 500;
              color: white;
            }

            input {
              border: 0;
              width: 100%;
              height: 30px;
              text-indent: 0;

              &.title {
                font-weight: 500;

                &::placeholder {
                  font-weight: 400;
                  color: rgba($black, 0.5);
                }
              }
            }

            textarea {
              width: 100%;
              margin-top: 0.4rem;

              &.text {
                font-family: proxima-nova;
                font-size: 1rem;
                color: $black;
                font-weight: 300;
                border: 0;
                width: 100%;

                &::placeholder {
                  font-weight: 300;
                  color: rgba($black, 0.5);
                }
              }
            }

            .buttons {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-left: 2rem;

              button {
                padding: 0 !important;

                &:first-child {
                  margin-right: 1rem;
                }
              }
            }
          }
        }

        &.open {
        }

        &.closed {
          border: none;
          cursor: pointer;

          .row {
            align-items: center;
          }

          .col {
            .number {
              background: $blue;
              color: $primary;
            }

            input,
            textarea {
              pointer-events: none;
            }
          }
        }

        &.add {
          .row {
            align-items: center;
            min-height: 62px;
            padding: 0;

            button {
              width: 100%;
              height: 62px;
              padding: 0 !important;
              text-align: left;
              justify-content: flex-start;
              text-indent: 1.2rem;
            }
          }
        }
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;

      button:first-child {
        margin-right: 1rem;
      }
    }
  }
}
