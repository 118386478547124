@import "variables.scss";

:local {
  option[disabled] {
    display: none;
  }

  .row {
    height: 60px;
    border: 1px solid $grey5;
    background: white;
    width: 100%;

    &:hover {
      .lastCol {
        button {
          opacity: 1;
        }
      }
    }

    td {
      vertical-align: middle;
    }

    &:hover {
      background: rgba($grey5, 0.2);
    }

    .avatarCol {
      display: flex;
      height: 60px;
      align-items: center;
      padding-left: 1rem;

      .avatar {
        margin-right: 1rem;
      }

      .nameAndEmail {
        // width: 30%;

        .name {
          font-weight: 600;
          font-size: 0.9rem;
          line-height: 1.2rem;
          max-width: 500px;
          min-width: 230px;
          color: $black;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 220px;
        }

        .email {
          color: $darkgrey;
          font-size: 0.8rem;
          font-weight: 300;
          line-height: 1.2rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 220px;
        }
      }
    }

    .organizations {
      width: 35%;
      padding-right: 4rem;

      p {
        font-size: 0.9rem;
        color: $darkgrey;
        font-weight: 300;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }

    .state {
      padding-right: 4rem;
      width: 10%;
      position: relative;

      .stateFlex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .tooltipPopover {
        width: 118px !important;
        text-align: center;

        span {
          width: 100%;
          text-align: center;
        }
      }

      .shareLink {
        margin-left: 0.6rem;

        svg path {
          transition: $transition;
        }

        &:hover {
          svg path {
            fill: $grey1;
          }
        }
      }

      p {
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0.1rem 0.8rem;
        border: 1px solid $grey5;
        border-radius: $bradius2;
        text-align: center;
      }

      &.active {
        color: black;
      }

      &.invite {
        p {
          color: $yellow;
          border-color: $yellow;
        }
      }
    }

    .guest {
      width: 20%;
      cursor: default;

      p {
        font-size: 13px;
        line-height: 0.9rem;
        font-weight: 300;
        text-transform: capitalize;
        color: $darkgrey;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          height: 16px;
          margin-top: -2px;
        }

        svg path {
          fill: $grey1;
        }
      }

      .dropdown {
        margin-top: 1rem;
      }

      .guestPopup {
        max-width: 180px;
        padding: 0.8rem;
        font-size: 0.85rem;
        line-height: 1.2rem;
        font-weight: 300;
        color: $darkgrey;
        text-transform: initial;
      }
    }

    .role {
      width: 10%;

      p {
        font-size: 0.75rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    .lastCol {
      width: 8%;
      vertical-align: middle;
      padding: 0 2rem;

      button {
        float: right;
        opacity: 0;
      }
    }
  }
}
