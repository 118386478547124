@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    border: 1px solid $grey5;
    border-radius: $bradius3;
    min-height: 248px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    @media (max-width: 1212px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .left {
      width: 60%;
      margin-right: 4rem;

      @media (max-width: 1320px) {
        width: 60%;
      }

      @media (max-width: 1212px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 1.6rem;
        line-height: 2rem;
        color: $extradarkgrey;
        margin-bottom: 0.6rem;

        @media (max-width: 768px) {
          font-size: 28px;
          line-height: 32px;
        }
      }

      p {
        font-size: 15px;
        line-height: 24px;
        font-weight: 300;
        color: $grey1;
      }

      button {
        margin-top: 1rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 40%;

      @media (max-width: 1212px) {
        width: 100%;
      }

      .listItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $bradius3;
          background-color: $green;
          margin-right: 1rem;

          svg path {
            fill: $extradarkgrey;
          }
        }

        p {
          color: $grey1;
          font-size: 14px;
          line-height: 14px;
          font-weight: 300;
        }

        &:nth-child(2) {
          .icon {
            background-color: #80d2e5;
          }
        }

        &:nth-child(3) {
          .icon {
            background-color: $yellow;
          }
        }
      }
    }
  }
}
