@import "variables.scss";

:local {
  .separator {
    display: block;
    width: calc(100% - 0.8rem);
    height: 0.5px;
    background: $grey4;
    margin: 0.4rem;
  }
}
