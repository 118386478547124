@import "variables.scss";

:local {
  .wrapper {
    width: 100%;

    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 5rem 0;
      width: 100%;

      @media (max-width: 1360px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .left {
        width: 50%;
        margin-right: 2rem;

        @media (max-width: 1360px) {
          margin-right: 0;
          margin-bottom: 2rem;
          width: 100%;
        }
      }

      .right {
        flex: 1;
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .summaryHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 600px) {
          margin-bottom: 1rem;
        }

        .aiPopup {
          max-width: 400px;
          padding: 1rem 1.2rem;

          p {
            font-size: 14px;
            line-height: 22px;
            color: $darkgrey;
          }
        }
      }

      .buttonGroup {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .generateButton {
          z-index: 1;
          margin-right: 1rem;

          .loadingIcon {
            i {
              animation-name: spin;
              animation-duration: 1200ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
            }
          }

          &.generating {
            cursor: not-allowed;

            button {
              pointer-events: none;
              cursor: not-allowed;
            }
          }
        }
      }

      .generateButton {
        z-index: 1;

        .loadingIcon {
          i {
            animation-name: spin;
            animation-duration: 1200ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        }

        &.generating {
          cursor: not-allowed;

          button {
            pointer-events: none;
            cursor: not-allowed;
          }
        }
      }
    }

    .contentWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &.generating {
        min-height: 200px;
      }

      .loaderBar {
        position: absolute;
        max-width: 450px;
        width: 100%;
        margin: 0 auto;
        margin-top: -8rem;
        z-index: 2;
        padding: 1.4rem;
        background: rgba(255, 255, 255, 1);
        border-radius: $bradius2;
        border: 1px solid $grey5;

        &.firstTime {
          margin-top: 0rem;
        }

        .text {
          width: 155px;
          margin: 0 auto;

          .loadingText {
            font-size: 15px;
            line-height: 18px;
            text-align: left;
            margin-top: 1rem;
            color: $darkgrey;

            &:after {
              overflow: hidden;
              display: inline-block;
              vertical-align: bottom;
              animation: dotty steps(1, end) 1.5s infinite;
              content: "";
            }

            @keyframes dotty {
              0% {
                content: "";
              }
              25% {
                content: ".";
              }
              50% {
                content: "..";
              }
              75% {
                content: "...";
              }
              100% {
                content: "";
              }
            }
          }
        }
      }

      .content {
        position: relative;
        margin-bottom: 1rem;
        column-count: 2;
        column-gap: 3rem;
        transition: 400ms ease-in-out;

        &.generating {
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(white, 0.7);
            backdrop-filter: blur(3px);
          }
        }

        @media (max-width: 1220px) {
          column-count: 1;
        }

        p {
          font-size: 15px;
          line-height: 32px;
          margin: 1rem 0;

          .textLabel {
            font-size: 11.5px;
            line-height: 11.5px;
            letter-spacing: 0.5px;
            font-weight: 500;
            padding: 0.2rem 0.6rem;
            border: 1px solid $grey2;
            border-radius: 25px;
            background-color: $grey6;
            color: $grey2;
            text-transform: uppercase;
            margin: 0 0.1rem;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            white-space: nowrap;

            &:hover {
              background-color: $grey2;
              color: white;
            }

            &.green {
              border-color: $green;
              background-color: $lightgreen;
              color: $green;
              &:hover {
                background-color: $green;
                color: white;
              }
            }

            &.blue {
              border-color: $primary;
              background-color: $blue;
              color: $primary;
              &:hover {
                background-color: $primary;
                color: white;
              }
            }

            &.yellow {
              border-color: $yellow;
              background-color: $lightorange;
              color: $yellow;
              &:hover {
                background-color: $yellow;
                color: white;
              }
            }

            &.red {
              border-color: $red;
              background-color: $lightred;
              color: $red;
              &:hover {
                background-color: $red;
                color: white;
              }
            }
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
