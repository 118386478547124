@import "variables.scss";

:local {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .popupWrapper {
    .popup-arrow {
      background: rgba(black, 1) !important;
    }
  }

  button i {
    margin-right: 0.6rem;
  }

  .loading i {
    animation-name: spin;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: $primary !important;
  }

  .button {
    button {
      display: flex;
      justify-content: center;
    }

    &.primary {
      button {
        color: $primary;

        svg path {
          fill: $primary;
        }

        &:hover {
          color: $primary;

          svg path {
            fill: $primary;
          }
        }
      }
    }
  }
}
