@import "variables.scss";

:local {
  .wrapper {
    max-width: 100%;
    width: 100%;
    padding: 0;
    position: relative;

    .notificationsWrapper {
      max-height: 400px;
      width: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        background: rgba($grey5, 0.4);
      }

      &::-webkit-scrollbar-thumb {
        background: $grey4;
        border-radius: 2px;
      }
    }

    .none {
      font-size: 0.9rem;
      font-weight: 400;
      color: $grey3;
      margin: 0 auto;
      padding: 1rem 0;
    }

    button {
      width: 100%;
    }

    .invite {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.8rem;
      cursor: pointer;
      border: 1px solid $grey5;
      border-radius: $bradius2;
      width: 100%;

      &:hover {
        border-color: $grey3;
      }

      .icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.3rem;
        margin-left: 0.3rem;

        svg {
          width: 14px;
          height: 14px;
        }

        svg path {
          fill: $primary;
        }
      }

      .orgName {
        color: $primary;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.4rem;
      }

      .text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: $black;
      }
    }
  }
}
