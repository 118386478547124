@import "variables.scss";

:local {
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: white;
    padding: 1rem;
    border: 1px solid $grey5;
    border-radius: $bradius3;
    box-shadow: 0px 2px 2px 0px #00000008;

    .avatarWrapper {
      margin-right: 1.4rem;
    }

    .inputWrapper {
      textarea {
        position: relative;
        font-family: proxima-nova;
        font-size: 15px;
        resize: none;
        border: none;
        max-width: 100%;
        width: 100%;
        max-height: 90px;
        height: 90px;

        &::placeholder {
          color: $grey2;
          font-size: 15px;
        }
      }
    }

    .buttons {
      margin-top: 0.6rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        margin: 0;
        margin-right: 0.8rem;
        padding: 0 !important;
        font-size: 15px;

        &:hover {
          background: none;
        }
      }
    }

    .bottomText {
      p {
        color: $grey1;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
}
