@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 560px) {
      transform: scale(0.8);

      @media (max-width: 415px) {
        transform: scale(0.6);
      }
    }

    .separate {
      height: 80px;
      width: 1px;
      background-color: $grey4;
      margin: 0 1rem 0 1rem;
    }

    button {
      background-color: $grey4;
      margin: 0.3rem;
      border-radius: 100%;
      transition: $transition;
      border: none;
      cursor: pointer;

      &.dotL {
        width: 5.2rem;
        height: 5.2rem;
        &.hover,
        &:hover {
          background-color: $primary4;
        }

        @media (max-width: 1024px) {
          &:hover {
            background-color: $grey4;
          }
        }
      }

      &.dotM {
        width: 4rem;
        height: 4rem;
        &.hover,
        &:hover {
          background-color: $primary;
        }

        @media (max-width: 1024px) {
          &:hover {
            background-color: $grey4;
          }
        }
      }

      &.dotS {
        width: 3rem;
        height: 3rem;
        &.hover,
        &:hover {
          background-color: lighten($primary, 5%);
        }

        @media (max-width: 1024px) {
          &:hover {
            background-color: $grey4;
          }
        }
      }
    }
  }
}
