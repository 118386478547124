@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: $wrapperWidth;
    width: 100%;

    .topWrapper {
      width: 100%;
      margin-bottom: 2rem;

      .name {
        color: $grey2;
      }
    }

    .left {
      width: 50%;
      margin-right: 4rem;

      @media (max-width: 600px) {
        width: 100%;
      }

      .nameChange {
        margin-bottom: 2rem;
        padding-bottom: 4rem;
        border-bottom: 1px solid $grey5;

        .input {
          margin-bottom: 1.5rem;

          .label {
            margin-bottom: 0.6rem;
            font-size: 15px;
            font-weight: 500;
            color: black;
          }
        }
      }

      .deleteOrg {
        border: 1px solid $grey5;
        border-radius: $bradius3;
        padding: 1.4rem;

        .title {
          font-size: 1rem;
          color: black;
          margin-bottom: 0.8rem;
        }

        p {
          max-width: 80%;
          font-size: 0.9rem;
          line-height: 1.4rem;
          font-weight: 300;
          color: $darkgrey;
        }

        button {
          margin-top: 1rem;
        }
      }
    }

    .right {
      margin-top: 2rem;
      background: $grey6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      width: 50%;
      min-height: 300px;
      border-radius: $bradius3;

      h5 {
        margin-bottom: 1rem;
      }

      p {
        font-size: 15px;
      }
    }
  }
}
