@import 'variables.scss';

:local {
  .notificationBar {
    width: 100%;
    background: #e03a3a;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: $boxshadow;

    .wrapper {
      max-width: $wrapperWidth;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 1rem 0;

      .close {
        position: absolute;
        top: 1rem;
        right: 1.6rem;
        text-shadow: $textshadow;

        i {
          color: white;
          font-size: 1.6rem;
        }
      }

      .infoTitle {
        width: 100%;
        color: white;
        font-size: 1.4rem;
        text-align: left;
        margin-bottom: 0.6rem;

        i {
          margin-right: 1rem;
        }
      }

      .infoText {
        width: 100%;
        color: white;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;

        a {
          font-size: 1rem;
          border-color: white;
          color: white;
          margin-top: 1rem;
        }
      }

      .links {
        a {
          font-size: 1rem;
          border-color: white;
          color: white;
          margin-top: 1rem;
        }
      }
    }
  }
}
