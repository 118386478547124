@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: $wrapperWidth;
    width: 100%;

    .topWrapper {
      width: 100%;
      margin-bottom: 2rem;

      .name {
        color: $grey2;
      }
    }

    @media (max-width: 1150px) {
      padding: 0 1rem;
    }

    @media (max-width: 830px) {
      flex-direction: column;
      align-items: center;
    }

    .bottomWrapper {
      display: flex;
    }

    .left {
      width: 50%;
      max-width: 350px;
      min-height: 400px;
      margin-right: 2rem;
      background: $grey6;
      border-radius: $bradius3;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 830px) {
        width: 100%;
        max-width: 100%;
        margin: 0;
        margin-bottom: 2rem;
      }

      .icon {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: $blue2;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        svg {
          path {
            fill: white;
          }
        }
      }

      .seats {
        margin-bottom: 0.4rem;
      }

      .usedBar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .used {
          font-weight: 400;
          color: $grey2;
        }

        .bar {
          width: 80%;
          height: 4px;
          border-radius: $bradius2;
          background: $grey2;
          margin-bottom: 1rem;

          .filler {
            background: $primary;
            height: 4px;
            border-radius: $bradius2;
          }
        }
      }

      .bottomText {
        max-width: 85%;

        p {
          color: $grey1;
          text-align: center;
          font-weight: 300;
        }
      }

      .bottomButtons {
        margin-top: 1rem;
      }

      .buttonGroup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;

        button:first-child {
          margin-bottom: 1rem;
        }

        button:last-child {
          font-size: 0.9rem;
          color: $grey1;
        }
      }
    }

    .right {
      background: $grey6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      width: 100%;
      min-height: 400px;
      border-radius: $bradius3;

      .icon {
        margin-bottom: 1rem;

        i {
          font-size: 1.8rem;
          color: black;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h5 {
          margin-bottom: 1rem;
          font-size: 1.1rem;
        }

        p {
          text-align: center;
          max-width: 70%;
          margin-bottom: 1rem;
          color: $darkgrey;
          font-weight: 300;

          a {
            margin-left: 0.3rem;
          }
        }

        button {
          margin-top: 1rem;
        }
      }
    }

    .contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.6rem;

      h4 {
        font-size: 1.1rem;
        margin-bottom: 0.6rem;
      }

      .name,
      .email {
        font-size: 15px;
        line-height: 15px;
      }

      .email {
        color: $grey1;
        margin-top: 0.4rem;

        a {
          text-decoration: none;
          border: none;
          transition: $transition;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .owner {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.4rem;

      a {
        border: none;
      }

      button {
        font-size: 0.9rem;
        color: $grey1;

        &:hover {
          color: $black;
        }
      }

      h5 {
        margin-bottom: 1rem;
      }

      .user {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1rem;
        padding: 1rem;
        border: 1px solid $grey5;
        border-radius: $bradius2;
        background: white;
        transition: $transition;

        &:hover {
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

          .email {
            a {
              color: $primary;
            }
          }
        }

        .avatar {
          margin-right: 0.6rem;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .name {
            font-size: 0.9rem;
            line-height: 0.9rem;
            margin-bottom: 0.2rem;
          }

          .email {
            font-size: 0.9rem;
            line-height: 0.9rem;
            color: $grey1;
            font-weight: 300;

            a {
              text-decoration: none;
              border: none;
            }
          }
        }
      }
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        margin-top: 0.6rem;

        p {
          font-size: 0.9rem;
          color: $grey1;
        }
      }
    }
  }
}
