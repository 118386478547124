@import 'variables.scss';

:local {
  .modalWrapper {
    max-width: $modalWrapper;
    margin: 0 auto;

    .modalHead {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-top: 1rem;
      margin-bottom: 3rem;

      p {
        font-size: 18px;
        line-height: 26px;
        color: $darkgrey;
        font-weight: 300;
        margin-top: 1rem;
        max-width: 70%;
      }
    }

    .adminsWrapper {
      margin-bottom: 2rem;

      h4 {
        margin-bottom: 0.4rem;
      }

      .lastCol {
        margin-left: 1rem;
      }
    }

    .formWrapper {
      margin: 0.5rem 0;
      margin-bottom: 2rem;
    }
  }
}
