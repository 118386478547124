@import "variables.scss";

:local {
  .insideWrapper {
    max-width: 259px;
  }

  .imageWrapper {
    min-height: 150px;
    padding-top: 1.2rem;
    padding-bottom: 1.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5,
    p {
      max-width: 90%;
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.4rem;
    }

    h5 {
      color: black;
      margin-top: 0.2rem;
    }

    p {
      font-size: 0.8rem;
      line-height: 1.2rem;
      margin-top: 0.2rem;
      color: $grey1;
      font-weight: 300;
    }
  }

  .image {
    background-image: url("~images/Support.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 120px;
    width: 140px;
  }

  .icon {
    svg {
      width: 22px;
      height: 22px;

      path {
        fill: $darkgrey;
      }
    }
  }

  .helpDropdown {
    width: 260px;

    li {
      border-top: 1px solid $grey5;

      button,
      a {
        font-size: 0.9rem;
        line-height: 1.4rem;
        margin: 0;
        padding: 0.8rem 1rem;
        color: black;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // border-top: 1px solid $grey5;

        &:hover {
          color: $black;
          background: rgba($grey5, 0.2);
        }

        svg {
          margin-right: 0.8rem !important;
        }
      }
    }
  }
}
