@import "variables.scss";

:local {
  .button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    padding: 0;
    border: none;
    background: none;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
    color: rgba($black, 0.8);
    transition: $transition;
    box-shadow: none;

    &:hover {
      background: rgba($grey5, 0.7);
      // box-shadow: $lightboxshadow;
      color: $black;
    }

    i {
      margin: 0;
      margin-left: 0px;
      margin-top: 1px;
      font-size: 1rem;
    }

    &.large {
      height: 32px;
      width: 32px;

      i {
        margin: 0;
        margin-left: 1px;
        margin-top: 2px;
        font-size: 1.2rem;
      }
    }

    &.extralarge {
      height: 48px;
      width: 48px;

      i {
        margin: 0;
        margin-left: 1px;
        margin-top: 1px;
        font-size: 1.4rem;
        color: black;
      }
    }
  }
}
