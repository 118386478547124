@import "variables.scss";

:local {
  .avatarDropdown {
    z-index: 99999;
    min-width: 220px;
    width: 100%;
    max-height: 520px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.2rem;

    a {
      border: none;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background: rgba($grey5, 0.4);
    }

    &::-webkit-scrollbar-thumb {
      background: $grey4;
      border-radius: 2px;
    }

    .dropdownSection {
      width: 100%;
      border-bottom: 0.5px solid $grey4;
      padding: 0.2rem 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0rem;
      }

      &.noBorder {
        border-bottom: none;
      }

      .sectionTitle {
        padding: 0.2rem 1rem;
        padding-top: 0.6rem;
        padding-bottom: 0.4rem;
        font-size: 11px;
        line-height: 11px;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        color: $grey1;
      }

      .accountsWrapper {
        border-radius: $bradius;
        max-height: 600px;
        overflow: auto;
      }

      li {
        &.selected {
          button {
            font-weight: 600;
            background-color: $grey6;
          }
        }
      }

      .avatar {
        width: 22px;
        height: 22px;
        border-radius: $bradius3;
        background: $purple;
        border: none;
        margin-right: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        h5 {
          font-size: 11px;
          line-height: 11px;
          font-weight: 500;
          color: white;
        }

        &.blue {
          background: $primary;
        }

        &.green {
          background: $green;
        }

        &.purple {
          background: $purple;
        }

        &.yellow {
          background: $yellow;
        }

        &.red {
          background: $red;
        }
      }

      li {
        button,
        a {
          font-size: 0.9rem;
          line-height: 1.4rem;
          margin: 0;
          padding: 0.6rem 1rem;
          color: $darkgrey;
          border-radius: $bradius2;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transition: 150ms ease-in-out;

          &:hover {
            color: $black;
            background: $grey6;

            svg path {
              fill: $black;
            }
          }

          .arrowIcon {
            position: absolute;
            right: 0;

            svg {
              width: 13px;
              height: 13px;

              path {
                fill: transparent;
                stroke: $grey1;
              }
            }
          }

          svg {
            margin-right: 0.8rem;

            path {
              fill: $darkgrey;
            }
          }

          i {
            width: 16px;
            margin-right: 0.8rem;
            font-size: 0.8rem;
            line-height: 1.4rem;
            color: black;
            text-align: center;
          }
        }

        &.selected {
          button {
            font-weight: 500;
          }
        }
      }

      .logOut {
        button {
          font-size: 0.9rem;
          line-height: 1.4rem;
          margin: 0;
          padding: 0.6rem 1rem;
          color: $darkgrey;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: $bradius2;

          svg {
            transform: rotate(180deg);
            margin-right: 0.8rem;

            path {
              fill: $darkgrey;
            }
          }

          &:hover {
            color: $black;
            background: $grey6;

            svg {
              path {
                fill: $red;
              }
            }
          }
        }
      }
    }
  }
}
