@import 'variables.scss';

:local {
  .wrapper {
    position: sticky;
    width: 100%;
    max-width: $wrapperWidth;
    margin: 2rem auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $grey6;
    border-radius: $bradius3;
    z-index: 1004;
    padding: 0 1rem;

    .icon {
      margin-top: -3rem;
      margin-bottom: -2rem;
    }

    h4 {
      font-weight: 500;
      color: black;
    }

    p {
      max-width: 400px;
      font-weight: 300;
      text-align: center;
      font-size: 0.9rem;
      line-height: 1.4rem;
      margin-top: 0.4rem;
    }

    .button {
      margin-top: 1rem;
    }
  }
}
