@import "variables.scss";
@import "logos.scss";

:local {
  .header {
    background-color: white;
    padding: 0 1rem 0 1rem;
    position: sticky;
    z-index: 1000;
    width: 100%;
    top: 0;
    box-sizing: border-box;
    border-bottom: 1px solid $grey5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.6rem 0rem 0.6rem 0rem;

      .icon {
        width: 36px;
        height: 36px;
        border-radius: 9px;
        background: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;

        svg {
          width: 16px;
          height: 16px;
        }

        &.blue {
          background: rgba($primary, 0.15);

          svg path {
            fill: $primary;
          }
        }

        &.green {
          background: rgba($green, 0.15);

          svg path {
            fill: $green;
          }
        }

        &.yellow {
          background: rgba($yellow, 0.15);

          svg path {
            fill: $yellow;
          }
        }
      }

      .info {
        .name,
        .email {
          font-size: 0.9rem;
          line-height: 1.2rem;
        }

        .email {
          color: $darkgrey;
        }
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }

      a {
        border: none;
        margin-left: 1rem;
      }
    }
  }
}
