@import "variables.scss";

:local {
  .percentageCircleWrapper {
    width: 56px;
    height: 56px;
    margin-bottom: 1rem;

    &.simple {
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .percentage {
      font-size: 10px;
      font-weight: 500;
      fill: $green4;
      text-align: center;

      &.smaller {
        font-size: 9px;
      }
    }

    .circularChart {
      display: block;
    }

    .circleBg {
      stroke: $grey4;
      fill: none;
      stroke-width: 3;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
    }

    .circle {
      stroke: $green4;
      fill: none;
      stroke-width: 3;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
    }

    &.simple {
      .circleBg,
      .circle {
        stroke-width: 4;
      }
    }

    @keyframes progress {
      0% {
        stroke-dasharray: 0 100;
      }
    }

    &.grey {
      .percentage {
        fill: $grey3;
      }

      .circle {
        stroke: $grey3;
      }
    }

    &.green {
      .percentage {
        fill: $green;
      }

      .circle {
        stroke: $green;
      }
    }

    &.darkgreen {
      .percentage {
        fill: $green4;
      }

      .circle {
        stroke: $green4;
      }
    }

    &.primary {
      .percentage {
        fill: $primary !important;
      }

      .circle {
        stroke: $primary !important;
      }
    }
  }
}
