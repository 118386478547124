@import "variables.scss";

:local {
  .createTemplateWrapper {
    max-width: 350px;
  }

  .createTemplateButton {
    &:hover {
      .tooltip {
        opacity: 1;
        transition-delay: 500ms;
      }
    }
  }

  .tooltip {
    opacity: 0;
    display: flex;
    position: absolute;
    top: calc(100%);
    margin-top: -8px;
    padding: 6px 12px;
    background-color: rgba($extradarkgrey, 0.8);
    justify-content: flex-start;
    align-items: center;
    border-radius: $bradius;
    z-index: 999999;
    transition-delay: 0 opacity;
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      top: -5px;
      left: 1.4rem;
      width: 0;
      height: 0;
      transform: rotate(135deg);
      border-top: 8px solid transparent;
      border-bottom: 8px solid rgba($extradarkgrey, 0.8);
      border-right: 8px solid transparent;
    }

    p {
      font-size: 13px;
      line-height: 18px;
      color: white;
    }
  }

  .wrapper {
    margin-bottom: 4rem;

    .head {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 2rem;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .icon {
            height: 40px;
            width: 40px;
            border-radius: $bradius3;
            background-color: $yellow;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1.4rem;

            svg path {
              fill: white;
            }
          }
        }

        .subtitle {
          font-size: 1rem;
          line-height: 1.6rem;
          color: $darkgrey;
          font-weight: 300;
          margin-top: 1rem;
          max-width: 50%;

          @media (max-width: 700px) {
            max-width: 100%;
          }
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 4rem;

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .tabs {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .tab {
              margin-right: 2rem;

              &:last-child {
                margin-right: 0;
              }

              button {
                color: $darkgrey;
                padding-bottom: 1rem;
                border-bottom: 2px solid $grey5;
                font-size: 15px;
                line-height: 15px;
                font-weight: 500;
              }

              &.selected {
                button {
                  color: $primary;
                  border-bottom: 2px solid $primary;
                }
              }
            }
          }
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .switchView {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 1rem;

            .icon {
              width: 20px;
              height: 20px;

              &.active {
                span {
                  background-color: $black;
                }
              }

              span {
                display: block;
                background-color: $grey3;
                border-radius: 2px;
              }

              &.cards {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1.5px;
                margin-right: 0.6rem;

                span {
                  width: 8px;
                  height: 8px;
                }
              }

              &.list {
                display: grid;
                grid-template-columns: 1fr;
                gap: 2px;

                span {
                  width: 100%;
                  height: 4px;
                }
              }
            }
          }

          .tagSelect {
            margin-right: 2rem;
          }

          .search {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            .searchIcon {
              position: absolute;
              top: 12px;
              left: -2px;
              z-index: 1;
            }

            input {
              border: 0;
              border-radius: 0;
              border-bottom: 1px solid $grey5;
              font-size: 15px;
              line-height: 15px;
              padding-left: 0.6rem;
              height: 40px;

              &::placeholder {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2rem;
    width: 100%;

    .column {
      width: 30%;

      &:first-child {
        width: 70%;
        margin-right: 4rem;
      }

      h5 {
        margin-bottom: 1rem;

        &:nth-child(3) {
          margin-top: 4rem;
        }
      }
    }

    @media (max-width: 850px) {
      flex-direction: column-reverse;

      .column,
      .column:first-child {
        width: 100%;
        margin: 0;
      }

      .column:first-child {
        margin-top: 2rem;
      }
    }
  }

  .editName {
    margin-bottom: 2rem;
    width: 100%;

    h4 {
      margin-bottom: 1rem;
    }

    .smallRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: 1rem;
      }
    }
  }

  .removeButton {
    margin-top: 1rem;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;

    button:first-child {
      margin-right: 1rem;
    }

    a {
      border: none;
    }
  }
}
