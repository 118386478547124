@import "variables.scss";

:local {
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    @media (max-width: 1300px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 1020px) {
      flex-direction: column;
      grid-template-columns: 1fr;
    }

    a,
    button {
      border: none;
      margin-right: 1rem;
      width: 100%;
      justify-content: flex-start;

      &:hover {
        .card {
          box-shadow: $boxshadowhover;
        }
      }

      &:visited {
        .card .bottom {
          border-color: $grey5;
        }
      }
    }

    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-radius: $bradius3;
      max-width: 350px;
      width: 100%;
      box-shadow: $boxshadow;
      transition: $transition;

      @media (max-width: 1300px) {
        max-width: 100%;
      }

      @media (max-width: 1020px) {
        max-width: 450px;
      }

      @media (max-width: 768px) {
        max-width: 100%;
      }

      .icon {
        position: absolute;
        bottom: 1.6rem;
        right: 1.4rem;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: $darkgrey;
          }
        }

        i {
          font-size: 0.7rem;
          color: $darkgrey;
        }
      }

      .image {
        width: 100%;
        height: 170px;
        background-color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: $bradius3;
        border-top-right-radius: $bradius3;
        position: relative;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &.green {
          background-color: rgba($green, 0.15);
        }

        &.blue {
          background-color: $blue;
        }

        &.yellow {
          background-color: rgba($yellow, 0.4);
        }
      }

      .bottom {
        padding: 1.4rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px solid $grey5;
        border-color: $grey5;
        border-top: none;
        width: 100%;
        border-bottom-left-radius: $bradius3;
        border-bottom-right-radius: $bradius3;

        .type {
          font-size: 11px;
          line-height: 11px;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $grey1;
          margin-bottom: 0.4rem;
        }

        h5 {
          font-size: 15px;
          line-height: 15px;
        }
      }
    }
  }
}
