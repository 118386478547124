@import "variables.scss";

:local {
  .modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 1080px) {
      flex-direction: column;
      min-height: auto;
    }

    .backButton {
      position: absolute;
      top: 1.4rem;
      left: 2rem;
      z-index: 1000;

      button {
        font-size: 0.95rem;
        color: $grey1;
        padding: 0.6rem 0;

        .icon {
          transform: rotate(180deg);
          margin-right: 0.8rem;

          svg {
            transform: scale(1.5);
          }

          svg path {
            fill: $grey1;
          }
        }

        &:hover {
          color: $darkgrey;

          .icon {
            svg path {
              fill: $darkgrey;
            }
          }
        }
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      max-width: 900px;
      padding: 0 8rem;

      @media (max-width: 1300px) {
        padding: 4rem;
      }

      @media (max-width: 1080px) {
        padding: 8rem 4rem;
      }

      @media (max-width: 550px) {
        padding: 4rem 2rem;
        padding-top: 6rem;
      }

      .content {
        max-width: 90%;
        margin-bottom: 2rem;

        h1 {
          font-size: 3.6rem;
          line-height: 4.2rem;
          font-weight: 700;
          margin-bottom: 1.6rem;
        }

        h2 {
          font-size: 2.4rem;
          line-height: 2.8rem;
          font-weight: 700;
          margin-bottom: 1.6rem;
        }

        p {
          font-size: 1rem;
          line-height: 1.6rem;
          font-weight: 300;
          color: $grey1;
        }

        ul {
          margin: 1rem 0;
          padding: 1rem 0;
          // border-top: 1px solid $grey5;
          // border-bottom: 1px solid $grey5;
          // max-width: 90%;
          margin-top: 2rem;
          padding-left: 1.5rem;

          li {
            color: $darkgrey;
            font-size: 1rem;
            line-height: 1.7rem;
            font-weight: 300;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              top: 0.6rem;
              left: -1.5rem;
              width: 8px;
              height: 8px;
              background: $primary;
              border-radius: 50%;
            }

            span {
              font-weight: 500;
              color: black;
            }
          }

          p {
            font-size: 0.9rem;
            line-height: 1.4rem;
            font-weight: 300;
            color: $grey1;
            width: 62%;
            padding-bottom: 1.5rem;

            @media (max-width: 480px) {
              width: 100%;
            }
          }
        }
      }
    }

    .right {
      width: 40%;
      min-width: 640px;
      height: 100vh;
      background: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @media (max-width: 1080px) {
        width: 100%;
        height: 400px;
      }

      img {
        height: 300px;
      }

      &.green {
        background: #e3f7eb;
      }

      &.yellow {
        background: #fcf6ef;
      }

      &.blue {
        background: #eff9ff;
      }
    }
  }
}
