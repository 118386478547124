@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: $wrapperWidth;
    width: 100%;

    .topWrapper {
      width: 100%;
      margin-bottom: 2rem;

      .name {
        color: $grey2;
      }
    }

    table {
      border-collapse: separate;
      box-shadow: $lightboxshadow;
      padding: 1px;
      border: 1px solid $grey5;
      border-radius: $bradius2;
      width: 100%;

      .tableHead {
        border-collapse: collapse;
        background: $grey6;
        border-radius: $bradius3;
        height: 30px;

        .name {
          width: 30%;
        }

        .members,
        .teams,
        .positions {
          width: 20%;
        }

        td {
          font-weight: 600;
          font-size: 0.7rem;
          line-height: 0.7rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: black;
          vertical-align: middle;
          border: none;
          padding-top: 5px;
          // text-align: center;

          &:first-child {
            padding-left: 2rem;
            // text-align: left;
          }
        }
      }

      td {
        border-top: 1px solid $grey5;
      }
    }
  }
}
