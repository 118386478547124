@import "variables.scss";

:local {
  .footerWrapper {
    position: relative;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.relative {
      position: relative;
    }
  }

  .footer {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    font-weight: 400;
    padding: 0 1rem;
    color: $darkgrey;
    font-family: proxima-nova;

    a {
      color: $darkgrey;
      font-size: 1rem;
      text-align: center;
      border-bottom: 1px solid $grey3;
      text-decoration: none;

      &:hover {
        color: $primary;
        border-color: $primary;
      }
    }

    strong {
      color: $black;
      font-weight: 500;
    }
  }

  .poweredBy {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: proxima-nova;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;

    p {
      font-size: 0.9rem;
      text-align: center;
      font-weight: 400;
      padding: 0 0.6rem;
      color: $darkgrey;
      margin-right: 0.4rem;
    }

    a {
      border: 0;
    }

    .logo {
      width: 110px;
      height: 40px;
      background: url("~images/logo/default/logo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.9;
      transition: $transition;

      &:hover {
        opacity: 1;
      }
    }
  }
}
