@import "variables.scss";

:local {
  .wrapper {
    margin-top: 2rem;
    padding-top: 2rem;
    max-width: $wrapperWidth;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .compWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1.4rem;
      margin-top: 2rem;
      width: 100%;

      @media (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 980px) {
        grid-template-columns: 1fr;
      }

      .compBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem 1rem;
        // background: $lightgreen;
        border-radius: $bradius3;
        &.green {
          background-color: rgba($lightgreen, 0.7);

          .colHead .icon {
            background-color: $green;
            &.high {
              background-color: $green4;
            }
            &.medium {
              background-color: $green;
            }
            &.low {
              background-color: $green2;
            }
          }
        }
        &.high {
          background-color: $lightgreen;

          .colHead .icon {
            background-color: $green;
          }
        }

        &.medium {
          background-color: $lightorange;

          .colHead .icon {
            background-color: $yellow;
          }
        }

        &.low {
          background-color: $grey6;

          .colHead .icon {
            background-color: $grey1;
          }
        }

        .colHead {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 1.6rem;

          .icon {
            width: 48px;
            height: 48px;
            border-radius: $bradius3;
            background-color: $grey4;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.8rem;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          .colTitle {
            margin-bottom: 0.2rem;
            font-size: 22px;
            line-height: 28px;
            font-weight: 600;
            color: $black;
          }

          .colSubtitle {
            font-size: 14px;
            line-height: 18px;
            font-weight: 300;
            color: $darkgrey;
          }
        }

        .compColumn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;

          .comp {
            width: 100%;
            min-height: 168px;
            margin: 0;
            margin-bottom: 0.6rem;
            background: white;
            border-radius: $bradius3;
            border: 0.5px solid $grey4;
            box-shadow: 0px 4px 10px 0px #00000008;
            display: flex;
            flex-direction: flex-start;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            box-sizing: border-box;

            &:last-child {
              margin: 0;
            }

            button {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              padding: 1rem;

              &:hover {
                box-shadow: $boxshadow;
              }
            }

            .compHead {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              margin-top: -0.4rem;
              margin-bottom: 1rem;
            }

            .compTitle {
              font-size: 1.1rem;
              color: $black;
              margin-bottom: 0.6rem;
            }

            .compDesc {
              font-size: 13px;
              line-height: 19px;
              font-weight: 300;
              color: $darkgrey;
            }

            .label {
              margin-top: 0.4rem;

              &.green {
                svg path {
                  fill: $green3;
                }
              }

              &.yellow {
                svg path {
                  fill: $yellow;
                }
              }

              &.grey {
                svg path {
                  fill: $grey1;
                }
              }

              .warningIcon {
                svg path {
                  &:first-child {
                    fill: white;
                  }

                  &:nth-child(2) {
                    fill: $red;
                  }

                  &:nth-child(3) {
                    fill: $red;
                  }
                }
              }
            }

            .priorityLabel {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              white-space: nowrap;
              margin-top: 0.4rem;
              font-size: 11px;
              line-height: 14px;
              font-weight: 600;
              text-transform: uppercase;
              letter-spacing: 1px;
              border-radius: 4px;

              svg {
                width: 16px;
                height: 16px;
                margin-right: 8px;
              }

              &.high {
                background: $lightgreen;
                padding: 4px 12px;

                svg path {
                  fill: $green;
                  stroke: transparent;
                }
                color: $green;
              }

              &.medium {
                svg circle {
                  stroke: $grey1;
                }
                color: $grey1;
              }

              &.low {
                background: $lightorange;
                padding: 4px 12px;

                svg path {
                  &:first-child {
                    fill: $yellow;
                  }
                  &:nth-child(2) {
                    fill: white;
                  }
                  &:nth-child(3) {
                    fill: white;
                  }
                }
                color: $yellow;
              }
            }
          }
        }
      }
    }
  }
}
