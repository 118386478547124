@import "variables.scss";

:local {
  .main {
    width: 100%;

    .wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      &.closed {
        .sidebarWrapper {
          min-width: 5%;
        }

        .evalWrapper {
          width: 100%;
        }
      }

      &:not(.closed) {
        .sidebarWrapper {
          &.mobile {
            box-shadow: 0 24px 48px rgba(0, 0, 0, 0.2);
            transition: 200ms;
          }
        }
      }

      .evalWrapper {
        width: 100%;

        &.mobile {
          margin-left: 7rem;
        }
      }
    }
  }
}
