@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: $wrapperWidth;

    .totals {
      width: 100%;
      margin-bottom: 4rem;
    }

    .execSummary {
      width: 100%;
      position: relative;

      .autosave {
        position: absolute;
        top: 12px;
        right: 0;
        z-index: 999;
        color: $grey1;
        font-size: 11px;
        opacity: 0;
        transition: 300ms;
        padding: 0px 6px;
        background: $grey5;
        border-radius: $bradius;

        &.show {
          opacity: 1;
        }
      }

      .title {
        margin-bottom: 1.6rem;
        font-weight: 500;
      }

      .textbox {
        border: 1px solid $grey5;
        border-radius: $bradius;
        padding: 1rem;
      }
    }

    .table {
      width: 100%;
      margin-top: 2rem;

      @media (max-width: 1500px) {
        width: 100%;
      }

      @media (max-width: 1000px) {
        width: 100%;
      }

      tbody {
        .moreInfo {
          border-bottom: 1px solid transparent;
          border-top: 0;
          display: block;
          box-sizing: border-box;
          clear: both;
          padding: 1rem 0;
          overflow: hidden;
          padding: 0;
          height: auto;
          transition: 200ms ease-in-out;

          &.show {
            border-bottom: 1px solid $grey5;
          }

          td {
            display: block;
            box-sizing: border-box;
            clear: both;
            height: auto;
            border: none;

            .description {
              // max-width: 600px;
              font-size: 14px;
              line-height: 22px;

              p {
                font-size: 14px;
                line-height: 22px;
                font-weight: 300;
                margin-bottom: 0.6rem;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .row {
              display: flex;
              margin-top: 1rem;

              @media (max-width: 1000px) {
                flex-direction: column;
              }
            }

            .questions {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 1rem;
              padding-bottom: 1rem;
              width: 100%;

              .questionWrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 0.6rem;
                width: 100%;

                &:last-child {
                  margin-bottom: 0;
                }

                .ask {
                  color: $grey1;
                  font-size: 15px;
                  font-weight: 500;
                  margin-right: 1rem;
                }

                .question {
                  font-size: 15px;
                  font-weight: 500;
                  max-width: 80%;
                }
              }

              .buttons {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-top: 1px solid $grey5;
                border-bottom: 1px solid $grey5;
                padding: 0.6rem 0;
                width: 100%;
                margin-top: 0.6rem;

                button {
                  color: $primary;
                  padding: 1rem 1.2rem;
                  border-radius: $bradius3;
                  font-size: 15px;
                  font-weight: 500;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 1rem;

                  &:last-child {
                    margin-right: 0;
                  }

                  &:hover {
                    background: rgba($blue, 0.7);
                  }

                  i {
                    margin-right: 0.8rem;
                    margin-top: 1px;
                    color: $primary;
                  }

                  svg {
                    margin-top: -2px;
                    margin-right: 0.8rem;
                  }

                  svg path {
                    fill: $primary;
                  }
                }
              }
            }

            .infoSection {
              width: 100%;
              margin-top: 2rem;

              .multiple {
                width: 100%;
                margin-bottom: 2rem;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .left {
                  width: 65.5%;
                }

                .right {
                  width: 35%;
                }

                @media (max-width: 1225px) {
                  flex-direction: column;

                  .left,
                  .right {
                    width: 100%;
                  }
                }

                .title {
                  font-size: 1.1rem;
                  line-height: 1.6rem;
                  font-weight: 600;
                  margin: 0.2rem 0;
                }

                .subtitle {
                  font-size: 15px;
                  font-weight: 300;
                  color: $darkgrey;
                  max-width: 90%;
                }

                .label {
                  margin-top: 0.6rem;
                  border-radius: $bradius2;
                  background: $grey3;
                  display: block;
                  height: 24px;
                  padding: 0 0.6rem;
                  width: initial;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  float: left;
                  margin-bottom: 1rem;

                  p {
                    font-size: 12px;
                    font-weight: 400;
                    color: white;
                  }

                  &.red {
                    background: $red;
                  }

                  &.green {
                    background: $green;
                  }

                  &.yellow {
                    background: $yellow;
                  }

                  &.blue {
                    background: $blue;
                  }

                  &.purple {
                    background: $lightpurple;
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .graphWrapper {
              position: relative;
              width: 35%;
              min-width: 400px;
              padding: 2rem 0;
              border: 1px solid $grey5;
              border-radius: $bradius3;
              margin-left: 2rem;
              margin-top: 1rem;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              box-shadow: 0px 2px 2px 0px #00000008;
              z-index: 9;
              transition: 400ms;

              .overlay {
                display: none;
                position: absolute;
                z-index: 15;
                bottom: -10px;
                left: -1px;
                width: calc(100% + 2px);
                height: 200px;
                background: linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 0) 0%,
                  #ffffff 39.51%
                );
              }

              &.overlay {
                height: 350px;

                .content {
                  height: 350px;
                  width: 100%;
                  overflow-y: hidden;
                }

                @media (min-width: 1225px) {
                  .overlay {
                    display: block;
                  }
                }

                &:hover {
                  height: auto;

                  .content {
                    height: auto;
                    overflow-y: auto;
                  }

                  .overlay {
                    display: none;
                  }
                }
              }

              .content {
                height: 100%;
                width: 100%;
                z-index: 15;
                padding: 0 1.4rem;
              }

              @media (max-width: 1225px) {
                max-width: $wrapperWidth;
                width: 90%;
                margin-left: 0;
                margin-top: 2rem;
              }

              .graph {
                margin-bottom: 1rem;
                width: 100%;
              }

              h5 {
                margin-bottom: 0.6rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                svg {
                  margin-top: -3px;
                  margin-right: 0.2rem;
                }

                &.motivator {
                  svg path {
                    fill: $green;
                  }
                }

                &.derailer {
                  svg path:nth-child(1) {
                    fill: $yellow;
                  }
                }
              }

              .behaviour {
                margin-bottom: 0.6rem;
                font-size: 14px;
                line-height: 24px;
                color: $grey1;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      tr {
        display: flex;
        height: 48px;
        transition: $transition;

        &.mainRow {
          &:hover,
          &.active {
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.05);
          }
        }

        @media (max-width: 1000px) {
          height: auto;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          &:first-child {
            height: 48px;
            flex-direction: row;
            width: 100%;

            th:nth-child(2),
            th:nth-child(3) {
              width: 0;
            }
          }
        }
      }

      th {
        // background-color: $blue;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 48px;
        width: 100%;
        padding: 1rem;
        font-size: 14px;
        font-weight: 500;
        // color: $primary;
      }

      td {
        border-bottom: 1px solid $grey5;
        border-right: 1px solid $grey5;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 48px;
        font-size: 14px;
        line-height: 15px;
        padding: 1rem;

        @media (max-width: 1000px) {
          border-right: 0;
        }

        &:last-child {
          border-right: 0;
        }

        &.title {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &.pointer {
            cursor: pointer;
          }

          .arrow {
            margin-left: 0.6rem;

            svg {
              transform: rotate(90deg);
              height: 14px;
              width: 14px;

              path {
                stroke: $primary;
              }
            }

            &.open {
              svg {
                transform: rotate(-90deg);
              }
            }
          }
        }

        &.evaluate {
          width: 100%;
          max-width: 150px;
          justify-content: center;

          @media (max-width: 1000px) {
            max-width: 100%;
            justify-content: flex-start;
          }

          .button {
            margin-right: 0.6rem;

            &:last-child {
              margin-right: 0;
            }

            button {
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              border: 1px solid $grey5;
              border-radius: 50%;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
            }

            &.selected {
              button {
                background-color: $primary;
                border-color: transparent;
                color: white;
              }
            }
          }

          .noInfo {
            margin-left: 0.6rem;

            button {
              font-size: 14px;
              line-height: 14px;
              color: $grey1;
              font-weight: 500;
            }

            &.selected {
              button {
                color: $primary;
              }
            }
          }
        }

        &.addComment {
          width: 100%;
          max-width: 70px;
          justify-content: flex-start;
          position: relative;

          @media (max-width: 1000px) {
            max-width: 100%;
            justify-content: center;
            border-bottom: 0;
          }

          @media (max-width: 700px) {
            justify-content: flex-start;
          }

          .tooltip {
            opacity: 0;
            display: flex;
            position: absolute;
            top: 3px;
            left: calc(100% + 0.6rem);
            padding: 6px 12px;
            background-color: rgba($darkgrey, 1);
            justify-content: flex-start;
            align-items: center;
            border-radius: $bradius;
            z-index: 99999;
            transition-delay: 0s opacity;
            pointer-events: none;

            &::before {
              content: "";
              position: absolute;
              left: -6px;
              width: 0;
              height: 0;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-right: 6px solid rgba($darkgrey, 1);
            }

            @media (max-width: 1000px) {
              top: 2.8rem;
              left: 0;
              right: 0;
              margin: 0 auto;
              max-width: 180px;

              &::before {
                top: -8px;
                left: 0;
                right: 0;
                margin: 0 auto;
                transform: rotate(90deg);
              }
            }

            p {
              color: white;
              font-size: 12px;
              line-height: 15px;
              width: 130px;
            }
          }

          &.disabled {
            button {
              opacity: 0.6;
            }

            svg {
              opacity: 0.6;
            }

            &:hover {
              .tooltip {
                opacity: 1;
                transition-delay: 500ms;
              }
            }
          }

          button {
            font-size: 14px;
            line-height: 14px;
            color: $primary;

            svg path {
              fill: $primary;
            }
          }
        }
      }

      &.green {
        th {
          background: $lightgreen;
          color: $green;
        }

        .title {
          .arrow {
            svg path {
              stroke: $green;
            }
          }
        }

        .addComment {
          button {
            color: $green;

            svg path {
              fill: $green;
            }
          }
        }
      }

      &.primary {
        th {
          background: $blue;
          color: $primary;
        }

        .title {
          .arrow {
            svg path {
              stroke: $primary;
            }
          }
        }

        .addComment {
          button {
            color: $primary;

            svg path {
              fill: $primary;
            }
          }
        }
      }

      &.purple {
        th {
          background: $lightpurple;
          color: $purple;
        }

        .title {
          .arrow {
            svg path {
              stroke: $purple;
            }
          }
        }

        .addComment {
          button {
            color: $purple;

            svg path {
              fill: $purple;
            }
          }
        }
      }

      &.yellow {
        th {
          background: rgba($yellow, 0.2);
          color: $yellow;
        }

        .title {
          .arrow {
            svg path {
              stroke: $yellow;
            }
          }
        }

        .addComment {
          button {
            color: $yellow;

            svg path {
              fill: $yellow;
            }
          }
        }
      }
    }
  }
}
