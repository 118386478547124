@import "variables.scss";

:local {
  .editHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.6rem;
    border-bottom: 1px solid $grey5;

    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-right: 0.6rem;
      }

      a {
        border: none;

        button {
          margin-right: 0;
        }
      }
    }
  }

  .contentWrapper {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2rem;

    .title {
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: 600;
      color: black;

      .icon {
        width: 48px;
        height: 48px;
        border-radius: $bradius3;
        background-color: $yellow;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.4rem;

        svg {
          width: 24px;
          height: 24px;

          path {
            fill: white;
          }
        }
      }
    }

    .subtitle {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 300;
      margin-top: 1rem;
      margin-bottom: 2rem;
      color: $grey1;
    }

    .row {
      margin-bottom: 1rem;
      width: 100%;
      position: relative;

      h5 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
      }

      .autosave {
        position: absolute;
        top: -12px;
        right: 0;
        z-index: 999;
        color: $grey1;
        font-size: 11px;
        opacity: 0;
        transition: 300ms;
        padding: 1px 8px;
        background: $grey5;
        border-radius: $bradius3;

        &.show {
          opacity: 1;
        }
      }
    }

    .footerButtons {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button:first-child {
        margin-right: 0.6rem;
      }
    }

    input {
      width: 100%;
      font-size: 15px;
      color: $black;

      &::placeholder {
        font-size: 14px;
        color: $grey1;
      }
    }

    .smallRow {
      textarea {
        min-height: 150px !important;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        font-size: 15px;
        color: $black;
        font-family: proxima-nova;
        padding: 1rem;
        border-radius: $bradius2;

        &::placeholder {
          font-size: 14px;
          color: $grey1;
        }
      }
    }

    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      margin: 1rem 0;

      @media (max-width: 1070px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 860px) {
        grid-template-columns: 1fr;
      }

      .card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border: 1px solid $grey5;
        border-radius: $bradius3;
        width: 100%;
        min-height: 150px;
        box-shadow: $boxshadow;
        transition: $transition;
        padding: 1.4rem;
        position: relative;

        &:hover {
          box-shadow: $boxshadowhover;

          .deleteIcon {
            opacity: 1;
          }
        }

        &.addNew {
          border: 1px dashed $grey3;
          background-color: $grey6;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none;

          &:hover {
            box-shadow: none;
          }
        }

        .deleteIcon {
          position: absolute;
          top: 1rem;
          right: 1rem;
          opacity: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            width: 100%;
            height: 100%;
          }

          &:hover {
            svg path {
              fill: $black;
            }
          }

          svg {
            width: 16px;
            height: 16px;

            path {
              fill: $grey1;
            }
          }
        }

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .icon {
            width: 40px;
            height: 40px;
            border-radius: $bradius3;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $grey5;
            cursor: pointer;

            svg path {
              fill: $darkgrey;
            }

            &.green {
              background: rgba($green, 0.1);

              svg path {
                fill: $green;
              }
            }

            &.primary {
              background: rgba($primary, 0.1);

              svg path {
                fill: $primary;
              }
            }

            &.yellow {
              background: rgba($yellow, 0.1);

              svg path {
                fill: $yellow;
              }
            }

            &.none {
              svg {
                opacity: 0;
                transition: $transition;
              }
            }

            &:hover {
              background-color: $grey5;

              svg path {
                fill: $darkgrey;
              }

              &.none {
                border: 1px solid $darkgrey;

                svg {
                  opacity: 1;
                }
              }
            }
          }

          .iconSelect {
            padding: 1rem;

            .colors {
              display: flex;
              justify-content: space-between;
              align-items: top;

              .color {
                height: 40px;
                width: 40px;
                border-radius: $bradius3;
                background-color: rgba($primary, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;

                &.active,
                &:hover {
                  border: 1px solid $primary;
                }

                svg {
                  height: 20px;
                  width: 20px;
                }

                &.primary {
                  background-color: rgba($primary, 0.1);

                  svg path {
                    fill: $primary;
                  }
                }

                &.green {
                  background-color: rgba($green, 0.1);

                  svg path {
                    fill: $green;
                  }
                }

                &.yellow {
                  background-color: rgba($yellow, 0.1);

                  svg path {
                    fill: $yellow;
                  }
                }
              }
            }

            .icons {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              gap: 0.6rem;
              margin-top: 1rem;

              .icon {
                height: 28px;
                width: 28px;
                border-radius: $bradius2;
                border: 1px solid $grey5;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;

                svg path {
                  fill: $grey1;
                }

                &:hover {
                  border-color: $primary;

                  svg path {
                    fill: $primary;
                  }
                }

                &.active {
                  border-color: $primary;

                  svg path {
                    fill: $primary;
                  }
                }
              }
            }

            .buttons {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 1rem;
            }
          }
        }

        .dragHandle {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          opacity: 0;
          transition: $transition;

          svg {
            height: 14px;

            path {
              fill: $grey4;
            }
          }
        }

        .bottom {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .title {
            font-family: proxima-nova;
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: 600;
            padding: 0;
            margin: 0;
            text-indent: 0;
            border: none;
            background: none;
            margin-bottom: 0.4rem;
            resize: none !important;
            width: 100%;

            &::placeholder {
              font-size: 1.4rem;
              line-height: 1.8rem;
              font-weight: 600;
              color: $grey3;
            }
          }

          .desc {
            font-family: proxima-nova;
            font-size: 15px;
            line-height: 18px;
            font-weight: 400;
            color: $darkgrey;
            border: none;
            padding: 0;
            margin: 0;
            resize: none;
            width: 100%;

            &::placeholder {
              font-size: 15px;
              line-height: 18px;
              font-weight: 400;
              color: $grey3;
            }
          }
        }
      }
    }

    .editParts {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      button {
        margin-bottom: 1rem;
        width: 100%;

        &:first-child {
          .card .icon {
            background: $yellow;
          }
        }

        &:last-child {
          .card .icon {
            background: $primary4;
          }
        }

        .card {
          padding: 1rem;
          border-radius: $bradius3;
          border: 1px solid $grey5;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          box-shadow: 0px 2px 2px #00000008;
          transition: $transition;

          &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.075);
          }

          &:last-child {
            margin-bottom: 0;
          }

          .icon {
            margin-right: 1rem;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $primary;
            border-radius: 50%;

            svg {
              transform: scale(0.9);
              margin: 0;
            }

            svg path {
              fill: white;
            }
          }

          .text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            h5 {
              margin: 0;
              font-size: 0.9rem;
              line-height: 1.1rem;
              margin-bottom: 0.2rem;
              color: black;
            }

            p {
              color: $grey1;
              font-size: 0.9rem;
              line-height: 1.1rem;
            }
          }
        }
      }
    }
  }
}
