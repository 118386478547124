@import "variables.scss";

:local {
  .main {
    .upperButtons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      button {
        margin: 1rem 0.5rem;

        @media (max-width: 450px) {
          margin: 0.5rem;
          width: 100%;
        }
      }
    }

    .moreBtn {
      position: absolute;
      top: 6px;
      right: 1rem;

      @media (max-width: 740px) {
        position: relative;
        top: initial;
        right: initial;
      }
    }

    .buttonText {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      padding: 0;
      text-shadow: 1px 1px 10px rgba(71, 75, 88, 0.4);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        margin-right: 0.4rem;
      }

      span {
        padding-top: 1px;
        margin: -0.4rem 0;
        margin-left: 0.4rem;
        background: rgba(0, 0, 0, 0.1);
        color: white;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &.green {
        color: white;

        span {
          color: white;
        }
      }

      &.secondary {
        color: $black;
        text-shadow: none;

        span {
          color: $black;
        }
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4rem 0;
      width: 100%;

      @media (max-width: 1280px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;
        margin-right: 2rem;

        @media (max-width: 1280px) {
          margin-right: 0;
          margin-bottom: 2rem;
          width: 100%;
        }

        .orgName {
          color: $primary;
          text-align: left;
        }

        .teamName {
          font-size: 2.8rem;
          line-height: 3.2rem;
          margin: 0.8rem 0;
          text-align: left;
          max-width: 100%;
        }

        .underName {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: left;

          h4 {
            color: $darkgrey;
            font-size: 1.1rem;
            line-height: 1.5rem;
            font-weight: 300;
          }
        }
      }

      .right {
        flex: 1;
      }
    }

    .separator {
      width: 65%;
      height: 1px;
      background: $grey3;
      margin: 3rem 0;
    }
  }
}
