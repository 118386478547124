@import 'variables.scss';

:local {
  .wrapper {
    width: 100%;
    max-width: $modalWrapper;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.blue {
      .imageWrapper {
        background: $blue;

        svg {
          margin-left: 7rem;
        }
      }
    }

    &.yellow {
      .imageWrapper {
        background: rgba($yellow, 0.3);

        svg {
          margin-top: 2rem;
          margin-left: 7rem;
        }
      }
    }

    &.green {
      .imageWrapper {
        background: rgba($green, 0.15);
      }
    }

    .icon {
      width: 64px;
      height: 64px;
      background: rgba($green, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      color: $green;
    }

    .title {
      margin: 1rem 0;
      text-align: center;
    }

    .subtitle {
      max-width: 650px;
      text-align: center;
      color: $darkgrey;
      font-size: 18px;
      line-height: 30px;
    }

    .actionBtn {
      margin-top: 2rem;
    }

    .action {
      margin-top: 2rem;
      padding: 1rem 1.2rem;
      border: 1px solid $grey5;
      border-radius: $bradius3;
      color: $grey1;
      font-size: 0.9rem;
      display: flex;
      align-items: center;

      button {
        font-size: 0.9rem;
        margin-left: 0.4rem;
        color: $primary;
      }
    }

    .imageWrapper {
      background: $blue;
      width: 80%;
      min-width: 750px;
      height: 293px;
      margin: 3rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $bradius3;
    }

    .section {
      max-width: 600px;
      margin-bottom: 4rem;

      .smallSubtitle {
        max-width: 50;
        color: $grey1;
        margin-top: 1rem;
      }

      .row {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 2rem;
        margin-bottom: 0.6rem;

        .column {
          width: 50%;
          margin-right: 2rem;

          &:last-child {
            margin-right: 0;
          }

          .icon {
            width: 40px;
            height: 40px;
            margin-bottom: 1rem;
            background: rgba($green, 0.1);

            svg {
              height: 16px;

              path {
                fill: $green;
              }
            }
          }

          h5 {
            font-size: 1.1rem;
            margin-bottom: 0.6rem;
            color: black;
          }

          p {
            color: $grey1;
          }
        }

        @media (max-width: 560px) {
          flex-direction: column;

          .column {
            width: 90%;
          }
        }
      }
    }
  }
}
