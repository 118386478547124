@import "variables.scss";

:local {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .wrapper {
    display: flex;

    @media (max-width: 920px) {
      flex-direction: column;
    }

    .left {
      max-width: 352px;
      width: 30%;
      padding: 3rem;
      background: $grey5;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        height: 100%;
        max-height: 90vh;
      }

      @media (max-width: 920px) {
        width: 100%;
        max-width: 100%;
        height: 200px;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
      }

      @media (max-width: 800px) {
        flex-direction: column;
        height: auto;
      }

      .title {
        font-weight: 600;
        max-width: 70%;
        font-size: 1.4rem;
        line-height: 1.8rem;

        @media (max-width: 920px) {
          max-width: 30%;
        }

        @media (max-width: 800px) {
          margin-bottom: 1rem;
          max-width: 80%;
        }
      }

      .logosImg {
        margin-left: -3rem;
        background-image: url("./logos.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: calc(100% + 6rem);
        height: 100%;

        @media (max-width: 920px) {
          display: none;
        }
      }

      .footerText {
        color: $darkgrey;

        @media (max-width: 920px) {
          max-width: 60%;
          max-width: 90%;
        }
      }
    }

    .right {
      width: 70%;
      padding: 3rem;
      margin-left: 24rem;

      @media (max-width: 920px) {
        margin-left: 0;
        width: 100%;
      }

      .title {
        margin-bottom: 1rem;
      }

      .subtitle {
        font-size: 1.1rem;
        line-height: 1.6rem;
        font-weight: 300;
        max-width: 55%;

        @media (max-width: 1070px) {
          max-width: 90%;
        }
      }

      .boxes {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 4rem;

        @media (max-width: 1070px) {
          flex-direction: column;
        }

        button {
          width: 100%;
          max-width: 360px;
          margin-right: 2rem;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 1070px) {
            width: 100%;
            max-width: 360px;
            margin: 0;
            margin-bottom: 2rem;
          }

          @media (max-width: 920px) {
            max-width: 100%;
          }
        }

        .product {
          width: 100%;
          padding: 2rem;
          border: 2px solid $grey6;
          border-radius: $bradius2;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background: $grey6;

          .top {
            min-height: 162px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .billingTitle {
              font-weight: 500;
            }

            .billing {
              font-weight: 500;
              margin-bottom: 2rem;
              font-size: 0.9rem;
            }

            .price {
              font-size: 2rem;
              font-weight: 700;
            }

            .perSeat {
              font-size: 0.9rem;
              color: $grey1;
              font-weight: 300;
            }

            .discount {
              margin-top: 1rem;
              padding: 0.2rem 0.8rem;
              background: $primary;
              border-radius: $bradius2;

              p {
                font-size: 0.85rem;
                color: white;
              }
            }
          }

          .table {
            width: 100%;
            margin-top: 2rem;

            .row {
              width: 100%;
              padding: 0.6rem 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-top: 1px solid $grey5;

              p {
                font-size: 0.85rem;

                &:last-child {
                  color: $grey1;
                }
              }
            }
          }

          &.selected {
            border-color: $primary;
            background: white;
          }

          &.featured {
            // background: white;

            .billing {
              color: $primary;
            }

            .table {
              .row {
                &:nth-child(2) {
                  p {
                    font-weight: 600;
                    color: $black;
                  }
                }
              }
            }
          }
        }
      }

      .coupon {
        margin-top: 3rem;

        h5 {
          margin-bottom: 0.6rem;
        }

        input {
          max-width: 360px;
          text-transform: uppercase;
          letter-spacing: 0.5px;

          &::placeholder {
            text-transform: initial;
            letter-spacing: 0px;
          }
        }
      }

      .buttonGroup {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4rem;

        button {
          margin-right: 1rem;

          .innerButton {
            width: 81px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          i {
            margin: 0;
            padding: 0;
            animation-name: spin;
            animation-duration: 1200ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
