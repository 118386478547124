@import "variables.scss";

:local {
  .wrapper {
    position: relative;
    background: white;
    border: 1px solid $grey5;
    border-radius: $bradius3;
    box-shadow: 0px 4px 10px 0px #00000008;
    transition: $transition;
    max-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    text-overflow: ellipsis;
    font-family: proxima-nova;

    &.candidate {
      max-width: 180px;
      min-width: 180px;
      min-height: 209px;
      margin: 12px 6px;
      padding: 1rem;
      padding-top: 2rem;

      &:after {
        content: "";
        width: calc(100% + 2px);
        height: 4px;
        background: $green;
        position: absolute;
        top: -1px;
        left: -1px;
        border-top-left-radius: $bradius3;
        border-top-right-radius: $bradius3;
        z-index: 9;
      }

      @media (max-width: 450px) {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        margin-left: 0;
      }

      &.pending {
        border-top: 4px solid $grey3;
        cursor: default;

        &:hover {
          box-shadow: $lightboxshadow;
        }

        @media (max-width: 450px) {
          max-width: 100%;
          width: 100%;
          margin-left: 0;
        }
      }

      h4 {
        text-align: center;
        font-weight: 500;
        margin: 0.6rem 0;
        font-size: 1.2rem;
        width: 100%;
      }

      p {
        text-align: center;
        font-size: 0.9rem;
        line-height: 1.1rem;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: $grey1;
        width: 100%;
      }

      .label {
        position: absolute;
        margin-top: -2.8rem;
        margin-bottom: 2.6rem;
        padding: 0.2rem 0.6rem;
        border-radius: $bradius;
        background: $red;
        color: white;

        &.pending {
          background: $darkgrey;
          color: white;
        }
      }
    }

    &.team,
    &.position {
      min-width: 100%;
      min-height: 184px;
      margin-bottom: 6px;
      padding: 1.2rem 1.2rem;
      cursor: pointer;
      justify-content: space-between;
      align-items: flex-start;

      &:after {
        content: "";
        width: calc(100% + 2px);
        height: 4px;
        background: $primary;
        position: absolute;
        top: -1px;
        left: -1px;
        border-top-left-radius: $bradius3;
        border-top-right-radius: $bradius3;
        z-index: 9;
      }

      @media (max-width: 620px) {
        max-width: 100%;
        margin: 6px 0;
        width: 100%;
      }

      h4 {
        font-size: 1.2rem;
        font-weight: 500;
        margin: 1rem 0;
      }

      p {
        font-weight: 300;
        color: $grey1;
      }

      div[aria-label="top"] {
        border-bottom: 1px solid $grey4;
        width: 100%;
        padding-bottom: 0.7rem;
        text-align: left;

        div[aria-label="upperLine"] {
          min-height: 85px;
        }

        h4 {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          margin: 0;
          font-size: 0.9rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &[aria-label="yellow"] {
            color: $yellow;
          }
        }
      }

      div[aria-label="bottom"] {
        padding-top: 1rem;

        p {
          margin: 0;
          font-size: 0.9rem;
          line-height: 0.9rem;
        }
      }
    }

    &.position {
      min-height: 169px;
      // min-width: 220px;
      // border-top: 4px solid $green;

      &:after {
        background-color: $green;
      }

      div[aria-label="upperLine"] {
        min-height: 101px !important;
      }
    }

    &.new {
      min-height: 208px;
      min-width: 180px;
      height: 160px;
      padding: 1.2rem;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      color: $darkgrey;

      &:hover {
        color: $black;
        box-shadow: $boxshadow;
      }

      svg {
        width: 35px;
        height: 35px;
      }

      i {
        font-size: 1.8rem;
        margin: 0;
      }

      h4 {
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-weight: 400;
        color: $darkgrey;
      }

      &.position {
        min-height: 213px;
        min-width: 220px;
        font-size: 1.1rem;
      }

      &.team {
        min-width: 220px;
        min-height: 192px;
        font-size: 1.2rem;
        margin: 4px;
      }
    }

    &.narrow {
      min-width: 209px;
      width: 209px;

      &.position {
        min-width: 209px;

        div[aria-label="upperLine"] {
          min-height: 58px !important;
        }
      }
    }

    &.hiringStep {
      cursor: pointer;
      max-width: 180px;
      min-width: 180px;
      min-height: 209px;
      margin: 12px 6px;
      padding: 1rem;
      padding-top: 2rem;
      border-top: 4px solid $green;

      @media (max-width: 450px) {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        margin-left: 0;
      }

      h4 {
        text-align: center;
        font-weight: 500;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        font-size: 1.2rem;
        letter-spacing: 0.2px;
        width: 100%;
      }

      p {
        text-align: center;
        font-size: 0.9rem;
        line-height: 1.1rem;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: $darkgrey;
        width: 100%;
      }

      &:hover {
        color: $black;
        box-shadow: $boxshadow;
      }
    }
  }

  .signup {
    width: 100%;
    padding: 2rem;
    border: 1px solid $grey4;
    border-top: 4px solid $primary;
    border-radius: $bradius;
    box-shadow: $boxshadow;
    background: white;
    max-width: none;
    text-align: left;

    p[data-label="seperator"] {
      text-align: center;
      color: $grey3;
      font-weight: 400;
      line-height: 4rem;
      font-size: 1.2rem;
    }
  }
}
