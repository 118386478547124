@import "variables.scss";

:local {
  form {
    width: 100%;

    input {
      position: relative;
      height: 48px;
      outline: none;
      border: 1px solid $grey5;
      border-radius: $bradius;
      color: black;
      font-weight: 300;
      background: white;
      text-indent: 1rem;
      font-size: 0.9rem;
      line-height: 1.4rem;

      &::placeholder {
        font-size: 0.9rem;
        font-family: proxima-nova;
        color: $grey1;
        font-weight: 300;
      }

      &:focus {
        border-color: $grey1 !important;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;

      @media (max-width: 450px) {
        flex-direction: column;
      }

      &:first-child div {
        margin-top: 0;
      }

      div {
        width: 100%;
        margin-top: 1rem;
        margin-right: 1.4rem;
        justify-content: space-between;

        &:last-child {
          margin-right: 0;
        }

        .label {
          font-weight: 500;
          font-size: 0.9rem;
          color: black;
          margin-bottom: 0.2rem;
        }
      }

      .checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1.2rem;
        cursor: pointer;
        width: 100%;

        .label,
        a {
          font-weight: 300;
          font-size: 0.9rem;
          line-height: 1.4rem;
          color: black;
        }

        a {
          color: $primary;
          border: none;
        }

        input {
          height: 15px;
          width: 15px;
          margin-right: 0.6rem;
        }
      }

      input[type="password"] {
        letter-spacing: 2px;
        font-size: 1.1rem;

        &::placeholder {
          letter-spacing: initial;
          font-size: 0.9rem;
        }
      }
    }

    .button {
      margin-top: 1.6rem;

      button {
        width: 100%;
        height: 40px;
      }
    }

    .submitError p {
      color: $red;
      font-size: 0.9rem;
      font-weight: 400;
      margin-top: 0.6rem;
    }
  }
}
