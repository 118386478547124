@import "variables.scss";

:local {
  .main {
    background-color: white;
    height: 45px;
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $transition;
    box-shadow: $headerboxshadow;
    z-index: 1000;
    padding: 0 1rem;
    opacity: 0;
    // display: none;

    @media (max-width: 500px) {
      display: none;
    }

    &.scrolling {
      background-color: white;
      transition: 200ms;
      opacity: 1;
      // display: flex;
    }

    .wrapper {
      max-width: $wrapperWidth;
      margin: 0 auto;
      width: $wrapperWidth;
      display: flex;
      justify-content: center;
      align-items: center;

      .row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .mainIcon {
          margin-right: 2rem;

          i {
            font-size: 1.1rem;
            color: $grey3;
          }
        }

        .listItem {
          color: white;
          font-weight: 500;
          margin-right: 2rem;
          width: auto;

          &:last-child {
            margin-right: 0;
          }

          &.activeLink button {
            color: $black;
            font-weight: 500;
            border-bottom: 4px solid $black;
          }

          button {
            border: 0;
            border-radius: 0;
            font-size: 1rem;
            line-height: 1.4rem;
            transition: $transition;
            position: relative;
            color: $black;
            padding: 0;
            margin: 0 5px;
            margin-top: 10px;
            padding-bottom: 10px !important;
            font-weight: 400;
          }
        }
      }
    }
  }
}
