@import "variables.scss";

:local {
  .sectionWrapper {
    margin-top: 6rem;
    width: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;

    .searchrow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 1rem;

      .search {
        width: 40%;
      }

      @media (max-width: 486px) {
        flex-direction: column;

        .search {
          width: 100%;
          margin-bottom: 1rem;
        }

        .invite,
        .invite button {
          width: 100%;
        }
      }
    }

    table {
      border-collapse: separate;
      box-shadow: $lightboxshadow;
      padding: 1px;
      border: 1px solid $grey5;
      border-radius: $bradius2;
      width: 100%;

      .tableHead {
        border-collapse: collapse;
        background: $grey6;
        border-radius: $bradius3;
        height: 40px;

        .name {
          width: 33%;
        }

        .organizations {
          width: 15%;
        }

        .status {
          width: 10%;
        }

        .guest {
          width: 10%;
        }

        .permissions {
          width: 5%;
        }

        td {
          font-weight: 600;
          font-size: 0.7rem;
          line-height: 1.4rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: black;
          vertical-align: middle;
          border: none;
          height: 40px;

          &:first-child {
            padding-left: 1rem;
          }
        }

        .permissions {
          padding-left: 1rem;
        }
      }

      td {
        border-top: 1px solid $grey5;
      }
    }

    .paginationRow {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;

      .show {
        margin-right: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-right: 1rem;
        }
      }
    }
  }
}
