@import "variables.scss";

:local {
  @keyframes loading {
    0% {
      background-position: 0% 50%;
      width: 0%;
    }
    25% {
      width: 70%;
    }
    50% {
      background-position: 100% 50%;
      width: 90%;
    }
    100% {
      background-position: 0% 50%;
      width: 99%;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .loaderbar {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: $grey5;
    border-radius: $bradius3;

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 99%;
      border-radius: $bradius3;
      animation-name: loading;
      animation-duration: 35s;
      animation-timing-function: ease-out;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        border-radius: $bradius3;
        animation: gradient 10s ease infinite;
      }
    }
  }
}
