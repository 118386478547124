@import "variables.scss";

:local {
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 6rem;

    @media (max-width: 960px) {
      margin: 0;
    }

    &::before {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 99999;
    }

    .title {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }

    .subtitle {
      color: $grey1;
      font-weight: 300;
      text-align: center;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999999;
      margin: 0 auto;
      width: 100%;

      @media (max-width: 960px) {
        margin-left: 0;
      }

      .account {
        font-size: 13px;
        color: $grey1;
        max-width: 50%;
        text-align: center;

        @media (max-width: 960px) {
          max-width: 100%;
        }
      }

      .banner {
        width: 100%;
        margin-top: 4rem;
      }

      .bottom {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .text {
          margin-bottom: 1rem;
          font-size: 13px;
          color: $grey1;
        }

        .actions {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 490px;

          button {
            border-color: $grey5;

            &:hover {
              border-color: $grey3;
            }
          }

          .notifications {
            width: 100%;
            justify-content: space-between;
            margin-bottom: 2rem;

            .left {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-weight: 500;
              font-size: 14px;
              color: $darkgrey;
              padding: 0.8rem;

              .icon {
                width: 32px;
                height: 32px;
                border-radius: $bradius3;
                background-color: #fff7f4;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 1rem;

                svg path {
                  fill: $red;
                }

                &.arrow {
                  background-color: $grey5;

                  svg {
                    transform: rotate(180deg);

                    path {
                      fill: $darkgrey;
                    }
                  }
                }
              }
            }
          }

          .workspaces {
            width: 100%;
            justify-content: space-between;
            margin-bottom: 3rem;

            button {
              width: 100%;
              justify-content: flex-start;
              padding: 0;
              border: none;

              .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-weight: 500;
                font-size: 14px;
                color: $darkgrey;
                padding: 0.8rem;

                .icon {
                  width: 32px;
                  height: 32px;
                  border-radius: $bradius3;
                  background-color: $haze;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 1rem;

                  svg path {
                    fill: $yellow;
                  }
                }

                .arrow {
                  margin-left: 0.6rem;

                  &.close {
                    svg {
                      transform: rotate(-90deg);
                    }
                  }

                  svg {
                    transform: rotate(90deg);

                    path {
                      stroke: $grey1;
                    }
                  }
                }
              }
            }
          }

          .accountsWrapper {
            max-height: 0;
            height: 100%;
            width: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 1rem;
            transition: max-height 400ms linear;

            &.show {
              max-height: 600px;
            }

            li {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;

              button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border-radius: $bradius2;
                padding: 0.8rem;
                border: 1px solid $grey5;
                font-size: 14px;
                line-height: 14px;
                font-weight: 500;

                &:hover {
                  border-color: $grey3;
                }

                .left {
                  display: flex;
                  align-items: center;
                  padding: 0;

                  .avatar {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background: $purple;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 2px;
                    margin-right: 1.2rem;

                    h5 {
                      font-size: 11px;
                      line-height: 11px;
                      font-weight: 600;
                      color: white;
                    }

                    &.blue {
                      background: $primary;
                    }

                    &.green {
                      background: $green;
                    }

                    &.purple {
                      background: $purple;
                    }

                    &.yellow {
                      background: $yellow;
                    }

                    &.red {
                      background: $red;
                    }
                  }
                }

                .arrow {
                  margin-right: 0.2rem;

                  svg path {
                    stroke: $grey1;
                  }
                }
              }
            }
          }

          .action {
            margin-bottom: 1rem;
            width: 100%;

            a {
              width: 100%;
              border: none;
            }

            button {
              width: 100%;
              justify-content: space-between;
              padding: 0.8rem;
              padding-right: 1rem;

              .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .icon {
                  width: 32px;
                  height: 32px;
                  border-radius: $bradius3;
                  background-color: #f0fff6;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 1rem;

                  svg path {
                    fill: $green;
                  }

                  &.arrow {
                    background-color: $grey5;

                    svg {
                      transform: rotate(180deg);

                      path {
                        fill: $grey1;
                      }
                    }
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }

              span {
                font-size: 12px;
                font-weight: 300;
                color: $grey2;

                &.bubble {
                  font-size: 11px;
                  line-height: 11px;
                  color: white;
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                  background-color: $primary;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 0.4rem;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}
