@import "variables.scss";

:local {
  .step {
    margin: 1rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .left {
      width: 80%;
      margin-right: 2rem;
      padding-right: 2rem;
      position: relative;

      @media (min-width: 1885px) {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
      }

      @media (max-width: 1600px) {
        width: 70%;
        margin-right: 0;
        padding-right: 0;
      }

      @media (max-width: 1500px) {
        width: 65%;
        margin-right: 0;
        padding-right: 0;
      }

      @media (max-width: 1400px) {
        width: 60%;
        margin-right: 2rem;
        padding-right: 2rem;
      }

      @media (max-width: 1400px) {
        &::after {
          content: "";
          position: absolute;
          top: -6rem;
          right: 0;
          width: 1px;
          height: calc(100svh - 130px);
          background-color: $grey5;
        }
      }

      .topRow {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .groupTitle {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .icon {
            width: 24px;
            height: 24px;
            border-radius: $bradius2;
            background-color: $grey5;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.8rem;

            svg path {
              fill: $grey1;
            }

            &.primary {
              background-color: $blue;

              svg path {
                fill: $primary;
              }
            }

            &.green {
              background-color: $lightgreen;

              svg path {
                fill: $green;
              }
            }

            &.yellow {
              background-color: $lightyellow;

              svg path {
                fill: $yellow;
              }
            }

            &.purple {
              background-color: $lightpurple;

              svg path {
                fill: $purple;
              }
            }
          }
        }
      }

      .dimContent {
        margin-top: 1rem;

        .dimTitle {
          font-weight: 500;
          margin-bottom: 0.6rem;
        }

        .readMoreWrapper {
          margin-top: 1rem;

          button {
            color: $primary;
            padding: 0.4rem 0;

            span {
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                margin-left: 1rem;
                transform: rotate(90deg);

                path {
                  stroke: $primary;
                }
              }

              &.open {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }
          }
        }

        .dimDescription {
          width: 100%;
          overflow-y: auto;
          margin-top: 2rem;
          background: white;
          height: 100px;
          position: relative;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 300;
          font-size: 15px;
          line-height: 22px;
          color: $darkgrey;
          margin-bottom: 0.6rem;

          @media (min-width: 1400px) {
            height: 80px;
          }

          &.long {
            &::after {
              position: absolute;
              content: "";
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100px;
              background: linear-gradient(to top, white, transparent);
            }
          }

          &.full {
            height: auto;
            max-height: 100%;

            &::after {
              display: none;
            }
          }

          p {
            font-weight: 300;
            font-size: 15px;
            line-height: 22px;
            color: $darkgrey;
            margin-bottom: 0.6rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .bottom {
        margin: 2rem 0;
        padding-top: 1rem;
        // border-bottom: 1px solid $grey5;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1210px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
        .subTitle {
          font-size: 14px;
        }
        .progress {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: auto;
          padding-right: 6px;
          .text {
            font-size: 12px;
            line-height: 12px;
            font-weight: 300;
            color: $darkgrey;
            margin-right: 0.4rem;
          }

          .bar {
            width: 32px;
            height: 4px;
            border-radius: 50px;
            background-color: $grey4;

            .filler {
              background-color: $primary;
              height: 100%;
              border-radius: 50px;
            }
          }
        }

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          @media (max-width: 1210px) {
            margin-top: 1rem;
          }

          button {
            margin-right: 1rem;

            &:last-child {
              margin-right: 0;
            }

            .arrow {
              margin-left: 0.4rem;

              &.reverse {
                margin-left: 0;
                margin-right: 0.4rem;

                svg {
                  transform: rotate(180deg);

                  path {
                    fill: $primary;
                  }
                }
              }
            }
          }
        }
      }

      .notes {
        margin-top: 1.6rem;
        width: 100%;
      }
    }

    .right {
      flex: 1;
    }
  }
}
