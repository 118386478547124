@import 'variables.scss';

:local {
  .wrapper {
    border: 1px solid $grey4;
    box-shadow: $lightboxshadow;
    border-top: 4px solid $darkgrey;
    border-radius: $bradius;
    padding: 0.5rem 1rem;
    font-family: proxima-nova;

    p {
      margin: 0.4rem 0;

      &.quote {
        font-size: 0.9rem;
        color: $darkgrey;
        margin-top: -1rem;
      }
    }
  }
}
