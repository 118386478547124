@import 'variables.scss';

:local {
  .wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    background-size: 100%;
    background-repeat: no-repeat;

    .logo {
      height: 30px;
      margin-bottom: 2rem;
    }

    .smallerWrapper {
      max-width: 400px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border: 1px solid $grey5;
      padding: 2rem;
      border-radius: $bradius2;
    }

    .title {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 500;
      text-align: left;
      margin-bottom: 1rem;
    }

    .subtitle {
      font-size: 1rem;
      line-height: 1.4rem;
      font-weight: 300;
      text-align: left;
      color: $grey1;
      margin-bottom: 2rem;
      max-width: 95%;
    }

    a {
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 300;
      border: none;
    }

    form {
      width: 100%;

      label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: black;
        font-size: 0.9rem;

        input[type='password'] {
          letter-spacing: 2px;
          font-size: 1.1rem;
          margin-bottom: 1.6rem;
          margin-top: 0.4rem;

          &::placeholder {
            letter-spacing: initial;
            font-size: 0.9rem;
          }
        }
      }

      button {
        width: 100%;
        height: 40px;
      }

      .submitError p {
        color: $red;
        font-size: 0.9rem;
        font-weight: 400;
        margin-top: 0.6rem;
      }

      .success {
        font-size: 1.2rem;
        color: $green;
      }
    }
  }
}
