@import "variables.scss";

:local {
  .confirmationPopup {
    padding: 0.8rem 1.6rem;
    min-width: fit-content;

    .title {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0.8rem;
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        max-width: max-content;
        padding: 0.2rem;
        margin-right: 0.4rem;
        max-height: 26px;
        font-size: 14px !important;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
