@import "variables.scss";

:local {
  .attachmentsWrapper {
    max-height: 85px;
    height: 85px;
    position: relative;

    .tooltip {
      width: calc(100% + 10px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
      background: #303030;
      color: white;
      font-size: 13px;
      padding: 0.5rem 0.7rem;
      border: none;
      border-radius: $bradius2;
      -webkit-animation: anvil 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
      margin-top: 0.2rem;
      margin-left: -10px;
      opacity: 0;
      position: absolute;
      transition: 200ms;

      &.show {
        opacity: 1;
      }
    }
  }

  .attachments {
    margin-top: 0.4rem;
    border: 1px solid $grey5;
    border-radius: $bradius3;
    background-color: white;
    margin-left: -10px;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: $grey2;
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .tooltipPopover {
      width: 118px !important;
      text-align: center;

      span {
        width: 100%;
        text-align: center;
      }
    }

    .fileRow {
      width: 100%;
      position: relative;
    }

    li {
      cursor: pointer;
      font-size: 0.9rem;
      line-height: 1.4rem;
      margin: 0;
      padding: 0.6rem;
      color: black;
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: none;
      position: relative;

      &.disabled {
        opacity: 0.5;
      }

      .fileName {
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 0.6rem;
      }

      .remove {
        position: absolute;
        right: 6px;
        top: 10px;

        button {
          margin: 0;
          padding: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: $red;

            svg rect {
              fill: white;
            }
          }
        }

        svg {
          width: 13px;
          height: 13px;
          margin: 0;
          padding: 0;
          margin-left: -1px;
        }

        svg rect {
          fill: $darkgrey;
        }
      }
    }

    li:first-child {
      border-bottom: 0.5px solid $grey5;
    }
  }
}
