@import 'variables.scss';

:local {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .popupWrapper {
    .popup-arrow {
      background: rgba(black, 1) !important;
    }
  }

  button i {
    margin-right: 0.6rem;
  }

  .loading i {
    animation-name: spin;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
  }

  .popover {
    background: rgba(black, 1);
    padding: 0.4rem 0.8rem;
    border-radius: $bradius;

    p {
      color: white;
      font-size: 0.7rem;
      line-height: 0.9rem;
      text-align: center;
    }
  }
}
