@import "variables.scss";

:local {
  .name {
    color: $grey2;
  }

  .header {
    margin: 1rem 0;
    width: 100%;
    border-bottom: 1px solid $grey5;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .items {
      max-width: $wrapperWidth;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item {
        padding: 1rem 0;
        margin-right: 2rem;
        border-bottom: 2px solid transparent;

        button {
          font-size: 15px;
          font-weight: 500;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border-bottom: 2px solid $primary;

          button {
            color: $primary;
          }
        }
      }
    }
  }

  .content {
    margin: 2rem 0;
    min-height: 500px;

    .main {
      width: 100%;

      .searchrow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .search {
          width: 40%;
        }

        @media (max-width: 486px) {
          flex-direction: column;

          .search {
            width: 100%;
            margin-bottom: 1rem;
          }

          .invite,
          .invite button {
            width: 100%;
          }
        }
      }
    }
  }
}
