@import "variables.scss";

:local {
  .notesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 4rem;
    width: 100%;

    .notes {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      .note {
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        // border-bottom: 0.5px solid $grey5;

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
        }

        &:hover {
          .actions {
            opacity: 1;
          }
        }

        .avatar {
          width: 30px;
          height: 30px;
          background-color: $grey5;
          border-radius: 50%;
          margin-right: 2rem;
        }

        .comment {
          margin-right: 3rem;
          max-width: 85%;
          width: 100%;

          p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 300;
          }

          textarea {
            font-family: proxima-nova;
            font-size: 14px;
            line-height: 22px;
            font-weight: 300;
            padding: 0.6rem;
            border: 1px solid $grey5;
            border-radius: $bradius2;
            text-indent: 0;
            width: 100%;
            height: initial;
            resize: none;
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          opacity: 0;

          button {
            margin-right: 1rem;
            font-size: 14px;
            padding: 4px;

            &:hover {
              background: none;
            }

            svg path,
            svg rect {
              fill: $grey1;
            }

            &:hover {
              svg path,
              svg rect {
                fill: $darkgrey;
              }
            }

            &:last-child {
              margin-right: 0;

              &:hover {
                svg path,
                svg rect {
                  fill: $red;
                }
              }
            }
          }
        }
      }
    }

    .createNote {
      width: 100%;
      max-width: 600px;
    }

    .bottom {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1rem;
    }
  }
}
