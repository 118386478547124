@import "variables.scss";

:local {
  .bigWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 6rem;

    &.motivators {
      .TopTitle {
        i {
          color: $green;
        }
      }

      .arrowLine {
        background-color: $green;

        &:after {
          border-top: 15px solid $green;
        }
      }

      .lineWrapper {
        .line {
          .avatar {
            img,
            div[aria-label="initials"] {
              border: 3px solid $green !important;

              p {
                color: $green;
              }
            }
          }
        }
      }
    }

    &.derailers {
      .TopTitle {
        i {
          color: $yellow;
        }
      }

      .arrowLine {
        background-color: $yellow;

        &:after {
          border-top: 15px solid $yellow;
        }
      }

      .lineWrapper {
        .line {
          .avatar {
            img,
            div[aria-label="initials"] {
              border: 3px solid $yellow !important;

              p {
                color: $yellow;
              }
            }
          }
        }
      }
    }

    .title {
      text-align: center;

      i {
        margin-right: 1rem;
      }
    }

    .TopTitle {
      text-align: center;
      font-weight: 500;
      margin-bottom: 0.6rem;

      i {
        margin-right: 1rem;
      }

      @media (max-width: 486px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i {
          margin: 0;
          margin-bottom: 1rem;
        }
      }
    }

    .subtitle {
      text-align: center;
      max-width: 95%;
      font-weight: 300;
      font-size: 1.1rem;
      margin-top: 0.6rem;

      @media (max-width: 486px) {
        font-size: 1.1rem;
        line-height: 1.5rem;
        max-width: 100%;
      }
    }

    .smallSubtitle {
      text-align: center;
      max-width: 75%;
    }

    .barChartWrapper {
      width: 100%;
      margin: 1.4rem 0;
    }

    .arrowLine {
      position: relative;
      width: 100%;
      height: 8px;
      background: $primary;
      margin: 3rem 0;

      &:after {
        position: absolute;
        content: "";
        left: calc(50% - 7.5px);
        top: 7.5px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $primary;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid $grey4;
      margin-bottom: 4rem;
      padding-bottom: 4rem;
      width: 100%;

      @media (max-width: 940px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &:first-child {
          width: 55%;
          margin-right: 1rem;

          @media (max-width: 940px) {
            width: 100%;
            margin: 0;
          }
        }

        &:last-child {
          width: 45%;
          margin-left: 1rem;

          @media (max-width: 940px) {
            width: 100%;
            margin: 0;
            margin-top: 2rem;
          }
        }

        .titleRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          span {
            font-size: 0.9rem;
            font-weight: 400;
            padding: 0.5rem;
            border-radius: $bradius;

            color: white;

            &.disagree {
              background: $yellow;
            }
            &.superAligned {
              background: $green;
            }
            &.wellAligned {
              background: $darkgrey;
            }
          }
        }

        .subtitle {
          color: $black;
          text-align: left;
        }

        .lineWrapper {
          margin: 3rem 0;
          width: 100%;

          .line {
            position: relative;
            width: 100%;
            height: 2px;
            width: 100%;
            background: $grey4;

            &:after {
              content: "";
              height: 23px;
              width: 2px;
              background: $grey3;
              position: absolute;
              top: -9.5px;
              left: calc(50% - 1.5px);
            }

            .avatar {
              position: absolute;
              top: -24px;
              height: 50px;
              width: 50px;
              margin: 0 auto;
              background: white;
              border-radius: 50%;

              img {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                position: relative;
                padding: 3px;
                border-color: $primary;
              }

              div[aria-label="initials"] {
                border-color: $primary;
              }
            }
          }
        }

        .extremes {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          .column {
            width: 50%;

            &:first-child {
              align-items: flex-start;
              margin-right: 1rem;

              .title,
              .text {
                text-align: left;
              }
            }

            &:last-child {
              align-items: flex-end;
              margin-left: 1rem;
              margin-top: 0;

              .title,
              .text {
                text-align: right;
              }
            }

            .title {
              color: $black;
              margin-bottom: 0.4rem;
            }

            .text {
              color: $grey1;
              font-size: 0.9rem;
              font-weight: 300;
              line-height: 1.4rem;
            }
          }
        }

        .card {
          border: 1px solid $grey4;
          border-radius: $bradius;
          box-shadow: $boxshadow;
          background: white;
          width: 100%;

          &:last-child {
            margin-top: 1.5rem;
          }

          .content {
            padding: 1.6rem;

            .title {
              text-align: left;
              margin-bottom: 1rem;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              i {
                margin-right: 0.6rem;
                color: $primary;
              }

              span {
                margin-right: 0.6rem;

                svg {
                  height: 20px;
                }
                svg path {
                  fill: $primary;
                }
              }
            }

            .text {
              color: $black;
            }
          }

          .footer {
            padding: 0;
            border-top: 1px solid $grey4;

            button {
              margin: 0 auto;
              width: 100%;
              height: 60px;
              color: $primary;
              font-size: 1rem;

              @media (max-width: 412px) {
                font-size: 1rem;
                padding: 0;
              }

              i {
                margin-right: 0.6rem;
              }

              &:hover {
                background: rgba($grey5, 0.2);
              }
            }
          }
        }
      }
    }
  }
}
