@import 'variables.scss';

:local {
  .evaluations {
    width: 100%;
    border-bottom: 1px solid $grey5;
    margin-top: 1rem;

    .noeval {
      color: $grey2;
      padding: 1rem 0;
    }

    .evaluation {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 1rem 0;
      border-top: 1px solid $grey5;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 90%;

        .avatar {
          margin-right: 1.6rem;
        }

        .comment {
          width: 100%;
          margin-top: 0.2rem;
          margin-bottom: 0.6rem;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;

            &.nocomment {
              color: $grey2;
              font-weight: 300;
            }
          }
        }
      }

      .score {
        width: 10%;
        margin-right: 1.8rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0.3rem;

        h5 {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
