@import "variables.scss";

:local {
  .wrapper {
    .positionList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 800px;
      width: 800px;
      max-height: 350px;
      overflow: auto;

      @media (max-width: 960px) {
        max-width: 350px;
        width: 350px;
      }

      .positionRow {
        width: 100%;

        a {
          display: block;
          margin: 0 !important;
          padding: 0;
          width: 100%;
        }

        button {
          margin: 0 !important;
          padding: 0.6rem 1rem !important;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $grey5;
          font-size: 15px;
          transition: $transition;

          @media (max-width: 960px) {
            padding: 0.4rem;
          }

          &:hover {
            background-color: $grey6;
          }

          .name {
            font-weight: 500;
            color: $black;
          }

          .right {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media (max-width: 960px) {
              display: none;
            }

            p {
              font-size: 13px;
              line-height: 13px;
              margin-right: 2rem;
              color: $grey1;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              &:last-child {
                margin-right: 0;
              }

              svg {
                path {
                  fill: $primary;
                }
              }

              &.candidates {
                width: 90px;
              }

              &.invites {
                width: 130px;

                svg path {
                  fill: $grey1;
                }

                &.bold {
                  color: $black;
                  font-weight: 500;

                  svg path {
                    fill: $black;
                  }
                }
              }

              &.profiles {
                width: 170px;

                svg path {
                  fill: $grey1;
                }

                &.complete {
                  color: $green;
                  font-weight: 500;

                  svg path {
                    fill: $green;
                  }
                }

                &.incomplete {
                  color: $yellow;
                  font-weight: 500;

                  svg path {
                    fill: $yellow;
                  }
                }
              }
            }
          }
        }

        &.new {
          position: absolute;
          bottom: -38px;
          background-color: white;
          border-left: 0.5px solid #ededed;
          border-right: 0.5px solid #ededed;
          border-top: 1px solid $grey5;
          border-bottom-left-radius: $bradius2;
          border-bottom-right-radius: $bradius2;
          box-shadow: 0 28px 28px rgba(0, 0, 0, 0.03) !important;
          width: calc(100% + 1px);
          margin-left: -0.5px;

          button {
            justify-content: flex-start;
            color: $primary;
            font-weight: 500;
            padding: 0.6rem 1rem !important;
            border-bottom-left-radius: $bradius2;
            border-bottom-right-radius: $bradius2;

            svg {
              margin-right: 0.6rem;

              path {
                fill: $primary;
              }
            }
          }
        }
      }

      .popup {
        min-width: 400px;
        max-width: 400px;

        button {
          justify-content: center;
          color: $primary;
          width: auto;

          &:hover {
            color: $primary;
          }
        }

        .buttonGroup {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 1.6rem;
          // margin-top: 0.4rem;

          button:first-child {
            margin-right: 0.6rem;
            color: white;
            padding: 0.7rem 1rem !important;

            &:hover {
              background-color: lighten($primary, 3);
              border-color: darken($primary, 0.2);
            }
          }

          button:last-child {
            border: 1px solid #e4e6e8;
            color: $black;
            padding: 0.7rem 1rem !important;
          }
        }
      }
    }
  }
}
