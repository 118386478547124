@import 'variables.scss';

:local {
  .main {
    background: url('~images/backgrounds/error-page-bg.jpg');
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    font-family: proxima-nova;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      max-width: $modalWrapper;
      margin: 0 auto;

      .logo {
        background: url('~images/logo/default/logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 150px;
        height: 50px;
        position: absolute;
        top: 2rem;
      }

      i {
        font-size: 4.6rem;
        color: $grey3;
        margin-bottom: 1rem;
      }

      h1 {
        font-size: 3.2rem;
        line-height: 3.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
        color: black;

        @media (max-width: 595px) {
          font-size: 2.8rem;

          @media (max-width: 420px) {
            font-size: 2.2rem;
          }
        }

        &.second {
          margin: 0;
          color: $primary;
          font-size: 2.2rem;
          line-height: 2.2rem;

          @media (max-width: 595px) {
            font-size: 1.8rem;
          }
        }
      }

      .subtitle {
        font-size: 1.2rem;
        color: $black;
        text-align: center;
        margin: 2rem 0;
        font-weight: 300;
        max-width: 90%;

        @media (max-width: 595px) {
          font-size: 1.1rem;
          line-height: 1.5rem;
        }

        a {
          font-size: 1.2rem;
          color: $black;
          text-decoration: none;
          border-bottom: 1px solid $black;

          @media (max-width: 595px) {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }
      }

      button {
        margin-top: 1rem;
        transform: scale(1.2);
        padding: 1.2rem 8rem !important;

        @media (max-width: 420px) {
          transform: scale(1);
          padding: 1.2rem 6rem !important;
        }
      }
    }
  }
}
