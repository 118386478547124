@import 'variables.scss';

:local {
  .wrapper {
    max-width: $wrapperWidth;
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1150px) {
      padding: 0 1rem;
    }

    &.left {
      align-items: flex-start;
    }

    &.superWide {
      max-width: 1350px;
    }
  }
}
