@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 2rem;

    .disclaimer {
      position: absolute;
      top: -200px;
      text-align: center;
      max-width: 250px;
      color: $grey1;
      font-weight: 300;
      font-size: 0.9rem;
      line-height: 1.3rem;

      @media (max-height: 820px) {
        position: relative;
        top: initial;
        margin-bottom: 1rem;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        margin: 0.2rem;

        &:first-child {
          i {
            margin-right: 0.5rem;
          }
        }

        &:last-child {
          i {
            margin-left: 0.5rem;
            margin-right: 0;
          }
        }
      }
    }
  }
}
