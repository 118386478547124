@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 40%;
    overflow: visible;

    @media (max-width: 560px) {
      width: 100%;
    }

    .mainText {
      margin-bottom: 2rem;
      text-align: center;
      max-width: 560px;
      font-weight: 600;

      @media (max-width: 560px) {
        max-width: 415px;

        @media (max-width: 415px) {
          font-size: 1.4rem;
          line-height: 1.8rem;
          margin-bottom: 0;
          max-width: 320px;
        }
      }

      span {
        color: $primary;
        font-size: 2rem;
        margin: 0.2rem;
      }
    }

    .underTexts {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      width: 125%;
      height: 80px;
      overflow: visible;

      @media (max-width: 560px) {
        width: 86%;

        @media (max-width: 415px) {
          width: 75%;
          margin-top: 0;

          @media (max-width: 350px) {
            width: 85%;
            margin-top: 0;
          }
        }
      }

      .answer {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: inherit;
        width: 200px;

        @media (max-width: 415px) {
          font-size: 0.9rem;
          &:first-child {
            text-align: left;
          }
          &:last-child {
            text-align: right;
          }
        }

        &.fix {
          opacity: 0.99;
        }
      }
    }
  }
}
