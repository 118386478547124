@import "variables.scss";

:local {
  .main {
    margin-bottom: 6rem;

    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 3rem;
      padding-bottom: 3rem;

      @media (max-width: 750px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .left {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 3rem;

        .description {
          font-weight: 300;
          font-size: 1.1rem;
          line-height: 1.6rem;
        }

        @media (max-width: 750px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 2rem;
        }

        .avatarWrapper {
          margin-right: 2rem;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .name {
            text-align: center;
          }

          .short {
            font-size: 1.1rem;
            line-height: 1.6rem;
            font-weight: 300;
          }
        }
      }

      .right {
        flex: 1;

        .description {
          font-weight: 300;
          font-size: 1.1rem;
          line-height: 1.6rem;
        }
      }
    }
  }
}
