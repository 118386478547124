@import "variables.scss";

:local {
  .wrapper {
    max-width: $modalWrapper;
    margin: 0 auto;

    .modalHead {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 1rem;
      margin-bottom: 3rem;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          width: 48px;
          height: 48px;
          background-color: $blue;
          border-radius: $bradius3;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.2rem;

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: $primary;
            }
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 26px;
        color: $darkgrey;
        font-weight: 300;
        margin-top: 1rem;
        max-width: 70%;
      }
    }

    .inputs {
      max-width: 700px;

      .inputRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      bottom: 4rem;

      @media (max-height: 710px) {
        position: relative;
        bottom: 0;
        margin-top: 2rem;
      }

      &.relative {
        position: relative;
        bottom: 0;
        margin-top: 2rem;
      }

      button:first-child {
        margin-right: 1rem;
      }

      button {
        box-shadow: 0px 1.5px 3px 0px rgba(black, 0.08);
      }
      .loading {
        i {
          animation: rotating 2s linear infinite;
        }
      }
    }
  }

  .searchResults {
    background: white;
    position: absolute;
    top: 56px;
    z-index: 100;
    width: 100%;
    max-height: 288px;
    overflow: auto;
    border: 1px solid $grey5;
    box-shadow: $lightboxshadow;
    border-radius: $bradius;
    margin-bottom: 2rem;

    &::-webkit-scrollbar {
      width: 5px;
      background: rgba($grey5, 0.4);
    }

    &::-webkit-scrollbar-thumb {
      background: $grey4;
      border-radius: 2px;
    }

    .searchWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0.8rem 1rem;
      color: $darkgrey;
      border-bottom: 1px solid $grey5;

      .avatarWrapper {
        margin-right: 1rem;

        .icon {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: $blue;

          svg {
            path {
              fill: $primary;
            }
          }
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .userName {
          font-weight: 500;
          color: black;
          font-size: 14px;
          line-height: 14px;
        }

        .userEmail {
          font-weight: 300;
          color: $grey1;
          font-size: 14px;
          line-height: 14px;
          margin-top: 0.4rem;
        }
      }

      &:hover {
        background: rgba($grey5, 0.4);
        color: $black;

        .userName {
          color: $black;
        }
      }
    }
  }
}
