@import "variables.scss";

:local {
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      font-size: 0.9rem;
      color: $grey2;
    }

    .selectWrapper {
      max-width: 300px;
      margin-left: 1rem;
    }
  }

  .candidate {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 4rem;

    @media (max-width: 1238px) {
      margin: 2rem 0;
    }

    .right {
      margin-left: 1rem;

      .name {
        margin: 0;
      }

      .email {
        color: $grey1;
      }
    }
  }

  .scoreContainer {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: $wrapperWidth;
    gap: 2rem;
    @media (max-width: 768px) {
      gap: 1rem;
    }

    .deviationPopover {
      max-width: 300px;
      z-index: 999;
      background: white;
      border-radius: $bradius;

      .highDev {
        padding: 1.2rem 1.2rem 0.6rem 1.2rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 999;

        h5 {
          font-weight: 600;
          margin-bottom: 0.4rem;
          color: black;
        }

        p {
          font-weight: 300;
          text-align: left;
          color: black;
        }

        .image {
          margin-top: 1rem;

          svg {
            width: 250px;
          }
        }
      }
    }

    .sectionTitle {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1rem;
      padding-top: 2rem;
      border-top: 1px solid $grey5;
      width: 100%;
      position: relative;
      @media (max-width: 768px) {
        flex-grow: 1;
      }

      .bigScoreWrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0.8rem;
        position: absolute;
        right: 0;
        top: 0;
        min-width: 105px;

        .legend {
          margin-right: 0.6rem;
          font-size: 0.75rem;
          line-height: 1rem;
          font-weight: 300;
          text-align: right;
          color: $grey1;
        }

        .bigScore {
          width: 65px;
          padding: 0.6rem;
          border: 1px solid $grey5;
          border-radius: $bradius2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h5 {
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 700;
            margin-bottom: 0.2rem;
          }

          p {
            font-size: 12px;
            font-weight: 300;
            line-height: 12px;
            color: $grey1;
          }
        }
      }

      &:first-child {
        border: 0;
      }

      .dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 1.8rem;
        margin-top: 0.5rem;

        &.primary {
          background: $primary;
        }

        &.green {
          background: $green;
        }

        &.yellow {
          background: $yellow;
        }

        &.red {
          background: $red;
        }

        &.purple {
          background: $purple;
        }
      }

      .text {
        width: 100%;
        max-width: $wrapperWidth;
        position: relative;

        .deviation {
          position: relative;
          font-size: 0.8rem;
          line-height: 0.8rem;
          padding: 0.4rem 0.6rem 0.4rem 1.6rem;
          background: $blue;
          border-radius: 25px;
          color: $primary;
          font-weight: 600;
          margin: 0.6rem 0;
          max-width: 122px;
          cursor: default;

          &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $primary;
            position: absolute;
            top: 33%;
            left: 0;
            margin-left: 0.6rem;
          }
        }

        .scoring {
          width: 100%;
          margin-top: 1rem;

          .commentsWrapper {
            margin-top: 1rem;
            max-width: 575px;
          }
        }
      }

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .desc {
        margin-top: 0.8rem;
        font-weight: 300;
        max-width: 85%;
        color: black;
      }
    }

    .error {
      margin-left: 2.9rem;
      color: $grey1;
      padding-left: 1rem;
      border-left: 2px solid black;
    }

    .section {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      @media (max-width: 1225px) {
        max-width: 90%;
      }
      // opacity: 0.5;
      // filter: blur(1px);
      // transition: $transition;
      //
      // &:hover {
      //   opacity: 1;
      //   filter: blur(0);
      // }

      &.full {
        margin-top: 0;

        .infoSection {
          display: none;
        }

        .rightWrapper {
          flex-direction: column;

          .right {
            padding-left: 3rem;
            margin-bottom: 3rem;
          }
        }
      }

      @media (max-width: 1225px) {
        margin: 4rem 0;
      }

      @media (max-width: 768px) {
        margin: 0 0 1rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .number {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 12px;
        font-weight: 500;
      }

      &.primary {
        .number {
          background: $primary;
        }
      }

      &.green {
        .number {
          background: $green;
        }
      }

      &.yellow {
        .number {
          background: $yellow;
        }
      }

      &.red {
        .number {
          background: $red;
        }
      }

      &.purple {
        .number {
          background: $purple;
        }
      }

      .rightWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: 1225px) {
          flex-direction: column;
        }
      }

      .right {
        width: 100%;
        max-width: $wrapperWidth;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .type {
          font-size: 0.9rem;
          line-height: 0.9rem;
          color: $grey1;
        }

        .titleRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 89%;
          }

          .title {
            font-size: 1.1rem;
            line-height: 1.6rem;
            font-weight: 600;
            margin: 0.2rem 0;
          }

          .deviation {
            position: relative;
            font-size: 0.8rem;
            line-height: 0.8rem;
            padding: 0.4rem 0.6rem 0.4rem 1.6rem;
            background: $blue;
            border-radius: 25px;
            color: $primary;
            font-weight: 600;
            margin: 0.6rem 0;
            cursor: default;

            &::before {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: $primary;
              position: absolute;
              top: 33%;
              left: 0;
              margin-left: 0.6rem;
            }
          }

          .bigScoreWrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 0.8rem;

            .legend {
              margin-right: 0.6rem;
              font-size: 0.75rem;
              line-height: 1rem;
              font-weight: 300;
              text-align: right;
              color: $grey1;
            }

            .bigScore {
              width: 65px;
              padding: 0.6rem;
              border: 1px solid $grey5;
              border-radius: $bradius2;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              h5 {
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 700;
                margin-bottom: 0.2rem;
              }

              p {
                font-size: 12px;
                font-weight: 300;
                line-height: 12px;
                color: $grey1;
              }
            }
          }
        }

        .subtitle {
          font-size: 15px;
          font-weight: 300;
          color: $darkgrey;
          max-width: 85%;
        }

        .label {
          margin-top: 0.6rem;
          margin-bottom: 1.2rem;
          border-radius: $bradius2;
          background: $grey3;
          font-size: 12px;
          font-weight: 400;
          color: white;
          display: block;
          height: 24px;
          padding: 0 0.6rem;

          &.green {
            background: $green;
          }

          &.yellow {
            background: $yellow;
          }

          &.primary {
            background: $primary;
          }
        }
      }

      .infoSection {
        width: 100%;
        padding-left: 3rem;

        .multiple {
          width: 100%;
          margin-bottom: 2rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .left {
            width: 65.5%;
          }

          .right {
            width: 35%;
          }

          @media (max-width: 1225px) {
            flex-direction: column;

            .left,
            .right {
              width: 100%;
            }
          }

          .title {
            font-size: 1.1rem;
            line-height: 1.6rem;
            font-weight: 600;
            margin: 0.2rem 0;
          }

          .subtitle {
            font-size: 15px;
            font-weight: 300;
            color: $darkgrey;
            max-width: 90%;
          }

          .label {
            margin-top: 0.6rem;
            border-radius: $bradius2;
            background: $grey3;
            display: block;
            height: 24px;
            padding: 0 0.6rem;
            width: initial;
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
            margin-bottom: 1rem;

            p {
              font-size: 12px;
              font-weight: 400;
              color: white;
            }

            &.yellow {
              background: $yellow;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .graphWrapper {
        position: relative;
        width: 35%;
        padding: 2rem 0;
        border: 1px solid $grey5;
        border-radius: $bradius3;
        margin-left: 2rem;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 1rem;
        box-shadow: 0px 2px 2px 0px #00000008;
        z-index: 9;
        transition: 400ms;

        .overlay {
          display: none;
          position: absolute;
          z-index: 15;
          bottom: -10px;
          left: -1px;
          width: calc(100% + 2px);
          height: 200px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 39.51%
          );
        }

        &.overlay {
          height: 400px;

          .content {
            height: 400px;
            width: 100%;
            overflow-y: hidden;
          }

          @media (min-width: 1225px) {
            .overlay {
              display: block;
            }
          }

          &:hover {
            height: auto;

            .content {
              height: auto;
              overflow-y: auto;
            }

            .overlay {
              display: none;
            }
          }
        }

        .content {
          height: 100%;
          width: 100%;
          z-index: 15;
          padding: 0 1.4rem;

          .multiple {
            width: 100%;
            margin-bottom: 1.6rem;

            h5 {
              margin-bottom: 2.4rem;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              span {
                margin-left: 0.6rem;
                border-radius: $bradius2;
                background: $grey3;
                font-size: 12px;
                font-weight: 400;
                color: white;
                display: block;
                height: 24px;
                padding: 0 0.6rem;

                &.yellow {
                  background: $yellow;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        @media (max-width: 1225px) {
          max-width: $wrapperWidth;
          width: 90%;
          margin-left: 0;
          margin-top: 2rem;
        }

        .graph {
          margin-bottom: 1rem;
          width: 100%;
        }

        h5 {
          margin-bottom: 0.6rem;
        }

        .behaviour {
          margin-bottom: 0.6rem;
          font-size: 14px;
          line-height: 24px;
          color: $grey1;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .sectionStyle {
    display: flex;
    gap: 16px;
  }

  .numberStyle {
    width: 30px;
    margin-top: 16px;
  }
}
