@import 'variables.scss';

:local {
  .main {
    margin-top: 1rem;

    @media (max-width: 1080px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    h2 {
      margin-bottom: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .checkWrapper {
        width: 41px;
        height: 41px;
        border-radius: 50%;
        border: 1px solid $grey3;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;

        i {
          font-size: 1rem;
          color: $grey3;
        }
      }
    }

    .subtitle {
      font-size: 18px;
      line-height: 26px;
      color: $darkgrey;
      font-weight: 300;
      margin-top: 1rem;
      max-width: 100%;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      @media (max-width: 725px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .col {
          width: 100% !important;

          &:first-child {
            padding: 0;
            margin-bottom: 2rem;
          }

          &:last-child {
            display: flex;
            justify-content: flex-start !important;
            align-items: center;
          }
        }
      }

      .col {
        width: 30%;

        &:first-child {
          width: 70%;
          padding-right: 4rem;
        }

        &:last-child {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          a {
            text-decoration: none;
            border: none;
          }
        }
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;

      button:first-child {
        margin-right: 1rem;
      }
    }
  }
}
