@import "variables.scss";

:local {
  .wrapper {
    position: relative;

    textarea {
      position: relative;
      min-width: 300px;
      outline: none;
      border: 1px solid $grey3;
      border-radius: $bradius;
      color: $black;
      font-weight: 400;
      background: rgba($grey5, 0.4);
      font-size: 1rem;
      line-height: 1.4rem;
      font-family: proxima-nova;
      padding: 0.6rem 1rem;

      &::placeholder {
        font-size: 0.9rem;
        font-family: proxima-nova;
        color: $grey2;
        font-weight: 400;
      }

      &:focus {
        border-color: $grey2;
      }
    }

    &.noBorder > textarea {
      border: 0 !important;
    }

    &.paddingRight {
      textarea {
        padding-right: 100px;
      }
    }

    &.active {
      textarea {
        border: 2px solid $primary !important;
      }
    }

    &.small {
      textarea {
        border-radius: $bradius;
        min-width: 300px;
        height: 45px;
        line-height: 45px;
        text-indent: 10px;
        font-size: 1rem;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);

        &::placeholder {
          font-size: 1rem;
        }
      }
    }

    &.white {
      textarea {
        background: white;
        border: 1px solid $grey4;

        &:focus {
          border-color: $grey2;
        }
      }
    }

    &.fullWidth {
      width: 100%;

      textarea {
        width: 100%;
      }
    }

    &.large {
      textarea {
        height: 57px;
      }
    }
  }

  .fieldStatus {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin: 0.6rem 0;
    padding-left: 0.2rem;

    &.success {
      color: $green;
    }

    &.warning {
      color: $yellow;
    }

    &.error {
      color: $red;
    }
  }
}
