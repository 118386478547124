@import "variables.scss";

:local {
  .wrapper {
    display: grid;
    grid-template-columns: minmax(600px, 40%) 1fr;
    position: relative;

    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
    }

    // &::after {
    //   content: "";
    //   width: 40%;
    //   min-width: 640px;
    //   height: 150%;
    //   position: absolute;
    //   background: #e3f7eb;
    //   right: 0;
    //   top: 0;
    //   z-index: -1;
    // }

    @media (max-width: 1080px) {
      flex-direction: column;
      min-height: auto;
    }

    .left {
      // width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      min-height: 100vh;
      overflow: hidden;
      // max-width: 900px;
      padding: 2rem 8rem;

      @media (max-width: 1300px) {
        padding: 4rem;
      }

      @media (max-width: 1080px) {
        padding: 8rem 4rem;
        // max-width: 100%;
        // width: 100%;
      }

      @media (max-width: 550px) {
        padding: 4rem 2rem;
        padding-top: 6rem;
        // min-height: 52vh;
      }

      .title {
        font-weight: 700;
        // max-width: 470px;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 2.4rem;

        @media (max-width: 1080px) {
          font-size: 36px;
          line-height: 42px;
          margin-bottom: 1.6rem;
        }
      }

      .subtitle {
        color: $grey1;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 3rem;
        max-width: 500px;

        @media (max-width: 1080px) {
          font-size: 0.9rem;
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 600px;

        @media (max-height: 800px) {
          position: fixed;
          z-index: 1;
          bottom: 1rem;
          width: 100%;
          max-width: 408px;

          @media (max-width: 1280px) {
            max-width: 504px;
          }

          @media (max-width: 1080px) {
            max-width: 600px;
          }
        }

        a {
          border-bottom: none;
        }

        button {
          &:first-child {
            margin-right: 2rem;
          }
        }
        .noBackgroundGrey {
          color: $grey1;
          font-size: 0.9rem;
        }
      }
    }

    .right {
      // width: 60%;
      // min-width: 640px;
      // height: 100vh;
      background: #e3f7eb;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &.green {
        background: #e3f7eb;
      }

      &.yellow {
        background: #fcf6ef;
      }

      &.blue {
        background: #eff9ff;
      }

      &.grey {
        background: $grey6;
      }

      @media (max-width: 1080px) {
        width: 100%;
        max-height: 300px;
      }

      .img {
        z-index: 1000;

        img {
          height: 500px;

          @media (max-width: 1080px) {
            height: 200px;
          }
        }
      }
    }
  }
}
