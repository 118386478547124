@import "variables.scss";

:local {
  .wrap {
    padding: 1rem;
    width: 530px;

    @media (max-width: 800px) {
      width: 100%;
      max-width: 350px;

      @media (max-width: 450px) {
        width: 100%;
        max-width: 250px;
      }
    }

    .url {
      max-width: 440px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 1rem;
      font-weight: 300;
      color: $black;

      @media (max-width: 800px) {
        width: 100%;
        max-width: 250px;

        @media (max-width: 450px) {
          width: 100%;
          max-width: 150px;
        }
      }
    }

    p {
      font-size: 15px;
      line-height: 15px;
      margin-right: 1rem;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.marginTop {
        margin-top: 1rem;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      button {
        font-size: 15px;
        line-height: 15px;
        color: $grey1;
      }
    }

    .generateLink {
      button {
        font-size: 15px;
        font-weight: 500;
        color: $primary;

        svg {
          width: 15px;
          height: 15px;

          path {
            fill: $primary;
          }
        }
      }
    }
  }

  .triggerButton {
    button {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .tooltipPopover {
    width: 118px !important;
    text-align: center;

    span {
      width: 100%;
      text-align: center;
    }
  }
}
