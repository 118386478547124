@import 'variables.scss';

:local {
  .modalWrapper {
    max-width: $modalWrapper;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 7rem;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        i {
          z-index: 100;
          background: white;
          border-radius: 50%;
          margin-left: 0.8rem;
          font-size: 2rem;
          color: $green;
        }
      }
    }

    h2,
    h4 {
      text-align: center;
    }

    h2 {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.2rem;
      max-width: 70%;
    }

    h4 {
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 300;
      margin-top: 1rem;
      max-width: 80%;
    }

    .buttonGroup {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;

      @media (max-width: 475px) {
        flex-direction: column;
        width: 100%;
      }

      button {
        @media (max-width: 475px) {
          width: 90%;
        }

        i {
          font-size: 1rem;
        }
      }

      button:first-child {
        margin-right: 2rem;

        @media (max-width: 475px) {
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
