@import "variables.scss";

:local {
  .main {
    background-color: white;
    border-bottom: 0.5px solid $grey4;
    position: sticky;
    top: 0;
    margin-left: -5rem;
    width: 100vw;
    margin-top: -4rem;
    padding-left: 5rem;
    padding-right: calc(256px + 128px);
    z-index: 9993;
    min-height: 76px;

    &.noSidebar {
      padding: 0;
      margin-left: 0;
      width: 100%;
      border: 0;

      &:before {
        position: absolute;
        content: "";
        height: 100%;
        width: 300vw;
        left: -100%;
        right: 0;
        background-color: white;
        z-index: -1;
      }

      &:after {
        position: absolute;
        content: "";
        height: 0.5px;
        width: 300%;
        left: -100%;
        right: 0;
        background-color: $grey5;
      }
    }

    @media (max-width: 960px) {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    &.collapsed {
      width: 100%;
      margin-left: 0;
      padding: 0;
      border-bottom: 0;

      // &:before {
      //   position: absolute;
      //   content: "";
      //   height: 100%;
      //   width: 300vw;
      //   left: -100%;
      //   right: 0;
      //   background-color: white;
      //   z-index: -1;
      // }

      &:after {
        position: absolute;
        content: "";
        height: 0.5px;
        width: 300%;
        left: -100%;
        right: 0;
        background-color: $grey5;
      }
    }

    @media (min-width: 1720px) {
      width: 100%;
      margin-left: 0;
      padding: 0;
      border-bottom: 0;

      // &:before {
      //   position: absolute;
      //   content: "";
      //   height: 100%;
      //   width: 300vw;
      //   left: -100%;
      //   right: 0;
      //   background-color: white;
      //   z-index: -1;
      // }

      &:before {
        position: absolute;
        content: "";
        height: 100%;
        width: 300%;
        left: -100%;
        top: 0;
        background-color: white;
        z-index: -2;
      }

      &:after {
        position: absolute;
        content: "";
        height: 0.5px;
        width: 300%;
        left: -100%;
        right: 0;
        background-color: $grey5;
        z-index: -1;
      }
    }

    &.noBreadcrumbs {
      margin-bottom: 8rem;

      &.noMargin {
        margin-bottom: 4rem;
      }
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 76px;

      @media (max-width: 1128px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 1rem 0;
      }

      @media (max-width: 1080px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      @media (max-width: 650px) {
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
      }
    }

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 1128px) {
        margin-bottom: 0.5rem;
      }

      @media (max-width: 650px) {
        margin-bottom: 1rem;
      }

      .title {
        font-size: 15px;
        line-height: 15px;
        min-width: 100px;
        max-width: fit-content;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .subtitle {
        font-size: 15px;
        line-height: 15px;
        margin-left: 1rem;
        padding-left: 1rem;
        border-left: 1px solid $grey5;
        color: $grey2;
        margin-right: 1rem;
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: fit-content;
      text-wrap: nowrap;

      @media (max-width: 650px) {
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
      }

      a {
        border: none;
      }

      button,
      a {
        margin-left: 1rem;
        text-decoration: none;

        &:first-child {
          margin-left: 0;
        }

        @media (max-width: 650px) {
          margin: 0.2rem !important;
        }
      }

      a button {
        margin-left: 0;
      }
    }
  }

  .breadcrumbsMain {
    background-color: white;
    border-bottom: 0.5px solid $grey4;
    margin-left: -5rem;
    width: 100vw;
    padding-left: 5rem;
    padding-right: calc(256px + 2rem);
    z-index: 99999;
    margin-bottom: 1rem;

    @media (max-width: 1080px) {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    &.noSidebar {
      margin: 0;
      padding: 0;

      &:after {
        position: absolute;
        content: "";
        height: 0.5px;
        width: 300%;
        left: -100%;
        right: 0;
        background-color: $grey5;
      }
    }

    &.collapsed {
      width: 100%;
      margin-left: 0;
      padding: 0;

      &:after {
        position: absolute;
        content: "";
        height: 0.5px;
        width: 300%;
        left: -100%;
        right: 0;
        background-color: $grey5;
      }
    }

    @media (max-width: 1720px) {
      margin-top: 0;
    }

    @media (min-width: 1720px) {
      width: 100%;
      margin-left: 0;
      padding: 0;
      margin-top: 4rem;

      &:after {
        position: absolute;
        content: "";
        height: 0.5px;
        width: 300%;
        left: -100%;
        right: 0;
        background-color: $grey5;
      }
    }
  }

  .breadcrumbs {
    padding: 0.6rem 0;
  }
}
