@import "variables.scss";

:local {
  .wrapper {
    display: flex;
    flex-direction: column;

    .teamName {
      text-align: center;
      margin-top: 4rem;

      h4 {
        color: $black;

        a {
          margin: 0 0.5rem;
          color: $green;
          border: none;
        }
      }
    }

    .sectionHeader {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid $grey5;
      padding-bottom: 1rem;
      margin: 1rem 0;

      .logo {
        width: 40px;
        height: 40px;
        margin-right: 1rem;

        svg {
          height: 100%;
          width: 100%;
        }
      }

      .title {
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-bottom: 0.4rem;
      }

      .subtitle {
        font-size: 14px;
        line-height: 14px;
        font-weight: 300;
        color: $darkgrey;
      }
    }

    .inviteTeam {
      min-height: 490px;
      width: 850px;
      margin: 0 auto;
      margin-bottom: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $blue;
      border-radius: $bradius3;

      @media (max-width: 850px) {
        width: auto;
        max-width: 850px;
      }

      a {
        border: none;
      }

      h4 {
        margin-top: 1.2rem;
        margin-bottom: 1rem;
        font-weight: 500;
        max-width: 55%;
        text-align: center;
      }

      p {
        margin-bottom: 1.2rem;
        font-size: 16px;
        color: $grey1;
        font-weight: 300;
        max-width: 55%;
        text-align: center;
      }

      @media (max-width: 450px) {
        h4,
        p {
          max-width: 80%;
        }
      }
    }

    .customTextWrapper {
      width: 100%;
      margin: 0 auto;
      padding: 0 1rem;
      padding-top: 4rem;
      padding-bottom: 4rem;
      margin-top: 4rem;
      background-color: $primary;
      background-attachment: fixed;
      background-position: right top;
      background-repeat: no-repeat;
    }

    .bigButton {
      border: 0;
      margin: 0 auto;
      margin-bottom: 4rem;

      button {
        padding: 1.8rem 3rem 1.8rem 3rem;
        font-size: 1.4rem;

        i {
          margin-right: 1rem;
        }
      }
    }
  }
}
