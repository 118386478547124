@import "variables.scss";

:local {
  .adminSection {
    max-width: $wrapperWidth;
    padding-right: 2rem;

    .sectionHeader {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-bottom: 1px solid $grey5;
      padding-bottom: 1.4rem;
      margin: 1.4rem 0;

      .title {
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-bottom: 0.4rem;
      }

      .subtitle {
        font-size: 14px;
        line-height: 14px;
        font-weight: 300;
        color: $darkgrey;
      }
    }

    .processBar {
      width: 100%;
      margin: 4rem 0;
      margin-bottom: 6rem;
    }

    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 3rem;
      margin-bottom: 2rem;

      @media (max-width: 1380px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .left {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 5rem;

        @media (max-width: 1380px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 2rem;
        }

        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.9rem;
          color: #454754;
          padding: 0.4rem 0.8rem !important;
          text-decoration: none;
          border-bottom: none;
        }

        .avatarWrapper {
          margin-right: 2rem;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .position {
            font-size: 15px;
            line-height: 1rem;
            margin-bottom: 0.4rem;
            color: $primary;
          }

          .name {
            text-align: center;
          }

          .short {
            font-size: 1.1rem;
            line-height: 1.6rem;
            font-weight: 300;
            margin-top: 1rem;
          }

          .incomplete {
            font-size: 14px;
            line-height: 14px;
            padding: 0.4rem 0.8rem;
            border-radius: $bradius2;
            color: white;
            background-color: $yellow;
            margin-top: 0.6rem;
          }
        }
      }

      .right {
        min-width: 220px;

        .buttonGroup {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @media (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-right: 2rem;
          }

          button {
            border: none;
            margin-bottom: 0.4rem;
          }

          .dropdownButton {
            &:last-child {
              margin-bottom: 0;
            }

            .button {
              &.empty {
                button {
                  color: $grey2;

                  svg path {
                    fill: $grey2;
                  }
                }
              }

              button {
                .downArrow {
                  transform: rotate(90deg);
                  margin-left: 0.6rem;
                }

                .icon {
                  margin-left: 0.6rem;

                  svg path {
                    fill: $primary;
                  }

                  &.edit {
                    svg path {
                      fill: $grey2;
                    }
                  }
                }
              }
            }

            .linkedIn {
              li:first-child {
                button {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                }

                span {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }

                .external {
                  i {
                    font-size: 11px;
                    margin: 0;
                    color: $grey2;
                  }
                }
              }

              li:last-child {
                border-top: 0.5px solid $grey5;

                svg path {
                  fill: $red;
                }
              }
            }

            .link {
              width: 220px;
              position: relative;

              input {
                width: 100%;
                font-size: 14px;
                line-height: 14px;
                height: 36px;
                text-indent: 8px;
                padding-right: 40px;
                border: none;

                &::placeholder {
                  font-size: 14px;
                  color: $grey1;
                }
              }

              &.error {
                input {
                  border: 1px solid $red;
                }

                .saveButton {
                  border-color: $red;
                }
              }

              .saveButton {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                background-color: $grey3;
                border-top-right-radius: $bradius;
                border-bottom-right-radius: $bradius;
                transition: $transition;
                border: 1px solid transparent;
                border-left: none;

                &.active {
                  background-color: $primary;

                  &:hover {
                    background-color: rgba($primary, 0.9);
                  }
                }

                button {
                  height: 100%;
                  width: 35px;
                }

                svg {
                  height: 25px;
                  width: 25px;
                }

                svg path {
                  stroke: white;
                  stroke-width: 1;
                }
              }

              .linkedInError {
                position: absolute;
                top: calc(100% + 0.2rem);
                left: 0;
                color: white;
                font-size: 13px;
                line-height: 17px;
                background: $red;
                border-radius: $bradius2;
                padding: 0.4rem;
              }

              &.phone {
                width: 250px;

                .PhoneInputCountry {
                  top: 11px !important;
                }

                .phoneNumber {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  & > div {
                    width: 100%;
                    padding-left: 12px;
                  }

                  .phoneInput input {
                    border-radius: 4px;
                    min-width: 300px;
                    height: 40px;
                    padding-left: 32px;
                    font-size: 14px;
                    line-height: 20px;
                    width: 100%;
                    min-width: 100%;
                    text-indent: 1rem;
                    border-color: $grey4;
                  }
                }
              }
            }
          }
        }
      }

      .attachmentsHeading {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .attachmentsWrapper {
        max-height: 85px;
        height: 85px;
        position: relative;

        .tooltip {
          width: calc(100% + 10px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
          background: #303030;
          color: white;
          font-size: 13px;
          padding: 0.5rem 0.7rem;
          border: none;
          border-radius: $bradius2;
          -webkit-animation: anvil 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9)
            forwards;
          margin-top: 0.2rem;
          margin-left: -10px;
          opacity: 0;
          position: absolute;
          transition: 200ms;

          &.show {
            opacity: 1;
          }
        }
      }

      .attachments {
        margin-top: 0.4rem;
        border: 1px solid $grey5;
        border-radius: $bradius3;
        margin-left: -10px;
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 4px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $grey2;
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

        .tooltipPopover {
          width: 118px !important;
          text-align: center;

          span {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
    .summary {
      p {
        font-size: 1rem;
        font-weight: 400;
        font-family: proxima-nova;
        color: $black;
        line-height: 1.5rem;
        min-height: 1.5rem;
      }
    }
    .summary {
      margin: 4rem 0;
      // max-width: 900px;
    }

    .section {
      margin-bottom: 6rem;
    }

    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 1rem;
      margin-bottom: 4rem;

      @media (max-width: 1312px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (max-width: 1070px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 860px) {
        grid-template-columns: 1fr;
      }

      .card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border: 1px solid $grey5;
        border-radius: $bradius3;
        // max-width: 215px;
        width: 100%;
        min-height: 150px;
        box-shadow: $boxshadow;
        transition: $transition;
        padding: 1.4rem;
        position: relative;
        // margin-right: 1rem;
        // margin-bottom: 1rem;

        &.addNew {
          border: 1px dashed $grey3;
          background-color: $grey6;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none;

          &:hover {
            box-shadow: none;
          }
        }

        .deleteIcon {
          position: absolute;
          top: 1rem;
          right: 1rem;
          opacity: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            width: 100%;
            height: 100%;
          }

          &:hover {
            svg path {
              fill: $black;
            }
          }

          svg {
            width: 16px;
            height: 16px;

            path {
              fill: $grey1;
            }
          }
        }

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          width: 100%;

          .icon {
            width: 40px;
            height: 40px;
            border-radius: $bradius3;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $grey5;

            svg path {
              fill: $darkgrey;
            }

            &.green {
              background: rgba($green, 0.1);

              svg path {
                fill: $green;
              }
            }

            &.primary {
              background: rgba($primary, 0.1);

              svg path {
                fill: $primary;
              }
            }

            &.yellow {
              background: rgba($yellow, 0.1);

              svg path {
                fill: $yellow;
              }
            }

            &.none {
              svg {
                opacity: 0;
                transition: $transition;
              }
            }
          }

          .iconSelect {
            padding: 1rem;

            .colors {
              display: flex;
              justify-content: space-between;
              align-items: top;

              .color {
                height: 40px;
                width: 40px;
                border-radius: $bradius3;
                background-color: rgba($primary, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;

                &.active,
                &:hover {
                  border: 1px solid $primary;
                }

                svg {
                  height: 20px;
                  width: 20px;
                }

                &.primary {
                  background-color: rgba($primary, 0.1);

                  svg path {
                    fill: $primary;
                  }
                }

                &.green {
                  background-color: rgba($green, 0.1);

                  svg path {
                    fill: $green;
                  }
                }

                &.yellow {
                  background-color: rgba($yellow, 0.1);

                  svg path {
                    fill: $yellow;
                  }
                }
              }
            }

            .icons {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              gap: 0.6rem;
              margin-top: 1rem;

              .icon {
                height: 28px;
                width: 28px;
                border-radius: $bradius2;
                border: 1px solid $grey5;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;

                svg path {
                  fill: $grey1;
                }

                &:hover {
                  border-color: $primary;

                  svg path {
                    fill: $primary;
                  }
                }

                &.active {
                  border-color: $primary;

                  svg path {
                    fill: $primary;
                  }
                }
              }
            }

            .buttons {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 1rem;
            }
          }
        }

        .dragHandle {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          opacity: 0;
          transition: $transition;

          svg {
            height: 14px;

            path {
              fill: $grey4;
            }
          }
        }

        .bottom {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .title {
            font-family: proxima-nova;
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: 600;
            padding: 0;
            margin: 0;
            text-indent: 0;
            border: none;
            background: none;
            margin-bottom: 0.4rem;
            resize: none !important;
            width: 100%;

            &::placeholder {
              font-size: 1.4rem;
              line-height: 1.8rem;
              font-weight: 600;
              color: $grey3;
            }
          }

          .desc {
            font-family: proxima-nova;
            font-size: 15px;
            line-height: 18px;
            font-weight: 400;
            color: $darkgrey;
            border: none;
            padding: 0;
            margin: 0;
            resize: none;

            &::placeholder {
              font-size: 15px;
              line-height: 18px;
              font-weight: 400;
              color: $grey3;
            }
          }
        }
      }
    }

    .notesWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 4rem;
      width: 100%;

      .notes {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .note {
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          border-bottom: 0.5px solid $grey5;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
          }

          &:hover {
            .actions {
              opacity: 1;
            }
          }

          .avatar {
            width: 30px;
            height: 30px;
            background-color: $grey5;
            border-radius: 50%;
            margin-right: 2rem;
          }

          .comment {
            margin-right: 3rem;
            max-width: 85%;
            width: 100%;

            p {
              font-size: 14px;
              line-height: 22px;
              font-weight: 300;
            }

            textarea {
              font-family: proxima-nova;
              font-size: 14px;
              line-height: 22px;
              font-weight: 300;
              padding: 0.6rem;
              border: 1px solid $grey5;
              border-radius: $bradius2;
              text-indent: 0;
              width: 100%;
              height: initial;
              resize: none;
            }
          }

          .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            opacity: 0;

            button {
              margin-right: 1rem;
              font-size: 14px;
              padding: 4px;

              &:hover {
                background: none;
              }

              svg path,
              svg rect {
                fill: $grey1;
              }

              &:hover {
                svg path,
                svg rect {
                  fill: $darkgrey;
                }
              }

              &:last-child {
                margin-right: 0;

                &:hover {
                  svg path,
                  svg rect {
                    fill: $red;
                  }
                }
              }
            }
          }
        }
      }

      .createNote {
        width: 100%;
        max-width: 600px;
      }

      .bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }
  }
}
